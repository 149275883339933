/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILURE,
  SIGNIN, SIGNIN_SUCCESS, SIGNIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE_SUCCESS,
} from 'src/actions/accountActions';


const initialState = {
  user: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {

    case SIGNUP_SUCCESS: {
      return produce(state, (draft) =>{
          draft.user = null;
      })
    }


    case SIGNIN: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case SIGNIN_SUCCESS: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case SIGNIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case UPDATE_PROFILE_SUCCESS: {
      const { userData } = action.payload;

      return produce(state, (draft) => {
        //draft.user = user;

        draft.user.name = userData.name;
        draft.user.mobile = userData.mobile;
        draft.user.dob = userData.dob;
        draft.user.state = userData.state;
        draft.user.country = userData.country;       
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
