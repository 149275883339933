import React, { useEffect, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import { dateFormatter, moneyFormatter } from "src/utils/formatUtils";
import styles from "./OrderDetailsStyles";
import { getOrderDocTypeString } from "src/utils/constantUtils";
import { ORDER_DOC_TYPES } from "src/constants";

// Create styles
Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
},
);

const OrderPDF = ({ docType, ordersData, logo }) => {

  // const itemsData = ordersData?.items ? ordersData?.items : [];

  const itemsData = (docType === ORDER_DOC_TYPES.ORDER) ? 
        (ordersData?.items ? ordersData?.items : []) : 
        (ordersData?.orderItems ? ordersData?.orderItems : [])

  console.log("items", docType, itemsData, logo);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ marginLeft: "10%", marginRight: "10%" }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View
              style={{ marginLeft: "25px", marginTop: "50px" }}
            >
              {logo ? (
                <Image src={{ uri: logo }} style={{ height: 50 }} />
              ) : (
                <Image src={"/static/logo1.png"} style={{ height: 50 }} />
              )}
              {/* <Image src={logo || "/static/logo1.png"} style={{ height: "50px" }} /> */}
            </View>
          </View>

          <View style={styles.addressSection}>
            <View style={styles.deliverySection}>
              <View style={styles.cardHeaders}>
                <Text>{ordersData?.businessDetails?.businessName}</Text>
              </View>
              <Text style={styles.text}>
                {/* Address Line 1 */}
                {ordersData?.businessDetails?.registeredAddress}
              </Text>
              {/* <Text style={styles.text}>
                {/* Address Line 1 * /}
                {ordersData?.businessDetails?.address?.addressLine1}
              </Text>
              <Text style={styles.text}>
                {/* Address Line 2 * /}
                {ordersData?.businessDetails?.address?.addressLine1}
              </Text>
              <Text style={styles.text}>
                {ordersData?.businessDetails?.address?.city}, {ordersData?.businessDetails?.address?.state},
              </Text> */}
              {/* <Text style={styles.text}>
                
                {ordersData?.businessDetails?.address?.country},
                {ordersData?.businessDetails?.address?.pincode}
              </Text> */}
              <Text style={styles.text}>
                <Text>Email : </Text>
                <Text style={styles.text}>
                  {ordersData?.businessDetails?.registeredEmail}
                </Text>
              </Text>
              <Text style={styles.text}>
                <Text>Contact : </Text>
                <Text style={styles.text}>
                  {ordersData?.businessDetails?.mobile}
                </Text>
              </Text>
              <Text style={styles.text}>
                <Text>State Code : </Text>
                <Text style={styles.text}>{ordersData?.businessDetails?.stateCode}</Text>
              </Text>

              {/* <Text style={styles.text}>
                <Text>PAN NO : </Text>
                <Text style={styles.text}>AAAAAAA</Text>
              </Text> */}
              <Text style={styles.text}>
                <Text>GSTIN : </Text>
                <Text style={styles.text}> {ordersData?.businessDetails?.GSTN}</Text>
              </Text>
            </View>
            <View style={styles.OrderIdSection}>
              { (docType === ORDER_DOC_TYPES.INVOICE) && <Text style={styles.text}>Invoice Id {ordersData?.invoiceId}</Text> }
              <Text style={styles.text}>Order Id {ordersData?.orderId}</Text>
              <Text style={styles.text}>
                Order Date : {dateFormatter(ordersData?.createdAt)}
              </Text>
              <Text style={styles.text}>
                Order Status : {ordersData?.status}
              </Text>
              <Text style={styles.text}>
                (Original for Receipt/Duplicate for Transport)
              </Text>
              <Text style={styles.text}>
                {getOrderDocTypeString(docType)}
                {/* Tax Invoice/Bill of Supply/Cash Memo */}
              </Text>

              {/* <Text style={{ marginTop: "15px" }}>
              Payment Status : {orderDetails?.status}
            </Text>
            <Text style={styles.text}>
              Payment Method : {orderDetails?.paymentGateway}
            </Text> */}
            </View>
          </View>
          <View style={styles.addressSection}>
            <View style={styles.SupplierSection}>
              <View>
                <Text>Billing Address </Text>
              </View>
              <View style={styles.cardHeaders}>
                <Text>{ordersData?.billTo?.address?.name}</Text>
              </View>
              <Text style={styles.text}>
                {/* Address Line 1 */}
                {ordersData?.billTo?.address?.addressLine1}
              </Text>
              <Text style={styles.text}>
                {/* Address Line 2 */}
                {ordersData?.billTo?.address?.addressLine1}
              </Text>
              <Text style={styles.text}>
                {ordersData?.billTo?.address?.city}, {ordersData?.billTo?.address?.state},
              </Text>
              <Text style={styles.text}>
                {ordersData?.billTo?.address?.country},
                {ordersData?.billTo?.address?.pinCode}.
              </Text>
              <Text style={styles.text}>
                <Text>Email : </Text>
                <Text style={styles.text}>
                  {ordersData?.billTo?.addresss?.email}
                </Text>
              </Text>
              <Text style={styles.text}>
                <Text>Contact : </Text>
                <Text style={styles.text}>
                  {ordersData?.billTo?.addresss?.mobile}
                </Text>
              </Text>
              <Text style={styles.text}>
                <Text>PAN NO : </Text>
                <Text style={styles.text}></Text>
              </Text>
              <Text style={styles.text}>
                <Text>GSTIN : </Text>
                <Text style={styles.text}>{ordersData?.userGSTIN?.GSTIN}</Text>
              </Text>
            </View>

            <View style={styles.billToSection}>
              <View>
                <Text>Shipping Address </Text>
              </View>
              <View style={styles.cardHeaders}>
                <Text>{ordersData?.shipTo?.address?.name}</Text>
              </View>
              <Text style={styles.text}>
                {/* Address Line 1 */}
                {ordersData?.shipTo?.address?.addressLine1}
              </Text>
              <Text style={styles.text}>
                {/* Address Line 2 */}
                {ordersData?.shipTo?.address?.addressLine1}
              </Text>
              <Text style={styles.text}>
                {ordersData?.shipTo?.address?.city}, {ordersData?.shipTo?.address?.state},
              </Text>
              <Text style={styles.text}>
                {ordersData?.shipTo?.address?.country},
                {ordersData?.shipTo?.address?.pinCode}.
              </Text>
              <Text style={styles.text}>
                <Text>Email : </Text>
                <Text style={styles.text}>
                  {ordersData?.shipTo?.addresss?.email}
                </Text>
              </Text>
              <Text style={styles.text}>
                <Text>Contact : </Text>
                <Text style={styles.text}>
                  {ordersData?.shipTo?.addresss?.mobile}
                </Text>
              </Text>
              <Text style={styles.text}>
                <Text>PAN NO : </Text>
                <Text style={styles.text}></Text>
              </Text>
              <Text style={styles.text}>
                <Text>GSTIN : </Text>
                <Text style={styles.text}>{ordersData?.userGSTIN?.GSTIN}</Text>
              </Text>
            </View>
          </View>

          <View style={styles.section}>
            <View style={styles.table}>
              <View style={styles.itemListHead}>
                <Text style={styles.id}>Sr/No</Text>
                <Text style={styles.desc}>Description</Text>
                <Text style={styles.quantity}>Qty</Text>
                <Text style={styles.unitPrice}>Unit Price</Text>
                <Text style={styles.amount}>Net Amount</Text>
                <Text style={styles.total}>GST(Rate / Amount)</Text>
                <Text style={styles.totalTax}>Total Taxable Amount</Text>
              </View>
              {itemsData.map((item, i) => (
                <View style={styles.itemList}>
                  <Text style={styles.id}>{i + 1}</Text>
                  <Text style={styles.desc}>{item.productName}</Text>
                  <Text style={styles.quantity}>{item.quantity}</Text>
                  <Text style={styles.unitPrice}>{item.price}</Text>
                  <Text style={styles.amount}>{item.netAmount}</Text>
                  {/* // TODO_SP :Need to fix the display of values */}
                  <Text style={styles.total}>{ item.netAmount}</Text>
                  <Text style={styles.totalTax}>
                    {moneyFormatter(item.total)}
                  </Text>
                </View>
              ))}
            </View>

            <View style={styles.totalTable}>
              {/* <View style={styles.totalEmptySpace}></View> */}
              <View style={{ width: "30%" }}>
                <Text style={styles.totalCell}>Sub Total :</Text>
                <Text style={styles.totalCell}>SGST :</Text>
                <Text style={styles.totalCell}>CGST :</Text>
                <Text style={styles.totalCell}>IGST :</Text>
                <Text style={styles.totalCell}>Cess :</Text>
                <Text style={styles.totalCell}>Delivery Charges :</Text>
                <Text style={styles.totalCell}>Total Amount :</Text>
              </View>
              <View style={{ width: "15 %" }}>
                <Text style={styles.totalCell}>{moneyFormatter(ordersData?.paymentDetails?.orderAmount)}</Text>
                <Text style={styles.totalCell}>{moneyFormatter(ordersData?.paymentDetails?.gst?.sgst)}</Text>
                <Text style={styles.totalCell}>{moneyFormatter(ordersData?.paymentDetails?.gst?.cgst)}</Text>
                <Text style={styles.totalCell}>{moneyFormatter(ordersData?.paymentDetails?.gst?.igst)}</Text>
                <Text style={styles.totalCell}>{moneyFormatter(ordersData?.paymentDetails?.gst?.cess)}</Text>
                <Text style={styles.totalCell}>{moneyFormatter(ordersData?.paymentDetails?.delivery)}</Text>
                <Text style={styles.totalCell}>{moneyFormatter(ordersData?.paymentDetails?.totalAmount)}</Text>
              </View>
            </View>
          </View>
        </View>
        {/* <View style={styles.tncSection}>
          <Text style={{ fontFamily: "Lato Bold" }}>
            Terms And Conditions :
          </Text>
          <Text style={styles.text}>{ordersData?.tnc}</Text>
        </View> */}
      </Page>
    </Document>
  );

};

export default OrderPDF;
