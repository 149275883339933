import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { PaymentGateWayTypeAdd, paymentGateWayType } from "src/actions/mgmtPaymentGateWayTypeAction";
import { PAYMENTGATEWAYTYPE_TYPE } from "src/utils/constants";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function PaymentGateWayTypeForm({ className, ...rest }) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showAccessCode, setShowAccessCode] = useState(false)
  const [showKeyIndex, setShowKeyIndex] = useState(false)

  return (
  
    <Formik
      initialValues={{
        gatewayType: "",
        showKeyIndex:  "",
        showAccessCode:  "",
      
      }}
      validationSchema={Yup.object().shape({
    
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        //console.log("Submitting the request", businessId, addedBy, values);
        try {
          await dispatch(PaymentGateWayTypeAdd(values,showAccessCode,showKeyIndex));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Successfully added", {
            variant: "success",
          });
          
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Successfully added", {
            variant: "success",
          });
          dispatch(paymentGateWayType());
          navigate("/app/management/payment/gateway/types");
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <h2 style={{marginLeft:'1.5em'}}>Add Payment GateWay Types</h2>
          <Card style={{width:"1150px",marginLeft:"2em",marginTop:"2em"}}>
            <CardHeader title="Add Payment GateWay Types" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>  
              <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.gatewayType && errors.gatewayType
                    )}
                    fullWidth
                    helperText={
                      touched.gatewayType && errors.gatewayType
                    }
                    label="GateWay Type"
                    name="gatewayType"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.gatewayType}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{marginLeft:"10em"}}>
                         <label> Show AccessCode </label>
                    </Grid>
                  <Grid item xs={12} sm={1} style={{marginLeft:"-10em",marginTop:"-0.2em"}}>
                    <input type="checkbox"
                      style={{
                        color: "#00e676",
                        width: 30,
                        height: 30
                      }}
                      checked={showAccessCode}
                      onChange={() => {
                        setShowAccessCode(!showAccessCode)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} style={{marginLeft:"12.8em"}}>
                         <label> Show KeyIndex</label>
                    </Grid>
                  <Grid item xs={12} sm={1} style={{marginLeft:"-12em",marginTop:"-0.2em"}}>
                    <input type="checkbox"
                      style={{
                        color: "#00e676",
                        width: 30,
                        height: 30
                      }}
                      checked={showKeyIndex}
                      onChange={() => {
                        setShowKeyIndex(!showKeyIndex)
                      }}
                    />
                  </Grid>
              </Grid>
            </CardContent>
          </Card>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              style={{ marginBottom: '5em' ,marginLeft:"2em"}}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

PaymentGateWayTypeForm.propTypes = {
  className: PropTypes.string,
};

export default PaymentGateWayTypeForm;


