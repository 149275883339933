import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import { useLocation, } from "react-router-dom";
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles'
import Page from 'src/components/Page';
import Header from './Header';
import ProductUpdate from './ProductEditForm';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { fetchCustomerDetails } from 'src/actions/mgmtSelectValueAction';
import { useDispatch, useSelector } from "react-redux";
import ProductCreateForm from '../ProductCreateForm/ProductCreateForm';

import axios from 'src/utils/axios';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));




function ProductEdit() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();

  const { state } = useLocation();
  const [productInfo, setProductInfo] = useState(undefined);

  const [categories, setCategories] = useState([]); // TODO_SP : Need to check if we can replace with with data from reducer

  const userType = useSelector((state) => state?.account?.user?.userType);
  const businessInfo = useSelector((state) => state?.app?.businessInfo);
  useEffect(() => {

  }, [businessInfo]);

  const customerId = businessInfo?.customerId;

  const getCategories = useCallback(() => {
    axios
      .get(`/api/admin/catalog/category?customerId=${businessInfo?.customerId}`) //, {})
      .then((response) => {
        if (isMountedRef.current) {
          console.log("get category info =>" + JSON.stringify(response.data));
          if (response?.data?.data?.categories) {
            // setProject(response.data.data.projects);
            setCategories(response.data.data.categories);
          } else {
            setCategories([]);
          }
        }
      });
  }, [isMountedRef]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  console.log("Product Edit: state ", state);

    const custId = state?.info?.customerId


  // const isMountedRef = useIsMountedRef();
  

  const serviceId = state?.info?.serviceId;

  // console.log("Product Edit: state:", state, " serviceId: ", serviceId, custId);

  const getProductInfo = useCallback(() => {
    setProductInfo(state?.info)
  }, [isMountedRef]);
 
  useEffect(() => {
    getProductInfo();
  }, [getProductInfo]);

  useEffect(() => {
    dispatch(fetchCustomerDetails(custId));
  }, []);

  const customerList = useSelector(
    (state) => state?.user?.customer
  );
 
  if (!productInfo) {
    return null;
  }
  
  return (
    <Page
      className={classes.root}
      title="Product Edit"
    >
      <Container maxWidth="lg">
        <Header />
        <br />
        {/* <ProductUpdate service={productInfo} serviceId={serviceId}  custId={custId} customerList={customerList} /> */}
        <ProductCreateForm customerId={customerId} productInfo={productInfo} isEngineeringType={businessInfo?.isEngineeringType} categories={categories} userType={userType}/>

      </Container>
    </Page>
  );
}

export default ProductEdit;
