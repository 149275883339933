import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ORDER_STATUS_TYPE} from "src/utils/constants";
import { OrderUpdate, fetchOrder } from "src/actions/mgmtOrderAction";
import OrderPdf from "../orderDetails/OrderPdfForm";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function OrderEditForm({ className, state, ...rest }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const paymentType = (state?.paymentType?.paymentType) ? state.paymentType.paymentType : ""
  const reference = (state?.paymentType?.reference) ? state.paymentType.reference : ""

  return (
    <Formik
      initialValues={{
        customerId: state.customerId || '',
        paymentOrderId: state.paymentOrderId || '',
        status: state.status || '',
        paymentType: paymentType ,
        reference:reference
      }}
      validationSchema={Yup.object().shape({
        paymentType: Yup.string().max(500).required("PaymentType is required"),
        reference: Yup.string().max(500).required("Reference is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        // console.log("Submitting the request", addedBy, values);
        // console.log("values",values.status);
        try {
          await dispatch(OrderUpdate(values,state));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Order Status Updated", {
            variant: "success",
          });
          dispatch(fetchOrder(state.customerId));
          navigate("/app/management/order/view");
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("update failed", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Update Order Status" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.customerId && errors.customerId
                    )}
                    fullWidth
                    helperText={
                      touched.customerId && errors.customerId
                    }
                    label="Customer Id"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                    disabled
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.paymentOrderId && errors.paymentOrderId
                    )}
                    fullWidth
                    helperText={
                      touched.paymentOrderId && errors.paymentOrderId
                    }
                    label="Payment OrderId"
                    name="paymentOrderId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.paymentOrderId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                  fullWidth
                    label="Order Id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={state.orderId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                  fullWidth
                    label="User Id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={state.userId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                  fullWidth
                    label="Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={state.name}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                  fullWidth
                    label="Mobile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={state.mobile}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                  fullWidth
                    label="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={state.email}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={values.status}
                    onChange={(event, newValue) => {
                      setFieldValue("status", newValue);
                    }}
                    disablePortal
                    id="status"
                    options={ORDER_STATUS_TYPE}
                    required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    error={errors.status}
                    helperText={errors.status}
                    renderInput={(params) => (
                      <>
                        {" "}
                        <TextField {...params} label="Order Status"
                          error={Boolean(touched.status && errors.status)}
                          helperText={touched.status && errors.status}
                        />
                      </>
                    )}
                  />
                </Grid>
                {values.status === "PAID" ?

                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.paymentType && errors.paymentType
                    )}
                    fullWidth
                    helperText={
                      touched.paymentType && errors.paymentType
                    }
                    label="Payment Type"
                    name="paymentType"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.paymentType}
                    variant="outlined"
                  />
                </Grid>
                :
                <Box/>
                  }
                           {values.status === "PAID" ?
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.reference && errors.reference
                    )}
                    fullWidth
                    helperText={
                      touched.reference && errors.reference
                    }
                    label="Reference"
                    name="reference"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.reference}
                    variant="outlined"
                  />
                </Grid>
                :
                <Box/>
                  }
              </Grid>
              <OrderPdf/>
            </CardContent>
          </Card>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

OrderEditForm.propTypes = {
  className: PropTypes.string,
};

export default OrderEditForm;


