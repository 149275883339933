import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RenewPassword } from "src/actions/mgmtAdminAction";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
}));

function PasswordRenew({ className, onClick, onSubmitSuccess,state,...rest }) {

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);

  const userTy = useSelector(
    (state) => state?.account?.user?.userType
  );
  
  const isDisabled = userTy === 'ADMIN' || userTy === 'SUPPORT';

  const [id, setId] = React.useState('');

  const handleChangeRenewPassword = (event) => {
    setId(event.target.value);
  
  };

  const customerIdList = useSelector(
    (state) => state?.user?.users?.customerList
  );

  if (!customerIdList) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        email:state.email || '',
        customerId:state.customerId|| '',
      }}
      validationSchema={Yup.object().shape({
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        console.log("Submitting the request", values);
        try {
          await dispatch(RenewPassword(values));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Successfully changed the password. Please check your email for credentials", {
            variant: "success",
          });
        } catch (err) {
          enqueueSnackbar("Password Not Changed,Something went wrong", {
            variant: "failure",
          });
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        handleKeyDown,
        handleKeyUp,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card style={{marginLeft:"2em" ,width:"400px"}}>
            <CardHeader title="Renew Password" />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                  <TextField
                    error={Boolean(touched.customerId && errors.customerId)}
                    fullWidth
                    helperText={touched.customerId && errors.customerId}
                    label="Customer Id"
                    margin="normal"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
              <Grid item xs={12} sm={12}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select CustomerId *</InputLabel>
                        <Select
                          labelId="demo-simple-select-label *"
                          label="Select CustomerId"
                          onChange={handleChangeRenewPassword}
                          value={id}
                        >
                          {customerIdList.map((idList) => (
                            <MenuItem value={idList.customerId}>

                              {idList.customerId}
                              {"..."}
                              {idList.businessName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FormControl>
                </Grid> */}
              </Grid>
            </CardContent>
          </Card>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              // disabled={isDisabled}
              style={{ marginBottom: '5em' ,marginLeft:"2em"}}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

PasswordRenew.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  onClick: PropTypes.func,
};

export default PasswordRenew;
