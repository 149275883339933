import React from 'react';

function Logo({isDark=true, props}) {

  const logoUrl = (isDark) ? "/static/mperito_logo_dark.png" : "/static/mperito_logo_light.png"
  return (
    <>
    <img
      alt="Logo"
      src={logoUrl}
      height={50}
      {...props}
    />
    </>
  );
}


// width={50}
export default Logo;
