import axios from 'src/utils/axios';


class MgmtAttibute {

  createAttibute = (newAttibute) => new Promise((resolve, reject) => {
console.log("newAttibute",newAttibute)
    // const payload = newAttibute;
 const payload = {
  "customerId":newAttibute.customerId,
  "attributesList": [
      {
          "attributesId": newAttibute.attributesId,
          "isFilterable": newAttibute.isFilterable,
          "type": newAttibute.type
      }
  ]
}
   

    axios.post('/api/admin/catalog/products/attributes',payload)
    .then((response) => {
      console.log("MgmtAttibute(Response):" + JSON.stringify(response));

      if(response.data.data.attibutes){
        let services = response.data.data.attibutes;
        resolve(services);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  updateService = (serviceInfo) => new Promise((resolve, reject) => {

    const payload = serviceInfo;
    const formData = new FormData()
    // formData.append('categoryData',{"customerId":newService.customerId})
    formData.append('categoryData', `{"customerId":${serviceInfo.customerId},"categoryId":${serviceInfo.categoryId},
     "name":"${serviceInfo.name}"}`);
    // formData.append('name',newService.name)
    // formData.append('logoFile', newService)
   

    axios.put('/api/admin/catalog/category',formData)
    .then((response) => {
      console.log("MgmtService(Response):" + JSON.stringify(response));

      if(response.data.data.category){
        let services = response.data.data.category;
        resolve(services);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  fetchAttribute = (cid) => new Promise((resolve, reject) => {
    // const cusid = (userType === "SUPER_ADMIN") ? cid : customerId;
    let searchUrl = "/api/admin/catalog/products/attributes";
    searchUrl += (cid) ? `?&customerId=${cid}` : "";
    axios
    .get(searchUrl)
      .then((response) => {
        // console.log("MgmtAttribute(fetchInvoice->Response): 1", response);
        if (response?.data?.data) {
          let attribute = response?.data?.data;
          // console.log("MgmtUser(fetchUsers)", users);
          resolve(attribute);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })
 
}


const mgmtAttibute = new MgmtAttibute();

export default mgmtAttibute;
