import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import {
  Box,
  IconButton,
  SvgIcon,
} from '@mui/material';
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon
} from 'react-feather';
import { useSelector } from 'react-redux';

function Results({ className,...rest }) {

  const navigate = useNavigate();

  const paymentGatewayType = useSelector(
    (state) => state?.paymentGateWayType?.paymentGatewayType
  );
  
  const columns = [
    { field: 'paymentGwId', headerName: 'Payment GwId ', width: 100 },
    { field: 'gatewayType', headerName: 'Gateway Type', width: 100},
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Tooltip title="Edit Payment Gateway Type">
              <IconButton
                onClick={() => {
                  navigate('/app/management/edit/payment/gateway/types', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <EditIcon/>
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <m>
            </m>
          </div>
        );
      },
    },
    { field: 'showAccessCode', headerName: 'showAccessCode', width: 150 },
    { field: 'showKeyIndex', headerName: 'showKeyIndex', width: 200 },
  ]

  return (

    <Box sx={{ height: 500, width: '100%',marginTop:"2em" }}>
      <DataGrid
        rows={paymentGatewayType}
        columns={columns}
        getRowId={(row) => row.paymentGwId}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
     
    </Box>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  services: PropTypes.array
};

Results.defaultProps = {
  services: []
};

export default Results;



















