class SearchOrder {
  constructor(info) {
    this.customerId = info.customerId;
    this.status = info.status;
    this.paymentOrderId = info.paymentOrderId;
    // this.paymentType = info.paymentType;
    // this.reference = info.reference;
    if(info && info?.paymentType){
      this.paymentType = `${info.paymentType,info.reference}`;
    }
  }
}

class OrderStatusPaid {
  constructor(info, addedBy) {
    this.customerId = info.customerId;
    this.orderId = info.orderId;
    this.status = info.status;
    // this.paymentType = info.paymentType;
    // this.reference = info.reference;
    this.paymentType = {
      paymentMethod : info.paymentType,  // 
      reference: info.reference
    };
  }
}

class OrderStatus {
  constructor(info, addedBy) {
    this.customerId = info.customerId;
    this.orderId = info.orderId;
    this.status = info.status;
    this.reference = info.comment;
    // this.paymentType = info.paymentType;
    // this.reference = info.reference;
    this.paymentType = {
      reference: info.comment
    };
  }
}


export default { SearchOrder };
export { SearchOrder, OrderStatusPaid, OrderStatus };



