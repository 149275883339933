import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 5,
        justifyContent: 'flex-end',
        width: '100%'
    },
    headerContainer1: {
        marginTop: 5,
        justifyContent: 'center',
        width: '100%'
    },
    billTo: {
        justifyContent: 'center',
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
        fontSize: 12,
        fontStyle: 'bold',
        color: '#3778C2',
    },
    billTo1: {
        justifyContent: 'flex-end',
        justifyContent: 'left',
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
        fontSize: 12,
        fontStyle: 'bold',
        color: '#3778C2',
    },
    shipTo: {
        marginleft: 150,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
        fontSize: 12,
        fontStyle: 'bold',
        color: '#3778C2',
    },
    label: {
        width: 120,
        alignItems: 'left',
    },
    label: {
        width: 120,
        alignItems: 'right',
    },
    label1: {
        width: 150,
        // alignItems: 'left',
        fontSize: 12,
        fontStyle: 'bold',
        // marginTop:5
    },
    label3: {
        width: 150,
        // alignItems: 'left',
        fontSize: 12,
        fontStyle: 'bold',
        // marginTop:5
    },
    label2: {
        // width: 200,
        // marginTop:20,
        marginleft: 150,
        // justifyContent: 'flex-end',
        // alignItems: 'left',
        // fontSize: 12,
        // fontStyle: 'bold',
        fontFamily: 'Helvetica-Oblique',
        fontSize: 12,
        fontStyle: 'bold',
        color: '#3778C2',
        // marginTop:5
    },
    invoiceDateContainer1: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'right',
        marginTop:20
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        // alignItems: 'right',
    },
    invoiceDateContainer1: {
        width:200,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginLeft:232,
        fontFamily: 'Helvetica-Oblique',
        fontSize: 12,
        fontStyle: 'bold',
        color: '#3778C2',
        
    },  
});

const BillTo = ({ invoice }) => {
    // console.log(`BillTo() :: invoice: `, invoice);
    const shipToName = invoice?.shipTo?.address?.name ?? "";
    const shipToAddressLine1 = invoice?.shipTo?.address?.addressLine1 ?? "";
    const shipToAddressLine2 = invoice?.shipTo?.address?.addressLine2 ?? "";
    const shipToCity = invoice?.shipTo?.address?.city ?? "";
    const shipToState = invoice?.shipTo?.address?.state ?? "";
    const shipToPinCode = invoice?.shipTo?.address?.pinCode ?? "";
    console.log(`BillTo() :: shipToName: `, shipToName, ` shipToAddressLine1: `, shipToAddressLine1, ` shipToAddressLine2: `, shipToAddressLine2, ` shipToCity: `, shipToCity, ` shipToState: `, shipToState, ` shipToPinCode: `, shipToPinCode);
    
    const billToName = invoice?.billTo?.address?.name ?? "";
    const billToAddressLine1 = invoice?.billTo?.address?.addressLine1 ?? "";
    const billToAddressLine2 = invoice?.billTo?.address?.addressLine2 ?? "";
    const billToCity = invoice?.billTo?.address?.city ?? "";
    const billToState = invoice?.billTo?.address?.state ?? "";
    const billToPinCode = invoice?.billTo?.address?.pinCode ?? "";
    console.log(`BillTo() :: billToName: `, billToName, ` billToAddressLine1: `, billToAddressLine1, ` billToAddressLine2: `, billToAddressLine2, ` billToCity: `, billToCity, ` billToState: `, billToState, ` billToPinCode: `, billToPinCode);
    
    return (
        <View style={styles.headerContainer}>
            <View style={styles.invoiceDateContainer}>
            <Text style={styles.label2}>Bill TO:</Text>
            <Text style={styles.invoiceDateContainer1}>Ship TO:</Text>
            </View>
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>NAME :- </Text>
                <Text style={styles.label1}>{billToName}</Text>
                <Text style={styles.label1}>NAME :- </Text>
                <Text>{shipToName}</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Address Line1:- </Text>
                <Text style={styles.label1}>{billToAddressLine1}</Text>
                <Text style={styles.label1}>Address Line1:- </Text>
                <Text >{shipToAddressLine1}</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Address Line2:- </Text>
                <Text style={styles.label1}>{billToAddressLine2}</Text>
                <Text style={styles.label1}>Address Line2:- </Text>
                <Text>{shipToAddressLine2}</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>City:- </Text>
                <Text style={styles.label1}>{billToCity}</Text>
                <Text style={styles.label1}>City:- </Text>
                <Text>{shipToCity}</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>State:- </Text>
                <Text style={styles.label1}>{billToState}</Text>
                <Text style={styles.label1}>State:- </Text>
                <Text>{shipToState}</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Pincode:- </Text>
                <Text style={styles.label1}>{billToPinCode}</Text>
                <Text style={styles.label1}>Pincode:- </Text>
                <Text>{shipToPinCode}</Text>
            </View >
        </View>
    )
}

export default BillTo;