import React from 'react';
import { DataGrid } from '@mui/x-data-grid'
import Delete from '@mui/icons-material/Delete';
import axios from 'src/utils/axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import {
  Box,
  IconButton,
  SvgIcon,
  Typography
} from '@mui/material';
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDistributorTypeList } from 'src/actions/mgmtDistributorTypeAction';

function DistributorTypeResults() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const distributeTypeInfo = useSelector(
    (state) => state?.distributetype?.distributorTypeList
  );

  // const distributeTypeInfoLength = useSelector(
  //   (state) => state?.distributetype?.distributorTypeList?.length
  // );

  const handleDelete = async (distributorTypeId, customerId) => {
    try {
      await axios.delete(`/api/master/distributor/dealer/type?distributorTypeId=${distributorTypeId}&customerId=${customerId}`);
      dispatch(fetchDistributorTypeList(customerId));
    } catch (err) { }
  };

  const columns = [
    { field: 'customerId', headerName: 'Customer Id', width: 100 },
    { field: 'category', headerName: 'Category', width: 100 },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Tooltip title="Edit Distributor Type">
              <IconButton
                onClick={() => {
                  navigate('/app/management/distributor/type/edit', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip><m>
              <Tooltip title="Delete Distributor Type">
                <IconButton
                  onClick={() => handleDelete(params.row.distributorTypeId, params.row.customerId)}
                >
                  <SvgIcon fontSize="small">
                    <Delete />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            </m>
          </div>
        );
      },
    },
    { field: 'discount', headerName: 'Discount', width: 100 },
    { field: 'distributorTypeId', headerName: 'Distributor Type Id', width: 150 },
  ];

  return (

    <Box sx={{ height: 400, width: '95%' }}>
      {/* {distributeTypeInfoLength > 0 ? */}
        <DataGrid
          rows={distributeTypeInfo}
          columns={columns}
          getRowId={(row) => row.distributorTypeId}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
        {/* :
        <Typography align='center'>No Data Found For This Customer Id</Typography>
      } */}
    </Box>
  )
}

export default DistributorTypeResults;
