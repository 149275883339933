import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PaymentGateWayTypeEdit, paymentGateWayType } from "src/actions/mgmtPaymentGateWayTypeAction";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function PaymentGateWayTypeEditForm({ className, greeting, onClick, base, ...rest }) {
  const { state } = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [showKeyIndex, setShowKeyIndex] = useState(state.showKeyIndex)
  const [showAccessCode, setShowAccessCode] = useState(state.showAccessCode)
  const navigate = useNavigate();

  const [paymentType, setPaymentType] = React.useState('');

  const handleChange1 = (event) => {
    setPaymentType(event.target.value);
  };
  const paymentGatewayType = useSelector(
    (state) => state?.paymentGateWayType?.paymentGatewayType
  );


  return (
   

    <Formik
      initialValues={{
        gatewayType: state.gatewayType || '',
        showKeyIndex: "",
        showAccessCode: "",
      
      }}
      validationSchema={Yup.object().shape({
    
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        //console.log("Submitting the request", businessId, addedBy, values);
        try {
          await dispatch(PaymentGateWayTypeEdit(values,showKeyIndex,showAccessCode,paymentType));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("SuccessfullY Updated", {
            variant: "success",
          });
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Successfully Updated", {
            variant: "success",
          });
          dispatch(paymentGateWayType());
          navigate("/app/management/payment/gateway/types");
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
             <h2 style={{marginLeft:'1.5em'}}>Update Payment GateWay Types</h2>
          <Card style={{width:"1150px",marginLeft:"2em",marginTop:"2em"}}>
            <CardHeader title="Update Payment GateWay Types" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
           
              <Grid item xs={12} >
                    <FormControl fullWidth >
                      <div>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Select GateWay Type</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            // id="demo-simple-select"
                            label="Select GateWay Type"
                            onChange={handleChange1}
                            value={values.gatewayType}
                          // value={values.categoryId}
                          >
                            {paymentGatewayType.map((types) => (
                              <MenuItem value={types.gatewayType}>
                                {types.gatewayType}
                              
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </FormControl>
                  </Grid> :
                <Grid item xs={12} sm={3} style={{marginLeft:"10em"}}>
                         <label> Show Key Index </label>
                    </Grid>
                  <Grid item xs={12} sm={1} style={{marginLeft:"-12em",marginTop:"-0.2em"}}>
                    <input type="checkbox"
                      style={{
                        color: "#00e676",
                        width: 30,
                        height: 30
                      }}
                      checked={showKeyIndex}
                      onChange={() => {
                        setShowKeyIndex(!showKeyIndex)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} style={{marginLeft:"12.8em"}}>
                         <label> Show Access Code</label>
                    </Grid>
                  <Grid item xs={12} sm={1} style={{marginLeft:"-11em",marginTop:"-0.2em"}}>
                    <input type="checkbox"
                      style={{
                        color: "#00e676",
                        width: 30,
                        height: 30
                      }}
                      checked={showAccessCode}
                      onChange={() => {
                        setShowAccessCode(!showAccessCode)
                      }}
                    />
                  </Grid>
              
             
              </Grid>
            </CardContent>
          </Card>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              style={{ marginBottom: '5em' ,marginLeft:"2em"}}
            >
              Submit
            </Button>
      
          </Box>
        </form>
      )}
    </Formik>
  );
}

PaymentGateWayTypeEditForm.propTypes = {
  className: PropTypes.string,
};

export default PaymentGateWayTypeEditForm;


