import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createLicense } from "src/actions/mgmtLicenseAction";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { fetchCategory, fetchUsers5 } from "src/actions/mgmtSelectValueAction";
import { dateFormatter } from "src/views/greeting/GreetingListView/DateFormate";
import { fetchCustomerList } from "src/actions/mgmtCustomerCreateAction";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function UpdateLicense({ className, state, validtill, cusId, ...rest }) {

  function getCurrentDate(separator = '-') {
    let newDate = new Date(state.license.issuedate)
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
  }
  const date = getCurrentDate()
  // console.log("date",date);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const customer = useSelector(
    (state) => state?.account?.user?.userType
  );
  // console.log("customer",customer)
  const isDisabled = customer === 'ADMIN';
  return (

    <Formik
      initialValues={{
        customerId: cusId || "",
        fromDate: date || '',
        toDate: validtill || '',
        remarks:state.license.remarks || '',
      }}
      validationSchema={Yup.object().shape({

      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        // console.log("Submitting the request", addedBy, values);
        try {
          await dispatch(createLicense(values, cusId));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("License Validity Updated", {
            variant: "success",
          });
          dispatch(fetchCustomerList());
          navigate("/app/management/customer/view");
        } catch (err) {
          setErrors({ submit: err.response.data.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("customerId or name Exits", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card style={{ marginLeft: '1em', maxWidth: '300px', maxHeight: '400px' }}>
            {/* <CardHeader title="Update License Validity" />
            <Divider /> */}
            <CardContent>
              <Grid container spacing={3}>
                {/* {
             userType !== "ADMIN" ?  <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <div>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select CustomerId *</InputLabel>
                  <Select
                    labelId="demo-simple-select-label *"
                    // id="demo-simple-select"
                    label="Select CustomerId"
                    onChange={handleChange1}
                    value={values.customerId}
                  >
                    {customerIdList.map((idlist) => (
                      <MenuItem value={idlist.customerId}>
                
                        {idlist.customerId}
                        {"..."}
                        {idlist.businessName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </FormControl>
          </Grid> : 
                <Grid item xs={12}>
                <TextField
                 fullWidth
                  label="Customer Id"
                  name="customerId"
               
                  onChange={handleChange}
                  value={values.customerId}
                  variant="outlined"
                  disabled
                />
              </Grid>
        }  */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Customer Id"
                    name="customerId"

                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select CustomerId</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select CustomerId"
                          onChange={handleChange}
                          name="customerId"
                          onBlur={handleBlur}
                          value={values.customerId}
                          variant="outlined"
                        >
                          {customerId.map((cities) => (
                            <MenuItem value={cities.customerId}>
                              {cities.customerId}
                              {"..."}
                              {cities.businessName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FormControl>
                </Grid> */}
                <Grid item xs={12}>
                  <Typography>From</Typography>
                  <TextField
                    error={Boolean(
                      touched.fromDate && errors.fromDate
                    )}
                    fullWidth
                    helperText={
                      touched.fromDate && errors.fromDate
                    }
                    // label="Date"
                    type="date"
                    name="fromDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fromDate}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>To</Typography>
                  <TextField
                    error={Boolean(
                      touched.toDate && errors.toDate
                    )}
                    fullWidth
                    helperText={
                      touched.toDate && errors.toDate
                    }
                    // label="Date"
                    type="date"
                    name="toDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.toDate}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.remarks && errors.remarks
                    )}
                    fullWidth
                    helperText={
                      touched.remarks && errors.remarks
                    }
                    label="Remarks"
                  
                    name="remarks"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.remarks}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isDisabled}
              style={{ marginLeft: '1em', }}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

UpdateLicense.propTypes = {
  className: PropTypes.string,
};

export default UpdateLicense;


