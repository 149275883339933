import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import Results from './Result';
import { fetchOrder } from 'src/actions/mgmtOrderAction';
import { fetchOrders, fetchOrdersByCriteria } from 'src/actions/orderActions';
import MperitoCard from 'src/components/MperitoCard';
import SearchForm from './SearchForm';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function SearchOrders() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [criteria, setCriteria] = useState(undefined);

  const businessInfo = useSelector((state) => state?.app?.businessInfo);
  const customerId = businessInfo?.customerId;

  useEffect(() => {
    async function getOrders(customerId){
      const ordersList = await dispatch(fetchOrdersByCriteria(customerId,criteria));
      setOrders(ordersList);
    }
    getOrders(customerId);

  }, [customerId, criteria]);




  return (
    <Page
      className={classes.root}
      title="Order List"
    >
      <Container maxWidth={false}>
        <Header />
        {/* <Button style={{ marginLeft: '1em', marginTop: '1em' }} color="secondary" variant='contained' onClick={handleClick1}>
           Get Oem Order Details</Button> */}
        <br />
        
        <br />
        <MperitoCard title="Orders Search Criteria">
          <SearchForm setCriteria={setCriteria} />            
        </MperitoCard>

          <MperitoCard title={`Orders List (${customerId} - ${businessInfo?.businessName} ) `} sx={{mt:2}}>
            <Results orders={orders} />
        </MperitoCard>
      </Container>
    </Page>

  );
}

export default SearchOrders;
