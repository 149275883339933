class Attibute {
  constructor(info) {
    this.customerId = info.customerId;
    this.attributesId = info.attributesId;
    this.isFilterable = info.isFilterable;
    this.type = info.type;
  }
}

export default { Attibute };
export { Attibute };



