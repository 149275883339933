import AuthGuard from "src/components/AuthGuard";

import DashboardLayout from "src/layouts/DashboardLayout";

import CategoryPage from 'src/views/category/Category';
import CategoryListView from 'src/views/category/CategoryListView';
import CategoryCreate from 'src/views/category/CategoryCreateView';
import CategoryEdit from 'src/views/category/CategoryEditView';

import ProductListView from 'src/views/product/ProductListView';
import ProductCreate from 'src/views/product/ProductCreateForm';
import ProductUploadFile from 'src/views/importfile/ProductUpload';
import ProductEdit from 'src/views/product/ProductEditView';

import CustomerView from 'src/views/customer/CustomerListView';
import CustomerCreate from 'src/views/customer/CustomerCreateForm';
import CustomerEdit from 'src/views/customer/CustomerLogoAndBannerEditView';
import CustomerUpload from 'src/views/customer/Upload';
import CustomerInfoEdit from 'src/views/customer/CustomerInfoEditForm';
import CustomerInfoView from 'src/views/customer/CustomerView';
import CustomerPrivacyPolicyEdit from 'src/views/customer/CustomerPrivacyPolicyUpdate';
import CustomerDetailsEdit from 'src/views/customer/CustomerDetailsEdit';

import NewsView from 'src/views/news/NewsListView';
import NewsEdit from 'src/views/news/NewsEditView';
import NewsCreateView from "src/views/news/NewsCreate";

import GreetingView from 'src/views/greeting/GreetingListView';
//import GreetingEdit from 'src/views/greeting/GreetingEditView';
import GreetingEdit from "src/views/greeting/GreetingEdit";
import GreetingCreate from "src/views/greeting/GreetingNew";

import AttributeView from 'src/views/attribute/attibuteList';
import AttibuteCreate from 'src/views/attribute/attibuteCreate';

import UserCreate from 'src/views/users/UserCreate';
import UserEdit from 'src/views/users/UserEdit';
import UserProfile from 'src/views/account';
import UserTarget from 'src/views/users/UserTarget';

import Info from 'src/views/dashboards/DashboardView/dashBoardMain';
import SuperAdminDashboardInfo from 'src/views/dashboards/DashboardView/superAdminDashboard';

import AdminEditPassword from 'src/views/adminchangepassword';

import DistributorListView from 'src/views/distributorType/DistributorTypeList';
import DistributorTypeEdit from 'src/views/distributorType/DistributorTypeEdit';
import DistributorTypeCreate from 'src/views/distributorType/DistributorTypeCreate';

import InvoiceListView from 'src/views/invoice/InvoiceListView';
import OrderPdf from 'src/views/order/orderDetails/OrderPdfForm';
import OrderView from 'src/views/order/OrderListView';
import OrderEdit from 'src/views/order/OderStatusEdit';

import SearchOrdersView from 'src/views/orders/SearchOrders';
import SearchInvoicesView from 'src/views/orders/SearchInvoices';

import GeneratedQuotations from 'src/views/quotationGenerate/GeneratedQuotation';
import QuotationCreate from 'src/views/quotationGenerate/QuotationCreate';
import QuotationRequestList from 'src/views/quotationGenerate/QuotationRequestListView';
import AdminView from 'src/views/admin/AdminList';
import AdminAddView from 'src/views/admin/AdminCreate';

import LicenseView from 'src/views/customer/validity/LicenseView';
import CustomerPaymentCredentialsForm from 'src/views/customer/CustomerPaymentCredentialsEdit';
import UserListView from 'src/views/users/UserListView';
import CustomerJsonDownload from 'src/views/customer/CustomerJsonDownload';
import FinancialDashboardMain from "src/views/dashboards/DashboardView/FinancialDashboardMain";
import OrderJsonDownload from "src/views/order/OrderExport";
import AdminRenewPassword from "src/views/adminRenewPassword";
import AddDeliveryCharges from "src/views/customer/CustomerDeliveryCharges";

import PaymentForm from "src/views/PaymentGateWayType";
import PaymentGateWayTypeForm from "src/views/PaymentGateWayType/PaymentGateWayTypeAdd";

import PaymentGateWayTypeEditForm from "src/views/PaymentGateWayType/PaymentGateWayTypeEdit";
import ViewOrder from "src/views/orders/ViewOrder";
import UpdateOrder from "src/views/orders/UpdateOrder";
import ViewInvoice from "src/views/orders/ViewInvoice";
import Dashboard from "src/views/dashboards/Dashboard";


import TestimonialsList from "src/views/testimonials/TestimonialsList";
import TestimonialCreate from "src/views/testimonials/TestimonialCreate";

import ServiceList from "src/views/services/ServiceList";
import ServiceCreate from "src/views/services/ServiceCreate";
import TestimonialEdit from "src/views/testimonials/TestimonialEdit";
import DashboardFinancial from "src/views/dashboards/DashboardFinancial";

//import ViewInvoice from "src/views/orders/ViewInvoice";


// ==============================|| MAIN ROUTING ||============================== //

const DashboardRoutes = {
    path: '/app',
    element: (
        <AuthGuard>
            <DashboardLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/app',
            element: <Info />
        },
        {
            path: '/app/management/order/view',
            element: <OrderView />
        },
        {
            path: '/app/management/order/search',
            element: <SearchOrdersView />
        },
        {
            path: '/app/management/invoice/search',
            element: <SearchInvoicesView />
        },
        {
            path: '/app/management/category/create',
            element: <CategoryCreate />
        },
        {
            path: '/app/management/service/edit',
            element: <CategoryEdit />
        },
        {
            path: '/app/management/importcsv/view',
            element: <ProductUploadFile/>
        },
        {
            path: '/app/management/news/view',
            element: <NewsView />
        },
        {
            path: '/app/management/news/create',
            element: <NewsCreateView />
        },
        {
            path: '/app/management/testimonials',
            element: <TestimonialsList />
        },
        {
            path: '/app/management/testimonials/create',
            element: <TestimonialCreate />
        },
        {
            path: '/app/management/testimonials/edit',
            element: <TestimonialEdit />
        },
        {
            path: '/app/management/services',
            element: <ServiceList />
        },
        {
            path: '/app/management/services/create',
            element: <ServiceCreate />
        },
        {
            path: '/app/management/product/create',
            element: <ProductCreate />
        },
        {
            path: '/app/management/product/update',
            element: <ProductEdit />
        },
        {
            path: '/app/management/customer/update',
            element: <CustomerEdit />
        },
        {
            path: '/app/management/customer/info/update',
            element: <CustomerInfoEdit />
        },
        {
            path: '/app/management/customer/privacypolicy/update',
            element: <CustomerPrivacyPolicyEdit />
        },
        {
            path: '/app/management/customer/customerdetails/update',
            element: <CustomerDetailsEdit />
        },
        {
            path: '/app/management/customer/info/view',
            element: <CustomerInfoView />
        },
        {
            path: '/app/management/category/update',
            element: <CategoryEdit />
        },
        {
            path: '/app/management/customer/view',
            element: <CustomerView />
        },
        {
            path: '/app/management/dashboard-info',
            element: <Info />
        },
        {
            path: '/app/management/dashboard',
            element: <Dashboard />
        },
        {
            path: '/app/management/dashboard/financial',
            element: <DashboardFinancial />
        },
        {
            path: '/app/management/category',
            element: <CategoryPage />
        },
        {
            path: '/app/management/category/view',
            element: <CategoryListView />
        },
        {
            path: "/app/management/product/view",
            element: <ProductListView />
        },
        // {
        //     path: "/app/management/invoice/view",
        //     element: <InvoiceListView />
        // },
        {
            path: "/app/management/greetings/view",
            element: <GreetingView />
        },
        {
            path: "/app/management/greetings/create",
            element: <GreetingCreate />
        },
        {
            path: "/app/management/greetings/edit",
            element: <GreetingEdit />
        },
        {
            path: "/app/management/news/edit",
            element: <NewsEdit />
        },
        {
            path: "/app/management/license/view",
            element: <LicenseView />
        },
        {
            path: "/app/management/customer/upload/view",
            element: <CustomerUpload />
        },
        {
            path: "/app/management/attribute/view",
            element: <AttributeView />
        },
        {
            path: "/app/management/attibute/create",
            element: <AttibuteCreate />
        },
        {
            path: "/app/management/user/view",
            element: <UserListView/>
        },
        {
            path: "/app/management/user/create",
            element: <UserCreate />
        },
        {
            path: "/app/management/user/update",
            element: <UserEdit />
        },
        {
            path: "/app/management/admin/view",
            element: <AdminView />
        },
        {
            path: "/app/management/customer/create",
            element: <CustomerCreate />
        },
        {
            path: "/app/account",
            element: <UserProfile />
        },
        // {
        //     path: "/app/management/order/update",
        //     element: <OrderEdit />
        // },
        {
            path: "/app/management/user/target",
            element: <UserTarget />
        },
        {
            path: "/app/management/super/admin/dashboard",
            element: <SuperAdminDashboardInfo />
        },
        {
            path: "/app/management/quotation/generated/view",
            element: <GeneratedQuotations />
        },
        {
            path: "/app/management/quotation/generate",
            element: <QuotationCreate />
        },
        {
            path: "/app/management/quotation/request/list",
            element: <QuotationRequestList />
        },
        {
            path: "/app/management/admin/change/password",
            element: <AdminEditPassword />
        },
        {
            path: "/app/management/distributor/type/edit",
            element: <DistributorTypeEdit />
        },
        {
            path: "/app/management/distributor/type/create",
            element: <DistributorTypeCreate />
        },
        {
            path: "/app/management/customer/quotation/create",
            element: <QuotationCreate />
        },
        {
            path: "/app/management/order/details",
            element: <ViewOrder />
        },
        {
            path: "/app/management/order/update",
            element: <UpdateOrder />
        },
        {
            path: "/app/management/invoice/details",
            element: <ViewInvoice />
        },
        {
            path: "/app/management/distributor/type",
            element: <DistributorListView />
        },
        {
            path: "/app/management/add/admin",
            element: <AdminAddView />
        },
        {
            path: "/app/management/update/payment/credentials",
            element: <CustomerPaymentCredentialsForm/>
        },
        {
            path: "/app/management/customer/json/download",
            element: <CustomerJsonDownload/>
        },
        {
            path: "/app/management/financial/dashboard",
            element: <FinancialDashboardMain/>
        },
        {
            path: "/app/management/order/export",
            element: <OrderJsonDownload/>
        },
        {
            path: "/app/management/admin/renew/password",
            element: <AdminRenewPassword/>
        },
        {
            path: "/app/management/delivery/charges",
            element: <AddDeliveryCharges/>
        },
        {
            path: "/app/management/payment/gateway/types",
            element: <PaymentForm/>
        }, 
        {
            path: "/app/management/add/payment/gateway/types",
            element: <PaymentGateWayTypeForm/>
        },
        {
            path: "/app/management/edit/payment/gateway/types",
            element: <PaymentGateWayTypeEditForm/>
        },
    ]
};

export default DashboardRoutes;
