import React, { useState, useEffect, } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types';
import Delete from '@mui/icons-material/Delete';
import axios from 'src/utils/axios';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import {
  Box,
  IconButton,
  SvgIcon,
} from '@mui/material';
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon
} from 'react-feather';
import NewsEdit from '../NewsEditView';
import { fetchNews } from 'src/actions/mgmtNewsAction';
import { useDispatch } from 'react-redux';

function Results({ className, newsList, businessInfo, ...rest }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {

  }, [newsList]);




  const handleDelete = async (customerId, newsId) => {
    // console.log("ServiceID", serviceId);
    try {
      const payload =
      {

        "customerId": customerId,
        "newsId": newsId

      }
      const deleteResult = await axios.post(`/api/admin/customer/info/delete/news`, payload);
      console.log(`deleteResult: `, deleteResult);
      // dispatch(fetchNews(customerId));
    } catch (err) { }
  };

  const columns = [
    // { field: 'customerId', headerName: 'customerId', width: 100 },
    { field: 'newsId', headerName: 'News Id', width: 80 },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Tooltip title="Edit News">
              <IconButton
                onClick={() => {
                  navigate('/app/management/news/edit', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <m>
              <Tooltip title="Delete News">
                <IconButton
                  onClick={() => handleDelete(params.row.customerId, params.row.newsId)}
                >
                  <SvgIcon fontSize="small">
                    <Delete />
                  </SvgIcon>
                </IconButton>
              </Tooltip></m>
          </div>
        );
      },
    },
    { field: 'Heading', headerName: 'Heading', width: 200 },
    { field: 'Description', headerName: 'Description', width: 200 },
    { field: 'ImageUrl', headerName: 'ImageUrl', width: 200 },
    { field: 'VideoUrl', headerName: 'VideoUrl', width: 200 },
    { field: 'CreatedDate', headerName: 'CreatedDate', width: 200 },
    { field: 'Validity', headerName: 'Validity', width: 200 },
  ];

  return (
    <Box sx={{ height: 400, width: '100%', padding: 2}}>
      <DataGrid
        rows={newsList}
        columns={columns}
        getRowId={(row) => row.newsId}
        // checkboxSelection={true}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Box>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  newsList: PropTypes.array
};

Results.defaultProps = {
  newsList: []
};

export default Results;