import { IMAGE_TYPE } from "src/config";
import { ORDER_DOC_TYPES } from "src/constants";

export function getFileNameByImageType(type, name) {
  let fileName = "";

  switch (type) {
    case IMAGE_TYPE.LOGO:
      fileName = "logo.png";
      break;

    case IMAGE_TYPE.SHORTLOGO:
      fileName = "shortlogo.png";
      break;

    case IMAGE_TYPE.BANNER1:
      fileName = "banner1_" + name + ".png";
      break;
      case IMAGE_TYPE.UPDATEBANNER1:
        fileName = "updatebanner1_" + name + ".png";
        break;
    case IMAGE_TYPE.BANNER2:
      fileName = "banner2_" + name + ".png";
      break;
      case IMAGE_TYPE.UPDATEBANNER2:
        fileName = "updatebanner2_" + name + ".png";
        break;

    case IMAGE_TYPE.BANNER3:
      fileName = "banner3_" + name + ".png";
      break;
      case IMAGE_TYPE.UPDATEBANNER3:
        fileName = "updatebanner3_" + name + ".png";
        break;

      case IMAGE_TYPE.BANNER4:
        fileName = "banner4_" + name + ".png";
        break;
        case IMAGE_TYPE.UPDATEBANNER4:
          fileName = "updatebanner4_" + name + ".png";
          break;

        case IMAGE_TYPE.BANNER5:
            fileName = "banner5_" + name + ".png";
        break;
        case IMAGE_TYPE.UPDATEBANNER5:
          fileName = "updatebanner5_" + name + ".png";
          break;

    case IMAGE_TYPE.BANNER6:
        fileName = "banner6_" + name + ".png";
      break;
      case IMAGE_TYPE.UPDATEBANNER6:
        fileName = "updatebanner6_" + name + ".png";
        break;

        case IMAGE_TYPE.PRODUCTIMAGE1:
          fileName = "procuctimage1_" + name + ".png";
          break;
          case IMAGE_TYPE.UPDATEPRODUCTIMAGE1:
            fileName = "updateproductimage1_" + name + ".png";
            break;
        case IMAGE_TYPE.PRODUCTIMAGE2:
          fileName = "procuctimage2_" + name + ".png";
          break;
          case IMAGE_TYPE.UPDATEPRODUCTIMAGE2:
            fileName = "updateproductimage2_" + name + ".png";
            break;
    
        case IMAGE_TYPE.PRODUCTIMAGE3:
          fileName = "procuctimage3_" + name + ".png";
          break;
          case IMAGE_TYPE.UPDATEPRODUCTIMAGE3:
            fileName = "updateproductimage3_" + name + ".png";
            break;
    
          case IMAGE_TYPE.PRODUCTIMAGE4:
            fileName = "procuctimage4_" + name + ".png";
            break;
            case IMAGE_TYPE.UPDATEPRODUCTIMAGE4:
              fileName = "updateproductimage4_" + name + ".png";
              break;
    
            case IMAGE_TYPE.PRODUCTIMAGE5:
                fileName = "procuctimage5_" + name + ".png";
            break;
            case IMAGE_TYPE.UPDATEPRODUCTIMAGE5:
              fileName = "updateproductimage5_" + name + ".png";
              break;
    
        case IMAGE_TYPE.PRODUCTIMAGE6:
            fileName = "procuctimage6_" + name + ".png";
          break;
          case IMAGE_TYPE.UPDATEPRODUCTIMAGE6:
            fileName = "updateproductimage6_" + name + ".png";
            break;
      case IMAGE_TYPE.NEWSIMAGE:
        fileName = "newsimage_" + name + ".png";
      break;
      case IMAGE_TYPE.GREETINGIMAGE:
        fileName = "greetingimage_" + name + ".png";
      break;
      case IMAGE_TYPE.CATEGORYIMAGE:
        fileName = "categoryimage_" + name + ".png";
      break;
      case IMAGE_TYPE.CATEGORYIMAGE:
        fileName = "baseimage_" + name + ".png";
      break;

    case IMAGE_TYPE.SEARCH:
      fileName = "search" + name + ".png";
      break;

    default:
      fileName = "default.png";
      break;
  }

  // console.log("getFileNameByImageType() ", type, name, fileName);
  return fileName;

}

export const getOrderDocTypeString = (docType) => {
  switch (docType) {
    case ORDER_DOC_TYPES.ORDER:
      return 'Bill of Supply ';    
    case ORDER_DOC_TYPES.INVOICE:
        return 'Invoice';  
    case ORDER_DOC_TYPES.TAX_INVOICE:
      return 'Tax Invoice';
    case ORDER_DOC_TYPES.DELIVERY_CHALLAN:
      return 'Delivery Challan';
    default:
      //return 'Unknown Type';
      return '';
  }
};
