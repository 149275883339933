import { Admin, AdminChangePassword } from "src/interface/adminIf";
import mgmtAdmin from "src/services/mgmtAdmin";

export const ADMIN_SEARCH = '@admin/search';
export const ADMIN_SEARCH_SUCCESS = '@admin/search-success';
export const ADMIN_SEARCH_FAILURE = '@admin/search-failure';
export const ADMINEDITPASSWORD_CREATE = '@admin/create';
export const ADMINEDITPASSWORD_CREATE_SUCCESS = '@admineditpassword/create-success';
export const ADMINEDITPASSWORD_CREATE_FAILURE = '@admineditpassword/create-failure';
export const ADMINRENEWPASSWORD_CREATE = '@adminrenew/create';
export const ADMINRENEWPASSWORD_CREATE_SUCCESS = '@adminrenewpassword/create-success';
export const ADMINRENEWPASSWORD_CREATE_FAILURE = '@adminrenewpassword/create-failure';
export const ADMINLIST_INFO = '@adminlist/info';
export const ADMINLIST_FETCH_SUCCESS = '@adminlist/info-success';
export const ADMINLIST_FETCH_FAILURE = '@adminlist/info-failure';

export function AdminCreate(adminInfo) {
  return async (dispatch) => {
    let newAdmin = new Admin(adminInfo);
    // console.log("ADMINAction():" + JSON.stringify(adminInfo) + " " + JSON.stringify(newAdmin));
    try {
      dispatch({ type: ADMIN_SEARCH });
      const admin = await mgmtAdmin.createAdmin(newAdmin);
      dispatch({
        type: ADMIN_SEARCH_SUCCESS,
        payload: {
          admin
        }
      });
    } catch (error) {
      dispatch({ type: ADMIN_SEARCH_FAILURE });
      throw error;
    }
  };
}

export function AdminEditPassWord(PasswordInfo,email) {
  return async (dispatch) => {
    // console.log("changeAdminPassword():",PasswordInfo);
    let newPassword = new AdminChangePassword(PasswordInfo);
    // console.log("changeAdminPassword():" + JSON.stringify(UserInfo) + " " + JSON.stringify(newPassword));
    try {
      dispatch({ type: ADMINEDITPASSWORD_CREATE });
      const password = await mgmtAdmin.adminPasswordChange(newPassword,email);
      // console.log("changeAdminPassword()(after):" + JSON.stringify(password));
      dispatch({
        type: ADMINEDITPASSWORD_CREATE_SUCCESS,
        payload: {
          password
        }
      });
    } catch (error) {
      dispatch({ type: ADMINEDITPASSWORD_CREATE_FAILURE });
      throw error;
    }
  };
}

export function RenewPassword(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMINRENEWPASSWORD_CREATE });
      const password = await mgmtAdmin.renewPassword(values);
      // console.log("changeAdminPassword()(after):" + JSON.stringify(password));
      dispatch({
        type: ADMINRENEWPASSWORD_CREATE_SUCCESS,
        payload: {
          password
        }
      });
    } catch (error) {
      dispatch({ type: ADMINRENEWPASSWORD_CREATE_FAILURE });
      throw error;
    }
  };
}

export function fetchAdminList(cid) {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMINLIST_INFO});
      const adminList = await mgmtAdmin.fetchAdminList(cid);
      // console.log("attribute Action", attribute);
      dispatch({
        type: ADMINLIST_FETCH_SUCCESS,
        payload: {
          adminList,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: ADMINLIST_FETCH_FAILURE });
      throw error;
    }
  };
}





