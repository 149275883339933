import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import { useLocation} from "react-router-dom";
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles'
import Page from 'src/components/Page';
import Header from './Header';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import CustomeForm from './CustomerLogoAndBannerEditForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function CustomerEdit({ match, location }) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const {state} = useLocation();
  console.log("state",state);
  const bid = (state.brandDetails[0]) ? state.brandDetails[0].customerId : "undefined";
 
  const customer = useSelector(
    (state) => state?.account?.user?.userType
  );
 
  const [service, setService] = useState();
 
  const getService = useCallback(() => {
      setService(state)
  }, [isMountedRef]);

  const [banner, setBanner] = useState(null);

  const getBanner = useCallback(() => {
    axios
      .get(`api/customer/brand?brandId=${bid}`) //, {})
      .then((response) => {
        if (isMountedRef.current) {
          // console.log("getBanner =>" + JSON.stringify(response.data.data));
          if(response?.data?.data){
            // setProject(response.data.data.projects);
            setBanner(response.data.data);
          }else{
            setBanner([]);
          }
        }
      });
  }, [isMountedRef]);

  const onSubmitSuccess = () => {
    getService();
  }

  useEffect(() => {
    getBanner();
  }, [getBanner]);


  useEffect(() => {
    getService();
  }, [getService]);

  if (!service) {
    return null;
  }

  if (!banner) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Customer Logo And Banner Edit"
    >
      <Container maxWidth="lg">
        <Header />
        <br/>
        <CustomeForm service={state} banner={banner} customer={customer} onSubmitSuccess={onSubmitSuccess}/>
      </Container>
    </Page>
  );
}

export default CustomerEdit;
