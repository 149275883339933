import greetingService from "src/services/greetingService";

export const FETCH_GREETINGS = '@business/fetch-greetings';
export const FETCH_GREETINGS_SUCCESS = '@business/fetch-greetings-success';
export const FETCH_GREETINGS_FAILURE = '@business/fetch-greetings-failure';


export function fetchGreetings(customerId) {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_GREETINGS });
            const resp = await greetingService.fetchGreetings(customerId);
            // console.log("fetchGreetings Action", resp);
            dispatch({
                type: FETCH_GREETINGS_SUCCESS,
                payload: {
                    greetings : resp?.greetingsRes
                },
            });
        } catch (error) {
            dispatch({ type: FETCH_GREETINGS_FAILURE });
            throw error;
        }
    };
}
