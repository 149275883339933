import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormHelperText,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createNews1, fetchNews, updateNews } from "src/actions/mgmtNewsAction";
import ImageEditor from "src/components/ImageEditor";
import { IMAGE_TYPE } from "src/config";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function NewsEditForm({ className,service,servi,myImg,base, ...rest }) {
  function refreshPage() {
    window.location.reload(false);
  }

  // const path1 = "https://storage.googleapis.com/mperito-dev-bucket"
  const path1 = process.env.REACT_APP_PATH
  const imgURL = `${path1}/${base}`;
  // console.log("imgURL",imgURL)
  const [image, setImage] = useState('')
const cusId =  service.customerId
const newsId =  service.newsId
  function handleImage(e) {
            console.log(e.target.files)
            setImage(e.target.files[0])
        }
  // console.log("SERVICE",service)
  const path = process.env.REACT_APP_PATH
  // const base = myImg
  // const base1 = service.images[0]
  // let d = Buffer.from(im).toString('base64');
  console.log("PATH : FROM : PRODUCT : BASE",base)
  console.log("PATH : FROM : PRODUCT : EDIT",path)
  // const ur =  `${path}/${base}`
  // console.log("SERVICE",ur)
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const addedBy = account.user.email;
  console.log("ServiceCreateForm: addedBy", addedBy);

  return (

    <Formik
      initialValues={{
        customerId:service.customerId || '',
      newsId:service.newsId || '',
        heading: service.Heading || '',
        description: service.Description || '',
        imageUrl:service.ImageUrl || '',
        videoUrl:service.VideoUrl || '',
      }}
      validationSchema={Yup.object().shape({
        heading: Yup.string().max(50).required("Heading  is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        // console.log("Submitting the request", businessId, addedBy, values);
        try {
          await dispatch(createNews1(addedBy, values));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("News Updated", {
            variant: "success",
          });
          // refreshPage()
          dispatch(fetchNews(service.customerId));
          navigate("/app/management/news/view");
        
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("customerId or name Exits", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Update News" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.customerId && errors.customerId
                    )}
                    fullWidth
                    helperText={
                      touched.customerId && errors.customerId
                    }
                    label="Customer Id"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.newsId && errors.newsId
                    )}
                    fullWidth
                    helperText={
                      touched.newsId && errors.newsId
                    }
                    label="News Id"
                    name="newsId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newsId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.heading && errors.heading
                    )}
                    fullWidth
                    helperText={
                      touched.heading && errors.heading
                    }
                    label="Heading"
                    name="heading"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.heading}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.description && errors.description
                    )}
                    fullWidth
                    helperText={
                      touched.description && errors.description
                    }
                  
                    label="Description"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
            <Paper
            sx={{
              width: 500,
              height: 320,
              background: "test",
              backgroundSize: "cover",
              mb: 1,
            }}
          >
            <h1>Image</h1>
            <ImageEditor type={IMAGE_TYPE.NEWSIMAGE} inImage={imgURL} customerId={cusId} id={newsId}/>  
          </Paper>
              </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.videoUrl && errors.videoUrl
                    )}
                    fullWidth
                    helperText={
                      touched.videoUrl && errors.videoUrl
                    }  
                    label="Video Url"
                    name="videoUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.videoUrl}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
         Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

NewsEditForm.propTypes = {
  className: PropTypes.string,
};

export default NewsEditForm;


