import React from 'react';
import {
  Container
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Header from './Header';
import { useSelector } from 'react-redux';
import EditPassword from './EditPassword';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function AdminEditPassword() {

  const classes = useStyles();

  const account = useSelector((state) => state.account);
  // console.log("account",account)
  const email = account.user.email

    if (!account) {
      return null;
    }
  
  return (
    <Page
      className={classes.root}
      title="Edit Password"
    >
      <Container maxWidth={false}>
        <Header />
        <br />
       <EditPassword email={email}/>
      </Container>
    </Page>
  );
}

export default AdminEditPassword;
