import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 36,
        justifyContent: 'flex-start',
        width: '50%'
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
        fontSize: 12,
        fontStyle: 'bold',
        color: '#3778C2',
    },
    label: {
        width: 120,
        alignItems: 'left',
    },
    label1: {
        width: 100,
        alignItems: 'left',
        fontSize: 12,
        fontStyle: 'bold',
        // marginTop:5
    },
    invoiceDateContainer1: {
        flexDirection: 'row',
        // justifyContent: 'flex-end',
        alignItems: 'right',
    },
});

const BillTo = ({ invoice }) => {
    // console.log("invoice",invoice);
return(
    <View style={styles.headerContainer}>
    <Text style={styles.billTo}>Business Details:</Text>
    <View style={styles.invoiceDateContainer1}>
        <Text style={styles.label}>BUSINESS NAME :- </Text>
        <Text >{invoice.businessName}</Text>
    </View >
    <View style={styles.invoiceDateContainer1}>
        <Text style={styles.label}>GST NO:- </Text>
        <Text>{invoice.gstin}</Text>
    </View >
</View>
)
   
};

export default BillTo;