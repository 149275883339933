import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createPaymentUpdate, fetchPaymentCredentialsInfo } from "src/actions/mgmtCustomerCreateAction";
import { PAYMENTGATEWAY_TYPE } from "src/utils/constants";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function CustomerPaymentCredentials({ className, paymentGWDetails, paymentGWList, customerId, isDisabled, ...rest }) {
  // console.log("CustomerPaymentCredentials() :: paymentGWDetails: ", paymentGWDetails, " customerId: ", customerId);

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // setPaymentType(paymentGWDetails?.paymentType);
  }, [paymentGWDetails, paymentGWList]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        customerId: customerId,
        paymentMode: paymentGWDetails?.paymentMode || 'OFFLINE',
        gatewayPayKeyId: paymentGWDetails?.gatewayPayKeyId || '',
        gatewayPayKeySecret: paymentGWDetails?.gatewayPayKeySecret || '',
        gatewayType: paymentGWList?.find(item => item.gatewayType === paymentGWDetails?.gatewayType),
        keyIndex: paymentGWDetails?.keyIndex || '',
        accessCode: paymentGWDetails?.accessCode || '',
        paymentCredentialId: paymentGWDetails?.paymentCredentialId || '',
        paymentServiceUrl: paymentGWDetails?.paymentServiceUrl || '',
      }}
      validationSchema={Yup.object().shape({

      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {        
        // console.log("CustomerPaymentCredentials() :: OnSubmit -> values: ", values);
        try {
          await dispatch(createPaymentUpdate(values));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Payment Credentials Updated Successfully", {
            variant: "success",
          });
          dispatch(fetchPaymentCredentialsInfo(customerId));
          navigate("/app/management/customer/view");
        } catch (err) {        
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Payment Credentials Updated UnSuccessfully", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.customerId && errors.customerId
                    )}
                    fullWidth
                    helperText={
                      touched.customerId && errors.customerId
                    }
                    label="customerId"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    value={values.paymentMode}
                    onChange={(event, newValue) => {
                      setFieldValue("paymentMode", newValue);
                    }}
                    disabled={isDisabled}
                    disablePortal
                    id="paymentMode"
                    options={PAYMENTGATEWAY_TYPE}
                    required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    error={errors.paymentMode}
                    helperText={errors.paymentMode}
                    renderInput={(params) => (
                      <>
                        {" "}
                        <TextField {...params} label="Payment Mode"
                          error={Boolean(touched.paymentMode && errors.paymentMode)}
                          helperText={touched.paymentMode && errors.paymentMode}
                        />
                      </>
                    )}
                  />
                </Grid>
                {(values.paymentMode === "ONLINE") &&
                  <Grid item xs={12} >
                    <Autocomplete
                      value={values.gatewayType}
                      onChange={(event, newValue) => {
                        console.log("Autocomplete: gatewayType", newValue);
                        setFieldValue("gatewayType", newValue);
                      }}
                      disablePortal
                      id="gatewayType"
                      name="gatewayType"
                      options={paymentGWList}
                      getOptionLabel={(option) => option.gatewayType}
                      fullWidth
                      isOptionEqualToValue={(option, value) =>
                        option.gatewayType === value.gatewayType
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Select Payment Gateway Type" />
                      )}
                    />
                  </Grid>
                }
                {(values.paymentMode === "ONLINE") &&
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.gatewayPayKeyId && errors.gatewayPayKeyId
                      )}
                      fullWidth
                      helperText={
                        touched.gatewayPayKeyId && errors.gatewayPayKeyId
                      }
                      label="Merchant Id/ Key Id"
                      name="gatewayPayKeyId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.gatewayPayKeyId}
                      variant="outlined"
                    />
                  </Grid>
                }

                {(values.paymentMode === "ONLINE") &&
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.paymentServiceUrl && errors.paymentServiceUrl
                      )}
                      fullWidth
                      helperText={
                        touched.paymentServiceUrl && errors.paymentServiceUrl
                      }
                      label="Payment Service URL"
                      name="paymentServiceUrl"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.paymentServiceUrl}
                      variant="outlined"
                    />
                  </Grid>
                }
                {(values.paymentMode === "ONLINE") &&
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.paymentCredentialId && errors.paymentCredentialId
                      )}
                      fullWidth
                      helperText={
                        touched.paymentCredentialId && errors.paymentCredentialId
                      }
                      label="Payment Credential Id"
                      name="paymentCredentialId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.paymentCredentialId}
                      variant="outlined"
                    />
                  </Grid>
                }

                {(values.paymentMode === "ONLINE") &&
                  <Grid item xs={(values.gatewayType?.gatewayType === "PhonePe") ? 8 : 12}>
                    <TextField
                      error={Boolean(
                        touched.gatewayPayKeySecret && errors.gatewayPayKeySecret
                      )}
                      fullWidth
                      helperText={
                        touched.gatewayPayKeySecret && errors.gatewayPayKeySecret
                      }
                      label="Secret / Key"
                      name="gatewayPayKeySecret"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.gatewayPayKeySecret}
                      variant="outlined"
                    />
                  </Grid>
                }

                {(values.gatewayType?.gatewayType === "PhonePe") &&
                  <Grid item xs={4}>
                    <TextField
                      error={Boolean(
                        touched.keyIndex && errors.keyIndex
                      )}
                      fullWidth
                      helperText={
                        touched.keyIndex && errors.keyIndex
                      }
                      label="Key Index"
                      name="keyIndex"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.keyIndex}
                      variant="outlined"
                    />
                  </Grid>
                }

                {(values.gatewayType?.gatewayType === "CCAvenue") &&
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.accessCode && errors.accessCode
                      )}
                      fullWidth
                      helperText={
                        touched.accessCode && errors.accessCode
                      }
                      label="Access Code"
                      name="accessCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.accessCode}
                      variant="outlined"
                    />
                  </Grid>
                }
              </Grid>
            </CardContent>
          </Card>

          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}

          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              style={{ marginBottom: '5em' }}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

CustomerPaymentCredentials.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,

};

export default CustomerPaymentCredentials;