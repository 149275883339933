import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types';
import Delete from '@mui/icons-material/Delete';
import axios from 'src/utils/axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import GradeIcon from '@mui/icons-material/Grade';
import AdjustIcon from '@mui/icons-material/Adjust';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  SvgIcon,
} from '@mui/material';
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon
} from 'react-feather';
import ResultsTarget from './TargetResults';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from 'src/actions/accountActions';
import { useEffect } from 'react';


function Results({ className, customerId, ...rest }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log("Results Users : ", id);

  // const customer15 = useSelector(
  //   (state) => state?.user?.users1
  // );

  // const selectedCustomerId = (customerId) ? customerId : customer15;

  // const user = useSelector(
  //   (state) => state?.account?.user?.userType
  // );

  // const customer1 = useSelector(
  //   (state) => state?.account?.user?.customerId
  // );

  // const userType = user

  // const cid12 = (userType === "SUPER_ADMIN") ? selectedCustomerId : customer1;


  useEffect(() => {
    dispatch(fetchUserData(customerId));
  }, [customerId]);


  const userData = useSelector((state) => state?.user?.userData);

  const users = userData ? userData : []

  const [isShown1, setIsShown1] = useState(false);
  const [servi, setServi] = useState({});
  const handleClick1 = (serviceRow) => {
    setServi(serviceRow);
    setIsShown1(current => !current);
  };

  const handleDelete = async (userId, customerId) => {
    // console.log("categoryId", categoryId);
    try {
      const deleteResult = await axios.delete(`/api/auth/admin/user?userId=${userId}&customerId=${customerId}`);
      // console.log(`deleteResult: `, deleteResult);
      // setUserInfo(userInfo.filter((row) => row.customerId !== customerId));
      // onSubmitSuccess();
    } catch (err) { }
  };

  const columns = [
    { field: 'userId', headerName: 'User Id', width: 80 },
    { field: 'customerId', headerName: 'Customer Id', width: 100 },
    {
      field: "action",
      headerName: "Action",
      width: 170,
      renderCell: (params) => {
        // console.log("RENDERCELLPARAMS:", params)
        const status = (params.row.userType) ? `${params.row.userType}` : "SALES" || "DISTRIBUTOR";
        const subType = status
        // console.log("subType:", subType)
        const isDisabled = subType !== 'SALES' && subType !== 'DISTRIBUTOR';
        return (
          <div className="cellAction">
            <Tooltip title="Edit User">
              <IconButton
                // component={RouterLink}
                // to={`/app/management/service/update/${params.row.serviceId}`}
                onClick={() => {
                  navigate('/app/management/user/update', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <m>
              <Tooltip title="Delete User">
                <IconButton
                  onClick={() => handleDelete(params.row.userId, params.row.customerId)}
                >
                  <SvgIcon fontSize="small">
                    <Delete />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title="User Target">
                <IconButton
                  // component={RouterLink}
                  // to={`/app/management/service/update/${params.row.serviceId}`}
                  onClick={() => {
                    navigate('/app/management/user/target', { state: params.row });
                  }
                  }
                  disabled={isDisabled}
                >
                  <SvgIcon fontSize="small">
                    <GradeIcon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title="User Target Information">
                <IconButton
                  onClick={() => {
                    handleClick1(params.row);
                  }
                  }
                  disabled={isDisabled}
                >
                  <SvgIcon fontSize="small">
                    <AdjustIcon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            </m>
          </div>
        );
      },
    },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'mobile', headerName: 'Mobile', width: 120 },
    { field: 'userType', headerName: 'User Type', width: 120 },
    { field: 'isActive', headerName: 'Is Active', width: 100 },
    { field: 'GSTN', headerName: 'GSTN', width: 200 },
    { field: 'isEmailValidated', headerName: 'Is Email Validated', width: 200 },
    { field: 'isMobileValidated', headerName: 'Is Mobile Validated', width: 200 },
  ];
  return (


    <Card>

      <CardHeader title={`User Lists for Business ( ${customerId} )`} />
      <Divider />
      <CardContent>
        {isShown1 && (
          <ResultsTarget service={servi} onClick={handleClick1} />
          // <BusinessServiceView service={servi} />
        )}
        {/* {isShown1 && <Box />} */}
        {/* <br />
          <br /><br /> */}
        <Box sx={{ height: '600px', width: '95%' }}>
          <DataGrid
            rows={users}
            columns={columns}
            getRowId={(row) => row.userId}
            // checkboxSelection={true}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </Box>


      </CardContent>
    </Card>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

Results.defaultProps = {
};

export default Results;
