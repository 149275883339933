import React from 'react';
import {
  Container,
} from '@mui/material';
import Page from "src/components/Page";
import { makeStyles } from '@mui/styles';
import Header from "./Header";
import QuotationCreateForm from "./QuotationCreateForm";
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
}));

function QuotationCreate({id1,customer,s,onClick,quotation1}) {
  const { state } = useLocation();
  const classes = useStyles();
 
  return (
    <Page className={classes.root} title="Quotation Create">
      <Container maxWidth={false} className={classes.container}>
        <Header />
        <br/>
                <QuotationCreateForm  quotation1={state} customer={customer} id1={id1} s={s} onClick={onClick}/>
      </Container>
    </Page>
  );
}

export default QuotationCreate;

