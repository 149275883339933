import axios from 'src/utils/axios';


class MgmtSearchBusiness {

  getSearchBusiness = (info, s, customer, id, customer1,categoryId,equipmentId) => new Promise((resolve, reject) => {
 console.log("id",id)
 const bid = id ? id:0
    // const bname = info ? info.categoryId : undefined;
    const bname = equipmentId
    const cid1 = (customer1 === "SUPER_ADMIN") ? bid : customer;

    let searchUrl = "/api/admin/catalog/products/categoryId?";
    searchUrl += (bname) ? `&categoryId=${bname}` : "";
    searchUrl += (cid1) ? `&customerId=${cid1}` : "";
    // console.log("SEARCHURL",searchUrl)
    axios.get(searchUrl)
      // axios.get(`/api/supplier/mgmt/suppliers?businessName=${bname}&supplierId=${bid}&email=${bemail}`)
      .then((response) => {
        // console.log("MgmtSearchBusiness(Response):" + JSON.stringify(response));
        if (response.data.data.result[0]) {
          let business = response.data.data.result;
          resolve(business);
          // setBusiness(response.data.data.suppliers);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })
}

const mgmtSearchBusiness = new MgmtSearchBusiness();

export default mgmtSearchBusiness;
