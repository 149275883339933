import { Quotation } from "src/interface/quotationIf";
import mgmtQuotation from "src/services/mgmtQuotationGenerate";

export const QUOTATION_SEARCH = '@quotation/search';
export const QUOTATION_SEARCH_SUCCESS = '@quotation/search-success';
export const QUOTATION_SEARCH_FAILURE = '@quotation/search-failure';
export const QUOTATIONINFO_INFO = '@quotationinfo/info';
export const QUOTATIONINFO_CREATE = '@quotationinfo/create';
export const QUOTATIONINFO_CREATE_SUCCESS = '@quotationinfo/create-success';
export const QUOTATIONINFO_CREATE_FAILURE = '@quotationinfo/create-failure';
export const FETCHGENERATEDQUOTATIONINFO_INFO = '@generatedquotationinfo/info';
export const FETCHGENERATEDQUOTATIONINFO_INFO_SUCCESS = '@generatedquotationinfo/create-success';
export const FETCHGENERATEDQUOTATIONINFO_INFO_FAILURE = '@generatedquotationinfo/create-failure';

export function QuotationCreate(userInfo,customer,id1,s) {
  return async (dispatch) => {
    // console.log("QUOTATIONINFO", userInfo);
    let newQuotation = new Quotation(userInfo);
    // console.log("QUOTATIONAction():" + JSON.stringify(userInfo) + " " + JSON.stringify(newQuotation));
    try {
      dispatch({ type: QUOTATION_SEARCH });
      const quotation = await mgmtQuotation.createQuotation(newQuotation,customer,id1,s);
      dispatch({
        type: QUOTATION_SEARCH_SUCCESS,
        payload: {
          quotation
        }
      });
    } catch (error) {
      dispatch({ type: QUOTATION_SEARCH_FAILURE });
      throw error;
    }
  };
}

export function fetchQuotationRequestList(cid) {

  return async (dispatch) => {
    try {
      dispatch({ type: QUOTATIONINFO_INFO });
      const quoreqlist = await mgmtQuotation.fetchQuoReqList(cid);
      dispatch({
        type: QUOTATIONINFO_CREATE_SUCCESS,
        payload: {
          quoreqlist,
        },
      });
    } catch (error) {
      dispatch({ type: QUOTATIONINFO_CREATE_FAILURE });
      throw error;
    }
  };
}

export function fetchGeneratedQuotationList(cid) {
console.log("cid",cid);
  return async (dispatch) => {

    try {
      dispatch({ type: FETCHGENERATEDQUOTATIONINFO_INFO });
      const generatedQuotation = await mgmtQuotation.fetchGeneratedQuotationList(cid);
      dispatch({
        type: FETCHGENERATEDQUOTATIONINFO_INFO_SUCCESS,
        payload: {
          generatedQuotation,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type:FETCHGENERATEDQUOTATIONINFO_INFO_FAILURE });
      throw error;
    }
  };
}





