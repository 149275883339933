import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import { useLocation } from "react-router-dom";
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles'
import Page from 'src/components/Page';
import Header from './Header';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import CategoryEditForm from './CategoryEditForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function CategoryEdit({ }) {
  const { state } = useLocation();
  const cusId= state.customerId
  const id = cusId

  const classes = useStyles();

  const isMountedRef = useIsMountedRef();

  const [category, setCategory] = useState();
 
  const getCategory = useCallback(() => {
    setCategory(state)
  }, [isMountedRef]);

  useEffect(() => {
    getCategory();
  }, [getCategory]);

  if (!category) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Category Edit"
    >
      <Container maxWidth="lg">
        <Header />
        <br />
        <CategoryEditForm category={category} id={id}/>
      </Container>
    </Page>
  );
}

export default CategoryEdit;

