import axios from 'src/utils/axios';

class MgmtQuotation {

  createQuotation = (newQuotation,customer,id1,s) => new Promise((resolve, reject) => {

    const payload = newQuotation;
    const cds = `/api/catalog/quotation?customerId=${id1}`;
    const cda = `/api/catalog/quotation`;
    const cid1 = (s === "SUPER_ADMIN" ) ? cds : cda;

    axios.post(cid1, payload)
      .then((response) => {
        console.log("MgmtUser(Response):" + JSON.stringify(response.data.data.items));
        if (response.data.data.items) {
          let users = response.data.data.items;
          resolve(users);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  fetchQuoReqList = (cid) => new Promise((resolve, reject) => {
    const selectedCustomerId = (cid) ? cid : "undefined";
    axios
      .get(`/api/catalog/quotation/request-list?&customerId=${selectedCustomerId}`)
      .then((response) => {
        // console.log("MgmtUser(fetchUsers->Response): 1", response);
        if (response?.data) {
          // console.log("MgmtUser(fetchUsers)", users);
          const nodata = []
          const quoreqlist = (response?.data?.data) ? response.data.data : nodata;
          resolve(quoreqlist);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  fetchGeneratedQuotationList = (cid) => new Promise((resolve, reject) => {
    const selectedCustomerId = (cid) ? cid : "undefined";
    axios
      .get(`/api/catalog/quotation?customerId=${selectedCustomerId}`)
      .then((response) => {
        // console.log("MgmtUser(fetchUsers->Response): 1", response);
        if (response?.data) {
          // console.log("MgmtUser(fetchUsers)", users);
          const nodata = []
          const quoreqlist = (response?.data?.data) ? response.data.data : nodata;
          resolve(quoreqlist);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

}

const mgmtQuotation = new MgmtQuotation();

export default mgmtQuotation;
