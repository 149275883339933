import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

import axios from 'axios'
import PropTypes from 'prop-types';
import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
// import PdfDocument from 'src/components/generateInvoice/Invoice';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import PdfDocument from './generateInvoice/Invoice';

function OrderPdf({ className, serviceId, ...rest}) {
  const title = "ORDER"
  const totitle = "BILL TO"
  const {state} = useLocation();
  const InvoiceData = state;
  console.log("InvoiceData",InvoiceData);
  const fileName = "Order.pdf";
 
    
  return (
    <div className="App">
      <br/>
      <PDFViewer width={800} height={600} showToolbar={false} style={{marginLeft:"5em"}}>
        <PdfDocument invoicedata={InvoiceData} title={title} totitle={totitle}/>
      </PDFViewer>
    </div>
  );
}

export default OrderPdf;