import axios from 'src/utils/axios';


class MgmtNews {

  createNews = (newNews,image) => new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('customerId',newNews.customerId);
    formData.append('heading',newNews.heading);
    formData.append('description',newNews.description);
    formData.append('newsImage',image);
    formData.append('videoUrl',newNews.videoUrl);
   
    axios.post('/api/admin/customer/info/news',formData)
    .then((response) => {
      console.log("MgmtService(Response):" + JSON.stringify(response));
      if(response.data){
        let services = response.data;
        resolve(services);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  createNews1 = (newNews) => new Promise((resolve, reject) => {

    // console.log("newNews",newNews)
    const formData = new FormData()
    formData.append('customerId',newNews.customerId);
    formData.append('newsId',newNews.newsId);
    formData.append('heading',newNews.heading);
    formData.append('description',newNews.description);
    // formData.append('newsImage',image);
    formData.append('videoUrl',newNews.videoUrl);
    axios.post('/api/admin/customer/info/news',formData)
    .then((response) => {
      console.log("MgmtService(Response):" + JSON.stringify(response));
      if(response.data){
        let services = response.data;
        resolve(services);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  fetchNews = (cid) => new Promise((resolve, reject) => {
    // const cusid = (userType === "SUPER_ADMIN") ? cid : customerId;
    axios
    .get('/api/customer/info/whatsnews', {
      headers: {
        'customerId': `${cid}`
      }
    })
      .then((response) => {
        // console.log("MgmtUser(fetchUsers->Response): 1", response);
        if (response?.data) {
           const nodata = []
           const news = (response?.data?.data) ? response.data.data : nodata;
          // console.log("MgmtUser(fetchUsers)", users);
          resolve(news);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

}

const mgmtNews = new MgmtNews();

export default mgmtNews;
