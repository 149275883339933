import React, {
  useEffect,
  useState,
} from 'react';
import {
  Autocomplete,
  Box,
  Container,
  Grid,
  Paper,
  Divider,
  Card,
  TextField,
  Typography,
  CardContent,
} from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { fetchUsers } from 'src/actions/idActions';
import { fetchMonthlygraphData, fetchweeklygraphData, financialTodaySalesAndOrdersData, financialTotalSalesAndOrderData } from 'src/actions/mgmtDashBoardAction';
import FinancialDashboard from './FinancialDashboard';
import Support from './supportAlertMessage';
import { fetchUsers5 } from 'src/actions/mgmtSelectValueAction';

const options = {
  indexAxis: 'x',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'center',
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function FinancialDashboardMain() {

  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  const handleChangeCustomer = (event, newValue) => {
    setSelectedCustomerId(newValue);
    setId(newValue?.customerId);
    // console.log("FinancialDashboard: handleChangeCustomer() : customerId: ", newValue?.customerId, JSON.stringify(newValue));

    if (event) {
      dispatch(fetchUsers5(newValue?.customerId));
      dispatch(financialTotalSalesAndOrderData(newValue?.customerId));
      dispatch(financialTodaySalesAndOrdersData(newValue?.customerId));
      dispatch(fetchweeklygraphData(newValue?.customerId, customerId, customerType));
      dispatch(fetchMonthlygraphData(newValue?.customerId, customerId, customerType));
    }
  };

  // Custom filter function to match the input value against businessName.
  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) =>
      option.businessName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };


  const weeklygraphData = useSelector(
    (state) => state?.financialData?.fetchweeklygraphData
  )

  const monthlygraphData = useSelector(
    (state) => state?.financialData?.fetchMonthlygraphData
  )

  const monthData = monthlygraphData
  const weekData = weeklygraphData

  const [data, setData] = useState({
    datasets: [4, 1, 5, 4,
    ],
  });

  const [weekdata, setWeekData] = useState({
    datasets: [4, 1, 5, 4,
    ],
  });

  const fetchFinancialMonthData = async () => {
    const dataSet1 = [];
    const dataSet2 = [];
    const dataSet8 = [];

    for (const val of monthData) {
      dataSet1.push(val.totalOrders);
      dataSet2.push(val._id)
    }
    setData({
      labels: dataSet2,
      datasets: [
        {
          label: 'Total Sales',
          data: dataSet1,
          borderColor: 'rgb(28,119,171)',
          backgroundColor: 'rgb(28,119,171)',
        },
      ],
    })
  }

  const fetchFinancialWeekData = async () => {

    const dataSet1 = [];
    const dataSet2 = [];
    const dataSet8 = [];

    for (const val of weekData) {
      dataSet1.push(val.totalOrders);
      dataSet2.push(val._id)
    }
    setWeekData({
      labels: dataSet2,
      datasets: [
        {
          label: 'Total Sales',
          data: dataSet1,
          borderColor: 'rgb(28,119,171)',
          backgroundColor: 'rgb(28,119,171)',
        },
      ],
    })
  }

  const classes = useStyles();
  const dispatch = useDispatch();

  const [id, setId] = React.useState('');

  const customerId = useSelector(
    (state) => state?.account?.user?.customerId
  );

  const customerType = useSelector(
    (state) => state?.account?.user?.userType
  );

  const handleChange = (event) => {
    setId(event.target.value);
    if (event) {
      dispatch(fetchUsers5(event.target.value));
      dispatch(financialTotalSalesAndOrderData(event.target.value));
      dispatch(financialTodaySalesAndOrdersData(event.target.value));
      dispatch(fetchweeklygraphData(event.target.value, customerId, customerType));
      dispatch(fetchMonthlygraphData(event.target.value, customerId, customerType));
    }
  };

  useEffect(() => {
    dispatch(fetchweeklygraphData(customerId));
  }, []);

  useEffect(() => {
    dispatch(fetchMonthlygraphData(customerId));
  }, []);

  const customerIdList = useSelector(
    (state) => state?.user?.users?.customerList
  );

  const userTy = useSelector(
    (state) => state?.account?.user?.userType
  );

  const userType = userTy

  const cusId = (userTy === "SUPER_ADMIN") ? id : customerId;

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    dispatch(financialTotalSalesAndOrderData(cusId));
  }, []);

  useEffect(() => {
    dispatch(financialTodaySalesAndOrdersData(cusId));
  }, []);


  useEffect(() => {
    fetchFinancialMonthData();
    fetchFinancialWeekData()
  }, [monthlygraphData, weeklygraphData]);

  if (!customerIdList) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Financial Dashboard"
    >
      <Typography
        variant="h3"
        color="textPrimary"
        style={{ marginLeft: "1em" }}
      >
        Financial Dashboard
      </Typography>
      <Container maxWidth={false}>
        {
          userType !== "ADMIN" && userType !== "SUPPORT" ?
            <Card style={{ marginLeft: "0.5em", maxWidth: '360px', maxHeight: '400px', marginTop: "1em" }}>
              <CardContent>
                <Grid container spacing={2}> <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="customer-id-autocomplete"
                      options={customerIdList}
                      getOptionLabel={(option) => option.customerId + "..." + option.businessName}
                      fullWidth
                      value={selectedCustomerId}
                      onChange={handleChangeCustomer}
                      filterOptions={filterOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select CustomerId"
                          variant="outlined"
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          {option.customerId} ... {option.businessName}
                        </li>
                      )}
                    />
                  </FormControl>
                  {/* <FormControl fullWidth>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select CustomerId</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          // id="demo-simple-select"
                          label="Select CustomerId"
                          onChange={handleChange}
                        >
                          {customerIdList.map((idlist) => (
                            <MenuItem value={idlist.customerId}>

                              {idlist.customerId}
                              {"..."}
                              {idlist.businessName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FormControl> */}
                </Grid>
                </Grid>
              </CardContent>
            </Card> :
            <FinancialDashboard />
        }

        {
          id > 0 ?
            <FinancialDashboard /> :
            <Box />
        }
        <Grid container spacing={3}>
          {userType === "SUPPORT" ?
            <Support />
            :

            <Grid item xs={12} sm={7} md={6} >
              {
                cusId > 0 ?
                  <Card component={Paper} style={{ marginTop: "2em", width: 572 }}>
                    <CardContent>
                      <Typography variant='h6' className={classes.cardTitle} align='left'>
                        Monthly Day Wise Order Sales
                      </Typography>
                    </CardContent>
                    <Divider />
                    <CardContent>
                      <Bar data={data} options={options} />
                    </CardContent>
                  </Card> :
                  <Box />
              }
            </Grid>
          }
          {userType === "SUPPORT" ?
            <Box /> :
            <Grid item xs={12} sm={7} md={6} >
              {
                cusId > 0 ?
                  <Card component={Paper} style={{ marginTop: "2em", width: 572 }}>
                    <CardContent>
                      <Typography variant='h6' className={classes.cardTitle} align='left'>
                        Weekly Day Wise Order Sales
                      </Typography>
                    </CardContent>
                    <Divider />
                    <CardContent>
                      <Bar data={weekdata} options={options} />
                    </CardContent>
                  </Card> :
                  <Box />
              }
            </Grid>
          }
        </Grid>
      </Container>
    </Page>
  );
}

export default FinancialDashboardMain;


