class ProductUpload {

  constructor(updatedBy, info) {
    this.customerId = info.customerId;
    this.categoryId = info.categoryId;
    this.excelSheet = info.excelSheet;
    this.productName = info.productName;
    this.price = info.price;
    this.make = info.make;
    this.productSKU = info.productSKU;
    this.baseImageUrl = info.baseImageUrl;
    this.imageURL = info.imageURL;
    this.brandName = info.brandName;
    this.categoryName = info.categoryName;
    this.updatedBy = updatedBy;
  }

}

export default { ProductUpload };
export { ProductUpload };



