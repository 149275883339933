import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Typography,
} from "@mui/material";


const MperitoCard = forwardRef(({
    title,
    action,
    children,
    ...rest
}, ref) => {

    return (
        <Card ref={ref} {...rest}>
            { action && <CardHeader 
                title={title} 
                action={
                    <Button onClick={action.callback} variant="outlined" disabled={action.disabled}>
                      {action.label}
                    </Button>
                  }                
                /> }
            {!action && <CardHeader title={title} /> }
            <Divider />
            {children && 
            <CardContent>
                <Grid container spacing={2}>
                    {children}
                </Grid>
            </CardContent> }
        </Card>

    );
});

MperitoCard.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
};

export default MperitoCard;
