export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const currencyFormatterINR = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
});

const fraction = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const usdPrice = {
  type: "number",
  width: 130,
  valueFormatter: ({ value }) => (value ? currencyFormatter.format(value) : ""),
  cellClassName: "font-tabular-nums",
};

export const inrPrice = {
  type: "number",
  width: 130,
  valueFormatter: ({ value }) => (value ? currencyFormatterINR.format(value) : ""),
  cellClassName: "font-tabular-nums",
};

export const percentDecimal = {
  type: "number",
  width: 130,
  valueFormatter: ({ value }) => (value ? fraction.format(value) : ""),
  cellClassName: "font-tabular-nums",
};

export const numberFormatUSD = (value) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);

export const numberFormatINR = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);


export const numberFormat = (value) =>
  new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    // style: "currency",
    // currency: "USD",
  }).format(value);

 export const numberDecimal = (value) => {
    // Ensure the input is a valid number
    const parsedNumber = parseFloat(value);
  
    if (isNaN(parsedNumber)) {
      return value;
    }
  
    // Use the toFixed method to format the number with 2 decimal places
    const formattedNumber = parseFloat(parsedNumber.toFixed(2));
    //const formattedNumber = Math.round(parsedNumber * 100) / 100;
  
    return formattedNumber;
  }