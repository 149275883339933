import { Category } from 'src/interface/categoryIf';
import mgmtCategory from 'src/services/mgmtCategory';
export const CATEGORIES_INFO = '@category/info';
export const CATEGORY_CREATE = '@category/create';
export const CATEGORY_CREATE_SUCCESS = '@category/create-success';
export const CATEGORY_CREATE_FAILURE = '@category/create-failure';
export const CATEGORY_UPDATE = '@category/update';
export const CATEGORY_UPDATE_SUCCESS = '@category/update-success';
export const CATEGORY_UPDATE_FAILURE = '@category/update-failure';

export const CATEGORY_DELETE = '@category/delete';
export const CATEGORY_DELETE_SUCCESS = '@category/delete-success';
export const CATEGORY_DELETE_FAILURE = '@category/delete-failure';


export function createCategory(userId, categoryInfo,image,id,userType) {
  return async (dispatch) => {
    // console.log("createCategory():", userId, categoryInfo);
    let newCategory = new Category(userId, categoryInfo);
    newCategory.addedBy = userId;
    // console.log("createCategory():" + JSON.stringify(categoryInfo) + " " + JSON.stringify(newCategory));
    try {
      dispatch({ type: CATEGORY_CREATE });
      const category = await mgmtCategory.createCategory(newCategory,image,id,userType);
      // console.log("createCategory()(after):" + JSON.stringify(category));
      dispatch({
        type: CATEGORY_CREATE_SUCCESS,
        payload: {
          category
        }
      });
    } catch (error) {
      dispatch({ type: CATEGORY_CREATE_FAILURE });
      throw error;
    }
  };
}

export function updateCategory(userId,categoryInfo,image) {
  return async (dispatch) => {
    // console.log("CATEGORYINFO", userId,categoryInfo);
    let newCategoryupdate = new Category(userId,categoryInfo);
    newCategoryupdate.addedBy = userId;
    // console.log("editCategoryAction():" + JSON.stringify(categoryInfo) + " " + JSON.stringify(newCategoryupdate));
    try {
      dispatch({ type: CATEGORY_UPDATE });
      const category = await mgmtCategory.updateCategory(categoryInfo,image);
      dispatch({
        type: CATEGORY_UPDATE_SUCCESS,
        payload: {
          category
        }
      });
    } catch (error) {
      dispatch({ type: CATEGORY_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function deleteCategory(customerId, categoryId) {
  return async (dispatch) => {
    // console.log("deleteCategory() ", customerId,categoryId);

    try {
      dispatch({ type: CATEGORY_DELETE });
      const category = await mgmtCategory.deleteCategory(customerId, categoryId);
      dispatch({
        type: CATEGORY_DELETE_SUCCESS,
        payload: {
          categoryId
        }
      });
    } catch (error) {
      // console.log("deleteCategory() action error: ", error);
      dispatch({ type: CATEGORY_DELETE_FAILURE });
      throw error;
    }
  };
}

