class CustomerUpload {
  constructor( info) {
    this.GSTN = info.GSTN;
    this.excelSheet = info.excelSheet;
    this.about = info.about;
    this.logoFile = info.logoFile;
    this.shortLogo = info.shortLogo;
    this.bannerFiles = info.bannerFiles;
    this.brandDetails = info.brandDetails;
    this.businessName = info.businessName;
    this.certificates = info.certificates;
    this.customerId = info.customerId;
    this.businessLocation = info.businessLocation;
    this.showQuotationRequest = info.showQuotationRequest;
    this.helpLine = info.helpLine;
    this.isEngineeringType = info.isEngineeringType;
    this.license = info.license;
    this.mobile = info.mobile;
    this.paymentGateway = info.paymentGateway;
    this.privacyPolicy = info.privacyPolicy;
    this.registeredEmail = info.registeredEmail;
    this.registeredAddress = info.registeredAddress;
    this.brandName = info.brandName;
    this.appName = info.appName;
    this.appDescription = info.appDescription;
    this.bannerContactAddress = info.bannerContactAddress;
    this.website = info.website;
    this.facebookUrl = info.facebookUrl;
    this.twitterUrl = info.twitterUrl;
    this.instagramUrl = info.instagramUrl;
    this.linkedInUrl = info.linkedInUrl;
    this.youtubeUrl = info.youtubeUrl;
    this.aboutUs = info.aboutUs;
    this.showContacts = info.showContacts;
    this.showDistributor = info.showDistributor;
    this.showMaintenanceTroubleshooting = info.showMaintenanceTroubleshooting;
    this.showServiceCenters = info.showServiceCenters;
    this.whatsApp = info.whatsApp;
    this.primaryColor = info.primaryColor;
    this.secondaryColor = info.secondaryColor;
    this.scaffoldColor = info.scaffoldColor;
    this.backgroundColor = info.backgroundColor;
    this.fontFamily = info.fontFamily;
    this.razorpayKeyId = info.razorpayKeyId;
    this.razorpayKeySecret = info.razorpayKeySecret;
   
  }
}

class CustomerLogoAndBannerUpload {
  constructor( info) {
    this.GSTN = info.GSTN;
    this.excelSheet = info.excelSheet;
    this.about = info.about;
    this.logoFile = info.logoFile;
    this.bannerFiles = info.bannerFiles;
  
  }
}




export default { CustomerUpload,CustomerLogoAndBannerUpload };
export { CustomerUpload,CustomerLogoAndBannerUpload };



