import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#3778C2'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#3778C2',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
});

const InvoiceTableFooter1 = ({ items}) => {
    // console.log(`InvoiceTableFooter1() :: items.paymentDetails: `, items.paymentDetails);
    
    const sgst = items?.paymentDetails?.gst?.sgst ?? undefined;
    const cgst = items?.paymentDetails?.gst?.cgst ?? undefined;
    const igst = items?.paymentDetails?.gst?.igst ?? undefined;
    // console.log("discount",total)
    // console.log(`InvoiceTableFooter1() :: sgst: `, sgst, ` cgst: `, cgst, ` igst: `, igst);

    return (
        <View style={styles.row} >
            <Text style={styles.description}>SGST</Text>
            <Text style={styles.total}>{sgst}</Text>
        </View>
        
        
    )
};

export default InvoiceTableFooter1;