import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { CardMedia, Tooltip, Box } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import axios from 'src/utils/axios';
import Delete from '@mui/icons-material/Delete';
import { CameraAlt as CameraAltIcon } from "@mui/icons-material";
import { IMAGE_TYPE } from "src/config";
import { getFileNameByImageType } from "src/utils/constantUtils";
import { uploadImage } from "src/actions/imageActions";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { fetchNews } from "src/actions/mgmtNewsAction";
import { fetchAdminCustomerList, fetchCustomerList } from "src/actions/mgmtCustomerCreateAction";
import { fetchGreeting } from "src/actions/mgmtGreetingAction";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function ImageEditor({ type, inImage, customerId, id, service, index, deletetype }) {
  const defaultImg = "/static/home/defaultImage.png";

  const handleDelete = async (deletetype, index, service) => {
    if (deletetype === "customer") {
      handleBannerDelete(index, service)
    }
    handleProductImageDelete(index, service)
  }

  const handleBannerDelete = async (index, service) => {
    const brandId = service.brandDetails[0].brandId
    try {
      const deleteResult = await axios.delete(`/api/admin/customer/delete-banner?customerId=${customerId}&brandId=${brandId}&bannerIndex=${index}`);
      enqueueSnackbar("Banner Deleted", {
        variant: "success",
      });
      dispatch(fetchCustomerList(customerId));
      navigate("/app/management/customer/view");
    } catch (err) { }
  };

  const handleProductImageDelete = async (index, service) => {
    const customerId = service.customerId
    const productId = service.productId
    try {
      const deleteResult = await axios.delete(`/api/admin/catalog/delete-imageurl?customerId=${customerId}&productId=${productId}&imageIndex=${index}`);
      enqueueSnackbar("Image Deleted", {
        variant: "success",
      });
      navigate("/app/management/product/view");
    } catch (err) { }
  };


  const alertMessage = (type === "newsimage") ? "News Image Updated" :
    (type === "logo") ? "Logo Image Updated" :
      (type === "shortlogo") ? "ShortLogo Image Updated" :
        (type === "greetingimage") ? "Greeting Image Updated" :
          (type === "categoryimage") ? "Category Image Updated" :
            (type === "baseimage") ? "Base Image Updated" :
              (type === "banner1") ? "Banner Image Upload" :
                (type === "updatebanner1") ? "Banner Image Updated" :
                  (type === "banner2") ? "Banner Image Upload" :
                    (type === "updatebanner2") ? "Banner Image Updated" :
                      (type === "banner3") ? "Banner Image Upload" :
                        (type === "updatebanner3") ? "Banner Image Updated" :
                          (type === "banner4") ? "Banner Image Upload" :
                            (type === "updatebanner4") ? "Banner Image Updated" :
                              (type === "banner5") ? "Banner Image Upload" :
                                (type === "updatebanner5") ? "Banner Image Updated" :
                                  (type === "banner6") ? "Banner Image Upload" :
                                    (type === "updatebanner6") ? "Banner Image Updated" :
                                      (type === "productimage1") ? "product Image Upload" :
                                        (type === "updateproductimage1") ? "Product Image Updated" :
                                          (type === "productimage2") ? "product Image Upload" :
                                            (type === "updateproductimage2") ? "Productimage Image Updated" :
                                              (type === "productimage3") ? "product Image Upload" :
                                                (type === "updateproductimage3") ? "Product Image Updated" :
                                                  (type === "productimage4") ? "product Image Upload" :
                                                    (type === "updateproductimage4") ? "Product Image Updated" :
                                                      (type === "productimage5") ? "product Image Upload" :
                                                        (type === "updateproductimage5") ? "Product Image Updated" :
                                                          (type === "productimage6") ? "product Image Upload" :
                                                            (type === "updateproductimage6") ? "Product Image Updated" : "not"

  const redirectPath = (type === "newsimage") ? "/app/management/news/view" : (type === "logo") ? "/app/management/customer/view" : "not"

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const uploadFile = useRef(type);
  const [open, setOpen] = React.useState(false);
  const [zoom, setZoom] = useState(1);
  const [zoom1, setZoom1] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [rotation, setRotation] = useState(0);
  const handleZoomChange = (e) => {
    setZoom1(parseFloat(e.target.value));
  };

  const handleRotateChange = (e) => {
    setRotate(parseFloat(e.target.value));
  };
  const [state, setState] = useState({
    editor: "",
    allowZoomOut: false,
    cropperOpen: false,
    img: null,
    scale: 1,

    croppedImg: inImage,
  });

  let ieHeight = 230;
  let ieWidth = 550;
  let ieBorder = 0;
  let ieBorderRadius = 0;
  let ieIconButtonPosition = 0;
  let ieIconButtonPositionX = 0;

  switch (type) {
    case IMAGE_TYPE.LOGO:
      ieHeight = 250;
      ieWidth = 500;
      ieBorder = 50;
      ieBorderRadius = 0;
      ieIconButtonPosition = -5;
      break;
    case IMAGE_TYPE.SHORTLOGO:
      ieHeight = 250;
      ieWidth = 250;
      ieBorder = 50;
      ieBorderRadius = 0;
      ieIconButtonPosition = -5;
      break;
    case IMAGE_TYPE.BANNER1:
      ieHeight = 250;
      ieWidth = 500;
      ieBorder = 50;
      ieBorderRadius = 0;
      ieIconButtonPosition = -5;
      break;
    case IMAGE_TYPE.UPDATEBANNER1:
    case IMAGE_TYPE.BANNER2:
    case IMAGE_TYPE.UPDATEBANNER2:
    case IMAGE_TYPE.BANNER3:
    case IMAGE_TYPE.UPDATEBANNER3:
    case IMAGE_TYPE.BANNER4:
    case IMAGE_TYPE.UPDATEBANNER4:
    case IMAGE_TYPE.BANNER5:
    case IMAGE_TYPE.BANNER6:
    case IMAGE_TYPE.UPDATEBANNER5:
    case IMAGE_TYPE.UPDATEBANNER6:

      ieHeight = 250;
      ieWidth = 500;
      ieBorder = 50;
      ieBorderRadius = 0;
      ieIconButtonPosition = -5;
      break;

    case IMAGE_TYPE.PRODUCT_IMAGE:
    case IMAGE_TYPE.PRODUCTIMAGE1:
    case IMAGE_TYPE.UPDATEPRODUCTIMAGE1:
    case IMAGE_TYPE.PRODUCTIMAGE2:
    case IMAGE_TYPE.UPDATEPRODUCTIMAGE2:
    case IMAGE_TYPE.PRODUCTIMAGE3:
    case IMAGE_TYPE.UPDATEPRODUCTIMAGE3:
    case IMAGE_TYPE.PRODUCTIMAGE4:
    case IMAGE_TYPE.UPDATEPRODUCTIMAGE4:
    case IMAGE_TYPE.PRODUCTIMAGE5:
    case IMAGE_TYPE.UPDATEPRODUCTIMAGE5:
    case IMAGE_TYPE.PRODUCTIMAGE6:
    case IMAGE_TYPE.UPDATEPRODUCTIMAGE6:
      ieHeight = 250;
      ieWidth = 500;
      ieBorder = 50;
      ieBorderRadius = 0;
      ieIconButtonPosition = -5;
      break;


    case IMAGE_TYPE.NEWSIMAGE:
      ieHeight = 250;
      ieWidth = 500;
      ieBorder = 50;
      ieBorderRadius = 0;
      ieIconButtonPosition = -5;
      break;
    case IMAGE_TYPE.GREETINGIMAGE:
      ieHeight = 250;
      ieWidth = 500;
      ieBorder = 50;
      ieBorderRadius = 0;
      ieIconButtonPosition = -5;
      break;
    case IMAGE_TYPE.CATEGORYIMAGE:
    case IMAGE_TYPE.TESTIMONIAL:
    case IMAGE_TYPE.SERVICE:
      ieHeight = 250;
      ieWidth = 250;
      ieBorder = 50;
      ieBorderRadius = 0;
      ieIconButtonPosition = -5;
      break;
    case IMAGE_TYPE.BASEIMAGE:
      ieHeight = 250;
      ieWidth = 500;
      ieBorder = 50;
      ieBorderRadius = 0;
      ieIconButtonPosition = -5;
      break;
    default:
      break;
  }

  useEffect(() => {
    setState({ ...state, croppedImg: inImage });
  }, [inImage]);

  const handleClickOpen = (e) => {
    setOpen(true);
    handlePhoto(e);
  };
  const handleZoomIn = () => {
    setZoom(zoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom(zoom - 0.1);
  };

  const handleRotate = () => {
    setRotation(rotation + 90);
  };


  const fileInputRef = useRef();

  const handleFileChange = (e) => {
    // console.log("change");
    let url = window.URL.createObjectURL(e.target.files[0]);

    setState({
      img: url,
      cropperOpen: true,
    });
  };

  const handleReset = () => {
    setState(null);
    setZoom(1);
    setRotation(0);
    fileInputRef.current.value = null;
  };
  const handleClose = () => {
    setOpen(false);
  };


  const handleSave = (e) => {
    if (state.editor) {

      const canvas = state.editor.getImage();
      const croppedImg = canvas.toDataURL();

      setState({
        ...state,
        img: null,
        cropperOpen: false,
        croppedImg: croppedImg,
      });

      let fileName = getFileNameByImageType(type);
      fetch(croppedImg)
        .then((res) => res.blob())
        .then((blob) => new File([blob], fileName, { type: blob.type }))
        .then((file) => dispatch(uploadImage(file, type, customerId, id, service, index)));
      switch (type) {
        case IMAGE_TYPE.NEWSIMAGE:
          dispatch(fetchNews(customerId))
          break;
        case IMAGE_TYPE.BASEIMAGE:
          dispatch(fetchGreeting(customerId));
          break;
        case IMAGE_TYPE.GREETINGIMAGE:
          dispatch(fetchGreeting(customerId));
          break;
        case IMAGE_TYPE.BANNER1:
          dispatch(fetchCustomerList(customerId));
          break;
        case IMAGE_TYPE.UPDATEBANNER1:
          dispatch(fetchCustomerList(customerId));
          break;
        case IMAGE_TYPE.BANNER2:
          dispatch(fetchCustomerList(customerId));
          break;
        case IMAGE_TYPE.UPDATEBANNER2:
          dispatch(fetchAdminCustomerList(customerId));
          break;
        case IMAGE_TYPE.BANNER3:
          dispatch(fetchCustomerList(customerId));
          break;
        case IMAGE_TYPE.UPDATEBANNER3:
          dispatch(fetchCustomerList(customerId));
          break;
        case IMAGE_TYPE.BANNER4:
          dispatch(fetchCustomerList(customerId));
          break;
        case IMAGE_TYPE.UPDATEBANNER4:
          dispatch(fetchCustomerList(customerId));
          break;
        case IMAGE_TYPE.BANNER5:
          dispatch(fetchCustomerList(customerId));
          break;
        case IMAGE_TYPE.UPDATEBANNER5:
          dispatch(fetchCustomerList(customerId));
          break;
        case IMAGE_TYPE.BANNER6:
          dispatch(fetchCustomerList(customerId));
          break;
        case IMAGE_TYPE.UPDATEBANNER6:
          dispatch(fetchCustomerList(customerId));
        case IMAGE_TYPE.LOGO:
          dispatch(fetchCustomerList(customerId));
          break;
        case IMAGE_TYPE.SHORTLOGO:
          dispatch(fetchCustomerList(customerId));
          break;
        default:
          break;
      }

      enqueueSnackbar(alertMessage, {
        variant: "success",
      })
      // navigate(redirectPath);
    }
  };

  const handleCancel = () => {
    setState({ croppedImg: inImage, cropperOpen: false });
  };

  const setEditorRef = (editor) => {
    state.editor = editor;
  };
  // console.log("inImage",state.croppedImg);
  const handleDefaultImageDisplay = (type) => {
    switch (type) {
      case IMAGE_TYPE.SHORTLOGO:
        return (
          <CardMedia component="img" height="300px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.BANNER1:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.UPDATEBANNER1:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.BANNER2:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.UPDATEBANNER2:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.BANNER3:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.UPDATEBANNER3:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.BANNER4:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.UPDATEBANNER4:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.BANNER5:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.UPDATEBANNER5:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.BANNER6:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.UPDATEBANNER6:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      
      case IMAGE_TYPE.PRODUCT_IMAGE:
          return (
            <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
          );
          break;
      case IMAGE_TYPE.PRODUCTIMAGE1:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.UPDATEPRODUCTIMAGE1:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.PRODUCTIMAGE2:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.UPDATEPRODUCTIMAGE2:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.PRODUCTIMAGE3:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.UPDATEPRODUCTIMAGE3:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.PRODUCTIMAGE4:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.UPDATEPRODUCTIMAGE4:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.PRODUCTIMAGE5:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.UPDATEPRODUCTIMAGE5:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.PRODUCTIMAGE6:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.UPDATEPRODUCTIMAGE6:
        return (
          <CardMedia component="img" height="375px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.LOGO:
        return (
          <CardMedia component="img" height="300px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.NEWSIMAGE:
        return (
          <CardMedia component="img" height="300px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.GREETINGIMAGE:
        return (
          <CardMedia component="img" height="300px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.CATEGORYIMAGE:
      case IMAGE_TYPE.TESTIMONIAL:
      case IMAGE_TYPE.SERVICE:
        return (
          <CardMedia component="img" height="300px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      case IMAGE_TYPE.BASEIMAGE:
        return (
          <CardMedia component="img" height="300px" image={state.croppedImg} style={{ height: ieHeight, width: ieWidth }} />
        );
        break;
      default: <img src="defaultImg" />

        break;
    }
  };
  <img src={defaultImg} />
  const handlePhoto = (e) => {
    uploadFile.current.click();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {handleDefaultImageDisplay(type)}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tooltip title="Select Image for Preview">
          <IconButton
            id="uploadImageBtn"
            color="primary"
            aria-label="preview picture"
            component="label"
            sx={{
              backgroundColor: "white",
              mt: ieIconButtonPosition,
              ml: ieIconButtonPositionX,
            }}
          >
            <input
              onClick={handleClickOpen}
              ref={uploadFile}
              type="file"
              accept="image/*"
              id={`contained-button-file-${type}`}
              onChange={handleFileChange}
              style={{ display: "none", }}
            />
            <CameraAltIcon />
          </IconButton>
        </Tooltip>
        {
          type === "shortlogo" || type === "logo"
            || type === "newsimage" || type === "greetingimage"
            || type === "categoryimage" || type === "baseimage" || type === "banner1"
            || type === "banner2" || type === "banner3" || type === "banner4"
            || type === "banner5" || type === "banner6" || type === "productimage1"
            || type === "productimage2" || type === "productimage3" || type === "productimage4"
            || type === "productimage5" || type === "productimage6" ?
            <Box />
            :
            <Tooltip title="Delete Banner">
              <IconButton
                id="uploadImageBtn"
                color="primary"
                aria-label="preview picture"
                component="label"
                onClick={() => handleDelete(deletetype, index, service)}
                sx={{
                  backgroundColor: "white",
                  mt: ieIconButtonPosition,
                  ml: ieIconButtonPositionX,
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
        }
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Image Editor
        </BootstrapDialogTitle>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: 500,
          }}
        >
          <AvatarEditor
            ref={setEditorRef}
            image={state.img}
            style={{
              transform: `scale(${zoom}) rotate(${rotation}deg)`,
              height: "300px",
              width: "550px",
              marginBottom: 10,
              paddingBottom: 0,
              backgroundColor: "#47e59e",
            }}
            width={ieWidth}
            height={ieHeight}
            border={ieBorder}
            quality={1}
            borderRadius={ieBorderRadius}
            color={[255, 255, 255, 0.8]}
            scale={zoom1}
            rotate={rotate}
          />
          {state.img && (
            <>
              <div>
                <label>ZoomIn & ZoomOut</label>
                <input
                  type="range"
                  min="0.1"
                  max="3"
                  step="0.01"
                  value={zoom1}
                  onChange={handleZoomChange}
                />
              </div>
              <div>
                <label>Rotate</label>
                <input
                  type="range"
                  min="0"
                  max="360"
                  step="1"
                  value={rotate}
                  onChange={handleRotateChange}
                />
                <span>{rotate}°</span>
              </div>
              <div style={{ marginBottom: "10px" }}>
                {""}
                <button style={{ marginLeft: "-1em" }} onClick={() => {
                  handleCancel();
                  handleClose();
                }}>Reset</button>
                {""}
                <button onClick={() => {
                  handleSave();
                  handleClose();
                }}>Save</button>
              </div>
              {""}
            </>
          )}
        </div>
      </BootstrapDialog>
    </div>
  );
}
ImageEditor.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  inImage: PropTypes.object.isRequired,
  supplierId: PropTypes.string,
};

export default ImageEditor;
