import { OrderStatus, OrderStatusPaid } from "src/interface/orderIf";
import orderService from "src/services/orderService";

export const FETCH_ORDERS = '@orders/fetch-orders';
export const FETCH_ORDERS_SUCCESS = '@orders/fetch-orders-success';
export const FETCH_ORDERS_FAILURE = '@orders/fetch-orders-failure';

export const FETCH_ORDERS_BY_CRITERIA = "@orders/fetch-orders-criteria";
export const FETCH_ORDERS_BY_CRITERIA_SUCCESS = "@orders/fetch-orders-criteria-success"
export const FETCH_ORDERS_BY_CRITERIA_FAILURE = "@orders/fetch-orders-criteria-failure";

export const FETCH_INVOICES_BY_CRITERIA = "@invoices/fetch-invoices-criteria";
export const FETCH_INVOICES_BY_CRITERIA_SUCCESS = "@invoices/fetch-invoices-criteria-success"
export const FETCH_INVOICES_BY_CRITERIA_FAILURE = "@invoices/fetch-invoices-criteria-failure";

export const UPDATE_ORDER_STATUS = "@orders/update-order-status";
export const UPDATE_ORDER_STATUS_SUCCESS = "@orders/update-order-status-success";
export const UPDATE_ORDER_STATUS_FAILURE = "@orders/update-order-status-failure";

export function fetchOrders(customerId) {

    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_ORDERS });
            const orders = await orderService.fetchOrders(customerId);
            // console.log("fetchBusinesses Action", orders);
            dispatch({
                type: FETCH_ORDERS_SUCCESS,
                payload: {
                    orders
                },
            });
            return orders;
        } catch (error) {
            dispatch({ type: FETCH_ORDERS_FAILURE });
            throw error;
        }
    };
}


export function fetchOrdersByCriteria(customerId, criteria) {
    return async (dispatch) => {
        try {
            let params = "";
            if (criteria?.orderId && criteria?.orderId != "")
                params += `&orderId=${criteria?.orderId}`;
            if (criteria?.mobile && criteria?.mobile != "")
                params += `&userMobile=${criteria?.mobile}`;
            if (criteria?.startDate && criteria?.startDate != "")
                params += `&orderFromDate=${criteria?.startDate}`;
            if (criteria?.endDate && criteria?.endDate != "")
                params += `&orderToDate=${criteria?.endDate}`;
            if (criteria?.orderStatus && criteria?.orderStatus != "")
                params += `&status=${criteria?.orderStatus}`;

            dispatch({ type: FETCH_ORDERS_BY_CRITERIA });
            const orders = await orderService.fetchOrdersByCriteria(customerId, params);
            console.log("fetchBusinesses Action", orders);
            dispatch({
                type: FETCH_ORDERS_BY_CRITERIA_SUCCESS,
                payload: {
                    orders
                },
            });
            return orders;
        } catch (error) {
            dispatch({ type: FETCH_ORDERS_BY_CRITERIA_FAILURE });
            throw error;
        }
    };
}

export function fetchInvoicesByCriteria(customerId, criteria) {
    return async (dispatch) => {
        try {
            let params = "";
            if (criteria?.invoiceId && criteria?.invoiceId != "")
                params += `&invoiceId=${criteria?.invoiceId}`;
            if (criteria?.mobile && criteria?.mobile != "")
                params += `&userMobile=${criteria?.mobile}`;
            if (criteria?.startDate && criteria?.startDate != "")
                params += `&invoiceFromDate=${criteria?.startDate}`;
            if (criteria?.endDate && criteria?.endDate != "")
                params += `&invoiceToDate=${criteria?.endDate}`;
            if (criteria?.orderStatus && criteria?.orderStatus != "")
                params += `&status=${criteria?.orderStatus}`;

            dispatch({ type: FETCH_INVOICES_BY_CRITERIA });
            const invoices = await orderService.fetchInvoicesByCriteria(customerId, params);
            console.log("fetchBusinesses Action", invoices);
            dispatch({
                type: FETCH_INVOICES_BY_CRITERIA_SUCCESS,
                payload: {
                    invoices
                },
            });
            return invoices;
        } catch (error) {
            dispatch({ type: FETCH_INVOICES_BY_CRITERIA_FAILURE });
            throw error;
        }
    };
}


export function updateOrderStatus(action, addedBy, orderInfo) {

    return async (dispatch) => {
        try {
            let orderStatus = undefined;

            // console.log("updateOrderStatus() ", action, orderInfo);

            switch (action) {
                case 'CAPTURE_PAYMENT':
                    orderStatus = new OrderStatusPaid(orderInfo, addedBy);
                    break;

                case 'ACCEPT_ORDER':
                case 'SHIP_ORDER':
                case 'DELIVERED':
                case 'CANCEL_ORDER':
                case 'HOLD':
                    orderStatus = new OrderStatus(orderInfo, addedBy);
                    break;

                default:
            }

            dispatch({ type: UPDATE_ORDER_STATUS });
            const order = await orderService.updateOrderStatus(orderStatus);

            // console.log("updateOrderStatus Action resp order: ", order);
            dispatch({
                type: UPDATE_ORDER_STATUS_SUCCESS,
                payload: {
                    order
                },
            });
            return order;
        } catch (error) {
            // console.log("updateOrderStatus: ", error);
            dispatch({ type: UPDATE_ORDER_STATUS_FAILURE });
            throw { message: error?.response?.data?.message };
        }
    };
}

