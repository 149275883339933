import { FETCH_GREETINGS_URL } from 'src/constants/services';
import axios from 'src/utils/axios';

class GreetingService {

    fetchGreetings = (customerId) => new Promise((resolve, reject) => {
        axios
            .get(`${FETCH_GREETINGS_URL}?customerId=${customerId}`)
            .then((response) => {
                console.log("GreetingService(fetchGreetings->Response): 1", response);
                if (response.data) {
                    let greetings = response.data?.data;
                    console.log("GreetingService(fetchGreetings)", greetings);
                    resolve(greetings);
                } else {
                    reject(response.data.error);
                }
            })
            .catch((error) => {
                console.log("GreetingService.fetchGreetings (Response Error):" + JSON.stringify(error));
                reject(error);
            });
    })
}

const greetingService = new GreetingService();
export default greetingService;
