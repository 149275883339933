import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Grid,
  TextField,
  Container,
  Card,
  Popover,
  Button,
  CardContent
} from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import Results from './Result';
import { fetchUsers } from 'src/actions/idActions';
import { fetchCategory, fetchUsers5 } from 'src/actions/mgmtSelectValueAction';
import { fetchProductDetails } from 'src/actions/mgmtProductAction';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function ProductListView({ }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const businessInfo = useSelector((state) => state?.app?.businessInfo);
  const categoryList = useSelector((state) => state?.user?.category);
  const customerId = businessInfo?.customerId;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [categoryId, setCategoryId] = useState(['']); // TODO_SP : need to see it is removed

  useEffect(() => {
    dispatch(fetchCategory(customerId));
    dispatch(fetchProductDetails(customerId, categoryId));
    setCategoryId(['']);
  }, [customerId]);

  /* 
    Fetch the list of products based on the customerId
    Update the Filter Tree based on the fetched category
    Display products (initially all and later based on filter criteria)
  */

  function refreshPage() {
    setCategoryId([''])
  }

  const handleSelectCategory = (event) => {
    //console.log("handleSelectCategory", event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id1 = open ? "simple-popover" : undefined;

  const renderTree = (nodes) => (
    <TreeItem key={nodes.categoryId} nodeId={nodes.categoryId} label={nodes.name}>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  if (!customerId) {
    return null;   // TODO_SP : return proper error message
  }

  return (
    <Page
      className={classes.root}
      title="Product List"
    >
      <Header />
      <Container maxWidth={false}>
        <br />
        <Grid>
          <Card style={{ maxHeight: "400px" }}>
            <CardContent>
              <Grid className="row" container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    label="Customer Id"
                    name="customerId"
                    // onChange={handleChange}
                    value={customerId}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    multiple
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Category"
                    // onChange={handleCategoryChange}
                    name="categoryId"
                    value={categoryId}
                    className="w-100"
                    onClick={handleSelectCategory}
                  />
                  <Popover
                    id={id1}
                    fullWidth
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left"
                    }}
                  >
                    <TreeView
                      multiple
                      aria-label="rich object"
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpanded={['root']}
                      defaultExpandIcon={<ChevronRightIcon />}
                      sx={{ height: 200, flexGrow: 1, width: 300, overflowY: 'auto' }}
                      onNodeSelect={(e, categoryId) => {
                        setCategoryId(categoryId);
                      }}
                    >
                      {categoryList.map((category) => (
                        renderTree(category)
                      ))}
                    </TreeView>
                  </Popover>
                </Grid>

                <Button style={{ marginTop: "1.2em", marginLeft: "1em", maxWidth: '250px', maxHeight: '55px' }} color="secondary" variant='contained' onClick={refreshPage}>
                  Clear Filter</Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <br />
        <Results customerId={customerId} categoryId={categoryId} />

        <br /><br />
      </Container>
    </Page>
  );
}

export default ProductListView;
