import produce from "immer";
import { SIGNOUT } from "src/actions/accountActions";

// import { SERVICEINFO_CREATE_SUCCESS } from "src/actions/mgmtNewsAction";
import { FETCH_SERVICE, FETCH_SERVICE_SUCCESS, FETCH_SERVICE_FAILURE } from "src/actions/serviceActions";
const initialState = {
    services: [],
};

const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
        // case SERVICEINFO_CREATE_SUCCESS: {   // TODO_SP : Need to review and remove this
        //     const { testinonails } = action.payload;
        //     return produce(state, (draft) => {
        //         draft.testinonails = testinonails;
        //     });
        // }

        case FETCH_SERVICE_SUCCESS: {
            const { services } = action.payload;

            return produce(state, (draft) => {
                draft.services = services;
            })
        }



        case FETCH_SERVICE:
        case FETCH_SERVICE_FAILURE: {
            return produce(state, (draft) => {
                draft.services = [];
            })
        }


        case SIGNOUT: {
            return produce(state, (draft) => {
                draft.services = [];
            })
        }



        default: {
            return state;
        }
    }
};

export default serviceReducer;


