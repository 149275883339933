import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 45,
        justifyContent: 'flex-end',
        width: '90%'
        
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        // width:100
        
    },
    invoiceDate: {
        fontSize: 12,
        fontStyle: 'bold',
    },
    label: {
        width: 90
    }
});

const InvoiceNo = ({ invoice }) => {

    const date = (invoice) ? `${invoice.createdAt}` : undefined;
    console.log("date",date);
    const quotationExpiry = (invoice) ? `${invoice.quotationDate}` : undefined;

    function Unix_timestamp(t) {
        var dt = new Date(t * 1000);
        var hr = dt.getHours();
        var m = "0" + dt.getMinutes();
        var s = "0" + dt.getSeconds();
        return hr + ':' + m.substr(-2) + ':' + s.substr(-2);
    }

    let timestamp = new Date(date).getTime();
    let Time = new Date(timestamp).getTime();
    const t = Unix_timestamp(Time)
    let Day = new Date(timestamp).getDate();
    // let Month = new Date(timestamp).getMonth() + 1;
    let Month = new Date(timestamp).toLocaleString('default', { month: 'short' });
    let Year = new Date(timestamp).getFullYear();
    let OurNewDateFormat = `${Day}-${Month}-${Year}`;

    let timestampexpiry = new Date(quotationExpiry).getTime();
    let TimeExp = new Date(timestamp).getTime();
    const tExp = Unix_timestamp(Time)
    let DayExp = new Date(timestampexpiry).getDate();
    // let Month = new Date(timestamp).getMonth() + 1;
    let MonthExp = new Date(timestampexpiry).toLocaleString('default', { month: 'short' });
    let YearExp = new Date(timestampexpiry).getFullYear();
    let quotationexpiry = `${DayExp}-${MonthExp}-${YearExp}`;
    console.log("quotationexpiry",quotationexpiry);
    
    return(
    <Fragment>
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Quotation No: </Text>
            <Text style={styles.label}>{invoice?.quotationId}</Text>
        </View >
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Date: </Text>
            <Text style={styles.label}>{OurNewDateFormat}</Text>
        </View >
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Quotation Expiry: </Text>
            <Text style={styles.label}>{quotationexpiry}</Text>
        </View >
    </Fragment>
)};

export default InvoiceNo;