import produce from "immer";
import { FETCHPAYMENTGATEWAYTYPE_INFO_SUCCESS } from "src/actions/mgmtPaymentGateWayTypeAction";


const initialState = {
    paymentGatewayType: [],

};

const paymentGateWayTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHPAYMENTGATEWAYTYPE_INFO_SUCCESS: {
            const { paymentGateWayTypeInfo } = action.payload;
            return produce(state, (draft) => {
                draft.paymentGatewayType = paymentGateWayTypeInfo;
            });
        }
        default: {
            return state;
        }
    }
};

export default paymentGateWayTypeReducer;


