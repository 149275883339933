import React, { useState, useEffect, } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types';
import Delete from '@mui/icons-material/Delete';
import axios from 'src/utils/axios';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import {
  Box,
  IconButton,
  SvgIcon,
} from '@mui/material';
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon
} from 'react-feather';

import { useDispatch } from 'react-redux';
import { deleteTestimonial, fetchTestimonials } from 'src/actions/testimonialActions';

function Results({ className, testimonials, businessInfo, ...rest }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {

  }, [testimonials]);

  const handleDelete = async (customerId, testimonialId) => {
    try {
      await dispatch(deleteTestimonial(customerId, testimonialId));
      dispatch(fetchTestimonials(customerId));
    } catch (err) { }
  };

  const columns = [
    { field: 'testimonyId', headerName: 'Testimonial Id', width: 80 },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Tooltip title="Edit Testimonial">
              <IconButton
                onClick={() => {
                  navigate('/app/management/testimonials/edit', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <m>
              <Tooltip title="Delete Testimonial">
                <IconButton
                  onClick={() => handleDelete(params.row.customerId, params.row.testimonyId)}
                >
                  <SvgIcon fontSize="small">
                    <Delete />
                  </SvgIcon>
                </IconButton>
              </Tooltip></m>
          </div>
        );
      },
    },
    { field: 'userName', headerName: 'User Name', width: 200 },
    { field: 'description', headerName: 'description', width: 200 },
    { field: 'date', headerName: 'Date', width: 200 },
    { field: 'videoUrl', headerName: 'VideoUrl', width: 200 },
    { field: 'createdAt', headerName: 'Created Date', width: 200 },
  ];

  return (
    <Box sx={{ height: 400, width: '100%', padding: 2}}>
      <DataGrid
        rows={testimonials}
        columns={columns}
        getRowId={(row) => row.testimonyId}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Box>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  testimonials: PropTypes.array
};

Results.defaultProps = {
  testimonials: []
};

export default Results;