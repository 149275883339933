import produce from "immer";
import { FETCHUSERTARGET_DATA_SUCCESS, FETCHUSER_DATA_SUCCESS } from "src/actions/accountActions";
import { FETCH_ID, FETCH_ID_FAILURE, FETCH_ID_SUCCESS } from "src/actions/idActions";
import { CUSTOMERSTATE_ID, FETCH1_ID, FETCH_CATEGORIES_SUCCESS, FETCHCUSTOMER_ID_SUCCESS } from "src/actions/mgmtSelectValueAction";
import { DISTRIBUTORCATEGORY_LIST_SUCCESS } from "src/actions/mgmtUserAction";

const initialState = {
    users: [],
    users1: "",
    cusState:"",
    category:[],
    customer:{},
    userTargetData: [],
    userData: [],
    distributorCategory:[]
};

const searchUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ID: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.users = [];
            });
        }
        case FETCH1_ID: {
            const { users1 } = action.payload;
            return produce(state, (draft) => {
                draft.users1 = users1;
            });
        }
        case CUSTOMERSTATE_ID: {
            const { cusState } = action.payload;
            return produce(state, (draft) => {
                draft.cusState = cusState;
            });
        }
        case FETCH_CATEGORIES_SUCCESS: {
            const { category } = action.payload;
            return produce(state, (draft) => {
                draft.category = category;
            });
        }
        case DISTRIBUTORCATEGORY_LIST_SUCCESS: {
            const { distributorCategory } = action.payload;
            return produce(state, (draft) => {
                draft.distributorCategory = distributorCategory;
            });
        }
        case FETCHCUSTOMER_ID_SUCCESS: {
            const { customer } = action.payload;
            return produce(state, (draft) => {
                draft.customer = customer;
            });
        }
        case FETCHUSERTARGET_DATA_SUCCESS: {
            const { userTargetData } = action.payload;
            return produce(state, (draft) => {
                draft.userTargetData = userTargetData;
            });
        }
        case FETCHUSER_DATA_SUCCESS: {
            const { userData } = action.payload;
            return produce(state, (draft) => {
                draft.userData = userData;
            });
        }
        case FETCH_ID_SUCCESS: {
            const { users } = action.payload;
            return produce(state, (draft) => {
                draft.users = users;
            });
        }
        case FETCH_ID_FAILURE: {
            return produce(state, () => {
                // Maybe store error
            });
        }
        default: {
            return state;
        }
    }
};

export default searchUserReducer;