import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import axios from 'src/utils/axios';
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import Delete from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import {
  Box,
  IconButton,
  Card,
  CardContent,
  SvgIcon,
  FormHelperText,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import mgmtCustomerForm from "src/services/mgmtCustomerForm";
import { fetchCategory } from "src/actions/mgmtSelectValueAction";
import { fetchCustomerList } from "src/actions/mgmtCustomerCreateAction";
import ImageEditor from "src/components/ImageEditor";
import { IMAGE_TYPE } from "src/config";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function CustomerForm({ className, service, banner, customer, onSubmitSuccess, ...rest }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const cusId = service.customerId
  const logourl = service.brandDetails[0].logoURL
  const sLogo = service.brandDetails[0].shortLogo
  const path = process.env.REACT_APP_PATH

  const logoUrl = `${path}/${logourl}`
  const shortLogo = `${path}/${sLogo}`

  const bid = service.brandDetails[0].brandId

  const image0 = service.brandDetails[0].bannerURLs[0]
  const ur0 = `${path}/${image0}`

  const image1 = service.brandDetails[0].bannerURLs[1]
  const ur1 = `${path}/${image1}`

  const image2 = service.brandDetails[0].bannerURLs[2]
  const ur2 = `${path}/${image2}`

  const image3 = service.brandDetails[0].bannerURLs[3]
  const ur3 = `${path}/${image3}`

  const image4 = service.brandDetails[0].bannerURLs[4]
  const ur4 = `${path}/${image4}`

  const image5 = service.brandDetails[0].bannerURLs[5]
  const ur5 = `${path}/${image5}`

  const ifBanner1 = (service?.brandDetails[0]?.bannerURLs[0]?.length) ? service.brandDetails[0].bannerURLs[0].length : "not"
  const ifBanner2 = (service?.brandDetails[0]?.bannerURLs[1]?.length) ? service.brandDetails[0].bannerURLs[1].length : "not"
  const ifBanner3 = (service?.brandDetails[0]?.bannerURLs[2]?.length) ? service.brandDetails[0].bannerURLs[2].length : "not"
  const ifBanner4 = (service?.brandDetails[0]?.bannerURLs[3]?.length) ? service.brandDetails[0].bannerURLs[3].length : "not"
  const ifBanner5 = (service?.brandDetails[0]?.bannerURLs[4]?.length) ? service.brandDetails[0].bannerURLs[4].length : "not"
  const ifBanner6 = (service?.brandDetails[0]?.bannerURLs[5]?.length) ? service.brandDetails[0].bannerURLs[5].length : "not"

  return (

    <Formik
      initialValues={{
        customerId: service.customerId || '',
        logoFile: "",
        bannerFiles: "",
        shortLogo: "",
      }}
      validationSchema={Yup.object().shape({

      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        // console.log("Submitting the request", values, image, image1, image2);
        try {
          await dispatch(mgmtCustomerForm.customerBannerAndLogoUpload(values));
          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          dispatch(fetchCategory(service.customerId));
          enqueueSnackbar("customer Updated", {
            variant: "success",
          });
          dispatch(fetchCustomerList());
          navigate("/app/management/customer/view");
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.customerId && errors.customerId
                    )}
                    fullWidth
                    helperText={
                      touched.customerId && errors.customerId
                    }
                    label="customerId"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Card style={{ marginLeft: "2em", marginTop: "2em", height: 350 }}>
                  <CardContent>
                    <Grid item xs={6}>
                      <Paper
                        sx={{
                          width: 500,
                          height: 300,
                          background: "test",
                          backgroundSize: "cover",
                          mb: 1,
                        }}
                      >
                        <h1 align="center">Logo Image</h1>
                        <ImageEditor type={IMAGE_TYPE.LOGO} inImage={logoUrl} customerId={cusId} />

                      </Paper>
                    </Grid>
                  </CardContent>
                </Card>
                <Card style={{ marginLeft: "2em", marginTop: "2em", height: 350 }}>
                  <CardContent>
                    <Grid item xs={6}>
                      <Paper
                        sx={{
                          width: 500,
                          height: 300,
                          background: "test",
                          backgroundSize: "cover",
                          mb: 1,
                        }}
                      >
                        <h1 align="center">Short Logo</h1>
                        <ImageEditor type={IMAGE_TYPE.SHORTLOGO} inImage={shortLogo} customerId={cusId} />
                      </Paper>
                    </Grid>
                  </CardContent>
                </Card >
                <br />
                <br />
                <Grid item xs={6}>
                  <Paper
                    sx={{
                      width: 530,
                      height: 350,
                      background: "test",
                      backgroundSize: "cover",
                      mb: 1,
                      marginLeft: "0.4em"
                    }}
                  >
                    <h1 align="center"> Banner 1</h1>

                    {ifBanner1 > 0 ?
                      <ImageEditor type={IMAGE_TYPE.UPDATEBANNER1} inImage={ur0} customerId={cusId}  bid={bid} index={"0"} service={service} deletetype={"customer"} />

                      :
                      <ImageEditor type={IMAGE_TYPE.BANNER1} inImage={ur0} customerId={cusId}  bid={bid} index={"0"} service={service} deletetype={"customer"}/>
                    }


                   
                  </Paper>
                </Grid>

                <Grid item xs={6}>
                  <Paper
                    sx={{
                      width: 530,
                      height: 350,
                      background: "test",
                      backgroundSize: "cover",
                      mb: 1,
                      marginLeft: "-0.3em"

                    }}
                  >
                    <h1 align="center">Banner 2</h1>
                    {ifBanner2 > 0 ?
                      <ImageEditor type={IMAGE_TYPE.UPDATEBANNER2} inImage={ur1} customerId={cusId} index={"1"} service={service} deletetype={"customer"} />
                      :
                      <ImageEditor type={IMAGE_TYPE.BANNER2} inImage={ur1} customerId={cusId} index={"1"} service={service} deletetype={"customer"} />
                    }


              
                  </Paper>
                </Grid>

                <Grid item xs={6}>
                  <Paper
                    sx={{
                      width: 530,
                      height: 350,
                      background: "test",
                      backgroundSize: "cover",
                      mb: 1,
                      marginLeft: "0.4em"

                    }}
                  >
                    <h1 align="center">Banner 3</h1>
                    {ifBanner3 > 0 ?
                      <ImageEditor type={IMAGE_TYPE.UPDATEBANNER3} inImage={ur2} customerId={cusId} index={"2"} service={service}  deletetype={"customer"}/>
                      : <ImageEditor type={IMAGE_TYPE.BANNER3} inImage={ur2} customerId={cusId} index={"2"} service={service} deletetype={"customer"}/>
                    }

                   
                  </Paper>
                </Grid>

                <Grid item xs={6}>
                  <Paper
                    sx={{
                      width: 530,
                      height: 350,
                      background: "test",
                      backgroundSize: "cover",
                      mb: 1,
                      marginLeft: "-0.3em"
                    }}
                  >
                    <h1 align="center">Banner 4</h1>

                    {ifBanner4 > 0 ?
                      <ImageEditor type={IMAGE_TYPE.UPDATEBANNER3} inImage={ur3} customerId={cusId} index={"3"} service={service} deletetype={"customer"}/>
                      : <ImageEditor type={IMAGE_TYPE.BANNER4} inImage={ur3} customerId={cusId} index={"3"} service={service} deletetype={"customer"}/>
                    }


                   
                  </Paper>
                </Grid>

                <Grid item xs={6}>
                  <Paper
                    sx={{
                      width: 530,
                      height: 350,
                      background: "test",
                      backgroundSize: "cover",
                      mb: 1,
                      marginLeft: "0.4em"

                    }}
                  >
                    <h1 align="center">Banner 5</h1>

                    {ifBanner5 > 0 ?
                      <ImageEditor type={IMAGE_TYPE.UPDATEBANNER4} inImage={ur4} customerId={cusId} index={"4"} service={service} deletetype={"customer"}/>
                      : <ImageEditor type={IMAGE_TYPE.BANNER5} inImage={ur4} customerId={cusId} index={"4"} service={service} deletetype={"customer"}/>
                    }

                  
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    sx={{
                      width: 530,
                      height: 350,
                      background: "test",
                      backgroundSize: "cover",
                      mb: 1,
                      marginLeft: "-0.3em"
                    }}
                  >
                    <h1 align="center">Banner 6</h1>
                    {ifBanner6 > 0 ?
                      <ImageEditor type={IMAGE_TYPE.UPDATEBANNER4} inImage={ur5} customerId={cusId} index={"5"} service={service}  deletetype={"customer"}/>
                      : <ImageEditor type={IMAGE_TYPE.BANNER6} inImage={ur5} customerId={cusId} index={"5"} service={service}  deletetype={"customer"}/>
                    }
                  
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
        </form>
      )}
    </Formik>
  );
}

CustomerForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,

};

export default CustomerForm;


