import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import {
  Box,
  Button,
  Container
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'src/utils/axios';
import Page from 'src/components/Page';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import JsonDownload from './download';
import { customerState } from 'src/actions/mgmtSelectValueAction';
import Header from './Header';
import { customerJsonDownload } from 'src/actions/mgmtCustomerCreateAction';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function CustomerJsonDownload() {
  const {state} = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  console.log("state",state)
  const cusId =state.customerId
  useEffect(() => {
    dispatch(customerState(state));
    dispatch(customerJsonDownload(cusId,"default"));
  }, []);

  // const customer15 = useSelector(
  //   (state) => state?.user?.cusState
  // );
  // console.log("customer15",customer15)
  return (
    <Page
      className={classes.root}
      title="Customer List"
    >
      <Container maxWidth={false}>
     <JsonDownload/>
      </Container>
    </Page>
  );
}

export default CustomerJsonDownload;
