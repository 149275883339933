import { DistributorType } from "src/interface/distributorTypeIf";
import mgmtDistributorType from "src/services/mgmtDistributorTypeService";

export const DISTRIBUTORTYPE_INFO = '@distributortype/info';

export const DISTRIBUTORTYPE_CREATE = '@distributortype/create';
export const DISTRIBUTORTYPE_CREATE_SUCCESS = '@distributortype/create-success';
export const DISTRIBUTORTYPE_CREATE_FAILURE = '@distributortype/create-failure';

export const DISTRIBUTORTYPE_UPDATE = '@distributortype/update';
export const DISTRIBUTORTYPE_UPDATE_SUCCESS = '@distributortype/update-success';
export const DISTRIBUTORTYPE_UPDATE_FAILURE = '@distributortype/update-failure';

export function createDistributorType(distributorTypeInfo,id,customer) {
  return async (dispatch) => {
    let newDistributorType = new DistributorType(distributorTypeInfo);
    // console.log("distributorTypeInfo():" + JSON.stringify(distributorTypeInfo) + " " + JSON.stringify(newDistributorType));
    try {
      dispatch({ type: DISTRIBUTORTYPE_CREATE });
      const distributorType = await mgmtDistributorType.createDistributorType(newDistributorType,id,customer);
      // console.log("distributorTypeInfo()(after):" + JSON.stringify(distributorType));
      dispatch({
        type: DISTRIBUTORTYPE_CREATE_SUCCESS,
        payload: {
          distributorType
        }
      });
    } catch (error) {
      dispatch({ type: DISTRIBUTORTYPE_CREATE_FAILURE });
      throw error;
    }
  };
}

export function updateDistributorType(distributorTypeInfo) {
  return async (dispatch) => {
    let newDistributorTypeInfo = new DistributorType(distributorTypeInfo);
    // console.log("distributorTypeInfo():" + JSON.stringify(distributorTypeInfo) + " " + JSON.stringify(newDistributorTypeInfo));
    try {
      dispatch({ type: DISTRIBUTORTYPE_UPDATE });
      const distributorType = await mgmtDistributorType.updateDistributorType(distributorTypeInfo);
      dispatch({
        type: DISTRIBUTORTYPE_UPDATE_SUCCESS,
        payload: {
          distributorType
        }
      });
    } catch (error) {
      dispatch({ type: DISTRIBUTORTYPE_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function fetchDistributorTypeList(cid) {
  return async (dispatch) => {
    try {
      const distributorTypeList = await mgmtDistributorType.fetchDistributorTypeList(cid);
      dispatch({
        type:DISTRIBUTORTYPE_INFO,
        payload: {
          distributorTypeList,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      throw error;
    }
  };
}
