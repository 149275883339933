import React, {
  useEffect,
} from 'react';
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types';
import {
  Box, Button,
} from '@mui/material';
import { fetchUserTargetData } from 'src/actions/accountActions';
import { useDispatch, useSelector } from 'react-redux';

function ResultsTarget({ className, service, onClick, ...rest }) {
  const dispatch = useDispatch();
  const userId = service.userId
  const customerId = service.customerId

  useEffect(() => {
    dispatch(fetchUserTargetData(customerId, userId));
  }, []);

  const userTargetData = useSelector(
    (state) => state?.user?.userTargetData

  );
 
  const columns = [
    { field: 'userId', headerName: 'User Id', width: 80 },
    { field: 'customerId', headerName: 'Customer Id', width: 100 },
    { field: 'targetId', headerName: 'Target Id', width: 100 },
    { field: 'userType', headerName: 'User Type', width: 200 },
    { field: 'targetType', headerName: 'Target Type', width: 200 },
    { field: 'target', headerName: 'Target', width: 120 },
    { field: 'achieved', headerName: 'Achieved', width: 120 },
  ];

  return (

    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={userTargetData}
        columns={columns}
        getRowId={(row) => row.targetId}
        // checkboxSelection={true}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
      <br />
      <Button
        color="secondary"
        variant="contained"
        style={{ marginBottom: "2em" }}
        onClick={onClick}
      >
        Cancel
      </Button>
    </Box>
  )
}

ResultsTarget.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ResultsTarget.defaultProps = {
};

export default ResultsTarget;



















