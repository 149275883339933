/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SIGNIN, SIGNIN_SUCCESS, SIGNIN_FAILURE,
  LOGOUT,

} from 'src/actions/accountActions';
import { FETCHADMINCUSTOMERPAYMENT_INFO, FETCHADMINCUSTOMERPAYMENT_INFO_SUCCESS } from 'src/actions/mgmtCustomerCreateAction';
import { FETCHPAYMENTGATEWAYTYPE_INFO_SUCCESS } from 'src/actions/mgmtPaymentGateWayTypeAction';


const initialState = {
  paymentGWList: [],
  paymentGWDetails: {
    paymentMode: "OFFLINE",
    apkTobeGenerated: false,
    customerId: undefined,
    gatewayPayKeyId: undefined,
    gatewayPayKeySecret: undefined,
    gatewayType: "",
    paymentCredentialId: undefined,
    accessCode: undefined,
    keyIndex: undefined,
    paymentServiceUrl: undefined,
  },
};


// TODO_SP : Added to merge all payment info in one place. remove later to remove customerReducer and paymentGatewayTypeReducer

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCHPAYMENTGATEWAYTYPE_INFO_SUCCESS: {        
        const { paymentGateWayTypeInfo } = action.payload;
        // console.log("paymentReducer: GatewayList: ", action.type, paymentGateWayTypeInfo);
        return produce(state, (draft) => {
            draft.paymentGWList = paymentGateWayTypeInfo;
        });
    }

    case FETCHADMINCUSTOMERPAYMENT_INFO_SUCCESS: {
        const { customerpaymentInfoList } = action.payload;
        console.log("paymentReducer: CustomerInfo: ", action.type, customerpaymentInfoList);
        return produce(state, (draft) => {
            draft.paymentGWDetails = {
                paymentMode: customerpaymentInfoList?.paymentMode,
                apkTobeGenerated: customerpaymentInfoList?.apkTobeGenerated,
                customerId: customerpaymentInfoList?.customerId,
                gatewayPayKeyId:customerpaymentInfoList?.gatewayPayKeyId,
                gatewayPayKeySecret: customerpaymentInfoList?.gatewayPayKeySecret,
                gatewayType: customerpaymentInfoList?.gatewayType,
                paymentCredentialId: customerpaymentInfoList?.paymentCredentialId,
                accessCode: customerpaymentInfoList?.accessCode,
                keyIndex: customerpaymentInfoList?.keyIndex,
                paymentServiceUrl: customerpaymentInfoList?.paymentServiceUrl,
            };
        });
    }

    case FETCHADMINCUSTOMERPAYMENT_INFO:
    case SIGNIN:
    case SIGNIN_FAILURE:        
    case LOGOUT: {
      return produce(state, (draft) => {
        draft = initialState;
        // draft.paymentGWList = [];
        // draft.paymentGWDetails = undefined;
      });
    }

    default: {
      return state;
    }
  }
};

export default paymentReducer;
