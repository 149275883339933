import produce from "immer";
import { SIGNOUT } from "src/actions/accountActions";
import { DASHBOARD_FINANCIAL_SALES_SUMMARY, DASHBOARD_FINANCIAL_SALES_SUMMARY_SUCCESS, DASHBOARD_FINANCIAL_SALES_TREND_SUCCESS } from "src/actions/dashboardFinancialActions";
import { DASHBOARD_SUMMARY_TYPES } from "src/constants";

// import { FETCH_TESTIMONIAL, FETCH_TESTIMONIAL_SUCCESS, FETCH_TESTIMONIAL_FAILURE } from "src/actions/testimonialActions";
const initialState = {
    summary: {
        totalSales: -1,
        totalOrders: -1,
        totalSalesToday: -1,
        totalOrdersToday: -1,
    },
    trends: {
        week: [],
        month: [],
    }
};

const dashboardFinancialReducer = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_FINANCIAL_SALES_SUMMARY_SUCCESS: {
            
            const { summaryType, data } = action.payload;

            switch(summaryType) {
                case DASHBOARD_SUMMARY_TYPES.YEARLY:{
                    const { totalSales, totalOrders } = data;
                    // data.data[] to be use leveraged based on need
                    return produce(state, (draft) => {
                        draft.summary.totalSales = totalSales;
                        draft.summary.totalOrders = totalOrders;
                    });
                }

                case DASHBOARD_SUMMARY_TYPES.TODAY: {
                        const { totalSales, totalOrders } = data;
                        // data.data[] to be use leveraged based on need
                        return produce(state, (draft) => {
                            draft.summary.totalSalesToday = totalSales;
                            draft.summary.totalOrdersToday = totalOrders;
                        });
                    }
    

                default:
                    return produce(state, (draft) => {});
            }
        }

        case DASHBOARD_FINANCIAL_SALES_TREND_SUCCESS: {
            
            const { summaryType, data } = action.payload;

            console.log("reducer: ", summaryType, data);

            switch(summaryType) {
                case DASHBOARD_SUMMARY_TYPES.WEEKLY:{
                    return produce(state, (draft) => {
                        draft.trends.week = data;
                    });
                }

                case DASHBOARD_SUMMARY_TYPES.MONTHLY: {
                        return produce(state, (draft) => {
                            draft.trends.month = data;
                        });
                    }
    

                default:
                    return produce(state, (draft) => {});
            }
        }










        case SIGNOUT: {
            return produce(state, (draft) => {
               draft.summary = {};
               draft.trends = {};
            })
        }



        default: {
            return state;
        }
    }
};

export default dashboardFinancialReducer;


