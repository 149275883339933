import React, {
  useEffect,
} from 'react';
import {
  Container,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Header from './Header';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategory, fetchUsers5 } from 'src/actions/mgmtSelectValueAction';
import QuotationRequestResults from './Result';
import { fetchQuotationRequestList } from 'src/actions/mgmtQuotationGenerateAction';
import MperitoCard from 'src/components/MperitoCard';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function QuotationReqList() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const businessInfo = useSelector((state) => state?.app?.businessInfo);
  const customerId = businessInfo?.customerId;

  useEffect(() => {
    dispatch(fetchQuotationRequestList(customerId));
  }, [customerId]);

  const quoteList = useSelector((state) => state?.quotation?.quotation);

  return (
    <Page
      className={classes.root}
      title="Quotation Request List"
    >
      <Container maxWidth={false}>
        <Header />
        <br />        
        <MperitoCard title={`List of Quotations (  ${customerId} )`} >
          <QuotationRequestResults quotations={quoteList} />
        </MperitoCard>
      </Container>
    </Page>
  );
}

export default QuotationReqList;
