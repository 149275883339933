import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { PDFDownloadLink } from '@react-pdf/renderer';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Tooltip } from '@mui/material';
import PdfDocument from '../downloadInvoice/Invoice';

function Row2(props) {
    const { indiRow2 } = props;
    console.log(`Row2() :: props: `, indiRow2);
 
    return (
        <React.Fragment>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell>{indiRow2.productName}</TableCell>
                <TableCell>{indiRow2.productSKU}</TableCell>
                <TableCell>{indiRow2.price}</TableCell>
                <TableCell>{indiRow2.quantity}</TableCell>
                <TableCell>{indiRow2.total}</TableCell>
                {/* <TableCell align="center">Edit</TableCell> */}
            </TableRow>
        </React.Fragment>
    );
}

function Row(props) {

    const { indiRow } = props;
    console.log(`Row() :: props: `, indiRow);
    const [frstopen, setFrstopen] = React.useState(false);
const orderId = `Invoice-${indiRow.orderId}.pdf`
    return (
        <React.Fragment>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setFrstopen(!frstopen)}
                    >
                        {frstopen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="left">{indiRow.userId}</TableCell>
                <TableCell align="left">{indiRow.customerId}</TableCell>
                <TableCell align="left">{indiRow.paymentOrderId}</TableCell>
                <TableCell align="left">{indiRow.orderId}</TableCell>
                <TableCell align="left">{indiRow.invoiceId}</TableCell>
                {/* <TableCell align="left">{indiRow.paymentOrder}</TableCell> */}
                <TableCell align="left">{indiRow.orderAmount}</TableCell>
                <TableCell align="left">{indiRow.totalAmount}</TableCell>
                {/* <TableCell align="left">{indiRow.isActive}</TableCell>
                <TableCell align="left">{indiRow.isDeleted}</TableCell> */}
                
                <TableCell align="center">
                                        <Tooltip title="Download Invoice">
            <IconButton>
            <PDFDownloadLink
              document={<PdfDocument invoicedata={indiRow}  />}
              fileName={orderId}
            >
              {({ blob, url, loading, error }) =>
                <FileDownloadIcon style={{color:"blue"}}/>
              }
            </PDFDownloadLink>
            </IconButton >
            </Tooltip>
        </TableCell>
            </TableRow>
            <TableRow>
                {/*First Collapsable row*/}
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={frstopen} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                            ></Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Product Name</TableCell>
                                        <TableCell>Product SKU</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Quantity</TableCell>
                                        <TableCell>Total</TableCell>
                                        {/* <TableCell>CreatedAt</TableCell> */}
                                        {/* <TableCell align="right">Actions</TableCell> */}
                                  
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {indiRow.orderItems.map((detailsRow) => (
                                        <>
                                            <Row2 key={detailsRow.invoiceId} indiRow2={detailsRow} />
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            {/*First Collapsable row*/}
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        _id: PropTypes.number.isRequired,
        count: PropTypes.number.isRequired,
        data: PropTypes.arrayOf(
            PropTypes.shape({
                searchKeyword: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
};

function Results1({ className,invoiceList,length,selectedCustomerId, ...rest }) {
    const invoicdata = (selectedCustomerId >= 0) ? invoiceList : []
    console.log(`Result1() :: selectedCustomerId: `, selectedCustomerId);
    console.log(`Result1() :: invoiceList: `, invoiceList);
    console.log(`Result1() :: invoicdata: `, invoicdata);
    return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Order Items</TableCell>
              <TableCell align="left">UserId</TableCell>
              <TableCell align="left">customerId</TableCell>
              <TableCell align="left">paymentOrderId</TableCell>
              <TableCell align="left">OrderId</TableCell>
              <TableCell align="left">InvoiceId</TableCell>
              {/* <TableCell align="left">PaymentOrder</TableCell> */}
              <TableCell align="left">Order Amount</TableCell>
              <TableCell align="left">Total Amount</TableCell>
              <TableCell align="left">Action</TableCell>
              {/* <TableCell align="left">IsActive</TableCell>
                        <TableCell align="left">IsDeleted</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {invoicdata.map((indiRow) => (
              <Row key={indiRow.id} indiRow={indiRow} />
            ))}
          </TableBody>
        </Table>
        {length <= 0 ? (
          <Typography align="center">
            No Data Found For This Customer Id
          </Typography>
        ) : (
          <Box />
        )}
      </TableContainer>
    );
};

Results1.propTypes = {
    className: PropTypes.string,

};

Results1.defaultProps = {

};

export default Results1;

