import { ProductUpload } from "src/interface/productUploadIf";
import mgmtproductUpload from "src/services/mgmtProductUpload";

export const PRODUCT_UPLOAD = '@product/upload';
export const PRODUCT_UPLOAD_SUCCESS = '@product/upload-success';
export const PRODUCT_UPLOAD_FAILURE = '@product/upload-failure';

export function uploadProduct(userId,uploadProductInfo,gstin) {
  return async (dispatch) => {
    console.log("ProductUpload():", userId,uploadProductInfo,gstin);
    let newProductUpload = new ProductUpload(userId,uploadProductInfo,gstin);
    newProductUpload.addedBy = userId;
    console.log("ProductUpload():" + JSON.stringify(uploadProductInfo) + " " + JSON.stringify(newProductUpload));
    try {
      dispatch({ type: PRODUCT_UPLOAD });
      const productUpload = await mgmtproductUpload.uploadProduct(newProductUpload,gstin);
      console.log("ProductUpload()(after):" + JSON.stringify(productUpload));
      dispatch({
        type: PRODUCT_UPLOAD_SUCCESS,
        payload: {
          productUpload
        }
      });
    } catch (error) {
      dispatch({ type: PRODUCT_UPLOAD_FAILURE });
      throw error;
    }
  };
}



