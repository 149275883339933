import React, { useCallback, useState } from "react";
import { Container, Grid } from "@mui/material";
import Page from "src/components/Page";
import { makeStyles } from '@mui/styles';
import Header from "./Header";
import {
  Box
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchUsers, setProductData } from "src/actions/idActions";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import UpdateLicense from "./UpdateLicenseForm";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
}));

function LicenseView() {
  const { state } = useLocation();
  // console.log("STATE", state);
  const cusId = state.customerId
  // console.log("cusId", cusId);
  const dispatch = useDispatch();
  const classes = useStyles();

  const customerIdList = useSelector(
    (state) => state?.user?.users?.customerList
  );

  function getCurrentDate1(separator = '-') {
    let newDate1 = new Date(state.license.validtill)
    let date1 = newDate1.getDate();
    let month1 = newDate1.getMonth() + 1;
    let year1 = newDate1.getFullYear();
    return `${year1}${separator}${month1 < 10 ? `0${month1}` : `${month1}`}${separator}${date1}`
  }
  const validtill = getCurrentDate1()
  // console.log("validtill",validtill);

  return (
    <Page className={classes.root} title="License Validity Update">
      <Container maxWidth={false} className={classes.container}>
        <Header />
        <br/>
        <Box sx={{ flexDirection: "row", display: "flex" }}>
          <UpdateLicense  state={state}  validtill={validtill} cusId={cusId}/>
        </Box>
      </Container>
    </Page>
  );
}

export default LicenseView;

