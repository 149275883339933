import axios from 'src/utils/axios';


class MgmtOrder {
  getSearchOrder = (info) => new Promise((resolve, reject) => {
    // console.log("EDITDATA Business",info)
    const bid = info ? info.customerId : undefined
    let searchUrl = "/api/admin/order/customerId?";
    searchUrl+=(bid)?`&customerId=${bid}`:"";
    // console.log("SEARCHURL",searchUrl)
    axios.get(searchUrl)
    // axios.get(`/api/supplier/mgmt/suppliers?businessName=${bname}&supplierId=${bid}&email=${bemail}`)
    .then((response) => {
      console.log("MgmtSearchBusiness(Response):" + JSON.stringify(response));
      if(response.data.data.orders){
        let business = response.data.data.orders;
        resolve(business);
        // setBusiness(response.data.data.suppliers);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  updateOrder = (neworder,state) => new Promise((resolve, reject) => {
    // console.log("state",state);
    const orderId = state.orderId
    const paymentOrderId = state.paymentOrderId
    const payOrderId = (paymentOrderId === null) ? orderId : paymentOrderId;
   


console.log("payOrderId",payOrderId);
    // const payload = neworder;
    const payload = {
      "customerId": neworder.customerId,
      "status": neworder.status,
      "paymentOrderId":payOrderId,
      "paymentType": {
          "paymentType": neworder.paymentType,
          "reference": neworder.reference
      }
  }
  const payload1 = {
    "customerId": neworder.customerId,
    "status": neworder.status,
    "orderId":payOrderId,
    "paymentType": {
        "paymentType": neworder.paymentType,
        "reference": neworder.reference
    }
}
const payOrderIdFiled = (paymentOrderId === null) ? payload1 :payload ;
    axios.post('/api/admin/order/update/status',payOrderIdFiled)
    .then((response) => {
      console.log("MgmtService(Response):" + JSON.stringify(response));

      if(response.data){
        let services = response.data;
        resolve(services);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })
  
  fetchOrderList = (cid) => new Promise((resolve, reject) => {
    // const cusId = (cid) ? cid : "undefined";

    let APIurl = `/api/admin/order/by/customerId?customerId=`;
    if(cid) APIurl = `/api/admin/order/by/customerId?customerId=${cid}`;
    console.log(`MgmtOrder.fetchOrderList() :: APIurl`, APIurl);

    axios
      .get(APIurl)
      .then((response) => {
        // console.log("MgmtUser(fetchUsers->Response): 1", response);
        if (response?.data) {
          const nodata = []
          const order = (response?.data?.data?.orders) ? response.data.data.orders : nodata;
          // console.log("MgmtUser(fetchUsers)", users);
          resolve(order);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  orderExport = (customerId,OurNewDateFormat) => new Promise((resolve, reject) => {
    console.log("OurNewDateFormat",OurNewDateFormat);
          axios.get(`/api/admin/order/export/order-data/day-wise?customerId=${customerId}&orderDate=${OurNewDateFormat}`)
            .then((response) => {
              console.log("MgmtAdminChangePassword(Response) service:", response);
              // console.log("MgmtCustomerBrandDetailsUpdate(Response):" + JSON.stringify(response));
              if (response?.data?.data) {
                let brandDetails = response.data.data;
                resolve(brandDetails);
              } else {
                reject(response.data.error);
              }
            })
            .catch((error) => {
              // console.log("MgmtCustomerBrandDetailsUpdate (Response Error):" + JSON.stringify(error));
              reject(error);
            });
        })

 
}

const mgmtOrder = new MgmtOrder();

export default mgmtOrder;
