import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import { useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles'
import Page from 'src/components/Page';
import Header from './Header';
import { paymentGateWayType } from 'src/actions/mgmtPaymentGateWayTypeAction';
import Results from './Result';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function PaymentForm() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      paymentGateWayType());
    
  }, []);
  
  return (
    <Page
      className={classes.root}
      title="Payment GateWay Type"
    >
           <Container maxWidth={false}>
      <Header/>
      <Results/>
      </Container>
    </Page>
  );
}

export default PaymentForm;
