import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { DataGrid } from '@mui/x-data-grid'
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import PropTypes from 'prop-types';
import Delete from '@mui/icons-material/Delete';
import axios from 'src/utils/axios';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import {
  Box,
} from '@mui/material';

//need to change below fields as per the data
// const columns = [
//   // { field: 'addressLine1', headerName: 'BillTo Address Line 1', width: 200,
//   //  valueGetter: (params) => {
//   //     return params.row.billTo.address.addressLine1;
//   //   } },
// ]

function Results2({ className, services2, ccid, onSubmitSuccess, ...rest }) {
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const [services, setServices] = useState(null);
  const email = services2.registeredEmail
  const getServices = useCallback(() => {
    axios
      .get(`/api/admin/order/for/oem?customerId=${ccid}&oemEmail=${email}`) //, {})
      .then((response) => {
        if (isMountedRef.current) {
          // console.log("getServices1 =>" + JSON.stringify(response.data));
          if (response?.data?.data) {
            // setProject(response.data.data.projects);
            setServices(response.data.data);
          } else {
            setServices([]);
          }
        }
      });
  }, [isMountedRef]);

 
  // console.log("email", email);

  const handleDelete = async (customerId, categoryId) => {
    // console.log("ServiceID",customerId);
    try {
      const payload =
      {
        "customerId": customerId,
        "categoryId": categoryId
      }
      const deleteResult = await axios.delete(`/api/admin/catalog/category`, payload);
      console.log(`deleteResult: `, deleteResult);
      // setServices(services.filter((row) => row.categoryId !== categoryId));
    } catch (err) { }
  };
  const columns = [
    { field: 'customerId', headerName: 'CustomerId', width: 100 },
    { field: 'userId', headerName: 'UserId', width: 100 },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 100,
    //   renderCell: (params) => {
    //     console.log("RENDERCELLPARAMS:", params)
    //     return (
    //       <div className="cellAction">
    //         <Tooltip title="Update Order Status">
    //         <IconButton
    //           // component={RouterLink}
    //           // to={`/app/management/service/update/${params.row.serviceId}`}
    //           onClick={() => {
    //             navigate('/app/management/order/update', { state: params.row });
    //           }
    //           }
    //         >
    //           <SvgIcon fontSize="small">
    //             <EditIcon />
    //           </SvgIcon>
    //         </IconButton>
    //         </Tooltip><m>
    //         {/* <Tooltip title="Delete Service">
    //         <IconButton
    //           onClick={() => handleDelete(params.row.serviceId)}
    //         >
    //           <SvgIcon fontSize="small">
    //             <Delete />
    //           </SvgIcon>
    //         </IconButton>
    //         </Tooltip> */}
    //         </m>

    //       </div>

    //     );
    //   },
    // },

    { field: 'mobile', headerName: 'Mobile', width: 100 },
    { field: 'email', headerName: 'Email', width: 100 },
    { field: 'orderAmount', headerName: 'OrderAmount ', width: 100 },
    { field: 'totalAmount', headerName: 'Total Amount', width: 100 },
    { field: 'status', headerName: 'Status', width: 100 },
    { field: 'paymentOrderRef', headerName: 'Payment Order Ref', width: 100 },
    { field: 'paymentOrderId', headerName: 'Payment Order Id', width: 100 },
    { field: 'paymentGateway', headerName: 'Payment Gateway', width: 100 },
    { field: 'isDeleted', headerName: 'Is Deleted', width: 100 },
    { field: 'isActive', headerName: 'Is Active', width: 100 },
  ];

  useEffect(() => {
    getServices();
  }, [getServices]);

  if (!services) {
    return null;
  }
  return (

    <Box sx={{ height: 400, width: '95%' }}>
      <DataGrid
        rows={services}
        columns={columns}
        getRowId={(row) => row.userId}
        // checkboxSelection={true}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Box>
  )
}

Results2.propTypes = {
  className: PropTypes.string,
  services: PropTypes.array,
  onSubmitSuccess: PropTypes.func,
};

Results2.defaultProps = {
  services: []
};

export default Results2;



















