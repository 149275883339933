import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";

import axios from 'src/utils/axios';
import { DeliveryChargesCreate } from "src/actions/mgmtCustomerCreateAction";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function DeliveryCharges({ className, state, deliverychargesInfo, ...rest }) {
  console.log(`DeliveryCharges() :: state: `, state, ` deliverychargesInfo: `, deliverychargesInfo);
  
  const prevDistance = deliverychargesInfo?.distance ? deliverychargesInfo.distance : "";
  const prevCost = deliverychargesInfo?.cost ? deliverychargesInfo.cost : "";
  console.log(`DeliveryCharges() :: prevDistance: `, prevDistance, ` prevCost: `, prevCost);
  
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (

    <Formik
      enableReinitialize
      initialValues={{
        customerId: state.customerId || '',
        distance: prevDistance,
        cost: prevCost,
      }}
      validationSchema={Yup.object().shape({
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        //console.log("Submitting the request", businessId, addedBy, values);
        try {
          await dispatch(DeliveryChargesCreate(values));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Delivery Charges Added", {
            variant: "success",
          });
          navigate("/app/management/customer/view");
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Something went wrong", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card style={{marginLeft:"2em",width:"600px"}}>
            <CardHeader title="Add Delivery Charges" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.customerId && errors.customerId
                    )}
                    fullWidth
                    helperText={
                      touched.customerId && errors.customerId
                    }
                    label="Customer Id"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.distance && errors.distance
                    )}
                    fullWidth
                    helperText={
                      touched.distance && errors.distance
                    }
                    label="Distance"
                    type="number"
                    name="distance"
                    id="distance"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.distance}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.cost && errors.cost
                    )}
                    fullWidth
                    helperText={
                      touched.cost && errors.cost
                    }
                    label="Cost"
                    type="number"
                    name="cost"
                    id="cost"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.cost}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              style={{marginLeft:"2em"}}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

DeliveryCharges.propTypes = {
  className: PropTypes.string,
};

export default DeliveryCharges;


