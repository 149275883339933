import produce from "immer";
import { SIGNOUT } from "src/actions/accountActions";

// import { TESTIMONIALINFO_CREATE_SUCCESS } from "src/actions/mgmtNewsAction";
import { FETCH_TESTIMONIAL, FETCH_TESTIMONIAL_SUCCESS, FETCH_TESTIMONIAL_FAILURE } from "src/actions/testimonialActions";
const initialState = {
    testimonials: [],
};

const testimonialReducer = (state = initialState, action) => {
    switch (action.type) {
        // case TESTIMONIALINFO_CREATE_SUCCESS: {   // TODO_SP : Need to review and remove this
        //     const { testinonails } = action.payload;
        //     return produce(state, (draft) => {
        //         draft.testinonails = testinonails;
        //     });
        // }

        case FETCH_TESTIMONIAL_SUCCESS: {
            const { testimonials } = action.payload;

            return produce(state, (draft) => {
                draft.testimonials = testimonials;
            })
        }



        case FETCH_TESTIMONIAL:
        case FETCH_TESTIMONIAL_FAILURE: {
            return produce(state, (draft) => {
                draft.testimonials = [];
            })
        }


        case SIGNOUT: {
            return produce(state, (draft) => {
                draft.testimonials = [];
            })
        }



        default: {
            return state;
        }
    }
};

export default testimonialReducer;


