import React from 'react';
import clsx from 'clsx';
import {
  Box, Card,
  CardContent,
  Grid,
  Collapse, IconButton, Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },

}));

function SummaryCard({ title, total, imageIcon }) {
  const classes = useStyles();

  return (
    <Grid >
      <Card sx={{ width: "90%" }}>
        <Box sx={{ flexDirection: "row", display: "flex" }}>
          <Box sx={{ padding: 2 }}>
            <Card sx={{ backgroundColor: "#0c2860", height: 50 }}>
              <CardContent sx={{}}>{imageIcon}</CardContent>
            </Card>
          </Box>
          <Box sx={{ padding: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ mb: 1 }}>{title}</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {total}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
}

export default SummaryCard
