import React from 'react';
import {
  Container
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Header from './Header';
import { useSelector } from 'react-redux';
import RenewPassword from './RenewPassword';
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function AdminRenewPassword() {

  const classes = useStyles();
  const { state } = useLocation();
  const account = useSelector((state) => state.account);
  const email = account.user.email
  const cusId = account.user.customerId

    if (!account) {
      return null;
    }
  
  return (
    <Page
      className={classes.root}
      title="Renew Password"
    >
      <Container maxWidth={false}>
        <Header />
        <br />
       <RenewPassword state={state}/>
      </Container>
    </Page>
  );
}

export default AdminRenewPassword;
