import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader';
import InvoiceTableRow from './InvoiceTableRow';
import InvoiceTableFooter from './InvoiceTableFooter';
import InvoiceTableFooter1 from './Discount';
import InvoiceTableFooter2 from './FinalPrice';
import Cgst from './Cgst';
import Igst from './Igst';

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#3778C2',
    },
});

const InvoiceItemsTable = ({ invoice,dif }) => (
    <View style={styles.tableContainer}>
        <InvoiceTableHeader />
        <InvoiceTableRow items={invoice.orderItems} />
        <InvoiceTableFooter items={invoice.orderItems} />
        <InvoiceTableFooter1 items={invoice} />
        <Cgst items={invoice} />
        <Igst items={invoice} />
        <InvoiceTableFooter2 items={invoice} dif={dif}/>
    </View>
);

export default InvoiceItemsTable;