import axios from 'src/utils/axios';
import { IMAGE_TYPE } from "src/config";
import { Type } from 'react-feather';
import { CREATE_TESTIMONIAL_URL } from 'src/constants/services';

const UPLOAD_IMAGE_LOGO_URL = "/api/admin/customer/upload/images";
const UPLOAD_IMAGE_UPDATEBANNER1_URL = "api/admin/customer/replace-banner";
const UPLOAD_IMAGE_NEWS_URL = "/api/admin/customer/info/news";
const UPLOAD_IMAGE_GREETING_URL = "/api/admin/customer/info/greeting";
const UPLOAD_IMAGE_CATEGORY_URL = "/api/admin/catalog/category";
const UPLOAD_IMAGE_BASE_URL = "/api/admin/catalog/product";
const UPLOAD_IMAGE_UPDATEPRODUCT_URL = "/api/admin/catalog/replace-imageurl";


class MgmtImageUpload {

    uploadImage = (data, type, customerId, id, service,index) =>
        new Promise((resolve, reject) => {
            console.log("uploadImage() ", type, customerId, id);

            const formData = new FormData();
            const method = (type === "baseimage")? "put" :"post"
            let requestURL = "";
           
            switch (type) {
                case IMAGE_TYPE.LOGO:
                    formData.append("customerId", customerId);
                    formData.append("logoFile", data);
                    requestURL = UPLOAD_IMAGE_LOGO_URL;
                    break;
                case IMAGE_TYPE.SHORTLOGO:
                    formData.append("customerId", customerId);
                    formData.append("shortLogo", data);
                    requestURL = UPLOAD_IMAGE_LOGO_URL;
                    break;
                case IMAGE_TYPE.BANNER1:
                    formData.append("customerId", customerId);
                    formData.append("bannerFiles", data);
                    formData.append('insertOrOverwrite', "1");
                    requestURL = UPLOAD_IMAGE_LOGO_URL;
                    break;
                case IMAGE_TYPE.UPDATEBANNER1:
                        formData.append("customerId", customerId);
                        formData.append("bannerFiles", data);
                        formData.append('brandId', service.brandDetails[0].brandId);
                        formData.append('index', index);
                        // formData.append('insertOrOverwrite', "1");
                        requestURL = UPLOAD_IMAGE_UPDATEBANNER1_URL;
                        break;
                case IMAGE_TYPE.BANNER2:
                    formData.append("customerId", customerId);
                    formData.append("bannerFiles", data);
                    formData.append('insertOrOverwrite', "1");
                    requestURL = UPLOAD_IMAGE_LOGO_URL;
                    break;
                    case IMAGE_TYPE.UPDATEBANNER2:
                        formData.append("customerId", customerId);
                        formData.append("bannerFiles", data);
                        formData.append('brandId', service.brandDetails[0].brandId);
                        formData.append('index', index);
                        // formData.append('insertOrOverwrite', "1");
                        requestURL = UPLOAD_IMAGE_UPDATEBANNER1_URL;
                        break;
                case IMAGE_TYPE.BANNER3:
                    formData.append("customerId", customerId);
                    formData.append("bannerFiles", data);
                    formData.append('insertOrOverwrite', "1");
                    requestURL = UPLOAD_IMAGE_LOGO_URL;
                    break;
                    case IMAGE_TYPE.UPDATEBANNER3:
                        formData.append("customerId", customerId);
                        formData.append("bannerFiles", data);
                        formData.append('brandId', service.brandDetails[0].brandId);
                        formData.append('index', index);
                        // formData.append('insertOrOverwrite', "1");
                        requestURL = UPLOAD_IMAGE_UPDATEBANNER1_URL;
                        break;
                case IMAGE_TYPE.BANNER4:
                    formData.append("customerId", customerId);
                    formData.append("bannerFiles", data);
                    formData.append('insertOrOverwrite', "1");
                    requestURL = UPLOAD_IMAGE_LOGO_URL;
                    break;
                    case IMAGE_TYPE.UPDATEBANNER4:
                        formData.append("customerId", customerId);
                        formData.append("bannerFiles", data);
                        formData.append('brandId', service.brandDetails[0].brandId);
                        formData.append('index', index);
                        // formData.append('insertOrOverwrite', "1");
                        requestURL = UPLOAD_IMAGE_UPDATEBANNER1_URL;
                        break;
                case IMAGE_TYPE.BANNER5:
                    formData.append("customerId", customerId);
                    formData.append("bannerFiles", data);
                    formData.append('insertOrOverwrite', "1");
                    requestURL = UPLOAD_IMAGE_LOGO_URL;
                    break;
                    case IMAGE_TYPE.UPDATEBANNER5:
                        formData.append("customerId", customerId);
                        formData.append("bannerFiles", data);
                        formData.append('brandId', service.brandDetails[0].brandId);
                        formData.append('index', index);
                        // formData.append('insertOrOverwrite', "1");
                        requestURL = UPLOAD_IMAGE_UPDATEBANNER1_URL;
                        break;
                case IMAGE_TYPE.BANNER6:
                    formData.append("customerId", customerId);
                    formData.append("bannerFiles", data);
                    formData.append('insertOrOverwrite', "1");
                    requestURL = UPLOAD_IMAGE_LOGO_URL;
                    break;
                    case IMAGE_TYPE.UPDATEBANNER6:
                        formData.append("customerId", customerId);
                        formData.append("bannerFiles", data);
                        formData.append('brandId', service.brandDetails[0].brandId);
                        formData.append('index', index);
                        // formData.append('insertOrOverwrite', "1");
                        requestURL = UPLOAD_IMAGE_UPDATEBANNER1_URL;
                        break;

                    case IMAGE_TYPE.PRODUCT_IMAGE:
                        // TODO_SP: the below code to be changed for inserting an image
                        // console.log("mgmtImageService: Product_Image", customerId, id, service, index);
                        // formData.append("imageURL", data);
                        //     formData.append('insertOrOverwrite', "1");
                        //     formData.append('productData', `{"customerId":${customerId},
                        //     "productId":${service.productId},
                        //     "categoryId":[${service.categoryId}],
                        //     "productName":"${service.productName}",
                        //     "productSKU":"${service.productSKU}",
                        //     "price":"${service.price}"}`
                        //     );
                        //     requestURL = UPLOAD_IMAGE_BASE_URL;
                        //     break;

                        formData.append("customerId", customerId);
                        formData.append("imageURL", data);
                        formData.append('productId', id);
                        formData.append('index', index);
                        formData.append('insertOrOverwrite', 1);
                        requestURL = UPLOAD_IMAGE_UPDATEPRODUCT_URL;
                        break;
                        
                    


                        case IMAGE_TYPE.PRODUCTIMAGE1:
                            // formData.append("customerId", customerId);
                            formData.append("imageURL", data);
                            formData.append('insertOrOverwrite', "1");
                            formData.append('productData', `{"customerId":${customerId},
                            "productId":${service.productId},
                            "categoryId":[${service.categoryId}],
                            "productName":"${service.productName}",
                            "productSKU":"${service.productSKU}",
                            "price":"${service.price}"}`
                            );
                            requestURL = UPLOAD_IMAGE_BASE_URL;
                            break;
                        case IMAGE_TYPE.UPDATEPRODUCTIMAGE1:
                                formData.append("customerId", customerId);
                                formData.append("imageURL", data);
                                formData.append('productId', service.productId);
                                formData.append('index', index);

                                // formData.append('insertOrOverwrite', "1");
                                requestURL = UPLOAD_IMAGE_UPDATEPRODUCT_URL;
                                break;
                        case IMAGE_TYPE.PRODUCTIMAGE2:
                            formData.append("imageURL", data);
                            formData.append('insertOrOverwrite', "1");
                            formData.append('productData', `{"customerId":${customerId},
                            "productId":${service.productId},
                            "categoryId":[${service.categoryId}],
                            "productName":"${service.productName}",
                            "productSKU":"${service.productSKU}",
                            "price":"${service.price}"}`
                            );
                            requestURL = UPLOAD_IMAGE_BASE_URL;
                            break;
                            case IMAGE_TYPE.UPDATEPRODUCTIMAGE2:
                                formData.append("customerId", customerId);
                                formData.append("imageURL", data);
                                formData.append('productId', service.productId);
                                formData.append('index', index);
                                // formData.append('insertOrOverwrite', "1");
                                requestURL = UPLOAD_IMAGE_UPDATEPRODUCT_URL;
                                break;
                        case IMAGE_TYPE.PRODUCTIMAGE3:
                            formData.append("imageURL", data);
                            formData.append('insertOrOverwrite', "1");
                            formData.append('productData', `{"customerId":${customerId},
                            "productId":${service.productId},
                            "categoryId":[${service.categoryId}],
                            "productName":"${service.productName}",
                            "productSKU":"${service.productSKU}",
                            "price":"${service.price}"}`
                            );
                            requestURL = UPLOAD_IMAGE_BASE_URL;
                            break;
                            case IMAGE_TYPE.UPDATEPRODUCTIMAGE3:
                                formData.append("customerId", customerId);
                                formData.append("imageURL", data);
                                formData.append('productId', service.productId);
                                formData.append('index', index);
                                // formData.append('insertOrOverwrite', "1");
                                requestURL = UPLOAD_IMAGE_UPDATEPRODUCT_URL;
                                break;
                        case IMAGE_TYPE.PRODUCTIMAGE4:
                            formData.append("imageURL", data);
                            formData.append('insertOrOverwrite', "1");
                            formData.append('productData', `{"customerId":${customerId},
                            "productId":${service.productId},
                            "categoryId":[${service.categoryId}],
                            "productName":"${service.productName}",
                            "productSKU":"${service.productSKU}",
                            "price":"${service.price}"}`
                            );
                            requestURL = UPLOAD_IMAGE_BASE_URL;
                            break;
                            case IMAGE_TYPE.UPDATEPRODUCTIMAGE4:
                                formData.append("customerId", customerId);
                                formData.append("imageURL", data);
                                formData.append('productId', service.productId);
                                formData.append('index', index);
                                // formData.append('insertOrOverwrite', "1");
                                requestURL = UPLOAD_IMAGE_UPDATEPRODUCT_URL;
                                break;
                        case IMAGE_TYPE.PRODUCTIMAGE5:
                            formData.append("imageURL", data);
                            formData.append('insertOrOverwrite', "1");
                            formData.append('productData', `{"customerId":${customerId},
                            "productId":${service.productId},
                            "categoryId":[${service.categoryId}],
                            "productName":"${service.productName}",
                            "productSKU":"${service.productSKU}",
                            "price":"${service.price}"}`
                            );
                            requestURL = UPLOAD_IMAGE_BASE_URL;
                            break;
                            case IMAGE_TYPE.UPDATEPRODUCTIMAGE5:
                                formData.append("customerId", customerId);
                                formData.append("imageURL", data);
                                formData.append('productId', service.productId);
                                formData.append('index', index);
                                // formData.append('insertOrOverwrite', "1");
                                requestURL = UPLOAD_IMAGE_UPDATEPRODUCT_URL;
                                break;
                        case IMAGE_TYPE.PRODUCTIMAGE6:
                            formData.append("imageURL", data);
                            formData.append('insertOrOverwrite', "1");
                            formData.append('productData', `{"customerId":${customerId},
                            "productId":${service.productId},
                            "categoryId":[${service.categoryId}],
                            "productName":"${service.productName}",
                            "productSKU":"${service.productSKU}",
                            "price":"${service.price}"}`
                            );
                            requestURL = UPLOAD_IMAGE_BASE_URL;
                            break;
                            case IMAGE_TYPE.UPDATEPRODUCTIMAGE6:
                                formData.append("customerId", customerId);
                                formData.append("imageURL", data);
                                formData.append('productId', service.productId);
                                formData.append('index', index);
                                // formData.append('insertOrOverwrite', "1");
                                requestURL = UPLOAD_IMAGE_UPDATEPRODUCT_URL;
                                break;
                case IMAGE_TYPE.NEWSIMAGE:
                    formData.append("customerId", customerId);
                    formData.append("newsImage", data);
                    formData.append("newsId", id);
                    requestURL = UPLOAD_IMAGE_NEWS_URL;
                    break;
                    case IMAGE_TYPE.GREETINGIMAGE:
                    formData.append("customerId", customerId);
                    formData.append("greetImage", data);
                    formData.append("greetId", id);
                    requestURL = UPLOAD_IMAGE_GREETING_URL;
                    break;
                case IMAGE_TYPE.CATEGORYIMAGE:                    
                    formData.append('categoryData', `{"categoryId":${id} ,"customerId":${customerId}
                    }`);
                    formData.append("categoryImage", data);
                    requestURL = UPLOAD_IMAGE_CATEGORY_URL;
                    break;
                case IMAGE_TYPE.BASEIMAGE:
                    formData.append('baseImageUrl',data);
                    formData.append('productData', `{"customerId":${customerId},
                    "productId":${id}}`);
                    // formData.append('productId',id);
                     requestURL = UPLOAD_IMAGE_BASE_URL;
                     break;
                case IMAGE_TYPE.TESTIMONIAL:
                        formData.append("customerId", customerId);
                        formData.append("testimonialImage", data);
                        formData.append("testimonyId", id);
                        requestURL = CREATE_TESTIMONIAL_URL;
                        break;
                default:
                    break;
            }

            switch (type) {
                case IMAGE_TYPE.CATEGORYIMAGE:
                    axios
                    .put(requestURL, formData)
                    .then((response) => {
                        // console.log("ImageService:imageUpload: Response", response.data);
                        if (response.data.error) {
                            reject(response.data.message);
                        } else {
                            resolve(response?.data);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            reject(error?.response);
                        }
                        reject(error);
                    });
                    break;
                    case IMAGE_TYPE.BASEIMAGE:
                        axios
                        .put(requestURL, formData)
                        .then((response) => {
                            // console.log("ImageService:imageUpload: Response", response.data);
                            if (response.data.error) {
                                reject(response.data.message);
                            } else {
                                resolve(response?.data);
                            }
                        })
                        .catch((error) => {
                            if (error.response) {
                                reject(error?.response);
                            }
                            reject(error);
                        });
                        break;
                        case IMAGE_TYPE.PRODUCT_IMAGE:
                            console.log("ImageService:imageUpload: Response", requestURL, formData);
                            axios
                            //.put(requestURL, formData)
                            .post(requestURL, formData)
                            .then((response) => {
                                // console.log("ImageService:imageUpload: Response", response.data);
                                if (response.data.error) {
                                    reject(response.data.message);
                                } else {
                                    resolve(response?.data);
                                }
                            })
                            .catch((error) => {
                                if (error.response) {
                                    reject(error?.response);
                                }
                                reject(error);
                            });
                            break;



                        case IMAGE_TYPE.PRODUCTIMAGE1:
                            axios
                            .put(requestURL, formData)
                            .then((response) => {
                                // console.log("ImageService:imageUpload: Response", response.data);
                                if (response.data.error) {
                                    reject(response.data.message);
                                } else {
                                    resolve(response?.data);
                                }
                            })
                            .catch((error) => {
                                if (error.response) {
                                    reject(error?.response);
                                }
                                reject(error);
                            });
                            break;
                            case IMAGE_TYPE.PRODUCTIMAGE2:
                                axios
                                .put(requestURL, formData)
                                .then((response) => {
                                    // console.log("ImageService:imageUpload: Response", response.data);
                                    if (response.data.error) {
                                        reject(response.data.message);
                                    } else {
                                        resolve(response?.data);
                                    }
                                })
                                .catch((error) => {
                                    if (error.response) {
                                        reject(error?.response);
                                    }
                                    reject(error);
                                });
                                break;
                                case IMAGE_TYPE.PRODUCTIMAGE3:
                                    axios
                                    .put(requestURL, formData)
                                    .then((response) => {
                                        // console.log("ImageService:imageUpload: Response", response.data);
                                        if (response.data.error) {
                                            reject(response.data.message);
                                        } else {
                                            resolve(response?.data);
                                        }
                                    })
                                    .catch((error) => {
                                        if (error.response) {
                                            reject(error?.response);
                                        }
                                        reject(error);
                                    });
                                    break;
                                    case IMAGE_TYPE.PRODUCTIMAGE4:
                                        axios
                                        .put(requestURL, formData)
                                        .then((response) => {
                                            // console.log("ImageService:imageUpload: Response", response.data);
                                            if (response.data.error) {
                                                reject(response.data.message);
                                            } else {
                                                resolve(response?.data);
                                            }
                                        })
                                        .catch((error) => {
                                            if (error.response) {
                                                reject(error?.response);
                                            }
                                            reject(error);
                                        });
                                        break;
                                        case IMAGE_TYPE.PRODUCTIMAGE5:
                                            axios
                                            .put(requestURL, formData)
                                            .then((response) => {
                                                // console.log("ImageService:imageUpload: Response", response.data);
                                                if (response.data.error) {
                                                    reject(response.data.message);
                                                } else {
                                                    resolve(response?.data);
                                                }
                                            })
                                            .catch((error) => {
                                                if (error.response) {
                                                    reject(error?.response);
                                                }
                                                reject(error);
                                            });
                                            break;
                                            case IMAGE_TYPE.PRODUCTIMAGE6:
                                                axios
                                                .put(requestURL, formData)
                                                .then((response) => {
                                                    // console.log("ImageService:imageUpload: Response", response.data);
                                                    if (response.data.error) {
                                                        reject(response.data.message);
                                                    } else {
                                                        resolve(response?.data);
                                                    }
                                                })
                                                .catch((error) => {
                                                    if (error.response) {
                                                        reject(error?.response);
                                                    }
                                                    reject(error);
                                                });
                                                break;
                     default:
            axios
            .post(requestURL, formData)
            .then((response) => {
                // console.log("ImageService:imageUpload: Response", response.data);
                if (response.data.error) {
                    reject(response.data.message);
                } else {
                    resolve(response?.data);
                }
            })
            .catch((error) => {
                if (error.response) {
                    reject(error?.response);
                }
                reject(error);
            });
            
        }
           
        });
             
}

const mgmtImageUpload = new MgmtImageUpload();

export default mgmtImageUpload;
