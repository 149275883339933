import { Testimonial } from "src/interface/testimonialIf";
import testimonialService from "src/services/testimonialService";

export const FETCH_TESTIMONIAL = '@business/fetch-testimonial';
export const FETCH_TESTIMONIAL_SUCCESS = '@business/fetch-testimonial-success';
export const FETCH_TESTIMONIAL_FAILURE = '@business/fetch-testimonial-failure';

export const TESTIMONIAL_CREATE = '@testimonial/create';
export const TESTIMONIAL_CREATE_SUCCESS = '@testimonial/create-success';
export const TESTIMONIAL_CREATE_FAILURE = '@testimonial/create-failure';

export const TESTIMONIAL_UPDATE = '@testimonial/update';
export const TESTIMONIAL_UPDATE_SUCCESS = '@testimonial/update-success';
export const TESTIMONIAL_UPDATE_FAILURE = '@testimonial/update-failure';

export const TESTIMONIAL_DELETE = '@testimonial/delete';
export const TESTIMONIAL_DELETE_SUCCESS = '@testimonial/delete-success';
export const TESTIMONIAL_DELETE_FAILURE = '@testimonial/delete-failure';

export function fetchTestimonials(customerId) {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_TESTIMONIAL });
            const resp = await testimonialService.fetchTestimonials(customerId);
            console.log("fetchTestimonials Action", resp);
            dispatch({
                type: FETCH_TESTIMONIAL_SUCCESS,
                payload: {
                    testimonials : resp?.testimonies
                },
            });
        } catch (error) {
            dispatch({ type: FETCH_TESTIMONIAL_FAILURE });
            throw error;
        }
    };
}

export function createTestimonial(userId, info, image) {
    return async (dispatch) => {
      console.log("createTestimonial():", userId, info);
      let newTestimonial = new Testimonial(userId, info);
      newTestimonial.addedBy = userId;
      console.log("createTestimonial():" + JSON.stringify(info) + " " + JSON.stringify(newTestimonial));
      try {
        dispatch({ type: TESTIMONIAL_CREATE });
        const testimonial = await testimonialService.createTestimonial(newTestimonial,image);
        console.log("createTestimonial()(after):" + JSON.stringify(testimonial));
        dispatch({
          type: TESTIMONIAL_CREATE_SUCCESS,
          payload: {
            testimonial
          }
        });
      } catch (error) {
        dispatch({ type: TESTIMONIAL_CREATE_FAILURE });
        throw error;
      }
    };
  }

  export function updateTestimonial(userId, info) {
    return async (dispatch) => {
      console.log("updateTestimonial():", userId, info);
      let newTestimonial = new Testimonial(userId, info);
      newTestimonial.addedBy = userId;
      console.log("updateTestimonial():" + JSON.stringify(info) + " " + JSON.stringify(newTestimonial));
      try {
        dispatch({ type: TESTIMONIAL_UPDATE });
        const testimonial = await testimonialService.updateTestimonial(newTestimonial);
        console.log("updateTestimonial()(after):" + JSON.stringify(testimonial));
        dispatch({
          type: TESTIMONIAL_UPDATE_SUCCESS,
          payload: {
            testimonial
          }
        });
      } catch (error) {
        dispatch({ type: TESTIMONIAL_UPDATE_FAILURE });
        throw error;
      }
    };
  }

  export function deleteTestimonial(customerId, testimonialId) {
    return async (dispatch) => {
        console.log("deleteTestimonial() : ", testimonialId);
        try {
            dispatch({ type: TESTIMONIAL_DELETE });
            const resp = await testimonialService.deleteTestimonial(customerId, testimonialId);
            console.log("deleteTestimonial Action", resp);
            dispatch({
                type: TESTIMONIAL_DELETE_SUCCESS,
                payload: {
                    testimonials : resp?.testimonies
                },
            });
        } catch (error) {
            dispatch({ type: TESTIMONIAL_DELETE_FAILURE });
            throw error;
        }
    };
}


