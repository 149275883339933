import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Field, FieldArray, Formik } from "formik";
import { useSnackbar } from "notistack";
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import mgmtProduct from "src/services/mgmtProduct";
import { getSearchBusiness } from "src/actions/mgmtSearchProductAction";
import { fetchCategory } from "src/actions/mgmtSelectValueAction";
import ImageEditor from "src/components/ImageEditor";
import { IMAGE_TYPE } from "src/config";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function ProductUpdate({ className, service, custId, categoryInfo2, customerList, props, ...rest }) {
  // const current = new Date();
  // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
  // console.log("date",date);
  function getCurrentDate(separator = '-') {
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
  }
  const date = getCurrentDate()
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const proId = service.productId 
  const isEngineeringType = (customerList) ? customerList.isEngineeringType : "false";

  const additionalFeatures = service.additionalFeatures

  console.log("additionalFeatures",additionalFeatures);

  const image62 = "/static/home/defaultImage.png";

  const path1 = process.env.REACT_APP_PATH

  const [categoryInfo1, setCategoryInfo1] = useState(null);

  const getCategoryInfo1 = useCallback(() => {
    axios
      .get(`/api/admin/catalog/category/names?customerId=${custId}`) //, {})
      .then((response) => {
        if (isMountedRef.current) {
          // console.log("get category info =>" + JSON.stringify(response.data));
          if (response?.data?.data?.categories) {
            setCategoryInfo1(response.data.data.categories);
          } else {
            setCategoryInfo1([]);
          }
        }
      });
  }, [isMountedRef]);

  
let formAdditionalFeatures = [];
if(service){
  let myAdditionalFeatures = service?.additionalFeatures ?? [];
  formAdditionalFeatures = myAdditionalFeatures.map((item) => {
    const newObj = {};
    for (const key in item) {
      newObj.Key = key;
      newObj.Value = Array.isArray(item[key]) ? item[key].join(',') : item[key].toString();
    }
    return newObj;
  });
}
console.log(`formAdditionalFeatures: `, formAdditionalFeatures);

  const base = service.baseImageUrl
  const ur = `${path1}/${base}`
  const logo = ur
  const image0 = service.images[0]
  const ur0 = `${path1}/${image0}`
  const src0 = image0 === null || image0 === undefined ? image62 : ur0
  const image1 = service.images[1]
  const ur1 = `${path1}/${image1}`
  const src1 = image1 === null || image1 === undefined ? image62 : ur1
  const image2 = service.images[2]
  const ur2 = `${path1}/${image2}`
  const src2 = image2 === null || image2 === undefined ? image62 : ur2
  const image3 = service.images[3]
  const ur3 = `${path1}/${image3}`
  const src3 = image3 === null || image3 === undefined ? image62 : ur3
  const image4 = service.images[4]
  const ur4 = `${path1}/${image4}`
  const src4 = image4 === null || image4 === undefined ? image62 : ur4
  const image5 = service.images[5]
  const ur5 = `${path1}/${image5}`
  const src5 = image5 === null || image5 === undefined ? image62 : ur5

  const ifProImg1 = (service?.images[0]?.length) ? service.images[0].length : "not"
  const ifProImg2 = (service?.images[1]?.length) ? service.images[1].length : "not"
  const ifProImg3 = (service?.images[2]?.length) ? service.images[2].length : "not"
  const ifProImg4 = (service?.images[3]?.length) ? service.images[3].length : "not"
  const ifProImg5 = (service?.images[4]?.length) ? service.images[4].length : "not"
  const ifProImg6 = (service?.images[5]?.length) ? service.images[5].length : "not"

  useEffect(() => {
    getCategoryInfo1();
  }, [getCategoryInfo1]);

  if (!categoryInfo1) {
    return null;
  }

  return (

    <Formik
      initialValues={{
        customerId: service.customerId || '',
        categoryId: service.categoryId || [''],
        productId: service.productId || '',
        brandName: service.brandName || '',
        availableStock: service.availableStock || 0,
        make: service.make || '',
        productName: service.productName || '',
        price: service.price.sellPrice || 0,
        specialPrice: service.price.specialPrice.price || 0,
        discount: service.price.specialPrice.discount || 0,
        from:date || "",
        till:"",   
        productSKU: service.productSKU || '',
        baseImageUrl: service.baseImageUrl || '',
        description: service.description || '',
        header: service.header || '',
        metrics: service.metrics || '',
        amPartNo: service.amPartNo || '',
        imageUrl: service.imageUrl || [''],
        additionalFeatures: formAdditionalFeatures
        // [
        //   {
        //     Key: "",
        //     Value: "",
        //   }]

      }}
      validationSchema={Yup.object().shape({
        productName: Yup.string().max(50).required("Product Name is required"),
        brandName: Yup.string().max(50).required("Brand Name is required"),
        price: Yup.string().max(1000).required("Price is required"),
        description: Yup.string().max(500).required("description is required"),
        productSKU: Yup.string().max(50).required("productSKU is required"),
        metrics: Yup.string().max(50).required("Metrics is required"),

      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        // console.log("Submitting the request", values, image11, image22);
        try {
          let newAdditionalFeatures = [];
          let newObj;
          values.additionalFeatures.map((val, ind, arr) => {
            if (val.Key && val.Value) {
              newObj = {}
              const myArray = val.Value.split(",");
              if (myArray.length > 1) {
                newObj[val.Key] = myArray;
              } else {
                newObj[val.Key] = myArray[0];
              }
              newAdditionalFeatures.push(newObj);
            }
          });
          values.additionalFeatures = newAdditionalFeatures;
          console.log(`ProductEditForm() :: values: `, values);

          await dispatch(mgmtProduct.updateService5(values));
          setStatus({ success: true });
          setSubmitting(false);
          dispatch(getSearchBusiness(service.customerId));
          dispatch(fetchCategory(service.customerId));
          enqueueSnackbar("Service Created", {
            variant: "success",
          });
        } catch (err) {
          setStatus({ success: false });
          setSubmitting(false);
          dispatch(fetchCategory(service.customerId));
          dispatch(getSearchBusiness(service.customerId));
          enqueueSnackbar("Product Updated", {
            variant: "success",
          });
          navigate("/app/management/product/view");
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Update Product" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    error={Boolean(
                      touched.customerId && errors.customerId
                    )}
                    fullWidth
                    helperText={
                      touched.customerId && errors.customerId
                    }
                    label="customerId"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    error={Boolean(
                      touched.productId && errors.productId
                    )}
                    fullWidth
                    helperText={
                      touched.productId && errors.productId
                    }
                    label="productId"
                    name="productId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.productId}
                    variant="outlined"
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiple
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select CategoryId"
                    //  onChange={handleChange}
                    name="categoryId"
                    value={equipmentId}
                    onChange={handleChange}
                    className="w-100"
                    onClick={handleClick}
                  />
                  <Popover
                    id={id}
                    fullWidth
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                  >
                    <TreeView
                      multiSelect={true}
                      aria-label="rich object"
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpanded={['root']}
                      defaultExpandIcon={<ChevronRightIcon />}
                      sx={{ height: 110, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                      onNodeSelect={(e, categoryId) => {
                        setEquipmentId(categoryId);
                        setEquipmentItem(e.target.innerText);
                      }}
                    >
                      {sd.map((cities) => (
                        renderTree(cities)
                      ))}
                    </TreeView>
                  </Popover>
                </Grid> */}
                {/* <Grid item xs={12} sm={12}> */}
                {/* <RichObjectTreeView service={service} cId={cId} /> */}
                {/* <MultiSelectTreeView service={sd}/> */}
                {/* <App58 service={sd}/>
                </Grid> */}

                {/* <TreeSelect
                  treeData={categoryList}
                  treeDataSimpleMode={{
                    id: "categoryId",
                    pId: "_id"
                    //rootPId can be set too
                  }}
                  treeNodeLabelProp="name"
                  treeNodeFilterProp="categoryId"
                /> */}

                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select CategoryId</InputLabel>
                        <Select
                          multiple
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select CategoryId"
                          onChange={handleChange}
                          name="categoryId"
                          onBlur={handleBlur}
                          value={values.categoryId}
                        >
                          {categoryInfo1.map((cities) => (
                            <MenuItem value={cities.categoryId}
                            >
                              {cities.name}
                              {"..."}
                              {cities.categoryId}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.productName && errors.productName
                    )}
                    fullWidth
                    helperText={
                      touched.productName && errors.productName
                    }
                    label="Product Name"
                    name="productName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.productName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.brandName && errors.brandName
                    )}
                    fullWidth
                    helperText={
                      touched.brandName && errors.brandName
                    }
                    label="Brand Name"
                    name="brandName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.brandName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.header && errors.header
                    )}
                    fullWidth
                    helperText={
                      touched.header && errors.header
                    }
                    label="Header"
                    name="header"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.header}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.productSKU && errors.productSKU
                    )}
                    fullWidth
                    helperText={
                      touched.productSKU && errors.productSKU
                    }
                    label="Product SKU"
                    name="productSKU"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.productSKU}
                    variant="outlined"
                  />
                </Grid>
                {isEngineeringType === true ?
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(
                        touched.metrics && errors.metrics
                      )}
                      fullWidth
                      helperText={
                        touched.metrics && errors.metrics
                      }
                      label="Metrics"
                      name="metrics"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.metrics}
                      variant="outlined"
                    />
                  </Grid>
                  :
                  <Box />
                }
                {isEngineeringType === true ?
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(
                        touched.amPartNo && errors.amPartNo
                      )}
                      fullWidth
                      helperText={
                        touched.amPartNo && errors.amPartNo
                      }
                      label="Am Part No"
                      name="amPartNo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.amPartNo}
                      variant="outlined"
                    />
                  </Grid>
                  :
                  <Box />
                }
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.make && errors.make
                    )}
                    fullWidth
                    helperText={
                      touched.make && errors.make
                    }
                    label="Make"
                    name="make"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.make}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.availableStock && errors.availableStock
                    )}
                    fullWidth
                    helperText={
                      touched.availableStock && errors.availableStock
                    }
                    label="Available Stock"
                    name="availableStock"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.availableStock}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.price && errors.price
                    )}
                    fullWidth
                    helperText={
                      touched.price && errors.price
                    }
                    label="Price"
                    name="price"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.price}
                    variant="outlined"
                  />
                </Grid>
                <Card style={{marginLeft:"1.8em",width:"1150px",marginTop:"2em"}}>
            <CardHeader title="Special Price" />
            <Divider />
            <CardContent>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.specialPrice && errors.specialPrice
                    )}
                    fullWidth
                    helperText={
                      touched.specialPrice && errors.specialPrice
                    }
                    label="Price"
                    type="number"
                    name="specialPrice"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.specialPrice}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.discount && errors.discount
                    )}
                    fullWidth
                    helperText={
                      touched.discount && errors.discount
                    }
                    label="Discount"
                    type="number"
                    name="discount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.discount}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.from && errors.from
                    )}
                    fullWidth
                    helperText={
                      touched.from && errors.from
                    }
                    // label="From"
                    type="date"
                    name="from"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.from}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.till && errors.till
                    )}
                    fullWidth
                    helperText={
                      touched.till && errors.till}
                    // label="Till"
                    type="date"
                    name="till"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.till}
                    variant="outlined"
                  />
                </Grid>
                </Grid>
                </CardContent>
</Card>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.description && errors.description
                    )}
                    fullWidth
                    helperText={
                      touched.description && errors.description
                    }
                    multiline
                    rows={4}
                    label="Description"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>
                <Card style={{ marginLeft: "2em", marginTop: "2em" }}>
                  <CardContent>
                    <Grid item xs={12}>
                      <Paper
                        sx={{
                          width: 530,
                          height: 350,
                          background: "test",
                          backgroundSize: "cover",
                          mb: 1,
                        }}
                      >
                        <h1 align="center">Base Image</h1>
                        <ImageEditor type={IMAGE_TYPE.BASEIMAGE} inImage={logo} customerId={custId} id={proId}/>  
                      </Paper>
                    </Grid>
                  </CardContent>
                </Card>
                <br />
             <br/>
                <Grid item xs={6}>
                  <Paper
                    sx={{
                      width: 530,
                      height: 350,
                      background: "test",
                      backgroundSize: "cover",
                      mb: 1,
                    }}
                  >
                    <h1 align="center">Product Image 1</h1>
                    
                    {ifProImg1 > 0 ?
                      <ImageEditor type={IMAGE_TYPE.UPDATEPRODUCTIMAGE1} inImage={ur0} customerId={custId}   index={"0"} service={service} deletetype={"product"}/>
                      :
                      <ImageEditor type={IMAGE_TYPE.PRODUCTIMAGE1} inImage={ur0} customerId={custId}   index={"0"} service={service}  deletetype={"product"}/>
                    } 
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    sx={{
                      width: 530,
                      height: 350,
                      background: "test",
                      backgroundSize: "cover",
                      mb: 1,

                    }}
                  >
                    <h1 align="center">Product Image 2</h1>
                    {ifProImg2 > 0 ?
                      <ImageEditor type={IMAGE_TYPE.UPDATEPRODUCTIMAGE2} inImage={ur1} customerId={custId}   index={"1"} service={service} deletetype={"product"}/>
                      :
                      <ImageEditor type={IMAGE_TYPE.PRODUCTIMAGE2} inImage={ur1} customerId={custId}   index={"1"} service={service}  deletetype={"product"}/>
                    }                 
                  </Paper>
                </Grid>

                <Grid item xs={6}>
                  <Paper
                    sx={{
                      width: 530,
                      height: 350,
                      background: "test",
                      backgroundSize: "cover",
                      mb: 1,

                    }}
                  >
                    <h1 align="center">Product Image 3</h1>
                    {ifProImg3 > 0 ?
                      <ImageEditor type={IMAGE_TYPE.UPDATEPRODUCTIMAGE3} inImage={ur2} customerId={custId}   index={"2"} service={service} deletetype={"product"}/>

                      :
                      <ImageEditor type={IMAGE_TYPE.PRODUCTIMAGE3} inImage={ur2} customerId={custId}   index={"2"} service={service}  deletetype={"product"}/>
                    }
                  </Paper>
                </Grid>

                <Grid item xs={6}>
                  <Paper
                    sx={{
                      width: 530,
                      height: 350,
                      background: "test",
                      backgroundSize: "cover",
                      mb: 1,

                    }}
                  >
                    <h1 align="center">Product Image 4</h1>
                    {ifProImg4 > 0 ?
                      <ImageEditor type={IMAGE_TYPE.UPDATEPRODUCTIMAGE4} inImage={ur3} customerId={custId}   index={"3"} service={service} deletetype={"product"}/>

                      :
                      <ImageEditor type={IMAGE_TYPE.PRODUCTIMAGE4} inImage={ur3} customerId={custId}   index={"3"} service={service}  deletetype={"product"}/>
                    }
                  
                  </Paper>
                </Grid>

                <Grid item xs={6}>
                  <Paper
                    sx={{
                      width: 530,
                      height: 350,
                      background: "test",
                      backgroundSize: "cover",
                      mb: 1,

                    }}
                  >
                    <h1 align="center">Product Image 5</h1>
                    {ifProImg5 > 0 ?
                      <ImageEditor type={IMAGE_TYPE.UPDATEPRODUCTIMAGE5} inImage={ur4} customerId={custId}   index={"4"} service={service} deletetype={"product"}/>

                      :
                      <ImageEditor type={IMAGE_TYPE.PRODUCTIMAGE5} inImage={ur4} customerId={custId}   index={"4"} service={service}  deletetype={"product"}/>
                    }
                  </Paper>
                </Grid>

                <Grid item xs={6}>
                  <Paper
                    sx={{
                      width:530 ,
                      height: 350,
                      background: "test",
                      backgroundSize: "cover",
                      mb: 1,
                    }}
                  >
                    <h1 align="center">Product Image 6</h1>
                    {ifProImg6 > 0 ?
                      <ImageEditor type={IMAGE_TYPE.UPDATEPRODUCTIMAGE6} inImage={ur5} customerId={custId}   index={"5"} service={service} deletetype={"product"}/>
                      :
                      <ImageEditor type={IMAGE_TYPE.PRODUCTIMAGE6} inImage={ur5} customerId={custId}   index={"5"} service={service}  deletetype={"product"}/>
                    }
                  </Paper>
                </Grid>
              </Grid>

              <FieldArray name="additionalFeatures">
                {fieldArrayProps => (
                  <>
                    <div>
                      <p>Click below button to add one more key and value </p>
                      <Button
                        color="secondary"
                        variant="contained"
                        type="button"
                        onClick={
                          () => {                            
                            console.log("Main1 fieldArrayProps: ", fieldArrayProps);
                            fieldArrayProps.push({ Key: "", Value: ""})
                            console.log("Updated values:", fieldArrayProps.form.values.additionalFeatures);
                          }
                        }  >
                        +
                      </Button>
                    </div>
                    {values.additionalFeatures.map((option, index) => (
                      <div key={`${index}`}>
                        <br />
                        <Card className={classes.root}>
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={5.5} >
                                <Field name={`additionalFeatures.${index}.Key`} minWidth>
                                  {fieldProps => (
                                    <div>
                                      { console.log("Key fieldProps: ", fieldProps.field) }
                                      <input placeholder="Key" {...fieldProps.field} />
                                    </div>
                                  )}
                                </Field>
                                </Grid>
                                <br />
                                <Grid item xs={12} sm={5.5} >
                                <Field name={`additionalFeatures.${index}.Value`} minWidth>
                                  {fieldProps => (
                                    <div>
                                      { console.log("Value fieldProps: ", fieldProps.field) }
                                      <input placeholder="Value" {...fieldProps.field} />
                                    </div>
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12} sm={1}>
                                <Button
                                  color="secondary"
                                  variant="contained"
                                  type="button"
                                  style={{marginLeft:"0em"}}
                                  onClick={() => fieldArrayProps.remove(index)}
                                >
                                  -
                                </Button>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </div>
                    ))}
                  </>
                )}
              </FieldArray>
            </CardContent>
          </Card>


          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Update Product
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

ProductUpdate.propTypes = {
  className: PropTypes.string,
};

export default ProductUpdate;


