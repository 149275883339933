import mgmtPaymentGateWay from "src/services/mgmtPaymentGateWayTypeService";


export const FETCHPAYMENTGATEWAYTYPE_INFO = '@gatewaytype/info';
export const FETCHPAYMENTGATEWAYTYPE_INFO_SUCCESS = '@gatewaytype/info-success';
export const FETCHPAYMENTGATEWAYTYPE_INFO_FAILURE = '@gatewaytype/info-failure';

export function paymentGateWayType() {

  return async (dispatch) => {
    try {
      dispatch({ type: FETCHPAYMENTGATEWAYTYPE_INFO });
      const paymentGateWayTypeInfo = await mgmtPaymentGateWay.gatewaypaymentTypeInfo();
      console.log("customerInfoList Action", paymentGateWayTypeInfo);
      dispatch({
        type: FETCHPAYMENTGATEWAYTYPE_INFO_SUCCESS,
        payload: {
          paymentGateWayTypeInfo,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: FETCHPAYMENTGATEWAYTYPE_INFO_FAILURE });
      throw error;
    }
  };
}

export function PaymentGateWayTypeAdd(paymentTypeInfo,showAccessCode,showKeyIndex) {
  return async (dispatch) => {

    try {
      const paymentInfo = await mgmtPaymentGateWay.addPaymentGateWayType(paymentTypeInfo,showAccessCode,showKeyIndex);
      dispatch({
    
        payload: {
          paymentInfo
        }
      });
    } catch (error) {
      throw error;
    }
  };
}
export function PaymentGateWayTypeEdit(paymentTypeInfo,showAccessCode,showKeyIndex,paymentType) {
  return async (dispatch) => {

    try {
      const paymentInfo = await mgmtPaymentGateWay.editPaymentGateWayType(paymentTypeInfo,showAccessCode,showKeyIndex,paymentType);
      dispatch({
    
        payload: {
          paymentInfo
        }
      });
    } catch (error) {
      throw error;
    }
  };
}








