import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography
} from '@mui/material';
import HailIcon from "@mui/icons-material/Hail";
import InventoryIcon from "@mui/icons-material/Inventory";
import GroupIcon from "@mui/icons-material/Group";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import { useSelector } from 'react-redux';

function FinancialDashboard() {

    const totalSales = useSelector(
        (state) => state?.financialData?.financialData?.totalSales
    )

    const totalOrders = useSelector(
        (state) => state?.financialData?.financialData?.totalOrders
    )

    const todaySales = useSelector(
        (state) => state?.financialData?.financialTodaySalesAndOrdersData?.totalSales
    )

    const todayOrders = useSelector(
        (state) => state?.financialData?.financialTodaySalesAndOrdersData?.totalOrders
    )

    const userType = useSelector(
        (state) => state?.account?.user?.userType
    );

    const OverviewData = [
        {
            id: 1,
            title: "Total Sales",
            total: totalSales,

            icon: <GroupIcon sx={{ color: "white" }} />,
        },
        {
            id: 2,
            title: "Total Orders",
            total: totalOrders,
            icon: <InventoryIcon sx={{ color: "white" }} />,
        },
        {
            id: 3,
            title: "Today Sales",
            total: todaySales,
            icon: <HailIcon sx={{ color: "white" }} />,
        },
        {
            id: 4,
            title: "Today Orders",
            total: todayOrders,
            icon: <MiscellaneousServicesIcon sx={{ color: "white" }} />,
        },
    ];

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0}
            marginTop={3}
            marginLeft={1}
            marginRight={1}
        >
            {OverviewData.map((overviewdata) => {
                return (
                    <Grid item md={3} xs={12} key={overviewdata.id}>
                        {userType !== "SUPPORT" ?
                            <Card sx={{ width: "90%" }}>
                                <Box sx={{ flexDirection: "row", display: "flex" }}>
                                    <Box sx={{ padding: 2 }}>
                                        <Card sx={{ backgroundColor: "#0c2860", height: 50 }}>
                                            <CardContent sx={{}}>{overviewdata.icon}</CardContent>
                                        </Card>
                                    </Box>
                                    <Box sx={{ padding: 2 }}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography sx={{ mb: 1 }}>{overviewdata.title}</Typography>
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                {overviewdata.total}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Card> :
                            <Box />}
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default FinancialDashboard;
