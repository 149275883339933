import React, { useState } from 'react'
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Delete from '@mui/icons-material/Delete';
import axios from 'src/utils/axios';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { SvgIcon, } from '@mui/material';
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon
} from 'react-feather';
import ConfirmBox from './ConfirmBox';
import { fetchCategory } from 'src/actions/mgmtSelectValueAction';
import { useDispatch} from 'react-redux';

function Row2(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { indiRow2} = props;
  const [secndopen, setSecndopen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  const data = indiRow2.children.length

  const isDisabled = data <= '0';

  function deleteUser() {
    axios
      .delete(`/api/admin/catalog/category?customerId=${deleteData.customerId}&categoryId=${deleteData.categoryId}`)
      .then((res) => {
        dispatch(fetchCategory(deleteData.customerId));
        setOpen(false);
      })
  }

  function openDelete(data) {
    setOpen(true);
    setDeleteData(data);
  }

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            disabled={isDisabled}
            aria-label="expand row"
            size="small"
            onClick={() => setSecndopen(!secndopen)}
          >
            {secndopen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{indiRow2.customerId}</TableCell>
        <TableCell>{indiRow2.parentId}</TableCell>
        <TableCell>{indiRow2.categoryId}</TableCell>
        <TableCell>{indiRow2.name}</TableCell>
        <TableCell>{indiRow2.description}</TableCell>
        <TableCell align="left">
          <IconButton
            onClick={(params) => {
              navigate('/app/management/category/update', { state: indiRow2 });
            }
            }
          >
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
          </IconButton>
        </TableCell>
        <TableCell align="left">
          <IconButton
            onClick={() => openDelete(indiRow2)}
          >
            <SvgIcon fontSize="small">
              <Delete />
            </SvgIcon>
          </IconButton>
        </TableCell>
        <TableCell align="left">
        </TableCell>
        <ConfirmBox
          open={open}
          closeDialog={() => setOpen(false)}
          title={deleteData?.name}
          deleteFunction={deleteUser}
        />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={secndopen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
              ></Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Customer Id </TableCell>
                    <TableCell>Category Id </TableCell>
                    <TableCell>Parent Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Edit</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {indiRow2.children.map((subchildrenRow) => (
                    <>
                      <TableRow key={subchildrenRow.parentId}>
                        <TableCell component="th" scope="row">
                          {subchildrenRow.customerId}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {subchildrenRow.categoryId}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {subchildrenRow.parentId}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {subchildrenRow.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {subchildrenRow.description}
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            onClick={(params) => {
                              navigate('/app/management/category/update', { state: subchildrenRow });
                            }
                            }
                          >
                            <SvgIcon fontSize="small">
                              <EditIcon />
                            </SvgIcon>
                          </IconButton>
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            onClick={() => openDelete(subchildrenRow)}
                          >
                            <SvgIcon fontSize="small">
                              <Delete />
                            </SvgIcon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <ConfirmBox
                        open={open}
                        closeDialog={() => setOpen(false)}
                        title={deleteData?.name}
                        deleteFunction={deleteUser}
                      />
                    </>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Row(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { indiRow, onSubmitSuccess} = props;
  const [frstopen, setFrstopen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});
 
  const data = indiRow.children.length

  const isDisabled = data <= '0';

  function deleteUser() {
    axios
      .delete(`/api/admin/catalog/category?customerId=${deleteData.customerId}&categoryId=${deleteData.categoryId}`)
      .then((res) => {
        dispatch(fetchCategory(deleteData.customerId));
        setOpen(false);
      })
  }

  function openDelete(data) {
    setOpen(true);
    setDeleteData(data);
  }

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            disabled={isDisabled}
            aria-label="expand row"
            size="small"
            onClick={() => setFrstopen(!frstopen)}
          >
            {frstopen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{indiRow.customerId}</TableCell>
        <TableCell align="left">{indiRow.categoryId}</TableCell>
        <TableCell align="left">{indiRow.name}</TableCell>
        <TableCell align="left">{indiRow.description}</TableCell>
        <TableCell align="left">
          <IconButton
            onClick={(params) => {
              navigate('/app/management/category/update', { state: indiRow });
            }
            }
          >
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
          </IconButton>
        </TableCell>
        <TableCell align="left">
          <IconButton
            onClick={() => openDelete(indiRow)}
          >
            <SvgIcon fontSize="small">
              <Delete />
            </SvgIcon>
          </IconButton>
        </TableCell>
        <TableCell align="left">
        </TableCell>
        <ConfirmBox
          open={open}
          closeDialog={() => setOpen(false)}
          title={deleteData?.name}
          deleteFunction={deleteUser}
        />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={frstopen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
              ></Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Sub Category</TableCell>
                    <TableCell>Customer Id</TableCell>
                    <TableCell>Parent Id</TableCell>
                    <TableCell>Category Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Edit</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {indiRow.children.map((detailsRow) => (
                    <>
                      <Row2 key={detailsRow.parentId} indiRow2={detailsRow} onSubmitSuccess={onSubmitSuccess} />
                    </>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Results1(props) {
  const { categoryList,length,selectedCustomerId } = props

  const selectedCusId = (selectedCustomerId > 0) ? categoryList : []

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Sub Category</TableCell>
            <TableCell align="left">CustomerId</TableCell>
            <TableCell align="left">Category Id</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Edit</TableCell>
            <TableCell align="left">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedCusId.map((indiRow) => (
            <Row key={indiRow.id} indiRow={indiRow} categoryList={categoryList} />
          ))}
        </TableBody>
      </Table>
      {length <= 0 ?
        <Typography align='center'>No Data Found For This Customer Id</Typography>
        :
        <Box />
      }
    </TableContainer>

  );
};

export default Results1;

