import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material';
import HailIcon from "@mui/icons-material/Hail";
import InventoryIcon from "@mui/icons-material/Inventory";
import GroupIcon from "@mui/icons-material/Group";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import { useSelector } from 'react-redux';
import ProductBarGraph from './productTrend';

function DashboardInfo({ idd }) {

  const user = useSelector(
    (state) => state?.account?.user?.userType
  );

  const customerId = useSelector(
    (state) => state?.account?.user?.customerId
  );

  const userType = user

  const cusid = (userType === "SUPER_ADMIN") ? idd : customerId;
 
  const orderList = useSelector(
    (state) => state?.order?.order?.length)

  const productData = useSelector(
    (state) => state?.searchBusiness?.productInfo?.length
    )

  const categoryList = useSelector(
    (state) => state?.user?.category?.length
  );
  
  const userData1 = useSelector(
    (state) => state?.user?.userData?.length
  );

  const OverviewData = [
    {
      id: 1,
      title: "Users",
      total: userData1,

      icon: <GroupIcon sx={{ color: "white" }} />,
    },

    {
      id: 2,
      title: "Products",
      total: productData,
      icon: <InventoryIcon sx={{ color: "white" }} />,
    },
    {
      id: 3,
      title: "Categories",
      total: categoryList,
      icon: <HailIcon sx={{ color: "white" }} />,
    },
    {
      id: 4,
      title: "Orders",
      total: orderList,
      icon: <MiscellaneousServicesIcon sx={{ color: "white" }} />,
    },
  ];

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={0}
      marginTop={3}
      marginLeft={1}
      marginRight={1}
    >
      {OverviewData.map((overviewdata) => {
        return (

          <Grid item md={3} xs={12} key={overviewdata.id}>
            <Card sx={{ width: "90%" }}>
              <Box sx={{ flexDirection: "row", display: "flex" }}>
                <Box sx={{ padding: 2 }}>
                  <Card sx={{ backgroundColor: "#0c2860", height: 50 }}>
                    <CardContent sx={{}}>{overviewdata.icon}</CardContent>
                  </Card>
                </Box>
                <Box sx={{ padding: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ mb: 1 }}>{overviewdata.title}</Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {overviewdata.total}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Card>
            {/* <ProductBarGraph/> */}
          </Grid>
      
        );
      })}
    </Grid>
  );
}

export default DashboardInfo;
