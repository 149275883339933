import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from "react";
import { createKart } from "src/actions/mgmtProductUploadAction";
import { customerUpload } from "src/actions/mgmtCusomerUploadAction";
import { fetchCustomerList } from "src/actions/mgmtCustomerCreateAction";


const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function ImportFileForm({ className, clientImage, ...rest }) {
  // console.log("Se", clientImage);
  const [gstin, setGstin] = useState('')
  function handleImage(e) {
    console.log(e.target.files)
    setGstin(e.target.files)
  }
  const [gstin1, setGstin1] = useState('')
  function handleImage1(e) {
    console.log(e.target.files)
    setGstin1(e.target.files[0])
  }
  const [gstin2, setGstin2] = useState('')
  function handleImage2(e) {
    console.log(e.target.files)
    setGstin2(e.target.files)
  }

  const [shortLogoUpload, setShortLogoUpload] = useState('')
  function handleImageShortLogo(e) {
    console.log(e.target.files)
    setShortLogoUpload(e.target.files[0])
  }

 
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const addedBy = account.user.email;
  console.log("ServiceCreateForm: addedBy", addedBy);

  return (

    <Formik
      initialValues={{
        excelSheet: "",
        logoFile: "",
        bannerFiles: "",
        shortLogo: "",
      }}
      validationSchema={Yup.object().shape({
    
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        console.log("Submitting the request", addedBy, values,"gstin",gstin,gstin2);
        try {
          await dispatch(customerUpload( values, gstin,gstin1,gstin2,shortLogoUpload));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("File Upload successful", {
            variant: "success",
          });
          dispatch(fetchCustomerList());
          navigate("/app/management/customer/view");
        } catch (err) {
          console.log("err",err)
          setErrors({ submit: err.response.data.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("File Upload unsuccessful", {
            variant: "failure",
          });
            //  navigate("/app/management/customer/view");

        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card style={{ marginLeft: '1em', maxWidth: '300px', maxHeight: '470px' }}>
            <CardHeader title="Upload Files" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                {/* <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select CustomerId</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Id"
                          onChange={handleChange}

                          name="customerId"
                          onBlur={handleBlur}

                          value={values.customerId}
                          variant="outlined"
                        >
                          {clientImage.map((cities) => (
                            <MenuItem value={cities.customerId}>
                              {cities.customerId}
                              {"..."}
                            {cities.businessName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FormControl>
                </Grid> */}
                <Grid item xs={12}>
                  <Typography>Upload customer excel or Csv File</Typography>
                  <TextField
                  required
                    error={Boolean(
                      touched.excelSheet && errors.excelSheet
                    )}
                    fullWidth
                    helperText={
                      touched.excelSheet && errors.excelSheet
                    }
                    // label="Date"
                    type="file"
                    name="excelSheet"
                    onBlur={handleBlur}
                    onChange={handleImage}
                    value={values.gstin}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Upload Logo</Typography>
                  <TextField
                  required
                    error={Boolean(
                      touched.logoFile && errors.logoFile
                    )}
                    fullWidth
                    helperText={
                      touched.logoFile && errors.logoFile
                    }
                    // label="Date"
                    type="file"
                    name="logoFile"
                    onBlur={handleBlur}
                    onChange={handleImage1}
                    value={values.gstin1}
                    variant="outlined"
                  />
                </Grid>
                  <Grid item xs={12}>
                  <Typography>Upload Short Logo</Typography>
                  <TextField
                    error={Boolean(
                      touched.shortLogo && errors.shortLogo
                    )}
                    fullWidth
                    helperText={
                      touched.shortLogo && errors.shortLogo
                    }
                    // label="Date"
                    type="file"
                    name="shortLogo"
                    onBlur={handleBlur}
                    onChange={handleImageShortLogo}
                    value={values.shortLogoUpload}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* <Typography>Upload Banner Files</Typography>
                  <TextField
                    error={Boolean(
                      touched.logoFile && errors.logoFile
                    )}
                    fullWidth
                    helperText={
                      touched.logoFile && errors.logoFile
                    }
                    // label="Date"
                    type="file"
                    name="logoFile"
                    onBlur={handleBlur}
                    onChange={handleImage2}
                    value={values.gstin2}
                    variant="outlined"
                  /> */}
                   <Typography>Banner Images</Typography>
                  <input type="file" multiple onChange={handleImage2} />
                </Grid>
              
              </Grid>
            </CardContent>
          </Card>

          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              style={{ marginLeft: '1em', }}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

ImportFileForm.propTypes = {
  className: PropTypes.string,
};

export default ImportFileForm;


