import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Header from './Header';
import { fetchUsers } from 'src/actions/idActions';
import Results2 from './Result';
import { fetchCategory, fetchUsers5 } from 'src/actions/mgmtSelectValueAction';
import OemList from '../OemOrderList.js/oemListView';
import { fetchOrder } from 'src/actions/mgmtOrderAction';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function OrderListView() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isShown1, setIsShown1] = useState(false);
  const handleClick1 = event => {
    setIsShown1(current => !current);
  };

  const [id, setId] = React.useState('');

  const customer15 = useSelector(
    (state) => state?.user?.users1
  );

  const selectedCustomerId = (id) ? id : customer15;

  const handleChange = (event) => {
    setId(event.target.value);
    dispatch(fetchUsers5(event.target.value));
      dispatch(fetchCategory(event.target.value));
      dispatch(fetchOrder(event.target.value));
  };

  const clientImage = useSelector(
    (state) => state?.user?.users?.customerList
  );

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const userType = useSelector(
    (state) => state?.account?.user?.userType
  );

  const customer1 = useSelector(
    (state) => state?.account?.user?.customerId
  );

  const userTy = userType

  const cid = (userTy === "SUPER_ADMIN") ? selectedCustomerId : customer1;

  useEffect(() => {
    dispatch(fetchOrder(cid));
  }, []);

  const orderList = useSelector(
    (state) => state?.order?.order
  );

  if (!clientImage) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Order List"
    >
      <Container maxWidth={false}>
        <Header />
        {/* <Button style={{ marginLeft: '1em', marginTop: '1em' }} color="secondary" variant='contained' onClick={handleClick1}>
           Get Oem Order Details</Button> */}
        <br />
        {
          userTy !== "ADMIN" && userTy !== "SUPPORT" ?
            <Card style={{ maxWidth: '1105px', maxHeight: '400px' }}>
              <CardContent>
                <Grid container spacing={2}> <Grid item xs={12} sm={8}>
                  <FormControl fullWidth>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select CustomerId *</InputLabel>
                        <Select
                          labelId="demo-simple-select-label *"
                          // id="demo-simple-select"
                          label="Select CustomerId"
                          onChange={handleChange}
                          value={selectedCustomerId}
                        >
                          {clientImage.map((cities) => (
                            <MenuItem value={cities.customerId}>

                              {cities.customerId}
                              {"..."}
                              {cities.businessName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FormControl>
                </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button style={{ marginLeft: '1em', marginTop: '0em', height: "55px", width: "150px" }} color="secondary" variant='contained' onClick={handleClick1}>
                      Get oem  Details</Button>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button style={{ marginLeft: '1em', marginTop: '0em', height: "55px" }} color="secondary" variant='contained' onClick={handleClick1}>
                      Cancel oem</Button></Grid>
                </Grid>
              </CardContent>
            </Card> :
            <Box />
        }
        <br />
        <br />
        {isShown1 && (
          <OemList cid={selectedCustomerId} />
        )}
        {isShown1 && <Box />}
        <br />
        <Results2 orderList={orderList} />
      </Container>
    </Page>

  );
}

export default OrderListView;
