class UserTarget {
  constructor(info) {
    this.userId = info.userId;
    if(info && info?.customerId){
      this.customerId = info.customerId;
    }
    this.achieved = info.achieved;
    this.target = info.target;
    this.targetType = info.targetType;
    this.userType = info.userType;
  }
}
export default { UserTarget };
export { UserTarget };



