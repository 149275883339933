import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  SvgIcon,
  Typography
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {}
}));

function Header({ className, ...rest }) {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Typography
            variant="h3"
            color="textPrimary"
          >
            Orders
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item>
      <Button
        color="secondary"
        variant="contained"
        className={classes.action}
        component={RouterLink}
        to="/app/management/order/export"
      >
        <SvgIcon
          fontSize="small"
          className={classes.actionIcon}
        >
        </SvgIcon>
        Order Export
      </Button>
    </Grid>
    </Grid>
    
   
  );
}

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
