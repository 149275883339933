import produce from "immer";
import { ADMINLIST_FETCH_SUCCESS } from "src/actions/mgmtAdminAction";


const initialState = {
    adminList: [],
};

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMINLIST_FETCH_SUCCESS: {
            const { adminList } = action.payload;
            return produce(state, (draft) => {
                draft.adminList = adminList;
            });
        }
        default: {
            return state;
        }
    }
};

export default adminReducer;


