import axios from 'src/utils/axios';

class MgmtDistributorType {

  createDistributorType = (newDistributorType, id, customer) => new Promise((resolve, reject) => {

    const admin = newDistributorType.customerId
    const cusid = (customer === "ADMIN") ? admin : id;
    const payload = {
      "customCategory": {
        "category": newDistributorType.category,
        "discount": newDistributorType.discount,
        "customerId": cusid
      }
    }
    axios.post('/api/master/distributor/dealer/type', payload)
      .then((response) => {
        // console.log("MgmtDistributorType(Response):" + JSON.stringify(response));
        if (response.data) {
          let distributorType = response.data;
          resolve(distributorType);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  updateDistributorType = (updateDistributorType) => new Promise((resolve, reject) => {

    const payload = {
      "customCategory": {
        "category": updateDistributorType.category,
        "discount": updateDistributorType.discount,
        "customerId": updateDistributorType.customerId
      },
      "distributorTypeId": updateDistributorType.distributorTypeId
    }
    axios.post('/api/master/distributor/dealer/type', payload)
      .then((response) => {
        // console.log("MgmtUpdateDistributorType(Response):" + JSON.stringify(response));
        if (response.data) {
          let distributorType = response.data;
          resolve(distributorType);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  fetchDistributorTypeList = (cid) => new Promise((resolve, reject) => {
    let searchUrl = "/api/master/distributor/dealer/type";
    searchUrl += (cid) ? `?&customerId=${cid}` : "";
    console.log("searchUrl", searchUrl);
    axios
      .get(`/api/master/distributor/dealer/type?customerId=${cid}`)
      .then((response) => {
        // console.log("MgmtAttribute(fetchInvoice->Response): 1", response);
        if (response?.data) {
          // let attribute1 = response?.data?.data;
          const nodata = []
          const attribute1 = (response?.data?.data) ? response.data.data : nodata;
          // console.log("MgmtUser(fetchUsers)", users);
          resolve(attribute1);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })
}

const mgmtDistributorType = new MgmtDistributorType();

export default mgmtDistributorType;
