import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
} from "@mui/material";
import {
  makeStyles
} from '@mui/styles';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import PaymentIcon from '@mui/icons-material/Payment';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CategoryIcon from '@mui/icons-material/Category';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import {
  Truck as TruckIcon,
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Shield as ShieldIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  Package as ServiceIcon,
  MapPin as LocationIcon,
  Briefcase as BusinessIcon,
} from "react-feather";
import Logo from "src/components/Logo";
import NavItem from "./NavItem";



let navConfig = [
  {
    subheader: "Dashboards",
    items: [
      {
        title: "Dashboard",
        icon: PieChartIcon,
        href: "/app/management/dashboard",
      },
      {
        title: "Financial",
        icon: PieChartIcon,
        href: "/app/management/dashboard/financial",
      },
      {
        title: "Financial Dashboard",
        icon: PieChartIcon,
        href: "/app/management/financial/dashboard",
      },
      {
        title:"Business",
        icon: SupportAgentIcon,
        href: "/app/management/customer/view",
      },
      {
        title: "User",
        icon: AdminPanelSettingsIcon,
        href: "/app/management/user/view",
      },
      {
        title: "Admin",
        icon: AdminPanelSettingsIcon,
        href: "/app/management/admin/view",
      },
    ],
  },
  {
    subheader: "Master",
    items: [
      {
        title: "Payment Gateway Types",
        icon: PaymentIcon,
        href: "/app/management/payment/gateway/types",
      },
    ],
  },
  {
    subheader: "Catalog Management",
    items: [
      {
        title: "Category",
        icon: CategoryIcon,
        href: "/app/management/category",
      },
      {
        title: "Category Old",
        icon: CategoryIcon,
        href: "/app/management/category/view",
      },
      {
        title: "Product",
        icon: Inventory2Icon,
        href: "/app/management/product/view",
      },
      {
        title: "Attributes",
        icon: EditAttributesIcon,
        href: "/app/management/attribute/view",
      },
    ],
  },
  {
    subheader: "Order Management",
    items: [
      {
        title: "Search Orders",
        icon: BookmarksIcon,
        href: "/app/management/order/search",
      },
      {
        title: "Search Invoices",
        icon: ReceiptIcon,
        href: "/app/management/invoice/search",
      },
      // {
      //   title: "Order",
      //   icon: BookmarksIcon,
      //   href: "/app/management/order/view",
      // },
      // {
      //   title: "Invoice",
      //   icon: ReceiptIcon,
      //   href: "/app/management/invoice/view",
      // },
    ],
  },
  {
    subheader: "Quotation",
    items: [
      {
        title: "Quotation Request",
        icon: FormatQuoteIcon,
        href: "/app/management/quotation/request/list",
      },
      {
        title: "Generated Quotation",
        icon: FormatQuoteIcon,
        href: "/app/management/quotation/generated/view",
      },
    ],
  },
  {
    subheader: "Communication",
    items: [
      {
        title: "News",
        icon: NewspaperIcon,
        href: "/app/management/news/view",
      },
      {
        title: "Greeting",
        icon: CardGiftcardIcon,
        href: "/app/management/greetings/view",
      },
      {
        title: "Serivces",
        icon: BookmarksIcon,
        href: "/app/management/services",
      },
      {
        title: "Testimonials",
        icon: BookmarksIcon,
        href: "/app/management/testimonials",
      },
      {
        title: "Distributor Type",
        icon: BookmarksIcon,
        href: "/app/management/distributor/type",
      },
      {
        title: "Distributor Type",
        icon: BookmarksIcon,
        href: "/app/management/distributor/type",
      },


    ],
  },
 
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath({
      path: item.href,
      exact: false,
    }, pathname);

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    // top: '64px',
    // height: "calc(100% - 64px)",
    backgroundColor: "#878788",
    marginTop: '64px',
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();

  // console.log("NavBar: location", location);

  const { user } = useSelector((state) => state.account);
  // console.log("NavBar: user", user);
  // console.log("NavBar: user.userType", user.userType);
  if (user?.userType && user.userType.toUpperCase() != "SUPER_ADMIN") {
    console.log(`Navbar() :: Normal Users:`, true);
    navConfig = navConfig.filter((item) => item.subheader !== "Master");
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {/* <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden> */}
        {/* <Box p={2} display="flex" justifyContent="center">
          <Logo isDark={false} />
        </Box> */}
        <Box p={2}>
          <Box display="flex" justifyContent="center">

            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.name}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {/* user.role */}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
