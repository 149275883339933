import produce from "immer";

import { CATEGORIES_INFO, CATEGORY_CREATE_FAILURE, CATEGORY_CREATE_SUCCESS } from "src/actions/mgmtCategoryAction";

const initialState = {
    categories: []
};

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case CATEGORIES_INFO: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.categories = [];
            });
        }

        case CATEGORY_CREATE_SUCCESS: {
            const { searchUser } = action.payload;
            return produce(state, (draft) => {
                draft.categories = searchUser;
            });
        }
        case CATEGORY_CREATE_FAILURE: {
            return produce(state, () => {
                // Maybe store error
            });
        }
        default: {
            return state;
        }
    }
};

export default searchReducer;


