import { DASHBOARD_SUMMARY_TYPES } from "src/constants";
import dashboardFinancialService from "src/services/dashboardFinancialService";

export const FETCH_DASHBOARD_FINANCIAL = '@dashboard/fetch-dashboard-financial';
export const FETCH_DASHBOARD_FINANCIAL_SUCCESS = '@dashboard/fetch-dashboard-financial-success';
export const FETCH_DASHBOARD_FINANCIAL_FAILURE = '@dashboard/fetch-dashboard-financial-failure';

export const DASHBOARD_FINANCIAL_SALES_SUMMARY = '@dashboard/fetch-dashboard-financial-sales-summary';
export const DASHBOARD_FINANCIAL_SALES_SUMMARY_SUCCESS = '@dashboard/fetch-dashboard-financial-sales-summary-success';
export const DASHBOARD_FINANCIAL_SALES_SUMMARY_FAILURE = '@dashboard/fetch-dashboard-financial-sales-summary-failure';

export const DASHBOARD_FINANCIAL_SALES_TREND = '@dashboard/fetch-dashboard-financial-sales-trend';
export const DASHBOARD_FINANCIAL_SALES_TREND_SUCCESS = '@dashboard/fetch-dashboard-financial-sales-trend-success';
export const DASHBOARD_FINANCIAL_SALES_TREND_FAILURE = '@dashboard/fetch-dashboard-financial-sales-trend-failure';


export function fetchDashboardFinancialData(customerId) {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_DASHBOARD_FINANCIAL });
            
            dispatch(fetchSalesSummary(customerId, DASHBOARD_SUMMARY_TYPES.YEARLY));
            dispatch(fetchSalesSummary(customerId, DASHBOARD_SUMMARY_TYPES.TODAY));
            // dispatch(fetchSalesTrend(customerId, DASHBOARD_SUMMARY_TYPES.YEARLY ));
            dispatch(fetchSalesTrend(customerId, DASHBOARD_SUMMARY_TYPES.MONTHLY ));
            dispatch(fetchSalesTrend(customerId, DASHBOARD_SUMMARY_TYPES.WEEKLY ));

        } catch (error) {
            dispatch({ type: FETCH_DASHBOARD_FINANCIAL_FAILURE });
            throw error;
        }
    };
}

export function fetchSalesSummary(customerId, summaryType) {
  return async (dispatch) => {
    try {

      // console.log("fetchSalesSummary() customerId, summaryType : ", customerId, summaryType);
      dispatch({ type: DASHBOARD_FINANCIAL_SALES_SUMMARY });

      const data = await dashboardFinancialService.fetchSalesSummary(customerId, summaryType);
      // console.log("fetchSalesSummary() resp data: ", data);
      dispatch({
        type: DASHBOARD_FINANCIAL_SALES_SUMMARY_SUCCESS,
        payload: {
          summaryType,
          data,
        },
      });
    } catch (error) {
      dispatch({ type: DASHBOARD_FINANCIAL_SALES_SUMMARY_FAILURE });
      throw error;
    }
  };
}

export function fetchSalesTrend(customerId, summaryType) {
  return async (dispatch) => {
    try {

      // console.log("fetchSalesSummary() customerId, summaryType : ", customerId, summaryType);
      dispatch({ type: DASHBOARD_FINANCIAL_SALES_TREND });

      const data = await dashboardFinancialService.fetchSalesTrend(customerId, summaryType);
      // console.log("fetchSalesSummary() resp data: ", data);
      dispatch({
        type: DASHBOARD_FINANCIAL_SALES_TREND_SUCCESS,
        payload: {
          summaryType,
          data,
        },
      });
    } catch (error) {
      dispatch({ type: DASHBOARD_FINANCIAL_SALES_TREND_FAILURE });
      throw error;
    }
  };
}

