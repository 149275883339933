import React from "react";
import { Container, Grid } from "@mui/material";
import Page from "src/components/Page";
import { makeStyles } from '@mui/styles';
import Header from "./Header";
import CategoryCreateForm from "./CategoryCreateForm";
import { fetchUsers } from "src/actions/idActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
}));

function AttibuteCreate() {
  const {state} = useLocation();
  console.log("STATE",state);
  const classes = useStyles();
  const dispatch = useDispatch();

  const clientImage = useSelector(
    (state) => state?.user?.users?.customerList
  );

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  if (!clientImage) {
    return null;
  }

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false} className={classes.container}>
        <Header />
        <CategoryCreateForm clientImage={clientImage} state={state}/>
      </Container>
    </Page>
  );
}

export default AttibuteCreate;

