import React from "react";
import { Container} from "@mui/material";
import Page from "src/components/Page";
import { makeStyles } from '@mui/styles';
import Header from "./Header";
import CategoryCreateForm from "./UserCreateForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "src/actions/idActions";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
}));

function UserCreate() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const customerIdList = useSelector(
    (state) => state?.user?.users?.customerList
  );

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const userTy = useSelector(
    (state) => state?.account?.user?.userType
  );
  // console.log("customer",customer)

  const customerId = useSelector(
    (state) => state?.account?.user?.customerId
  );
  // console.log("customer1",customer1)

const userType = userTy
  if (!customerIdList) {
    return null;
  }


  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false} className={classes.container}>
        <Header />
        <CategoryCreateForm customerIdList={customerIdList} userType={userType} customerId={customerId}/>
      </Container>
    </Page>
  );
}

export default UserCreate;

