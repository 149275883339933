import React  from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box, IconButton, SvgIcon,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocument from 'src/components/generateInvoice/Invoice';

function Results({ className, generatedQuotationList, ...rest }) {
console.log("generatedQuotationList",generatedQuotationList);

  const columns = [
    { field: 'quotationId', headerName: 'Quotation Id', width: 100 },
    { field: 'quotationTitle', headerName: 'Quotation Title', width: 150 },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 100,
    //   renderCell: (params) => {
    //     console.log("RENDERCELLPARAMS:", params)
    //     const quoId = params.row.quotationId
    //     const title = "QUOTATION"
    //     const totitle = "QUOTATION TO"
    //     const fileName = `${quoId}.pdf`;
    //     return (
    //       <div className="cellAction">
    //         <Tooltip title={"Download Quotation"}>
    //           <IconButton>
    //             <PDFDownloadLink
    //               document={<PdfDocument invoicedata={params.row} title={title} totitle={totitle} />}
    //               fileName={fileName}
    //             >
    //               <SvgIcon fontSize="small">
    //                 <DownloadIcon />
    //               </SvgIcon>
    //             </PDFDownloadLink>
    //           </IconButton>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
    { field: 'status', headerName: 'Status', width: 200 },
    { field: 'specialInstructions', headerName: 'Special Instructions', width: 200 },
    { field: 'discount', headerName: 'Discount', width: 100 },
    { field: 'totalAmount', headerName: 'Total Amount', width: 100 },
    { field: 'amountAfterDiscount', headerName: 'Final Amount', width: 100 },
  ];
  
  return (

    <Box sx={{ height: 300, width: '100%' }}>
        <DataGrid
        rows={generatedQuotationList}
        columns={columns}
        getRowId={(row) => row.quotationId}
        // checkboxSelection={true}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Box>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  services: PropTypes.array
};

Results.defaultProps = {
  services: []
};

export default Results;



















