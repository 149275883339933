import React, {
  useEffect
} from 'react';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles'
import Page from 'src/components/Page';
import Header from './Header';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import CustomerPaymentCredentials from './CustomerPaymentCredentialsEditForm';
import { fetchPaymentCredentialsInfo } from 'src/actions/mgmtCustomerCreateAction';
import { paymentGateWayType } from 'src/actions/mgmtPaymentGateWayTypeAction';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function CustomerPaymentCredentialsForm({ match, location }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { state } = useLocation();
  // const businessInfo = useSelector((state) => state?.app?.businessInfo);
  // const customerId = businessInfo?.customerId;
  const customerId = state.customerId;

  const paymentGWDetails = useSelector((state) => state?.payment?.paymentGWDetails);
  const paymentGWList = useSelector((state) => state?.payment?.paymentGWList);

  const userType = useSelector((state) => state?.account?.user?.userType);

  const isDisabled = userType !== 'SUPER_ADMIN';

  useEffect(() => {
    dispatch(paymentGateWayType());
    dispatch(fetchPaymentCredentialsInfo(customerId));
  }, []);

  useEffect(() => {
    
  }, [paymentGWDetails, paymentGWList]);
  // console.log("CustomerPaymentCredentialsForm: paymentGWDetails", paymentGWDetails, pt);

  return (
    <Page
      className={classes.root}
      title="Payment Credentials Update"
    >
      <Container maxWidth="lg">
        <Header />
        <br />
        <CustomerPaymentCredentials paymentGWDetails={paymentGWDetails} paymentGWList={paymentGWList} customerId={customerId} isDisabled={isDisabled} />
      </Container>
    </Page>
  );
}

export default CustomerPaymentCredentialsForm;
