import { SearchBusiness } from "src/interface/searchProductIf";
import mgmtSearchBusiness from "src/services/mgmtSearchProduct";

export const BUSINESS_SEARCH = '@business/search';
export const BUSINESS_SEARCH_SUCCESS = '@business/search-success';
export const BUSINESS_SEARCH_FAILURE = '@business/search-failure';

export function getSearchBusiness(searchBusinessInfo,s,customer,id,customer1,categoryId,equipmentId) {
  return async (dispatch) => {
    console.log("SEARCHBUSINESSINFO", searchBusinessInfo);
    let newSearchBusiness = new SearchBusiness(searchBusinessInfo);
    console.log("SEARCHBusinessAction():" + JSON.stringify(searchBusinessInfo) + " " + JSON.stringify(newSearchBusiness));
    try {
      dispatch({ type: BUSINESS_SEARCH });
      const searchBusiness = await mgmtSearchBusiness.getSearchBusiness(newSearchBusiness,s,customer,id,customer1,categoryId,equipmentId);
      dispatch({
        type: BUSINESS_SEARCH_SUCCESS,
        payload: {
          searchBusiness
        }
      });
    } catch (error) {
      dispatch({ type: BUSINESS_SEARCH_FAILURE });
      throw error;
    }
  };
}



