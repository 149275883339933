import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types';
import axios from 'src/utils/axios';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import {
  Box,
  Grid,
  IconButton,
  SvgIcon,
} from '@mui/material';
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon
} from 'react-feather';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { moneyFormatter } from 'src/utils/formatUtils';
//import PdfDocument from '../orderDetails/generateInvoice/Invoice';

function Results({ className, orders, ...rest }) {
  const navigate = useNavigate();

  
  const columns = [
    { field: 'userId', headerName: 'User Id', width: 100 },
    { field: 'invoiceId', headerName: 'Invoice Id', width: 100 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const title = "INVOICE"
        const totitle = "Bill TO"
        const order = params.row.orderId
        const fileName = `Invoice-${order}.pdf`;
        return (
          <div className="cellAction">
            {/* <Tooltip title="Update Order Status">
              <IconButton
                // component={RouterLink}
                // to={`/app/management/service/update/${params.row.serviceId}`}
                onClick={() => {
                  navigate('/app/management/order/update', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <EditIcon style={{color:"blue"}}/>
                </SvgIcon>
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Invoice Details View">
              <IconButton
                // component={RouterLink}
                // to={`/app/management/service/update/${params.row.serviceId}`}
                onClick={() => {
                  navigate('/app/management/invoice/details', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <VisibilityIcon style={{color:"blue"}}/>
                </SvgIcon>
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Download Order">
            <IconButton>
             <PDFDownloadLink
              document={<PdfDocument invoicedata={params.row} title={title} totitle={totitle}  />}
              fileName={fileName}
            >
                 <SvgIcon fontSize="small" >
               <DownloadIcon style={{color:"blue"}}/>
               </SvgIcon>
            </PDFDownloadLink>             
            </IconButton>
            </Tooltip> */}
          </div>
        );
      },
    },
    { field: 'mobile', headerName: 'Mobile', width: 100 },
    { field: 'email', headerName: 'Email', width: 100 },
    { field: 'paymentDetails', headerName: 'OrderAmount ', width: 100, 
      renderCell: (params) => (moneyFormatter(params.value?.orderAmount)) },

    { field: 'paymentDetails', headerName: 'Total Amount', width: 100,
        renderCell: (params) => (moneyFormatter(params.value?.totalAmount)) },
    { field: 'status', headerName: 'Status', width: 100 },
    { field: 'paymentOrderRef', headerName: 'Payment Order Ref', width: 100 },
    { field: 'paymentOrderId', headerName: 'Payment Order Id', width: 100 },
    { field: 'paymentGateway', headerName: 'Payment Gateway', width: 100 },
    { field: 'isDeleted', headerName: 'Is Deleted', width: 100 },
    { field: 'isActive', headerName: 'Is Active', width: 100 },
    { field: 'createdAt', headerName: 'Created At', width: 100 },
    { field: 'updatedAt', headerName: 'Updated At', width: 100 },
  ];
  return (
    <Grid container spacing={2} margin={2 } sx={{ height: 400 }}>
      <DataGrid
        rows={orders}
        columns={columns}
        getRowId={(row) => row.orderId}
        // checkboxSelection={true}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Grid>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array
};

Results.defaultProps = {
  orders: []
};

export default Results;