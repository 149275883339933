import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import mgmtCustomerForm from "src/services/mgmtCustomerForm";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function CustomerViewForm({ className, service, banner, userType, ...rest }) {
  const brandDetails = service.brandDetails

  const time = (cusid) => {
    function Unix_timestamp(t) {
      var dt = new Date(t * 1000);
      var hr = dt.getHours();
      var m = "0" + dt.getMinutes();
      var s = "0" + dt.getSeconds();
      return hr + ':' + m.substr(-2) + ':' + s.substr(-2);
    }

    let timestamp = new Date(cusid).getTime();
    let Time = new Date(timestamp).getTime();
    const t = Unix_timestamp(Time)
    let Day = new Date(timestamp).getDate();
    // let Month = new Date(timestamp).getMonth() + 1;
    let Month = new Date(timestamp).toLocaleString('default', { month: 'short' });
    let Year = new Date(timestamp).getFullYear();
    let OurNewDateFormat = `${Day}/${Month}/${Year}`;
    // console.log("cusid", cusid)
    return OurNewDateFormat
  }

  const cusid = (userType <= "ADMIN") ? brandDetails : banner;

  const renderBrandDetails = (cusid) => {
    // console.log("cusid", cusid)

    return cusid.map(name =>

      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Brand Name"
            value={name.brandName}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="App Description"
            value={name.appDescription}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="App Name"
            value={name.appName}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Banner ContactAddress"
            value={name.bannerContactAddress}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="brandId"
            value={name.brandId}
            variant="outlined"
            disabled
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            fullWidth
            label="createdAt"
            value={time(name.createdAt)}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="updatedAt"
            value={time(name.updatedAt)}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Brand Theme</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Font Family"
            value={name.theme.fontFamily}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Background Color"
            value={name.theme.backgroundColor}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Primary Color"
            value={name.theme.primaryColor}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Secondary Color"
            value={name.theme.secondaryColor}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Scaffold Color"
            value={name.theme.scaffoldColor}
            variant="outlined"
            disabled
          />
        </Grid>

        <Grid item xs={7}>
          <Typography>Banner Logo </Typography>
          <br />
          <img alt="not fount" width={"350px"} height={240} src={`${path1}/${name.logoURL}`} />
        </Grid>

        <Grid item xs={5}>
          <Typography>Banner Short Logo </Typography>
          <br />
          <img alt="not fount" width={"350px"} height={240} src={`${path1}/${name.shortLogo}`} />
        </Grid>

        <Grid item xs={12}>
          <Typography>Banner Images</Typography>
          <br />
          {name.bannerURLs.map((imgSrc, index) => (<img src={`${path1}/${imgSrc}`} style={{ flexDirection: "column", justifyContent: 'space-between' }} width={"372px"} height={240} key={index} alt="not found" />))}

        </Grid>
      </Grid>
    )
  }
  const renderPrivacyPolicy = (privacyPolicy) => {
    // console.log("cusid", cusid)

    return privacyPolicy.map(name =>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Customer Id"
            value={name.customerId}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Policy Id"
            value={name.policyId}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Privacy Policy"
            value={name.privacyPolicy}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Terms Of Use"
            value={name.termsOfUse}
            variant="outlined"
            disabled
          />
        </Grid>
        {/* <Grid item xs={6}>
          <TextField
            fullWidth
            label="Created At"
            value={time(name.createdAt)}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Updated At"
            value={time(name.updatedAt)}
            variant="outlined"
            disabled
          />
        </Grid> */}
        
      
      </Grid>
    )
  }

  const path1 = process.env.REACT_APP_CLOUD_STORAGE_URL

  // const ur3 = `${path1}/${im4}`

  const [image, setImage] = useState('')
  function handleImage(e) {
    console.log("1", e.target.files)
    setImage(e.target.files[0])
  }

  const [image1, setImage1] = useState('')
  function handleImage1(e) {
    console.log("2", e.target.files)
    setImage1(e.target.files[0])
  }

  const [image2, setImage2] = useState('')
  function handleImage2(e) {
    console.log("3", e.target.files)
    setImage2(e.target.files[0])
  }
  const [files, setFiles] = useState([]);
  function uploadFileHandler(event) {
    setFiles(event.target.files);
  };

  function refreshPage() {
    window.location.reload(false);
  }
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const addedBy = account.user.email;
  console.log("ServiceCreateForm: addedBy", addedBy);

  return (

    <Formik
      initialValues={{
        customerId: service.customerId || '',

      }}
      validationSchema={Yup.object().shape({

      })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            {/* <CardHeader title="Add Customer" />
            <Divider /> */}
            <CardContent>
              <Grid container spacing={3}>


                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Customer Id"
                    value={values.customerId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Parent Id"
                    value={service.parentId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Business Name"
                    value={service.businessName}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="About"
                    value={service.about}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="GST Number"
                    value={service.GSTN}
                    variant="outlined"
                    disabled
                  />
                </Grid>



                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Help Line"
                    value={service.helpLine}
                    variant="outlined"
                    disabled
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Mobile"
                    value={service.mobile}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Whats App"
                    value={service.whatsApp}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    label="Registered Address"
                    value={service.registeredAddress}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Registered Email"
                    value={service.registeredEmail}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Is Engineering Type"
                    value={service.isEngineeringType}
                    variant="outlined"
                    disabled
                  />
                </Grid>



                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Show Contacts"
                    value={service.showContacts}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Show Distributor"
                    value={service.showDistributor}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Show Maintenance Troubleshooting"
                    value={service.showMaintenanceTroubleshooting}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Show Service Centers"
                    value={service.showServiceCenters}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Website"
                    value={service.website}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Payment Gateway"
                    value={service.paymentGateway}
                    variant="outlined"
                    disabled
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Updated At"
                    value={time(service.updatedAt)}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Created At"
                    value={time(service.createdAt)}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Brand Details</Typography>
                </Grid>


                <Grid item xs={12}>
                  {renderBrandDetails(cusid)}
                </Grid>
                <Grid item xs={12}>
                  <Typography>Customer Privacy Policy</Typography>
                </Grid>


                <Grid item xs={12}>
                  {renderPrivacyPolicy(service.privacyPolicy)}
                </Grid>
                <Grid item xs={12}>
                  <Typography>Brand License</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Issue Date"
                    value={time(service.license.issuedate)}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Validtill"
                    value={time(service.license.validtill)}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Brand Social Links</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Facebook Url"
                    value={service.socialLinks.facebookUrl}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Instagram Url"
                    value={service.socialLinks.instagramUrl}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Youtube  Url"
                    value={service.socialLinks.youtubeUrl}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Twitter Url"
                    value={service.socialLinks.twitterUrl}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Linked Url"
                    value={service.socialLinks.linkedInUrl}
                    variant="outlined"
                    disabled
                  />
                </Grid>



              </Grid>
            </CardContent>
          </Card>

        
        </form>
      )}
    </Formik>
  );
}

CustomerViewForm.propTypes = {
  className: PropTypes.string,

};

export default CustomerViewForm;


