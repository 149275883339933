import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import { useLocation} from "react-router-dom";
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from "react-redux";
import Page from 'src/components/Page';
import Header from './Header';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import CustomerDetailsEditForm from './CustomerDetailsEditForm';
import { fetchCustomerDetails } from 'src/actions/mgmtSelectValueAction';
import { fetchUsers } from 'src/actions/idActions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function CustomerDetailsEdit() {
  const {state} = useLocation();

  const dispatch = useDispatch();

  const customerId = state.customerId;

  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [service, setService] = useState();

  const getService = useCallback(() => {
      setService(state)
  }, [isMountedRef]);

  useEffect(() => {
    dispatch(fetchCustomerDetails(customerId));
  }, []);

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const customerList = useSelector(
    (state) => state?.user?.customer
  );

  useEffect(() => {
    getService();
  }, [getService]);

  if (!service) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Edit Customer Details"
    >
      <Container maxWidth="lg">
        <Header />
        <br/>
        <CustomerDetailsEditForm service={service} customerList={customerList}/>
      </Container>
    </Page>
  );
}

export default CustomerDetailsEdit;
