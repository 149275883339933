import axios from 'src/utils/axios';


class MgmtCustomerUpload {

  customerUpload = (newKart1,gstin,gstin1,gstin2,shortLogoUpload) => new Promise((resolve, reject) => {

    const formData = new FormData()
   console.log("formData",formData)
    formData.append('excelSheet',gstin[0]);
    formData.append('logoFile',gstin1);
    for (let i = 0; i < gstin2.length; i++) {
      formData.append(`bannerFiles`, gstin2[i]);
    }

    formData.append('shortLogo',shortLogoUpload);
   
    axios.post('/api/admin/customer/',formData)
    .then((response) => {
      console.log("MgmtUpload(Response)" + JSON.stringify(response));

      if(response.data){
        let services = response.data;
        resolve(services);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  


 
}



const mgmtcustomerUpload = new MgmtCustomerUpload();

export default mgmtcustomerUpload;




