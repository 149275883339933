import { SearchOrder } from "src/interface/orderIf";
import mgmtOrder from "src/services/mgmtOrder";

export const ORDER_SEARCH = '@order/search';
export const ORDER_SEARCH_SUCCESS = '@order/search-success';
export const ORDER_SEARCH_FAILURE = '@order/search-failure';
export const ORDERINFO_INFO = '@orderinfo/info';
export const ORDERINFO_CREATE = '@orderinfo/create';
export const ORDERINFO_CREATE_SUCCESS = '@orderinfo/create-success';
export const ORDERINFO_CREATE_FAILURE = '@orderinfo/create-failure';

export const CUSTOMERORDER_DOWNLOAD = '@customerorder/export';
export const CUSTOMERORDER_DOWNLOAD_SUCCESS = '@customerorder/export-success';
export const CUSTOMERORDER_DOWNLOAD_FAILURE = '@customerorder/export-failure';

export function OrderUpdate(orderInfo,state) {
  return async (dispatch) => {
    try {
      dispatch({ type: ORDER_SEARCH });
      const order = await mgmtOrder.updateOrder(orderInfo,state);
      dispatch({
        type: ORDER_SEARCH_SUCCESS,
        payload: {
            order
        }
      });
    } catch (error) {
      dispatch({ type: ORDER_SEARCH_FAILURE });
      throw error;
    }
  };
}

export function fetchOrder(customerId) {

  return async (dispatch) => {
    try {
      dispatch({ type: ORDERINFO_INFO });
      const orders = await mgmtOrder.fetchOrderList(customerId);
      // console.log("order Action", order);
      dispatch({
        type: ORDERINFO_CREATE_SUCCESS,
        payload: {
          orders,
        },
      });
      return orders;
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: ORDERINFO_CREATE_FAILURE });
      throw error;
    }
  };
}

export function orderExport(customerId,OurNewDateFormat) {
  return async (dispatch) => {
  
    // console.log("createCustomer():" + JSON.stringify(customerInfo) + " " + JSON.stringify(newCustomer));
    try {
      dispatch({ type: CUSTOMERORDER_DOWNLOAD });
      const orderExport = await mgmtOrder.orderExport(customerId,OurNewDateFormat);
      // console.log("createCustomer()(after):" + JSON.stringify(customer));
      dispatch({
        type: CUSTOMERORDER_DOWNLOAD_SUCCESS,
        payload: {
          orderExport
        }
      });
    } catch (error) {
      dispatch({ type: CUSTOMERORDER_DOWNLOAD_FAILURE });
      throw error;
    }
  };
}


