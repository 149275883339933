import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types';
import axios from 'src/utils/axios';

import { 
  Payment as PaymentIcon,
  CheckCircle as AcceptIcon,
  Cancel as CancelIcon,
  LocalShipping as ShippingIcon,
  Done as DeliveredIcon,
  Pause as HoldIcon,
  Visibility as ViewIcon,

 } from '@mui/icons-material';
// import CheckCircleIcon from '@mui/icons-material/';
// import CancelIcon from '@mui/icons-material/Cancel';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import DoneIcon from '@mui/icons-material/Done';
// import PauseIcon from '@mui/icons-material/Pause';
// import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link as RouterLink, useNavigate } from 'react-router-dom';





import Tooltip from '@mui/material/Tooltip';
import {
  Box,
  Grid,
  IconButton,
  SvgIcon,
} from '@mui/material';
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon
} from 'react-feather';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { dateFormatter, moneyFormatter } from 'src/utils/formatUtils';
//import PdfDocument from '../orderDetails/generateInvoice/Invoice';

function Results({ className, orders, ...rest }) {
  const navigate = useNavigate();

  
  const columns = [
    { field: 'userId', headerName: 'User Id', width: 100, align: 'right' },
    { field: 'orderId', headerName: 'Order Id', width: 100, align: 'right' },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        const title = "ORDER"
        const totitle = "Bill TO"
        const order = params.row.orderId
        const fileName = `Order-${order}.pdf`;
        return (
          <div className="cellAction">
            <Tooltip title="Order Details View">
              <IconButton
                // component={RouterLink}
                // to={`/app/management/service/update/${params.row.serviceId}`}
                onClick={() => {
                  navigate('/app/management/order/details', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <VisibilityIcon style={{color:"blue"}}/>
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Update / Capture Payment">
              <IconButton
                onClick={() => {
                  navigate('/app/management/order/update', 
                  { state: { 
                    orderId:params.row.orderId, 
                    status: params.row.status, 
                    action: "CAPTURE_PAYMENT" 
                  }});
                }
                }
              >
                <SvgIcon fontSize="small">
                  <PaymentIcon style={{color:"blue"}}/>
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Accept Order">
              <IconButton
                onClick={() => {
                  navigate('/app/management/order/update', 
                  { state: { 
                    orderId:params.row.orderId, 
                    status: params.row.status, 
                    action: "ACCEPT_ORDER" 
                  }});
                }
                }
              >
                <SvgIcon fontSize="small">
                  <AcceptIcon style={{color:"blue"}}/>
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel Order">
              <IconButton
                onClick={() => {
                  navigate('/app/management/order/update', 
                  { state: { 
                    orderId:params.row.orderId, 
                    status: params.row.status, 
                    action: "CANCEL_ORDER" 
                  }});
                }
                }
              >
                <SvgIcon fontSize="small">
                  <CancelIcon style={{color:"blue"}}/>
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Ship Order">
              <IconButton
                onClick={() => {
                  navigate('/app/management/order/update',
                  { state: { 
                    orderId:params.row.orderId, 
                    status: params.row.status, 
                    action: "SHIP_ORDER" 
                  }});
                }
                }
              >
                <SvgIcon fontSize="small">
                  <ShippingIcon style={{color:"blue"}}/>
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Delivered Order">
              <IconButton
                onClick={() => {
                  navigate('/app/management/order/update', 
                  { state: { 
                    orderId:params.row.orderId, 
                    status: params.row.status, 
                    action: "DELIVERED" 
                  }});                  
                }
                }
              >
                <SvgIcon fontSize="small">
                  <EditIcon style={{color:"blue"}}/>
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Hold Order">
              <IconButton
                onClick={() => {
                  navigate('/app/management/order/update',
                  { state: { 
                    orderId:params.row.orderId, 
                    status: params.row.status, 
                    action: "HOLD" 
                  }});
                }
                }
              >
                <SvgIcon fontSize="small">
                  <HoldIcon style={{color:"blue"}}/>
                </SvgIcon>
              </IconButton>
            </Tooltip>
            
            {/* <Tooltip title="Download Order">
            <IconButton>
             <PDFDownloadLink
              document={<PdfDocument invoicedata={params.row} title={title} totitle={totitle}  />}
              fileName={fileName}
            >
                 <SvgIcon fontSize="small" >
               <DownloadIcon style={{color:"blue"}}/>
               </SvgIcon>
            </PDFDownloadLink>             
            </IconButton>
            </Tooltip> */}
          </div>
        );
      },
    },
    { field: 'mobile', headerName: 'Mobile', width: 120, align: 'right' },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'paymentDetails', headerName: 'OrderAmount ', width: 100, align: 'right',
      renderCell: (params) => (moneyFormatter(params.value?.orderAmount)) },

    { field: 'paymentDetails', headerName: 'Total Amount', width: 100,
        renderCell: (params) => (moneyFormatter(params.value?.totalAmount)) },
    { field: 'status', headerName: 'Status', width: 100 },
    { field: 'paymentOrderRef', headerName: 'Payment Order Ref', width: 100 },
    { field: 'paymentOrderId', headerName: 'Payment Order Id', width: 100 },
    { field: 'paymentGateway', headerName: 'Payment Gateway', width: 100 },
    { field: 'isDeleted', headerName: 'Is Deleted', width: 100 },
    { field: 'isActive', headerName: 'Is Active', width: 100 },
    { field: 'createdAt', headerName: 'Created At', width: 200},
    { field: 'updatedAt', headerName: 'Updated At', width: 200},
  ];
  return (
    <Grid container spacing={2} margin={2 } sx={{ height: 400 }}>
      <DataGrid
        disableVirtualization
        rows={orders}
        columns={columns}
        getRowId={(row) => row.orderId}
        // checkboxSelection={true}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Grid>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array
};

Results.defaultProps = {
  orders: []
};

export default Results;