import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Field, FieldArray, Formik } from "formik";
import { useSnackbar } from "notistack";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Popover,
  TextField,
  Typography,
} from "@mui/material";

import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { makeStyles } from "@mui/styles";
import { createProduct, updateProduct } from "src/actions/mgmtProductAction";
import "./helper.css";
import MperitoCard from 'src/components/MperitoCard';
import { formatDateToYYYYMMDD, getCurrentDate } from 'src/utils/dateUtils';
import ImageEditor from 'src/components/ImageEditor';
import { IMAGE_TYPE } from "src/config";
import ProductImages from './ProductImages';

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

const IMAGE_PATH = process.env.REACT_APP_PATH; // TODO_SP : change this to proper image server path

function ProductCreateForm({ className, customerId, productInfo, isEngineeringType, categories, userType, ...rest }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const addedBy = account.user.email;
  const isMountedRef = useIsMountedRef();

  

  // Category Handling Code below - start
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [categoryId, setCategoryId] = useState(['']);  // Used for holding category

  const categoryList = categories; // TODO_SP : Temp holding
  

  const handleSelectCategory = (event) => {
    //console.log("handleSelectCategory", event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id1 = open ? "simple-popover" : undefined;

  const renderTree = (nodes) => (
    <TreeItem key={nodes.categoryId} nodeId={nodes.categoryId} label={nodes.name}>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  // Category Handling Code below - end

  const date = getCurrentDate();

  // console.log("ProductCreateForm: customerId: ", customerId);

  /*
    TODO_SP
    There are 2 types of product ( general or engineering type based on customerInfo)
    - Check if the user has the permission to create the product or not
  */

  const [image, setImage] = useState('')

  function handleImage(e) {
    console.log(e.target.files)
    setImage(e.target.files[0])
  }

  const [files, setFiles] = useState([]);

  let additionalFeatures = [];
  if(productInfo){
      let inAdditionalFeatures = productInfo?.additionalFeatures ?? [];
      additionalFeatures = inAdditionalFeatures.map((item) => {
      const newObj = {};
      for (const key in item) {
        newObj.Key = key;
        newObj.Value = Array.isArray(item[key]) ? item[key].join(',') : item[key].toString();
      }
      return newObj;
    });
  }


  function uploadFileHandler(event) {
    setFiles(event.target.files);
  };

  return (

    <Formik
      enableReinitialize
      initialValues={{
        customerId: customerId,
        productId: productInfo ? productInfo.productId : '',
        categoryId: productInfo ? productInfo.categoryId : [],
        make: productInfo ? productInfo.make : "",
        productName: productInfo ? productInfo.productName :"",
        availableStock: productInfo ? productInfo.availableStock : 0,
        brandName: productInfo ? productInfo.brandName : "",
        
        productSKU: productInfo ? productInfo.productSKU : "",
        baseImageUrl: productInfo ? IMAGE_PATH +"/" + productInfo.baseImageUrl : "",
        description: productInfo ? productInfo.description :"",
        header: productInfo ? productInfo.header :"",
        metrics: productInfo ? productInfo.metrics :"",
        amPartNo: productInfo ? productInfo.amPartNo : "",
        imageURL: productInfo ? productInfo.imageURL : "",
        images: productInfo ? productInfo.images : "",
        // price: productInfo ? productInfo.price?.sellPrice : 0,  // TODO_SP: check if price or sellPrice to use
        sellPrice: productInfo ? productInfo.price?.sellPrice : 0,
        specialPrice: productInfo ? productInfo.price?.specialPrice?.price : 0,
        discount: productInfo ? productInfo.price?.specialPrice?.discount : 0,
        from: productInfo ? formatDateToYYYYMMDD(productInfo.price?.specialPrice?.from) : date || "",
        till: productInfo ? formatDateToYYYYMMDD(productInfo.price?.specialPrice?.till) : "",
        additionalFeatures: productInfo ? additionalFeatures : [
          {
            Key: "",
            Value: "",
          }]

      }}
      validationSchema={Yup.object().shape({
        productName: Yup.string().max(50).required("Product Name is required"),
        brandName: Yup.string().max(50).required("Brand Name is required"),
        specialPrice: Yup.string().max(10).required("Price is required"),
        description: Yup.string().max(500).required("description is required"),
        productSKU: Yup.string().max(50).required("productSKU is required"),
        make: Yup.string().max(50).required("Metrics is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        let newAdditionalFeatures = [];
        let newObj;
        values.additionalFeatures.map((val, ind, arr) => {
          if (val.Key && val.Value) {
            newObj = {}

            const myArray = val.Value.split(",");
            if (myArray.length > 1) {
              newObj[val.Key] = myArray;
            } else {
              newObj[val.Key] = myArray[0];
            }
            newAdditionalFeatures.push(newObj);
          }
        });

        values.additionalFeatures = newAdditionalFeatures;
        // console.log(`btnClick :: fdata: `, values);
        // console.log("Submitting the request", addedBy, values, image, files);
        try {
          if(!productInfo){
            await dispatch(createProduct(addedBy, values, image, files, customerId, userType));
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar("Product Created", {
              variant: "success",
            });
          } else {
            await dispatch(updateProduct(values, customerId, userType));
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar("Product Updated", {
              variant: "success",
            });
          }
          
          navigate("/app/management/product/view");
        } catch (err) {
          setErrors({ submit: err.responce.data.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Failed to Add the Product", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Grid container spacing={2} >
            <Grid item md={8} >
              <MperitoCard title="Product Information" >
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.productName && errors.productName
                    )}
                    fullWidth
                    helperText={
                      touched.productName && errors.productName
                    }
                    required
                    label="Product Name"
                    name="productName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.productName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.brandName && errors.brandName
                    )}
                    fullWidth
                    helperText={
                      touched.brandName && errors.brandName
                    }
                    required
                    label="Brand Name"
                    name="brandName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.brandName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.productSKU && errors.productSKU
                    )}
                    fullWidth
                    helperText={
                      touched.productSKU && errors.productSKU
                    }
                    required
                    label="Product SKU"
                    name="productSKU"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.productSKU}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.header && errors.header
                    )}
                    fullWidth
                    helperText={
                      touched.header && errors.header
                    }
                    required
                    label="Header"
                    name="header"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.header}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.make && errors.make
                    )}
                    fullWidth
                    helperText={
                      touched.make && errors.make
                    }
                    required
                    label="Make"
                    name="make"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.make}
                    variant="outlined"
                  />
                </Grid>
                {/* {isEngineeringType && */}
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(
                        touched.metrics && errors.metrics
                      )}
                      fullWidth
                      helperText={
                        touched.metrics && errors.metrics
                      }
                      label="Metrics"
                      name="metrics"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.metrics}
                      variant="outlined"
                    />
                  </Grid>
                  {/* } */}
                {isEngineeringType &&
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(
                        touched.amPartNo && errors.amPartNo
                      )}
                      fullWidth
                      helperText={
                        touched.amPartNo && errors.amPartNo
                      }
                      label="AM Part No"
                      name="amPartNo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.amPartNo}
                      variant="outlined"
                    />
                  </Grid>}
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.description && errors.description
                    )}
                    fullWidth
                    helperText={
                      touched.description && errors.description
                    }
                    required
                    multiline
                    rows={4}
                    label="Description"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>

              </MperitoCard>

            </Grid>
            <Grid item md={4} >
              <MperitoCard title="Product Pricing/Quantity" >
                <Grid item md={12}>
                  <TextField
                    error={Boolean(
                      touched.sellPrice && errors.sellPrice
                    )}
                    fullWidth
                    helperText={
                      touched.sellPrice && errors.sellPrice
                    }
                    required
                    label="Price"
                    type="number"
                    name="sellPrice"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.sellPrice}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    error={Boolean(
                      touched.availableStock && errors.availableStock
                    )}
                    fullWidth
                    helperText={
                      touched.availableStock && errors.availableStock
                    }
                    required
                    label="Available Stock"
                    name="availableStock"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.availableStock}
                    variant="outlined"
                  />
                </Grid>

              </MperitoCard>
              <MperitoCard title="Special Pricing" sx={{ mt: 2 }}>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.specialPrice && errors.specialPrice
                    )}
                    fullWidth
                    helperText={
                      touched.specialPrice && errors.specialPrice
                    }
                    label="Price"
                    type="number"
                    name="specialPrice"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.specialPrice}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.discount && errors.discount
                    )}
                    fullWidth
                    helperText={
                      touched.discount && errors.discount
                    }
                    label="Discount"
                    type="number"
                    name="discount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.discount}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.from && errors.from
                    )}
                    fullWidth
                    helperText={
                      touched.from && errors.from
                    }
                    // label="From"
                    type="date"
                    name="from"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.from}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.till && errors.till
                    )}
                    fullWidth
                    helperText={
                      touched.till && errors.till}
                    // label="Till"
                    type="date"
                    name="till"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.till}
                    variant="outlined"
                  />
                </Grid>
              </MperitoCard>
            </Grid>
            <Grid item md={8} >
              <MperitoCard title="Product Attributes" >
                <Grid container xs={12} spacing={2} margin={2}>
                  <FieldArray name="additionalFeatures">
                    {fieldArrayProps => (
                      <Grid container item spacing={2} >
                        <Grid item xs={12}>

                          <p>Click below button to add one more key and value </p>
                          <Button
                            color="secondary"
                            variant="contained"
                            type="button"
                            onClick={

                              () => {
                                console.log("Main1 fieldArrayProps: ", fieldArrayProps);
                                fieldArrayProps.push({ Key: "", Value: "" });
                                console.log("Updated values:", fieldArrayProps.form.values.additionalFeatures);
                              }

                            }  >
                            +
                          </Button>
                        </Grid>

                        {values.additionalFeatures.map((option, index) => (
                          <Grid container spacing={2} margin={1}>
                            <Grid item xs={12} sm={5.5} >
                              <Field name={`additionalFeatures.${index}.Key`} minWidth>
                                {fieldProps => (
                                  <div>
                                    {console.log("Key fieldProps: ", fieldProps.field)}
                                    <input placeholder="Key" {...fieldProps.field} />
                                  </div>
                                )}
                              </Field>
                            </Grid>
                            <br />
                            <Grid item xs={12} sm={5.5} >
                              <Field name={`additionalFeatures.${index}.Value`} minWidth>
                                {fieldProps => (
                                  <div>
                                    {console.log("Value fieldProps: ", fieldProps.field)}
                                    <input placeholder="Value" {...fieldProps.field} />
                                  </div>
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={12} sm={1}>
                              <Button
                                color="secondary"
                                variant="contained"
                                type="button"
                                style={{ marginLeft: "0em" }}
                                onClick={() => fieldArrayProps.remove(index)}
                              >
                                -
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </FieldArray>
                </Grid>
              </MperitoCard>
            </Grid>
            <Grid item md={4} >

            <MperitoCard title="Catalog Mapping" >
                <Grid container xs={12} spacing={2} margin={2}>
                <TextField
                    fullWidth
                    multiple
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Category"
                    // onChange={handleCategoryChange}
                    name="categoryId"
                    value={categoryId}
                    className="w-100"
                    onClick={handleSelectCategory}
                  />
                  <Popover
                    id={id1}
                    fullWidth
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left"
                    }}
                  >
                    <TreeView
                      multiple
                      aria-label="rich object"
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpanded={['root']}
                      defaultExpandIcon={<ChevronRightIcon />}
                      sx={{ height: 200, flexGrow: 1, width: 300, overflowY: 'auto' }}
                      onNodeSelect={(e, categoryId) => {
                        setCategoryId(categoryId);
                        setFieldValue("categoryId", [categoryId]);
                      }}
                    >
                      {categoryList.map((category) => (
                        renderTree(category)
                      ))}
                    </TreeView>
                  </Popover>

                </Grid>
            </MperitoCard>
            </Grid>

            <Grid item md={12} >
              <MperitoCard title="Product Images" >
                <Grid item xs={12}>
                  <Typography>Base Image</Typography>
                  <TextField
                    error={Boolean(
                      touched.baseImageUrl && errors.baseImageUrl
                    )}
                    fullWidth
                    helperText={
                      touched.baseImageUrl && errors.baseImageUrl
                    }
                    // label="baseImageUrl"
                    name="baseImageUrl"
                    type="file"
                    onBlur={handleBlur}
                    onChange={handleImage}
                    value={values.image}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Sub Image</Typography>
                  <input type="file" multiple onChange={uploadFileHandler} />
                </Grid>

                <Grid item xs={12}>
                  <ImageEditor type={IMAGE_TYPE.BASEIMAGE} inImage={values.baseImageUrl} customerId={customerId} id={productInfo?.productId}/>      
                </Grid>
                <Grid container xs={12} >
                  <ProductImages productInfo={productInfo} customerId={customerId} IMAGE_PATH={IMAGE_PATH} />



                  {/* {
                    productInfo?.images?.map((image, index) => (
                      <Grid key={index}>
                        <ImageEditor
                            type={IMAGE_TYPE.PRODUCT_IMAGE}
                            inImage={`${IMAGE_PATH}/${image}`}
                            customerId={customerId}
                            id={productInfo?.productId}
                            index={index.toString()} 
                          />
                      </Grid>
                    ))
                  } */}
                  
                </Grid>
                


              </MperitoCard>
            </Grid>
          </Grid>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}

            >
              {!productInfo ? "Create Product" : "Update Product"}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

ProductCreateForm.propTypes = {
  className: PropTypes.string,
};

export default ProductCreateForm;
