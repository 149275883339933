import { FETCH_BUSINESS_LIST } from 'src/constants';
import axios from 'src/utils/axios';

class MgmtId {

  fetchUsers = () => new Promise((resolve, reject) => {
    axios
      .get(FETCH_BUSINESS_LIST)
      .then((response) => {
        // console.log("MgmtUser(fetchUsers->Response): 1", response);
        if (response.data) {
          let users = response.data?.data;
          // console.log("MgmtUser(fetchUsers)", users);
          resolve(users);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })
}
const mgmtId = new MgmtId();
export default mgmtId;
