class Testimonial {
  constructor(updatedBy, info) {
    this.customerId = info.customerId;
    this.testimonialId = info.testimonialId;
    this.userName = info.userName;
    this.description = info.description;
    this.date = info.date;
    this.updatedBy = updatedBy;
  }
}

export default { Testimonial };
export { Testimonial };



