import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from "react";
import {uploadProduct} from "src/actions/mgmtProductUploadAction";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function ProductUploadForm({ className, customerIdList,userType,customerId, ...rest }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const addedBy = account.user.email;

  const [gstin, setGstin] = useState('')
  function handleImage(e) {
    console.log(e.target.files)
    setGstin(e.target.files[0])
  }
 
  return (

    <Formik
      initialValues={{
        customerId:customerId || '',
        excelSheet: "",
      }}
      validationSchema={Yup.object().shape({

      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        // console.log("Submitting the request", addedBy, values);
        try {
          await dispatch(uploadProduct(addedBy, values, gstin));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("File Upload successful", {
            variant: "success",
          });
          navigate("/app/management/product/view");
        } catch (err) {
          setErrors({ submit: err.response.data.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("File Upload Failed", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card style={{ marginLeft: '1em', maxWidth: '300px', maxHeight: '400px' }}>
            <CardHeader title="Upload Product excel or Csv File" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
              {
                userType !== "ADMIN" ? 
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select CustomerId</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select CustomerId"
                        onChange={handleChange}
                        name="customerId"
                        onBlur={handleBlur}
                        value={values.customerId}
                      >
                        {customerIdList.map((idList) => (
                          <MenuItem value={idList.customerId}>
                            {idList.customerId}
                            {"..."}
                            {idList.businessName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </FormControl>
              </Grid> :
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.customerId && errors.customerId
                    )}
                    fullWidth
                    helperText={
                      touched.customerId && errors.customerId
                    }
                    label="Customer Id"
                    name="customerId"
                    onBlur={handleBlur}
                    value={values.customerId}
                    variant="outlined"
                  />
                </Grid>
                 }
                <Grid item xs={12}>
                  <Typography>Upload Product excel or Csv File</Typography>
                  <TextField
                    error={Boolean(
                      touched.excelSheet && errors.excelSheet
                    )}
                    fullWidth
                    helperText={
                      touched.excelSheet && errors.excelSheet
                    }
                    type="file"
                    name="excelSheet"
                    onBlur={handleBlur}
                    onChange={handleImage}
                    value={values.gstin}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              style={{ marginLeft: '1em', }}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

ProductUploadForm.propTypes = {
  className: PropTypes.string,
};

export default ProductUploadForm;


