import axios from "axios";

// const gateway_port = process.env.REACT_APP_API_PORT || 6800;
// const gateway_protocol = process.env.REACT_APP_PROTOCOL || "http";
// const api_hostname = process.env.REACT_APP_API_HOSTNAME || window.location.hostname;
const url = process.env.REACT_APP_PATH

console.log(
  "ENV : " + process.env
);

console.log(
  "Axios URL : " + url 
);

const instance = axios.create({
  baseURL: url,
  timeout: 10000,
});

export default instance;

