import { CustomerUpload } from "src/interface/customerIf";
import mgmtcustomerUpload from "src/services/mgmtCustomer";

export const CART1_INFO = '@cart1/info';
export const CART1_CREATE = '@cart1/create';
export const CART1_CREATE_SUCCESS = '@cart1/create-success';
export const CART1_CREATE_FAILURE = '@cart1/create-failure';
export const CART1_UPDATE = '@cart1/update';
export const CART1_UPDATE_SUCCESS = '@cart1/update-success';
export const CART1_UPDATE_FAILURE = '@cart1/update-failure';

export function customerUpload( cartInfo1,gstin,gstin1,gstin2,shortLogoUpload) {
  return async (dispatch) => {
    console.log("upload():", cartInfo1,gstin);
    let newKart1 = new CustomerUpload( cartInfo1,gstin,gstin1,gstin1,shortLogoUpload);
 
    console.log("upload():" + JSON.stringify(cartInfo1) + " " + JSON.stringify(newKart1));
    try {
      dispatch({ type: CART1_CREATE });
      const cart1 = await mgmtcustomerUpload.customerUpload(cartInfo1,gstin,gstin1,gstin2,shortLogoUpload);
      console.log("upload()(after):" + JSON.stringify(cart1));
      dispatch({
        type: CART1_CREATE_SUCCESS,
        payload: {
          cart1
        }
      });
    } catch (error) {
      dispatch({ type: CART1_CREATE_FAILURE });
      throw error;
    }
  };
}



