import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ORDER_STATUS_TYPE, USER_TYPE } from "src/utils/constants";
import { OrderUpdate } from "src/actions/mgmtOrderAction";
import { updateDistributorType } from "src/actions/mgmtDistributorTypeAction";


const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function DistributorEditForm({ className, state, ...rest }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const addedBy = account.user.email;
  // console.log("ServiceCreateForm: addedBy", addedBy);

  return (

    <Formik
      initialValues={{
        customerId: state.customerId || '',
        distributorTypeId: state.distributorTypeId || '',
        category: state.category || '',
        discount: state.discount || '',
      }}
      validationSchema={Yup.object().shape({

      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        //console.log("Submitting the request", businessId, addedBy, values);
        try {
          await dispatch(updateDistributorType(values));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Distributor Type Updated", {
            variant: "success",
          });
          navigate("/app/management/distributor/type");
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Distributor Type update failed", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Update Distributor Type" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.customerId && errors.customerId
                    )}
                    fullWidth
                    helperText={
                      touched.customerId && errors.customerId
                    }
                    label="Customer Id"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                    disabled
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.distributorTypeId && errors.distributorTypeId
                    )}
                    fullWidth
                    helperText={
                      touched.distributorTypeId && errors.distributorTypeId
                    }
                    label="Distributor Type Id"
                    name="distributorTypeId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.distributorTypeId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.category && errors.category
                    )}
                    fullWidth
                    helperText={
                      touched.category && errors.category
                    }
                    label="Category"
                    name="category"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.category}
                    variant="outlined"
                    
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.discount && errors.discount
                    )}
                    fullWidth
                    helperText={
                      touched.discount && errors.discount
                    }
                    label="Discount"
                    name="discount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.discount}
                    variant="outlined"
                    
                  />
                </Grid>
              
              </Grid>
            </CardContent>
          </Card>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

DistributorEditForm.propTypes = {
  className: PropTypes.string,
};

export default DistributorEditForm;


