import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { USER_TARGRT_TYPE, USER_TYPE } from "src/utils/constants";
import { CreateUserTarget } from "src/actions/mgmtUserTargetAction";
import mgmtUser from "src/services/mgmtUser";

// import { UserTarget } from "src/actions/mgmtUserAction";
// import { UserCreate } from "src/actions/mgmtUserAction";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function UserTargetForm({ className,customerIdList,customerId,userType,state, ...rest }) {
  const cus= state.customerId
  const cusid = (userType === "SUPER_ADMIN") ? cus : undefined;
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // const account = useSelector((state) => state.account);
  // const addedBy = account.user.email;
  // console.log("ServiceCreateForm: addedBy", addedBy);

  return (

    <Formik
      initialValues={{
        customerId:cusid || '',
        userId:state.userId || '',
        target:"",
        achieved: "",
        targetType: "",
        userType:state.userType || '',
      }}
      validationSchema={Yup.object().shape({

      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        //console.log("Submitting the request", businessId, addedBy, values);
        try {
          await dispatch(CreateUserTarget(values));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("User Target Created", {
            variant: "success",
          });
          navigate("/app/management/user/view");
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("User Target Failed", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Create User Target" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
              {
                userType !== "ADMIN" ? 
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select CustomerId</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select CustomerId"
                        onChange={handleChange}
                        name="customerId"
                        onBlur={handleBlur}
                        value={values.customerId}
                      >
                        {customerIdList.map((cities) => (
                          <MenuItem value={cities.customerId}>
                            {cities.customerId}
                            {"..."}
                            {cities.businessName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </FormControl>
              </Grid> :
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.userId && errors.userId
                    )}
                    fullWidth
                    helperText={
                      touched.userId && errors.userId
                    }
                    label="User Id"
                    name="userId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.userId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
}

                  {/* <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={values.userType}
                    onChange={(event, newValue) => {
                      setFieldValue("userType", newValue);
                    }}
                    disablePortal
                    id="userType"
                    options={USER_TYPE}
                    required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    error={errors.userType}
                    helperText={errors.userType}
                    renderInput={(params) => (
                      <>
                        {" "}
                        <TextField {...params} label="User Type"
                          error={Boolean(touched.userType && errors.userType)}
                          helperText={touched.userType && errors.userType}
                        />
                      </>
                    )}
                  />
                </Grid> */}
              
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.target && errors.target
                    )}
                    fullWidth
                    helperText={
                      touched.target && errors.target
                    }
                    label="Target"
                    name="target"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.target}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={values.targetType}
                    onChange={(event, newValue) => {
                      setFieldValue("targetType", newValue);
                    }}
                    disablePortal
                    id="targetType"
                    options={USER_TARGRT_TYPE}
                    required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    error={errors.targetType}
                    helperText={errors.targetType}
                    renderInput={(params) => (
                      <>
                        {" "}
                        <TextField {...params} label="Target Type"
                          error={Boolean(touched.targetType && errors.targetType)}
                          helperText={touched.targetType && errors.targetType}
                        />
                      </>
                    )}
                  />
                </Grid>
               
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.achieved && errors.achieved
                    )}
                    fullWidth
                    helperText={
                      touched.achieved && errors.achieved
                    }
                    label="Achieved"
                    name="achieved"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.achieved}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

UserTargetForm.propTypes = {
  className: PropTypes.string,
};

export default UserTargetForm;


