import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 5,
        justifyContent: 'flex-end',
        width: '100%'
    },
    headerContainer1: {
        marginTop: 5,
        justifyContent: 'center',
        width: '100%'
    },
    billTo: {
        justifyContent: 'center',
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
        fontSize: 12,
        fontStyle: 'bold',
        color: '#3778C2',
    },
    billTo1: {
        justifyContent: 'flex-end',
        justifyContent: 'left',
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
        fontSize: 12,
        fontStyle: 'bold',
        color: '#3778C2',
    },
    shipTo: {
        marginleft: 150,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
        fontSize: 12,
        fontStyle: 'bold',
        color: '#3778C2',
    },
    label: {
        width: 120,
        alignItems: 'left',
    },
    label: {
        width: 120,
        alignItems: 'right',
    },
    label1: {
        width: 150,
        // alignItems: 'left',
        fontSize: 12,
        fontStyle: 'bold',
        // marginTop:5
    },
    label3: {
        width: 150,
        // alignItems: 'left',
        fontSize: 12,
        fontStyle: 'bold',
        // marginTop:5
    },
    label2: {
        // width: 200,
        // marginTop:20,
        marginleft: 150,
        // justifyContent: 'flex-end',
        // alignItems: 'left',
        // fontSize: 12,
        // fontStyle: 'bold',
        fontFamily: 'Helvetica-Oblique',
        fontSize: 12,
        fontStyle: 'bold',
        color: '#3778C2',
        // marginTop:5
    },
    invoiceDateContainer1: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'right',
        marginTop:20
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        // alignItems: 'right',
    },
    invoiceDateContainer1: {
        width:200,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginLeft:185,
        fontFamily: 'Helvetica-Oblique',
        fontSize: 12,
        fontStyle: 'bold',
        color: '#3778C2',
        
    },
});

const BillTo = ({ invoice }) => (
    <View style={styles.headerContainer}>
         <View style={styles.invoiceDateContainer}>
         <Text style={styles.label2}>Billing Address:</Text>
         <Text style={styles.invoiceDateContainer1}>Shipping Address:</Text>
         </View>

        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Address Line1:- </Text>
            <Text style={styles.label1}>{invoice.billingAddress.address.addressLine1}</Text>
            <Text style={styles.label1}>Address Line1:- </Text>
            <Text >{invoice.shippingAddress.address.addressLine1}</Text>
        </View >
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Address Line2:- </Text>
            <Text style={styles.label1}>{invoice.billingAddress.address.addressLine2}</Text>
            <Text style={styles.label1}>Address Line2:- </Text>
            <Text>{invoice.shippingAddress.address.addressLine2}</Text>
        </View >
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>City:- </Text>
            <Text style={styles.label1}>{invoice.billingAddress.address.city}</Text>
            <Text style={styles.label1}>City:- </Text>
            <Text>{invoice.shippingAddress.address.city}</Text>
        </View >
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>State:- </Text>
            <Text style={styles.label1}>{invoice.billingAddress.address.state}</Text>
            <Text style={styles.label1}>State:- </Text>
            <Text>{invoice.shippingAddress.address.state}</Text>
        </View >
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Pincode:- </Text>
            <Text style={styles.label1}>{invoice.billingAddress.address.pinCode}</Text>
            <Text style={styles.label1}>Pincode:- </Text>
            <Text>{invoice.shippingAddress.address.pinCode}</Text>
        </View >
    </View>
    
);

export default BillTo;