

export function findCategoryById (categories, targetCategoryId) {   
    for (const category of categories) {
      if (category.categoryId === targetCategoryId) {   
        return category;     
      } else if (category.children && category.children.length > 0) {        
        const foundCategory = findCategoryById(category.children, targetCategoryId);
        if(foundCategory) {
            return foundCategory;
        }
      }
    }
    return null;
  };


export function categoryIdToString(categories, categoryIds) {   

    const categoryNames = [];
    categoryIds?.map((item) => {
      const category = findCategoryById(categories, item);
      if(category) {
        categoryNames.push(category.name);
      }

    });

    return categoryNames;
  };
