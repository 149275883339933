class License {
  constructor(info) {
    this.customerId = info.customerId;
    this.fromDate = info.fromDate;
    this.toDate = info.toDate;
    this.remarks = info.remarks;
 
    
  }
}

export default { License };
export { License };



