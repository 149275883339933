import { UserTarget } from "src/interface/userTargetIf";
import mgmtUser from "src/services/mgmtUser";

export const USERTARGET_SEARCH = '@usertarget/search';
export const USERTARGET_SEARCH_SUCCESS = '@usertarget/search-success';
export const USERTARGET_SEARCH_FAILURE = '@usertarget/search-failure';

export function CreateUserTarget(userTargetInfo) {
  return async (dispatch) => {
    console.log("USERTARGETINFO", userTargetInfo);
    let newUserTarget = new UserTarget(userTargetInfo);
    console.log("USERTARGETAction():" + JSON.stringify(userTargetInfo) + " " + JSON.stringify(newUserTarget));
    try {
      dispatch({ type: USERTARGET_SEARCH });
      const userTarget = await mgmtUser.createUserTarget(newUserTarget);
      dispatch({
        type: USERTARGET_SEARCH_SUCCESS,
        payload: {
          userTarget
        }
      });
    } catch (error) {
      dispatch({ type: USERTARGET_SEARCH_FAILURE });
      throw error;
    }
  };
}



