class CustomerForm {
  constructor(updatedBy, info) {
    this.mobile = info.mobile;
    this.businessName = info.businessName;
    this.whatsApp = info.whatsApp;
    this.helpLine = info.helpLine;
    this.registeredEmail = info.registeredEmail;
    this.registeredAddress = info.registeredAddress;
    this.GSTN = info.GSTN;
    this.businessLocation = info.businessLocation;
    this.showQuotationRequest = info.showQuotationRequest;
    this.about = info.about;
    this.paymentGateway = info.paymentGateway;
    this.gatewayType = info.gatewayType;
    this.gatewayPayKeySecret = info.gatewayPayKeySecret;
    this.gatewayPayKeyId = info.gatewayPayKeyId;
    this.showContacts = info.showContacts;
    this.showDistributor = info.showDistributor;
    this.showServiceCenters = info.showServiceCenters;
    this.showMaintenanceTroubleshooting = info.showMaintenanceTroubleshooting;
    this.isEngineeringType = info.isEngineeringType;
    this.website = info.website;
    this.facebookUrl = info.facebookUrl;
    this.twitterUrl = info.twitterUrl;
    this.instagramUrl = info.instagramUrl;
    this.linkedInUrl = info.linkedInUrl;
    this.youtubeUrl = info.youtubeUrl;
    this.parentId = info.parentId;
    this.brandName = info.brandName;
    this.appName = info.appName;
    this.appDescription = info.appDescription;
    this.bannerContactAddress = info.bannerContactAddress;
    this.primaryColor = info.primaryColor;
    this.secondaryColor = info.secondaryColor;
    this.scaffoldColor = info.scaffoldColor;
    this.backgroundColor = info.backgroundColor;
    this.fontFamily = info.fontFamily;
    this.gatewayPayKeyId = info.gatewayPayKeyId;
    this.gatewayPayKeySecret = info.gatewayPayKeySecret;
    this.aboutUs = info.aboutUs;
    this.termsOfUse = info.termsOfUse;
    this.privacyPolicy = info.privacyPolicy;
    this.logoFile = info.logoFile;
    this.bannerFiles = info.bannerFiles;
    this.certificates = info.certificates;
    this.updatedBy = updatedBy;
  }
}

class CustomerCredentialsForm {
  constructor(info) {
    this.customerId = info.customerId;
    this.paymentMode = info.paymentMode;
    this.gatewayType = info.gatewayType?.gatewayType;
    this.gatewayPayKeySecret = info.gatewayPayKeySecret;
    this.gatewayPayKeyId = info.gatewayPayKeyId;
    if(info && info?.keyIndex){
      this.keyIndex = `${info.keyIndex}`;
    }
    if(info && info?.accessCode){
      this.accessCode = `${info.accessCode}`;
    }
    // this.paymentCredentialId = info.paymentCredentialId;
    this.paymentServiceUrl = info.paymentServiceUrl;

  }
}


export default { CustomerForm,CustomerCredentialsForm };
export { CustomerForm,CustomerCredentialsForm };



