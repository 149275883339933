import mgmtdashboard from "src/services/mgmtDashBoard";

export const FINANCIALINFO_INFO = '@financialinfo/info';
export const FINANCIALINFO_FETCH_SUCCESS = '@financialinfo/fetch-success';
export const FINANCIALINFO_FETCH_FAILURE = '@financialinfo/fetch-failure';
export const FINANCIALTODAYSALESANDORDERS_INFO = '@financialtodaysalesandordersinfo/info';
export const FINANCIALTODAYSALESANDORDERS_FETCH_SUCCESS = '@financialtodaysalesandordersinfo/fetch-success';
export const FINANCIALTODAYSALESANDORDERS_FETCH_FAILURE = '@financialtodaysalesandordersinfo/fetch-failure';
export const FETCHWEEKLYGRAPH_INFO = '@fetchweeklygraphinfo/info';
export const FETCHWEEKLYGRAPH_FETCH_SUCCESS = '@fetchweeklygraphinfo/fetch-success';
export const FETCHWEEKLYGRAPH_FETCH_FAILURE = '@fetchweeklygraphinfo/fetch-failure';
export const FETCHMONTHLYGRAPH_INFO = '@fetchmonthlygraphinfo/info';
export const FETCHMONTHLYGRAPH_FETCH_SUCCESS = '@fetchmonthlygraphinfo/fetch-success';
export const FETCHMONTHLYGRAPH_FETCH_FAILURE = '@fetchmonthlygraphinfo/fetch-failure'
export const FETCHPRODUCTGRAPH_INFO = '@fetchproductgraphinfo/info';
export const FETCHPRODUCTGRAPH_FETCH_SUCCESS = '@fetchproductgraphinfo/fetch-success';
export const FETCHPRODUCTGRAPH_FETCH_FAILURE = '@fetchproductgraphinfo/fetch-failure'
export const FETCHPRODUCTMONTHGRAPH_INFO = '@fetchproductmonthgraphinfo/info';
export const FETCHPRODUCTMONTHGRAPH_FETCH_SUCCESS = '@fetchproductmonthgraphinfo/fetch-success';
export const FETCHPRODUCTMONTHGRAPH_FETCH_FAILURE = '@fetchproductmonthgraphinfo/fetch-failure'

export function financialTotalSalesAndOrderData(cusId) {

  return async (dispatch) => {
    try {
      dispatch({ type: FINANCIALINFO_INFO });
      const financialData = await mgmtdashboard.fetchFinancialData(cusId);
      // console.log("financialData Action", financialData);
      dispatch({
        type: FINANCIALINFO_FETCH_SUCCESS,
        payload: {
          financialData,
        },
      });
    } catch (error) {
      // console.log("financialData:financialData:Error2", error);
      dispatch({ type: FINANCIALINFO_FETCH_FAILURE });
      throw error;
    }
  };
}

export function financialTodaySalesAndOrdersData(cusId) {

  return async (dispatch) => {
    try {
      dispatch({ type: FINANCIALTODAYSALESANDORDERS_INFO });
      const financialTodaySalesAndOrdersData = await mgmtdashboard.financialTodaySalesAndOrdersData(cusId);
      // console.log("financialTodaySalesAndOrdersData Action", financialData);
      dispatch({
        type: FINANCIALTODAYSALESANDORDERS_FETCH_SUCCESS,
        payload: {
          financialTodaySalesAndOrdersData,
        },
      });
    } catch (error) {
      // console.log("financialTodaySalesAndOrdersData:financialTodaySalesAndOrdersData:Error2", error);
      dispatch({ type: FINANCIALTODAYSALESANDORDERS_FETCH_FAILURE });
      throw error;
    }
  };
}

export function fetchweeklygraphData(cusId) {

  return async (dispatch) => {
    try {
      dispatch({ type: FETCHWEEKLYGRAPH_INFO});
      const fetchweeklygraphData = await mgmtdashboard.fetchweeklygraphData(cusId);
      // console.log("fetchweeklygraphData Action", financialData);
      dispatch({
        type: FETCHWEEKLYGRAPH_FETCH_SUCCESS,
        payload: {
          fetchweeklygraphData,
        },
      });
    } catch (error) {
      // console.log("fetchweeklygraphData:fetchweeklygraphData:Error2", error);
      dispatch({ type: FETCHWEEKLYGRAPH_FETCH_FAILURE });
      throw error;
    }
  };
}

export function fetchMonthlygraphData(cusId) {

  return async (dispatch) => {
    try {
      dispatch({ type: FETCHMONTHLYGRAPH_INFO});
      const fetchMonthlygraphData = await mgmtdashboard.fetchmonthlygraphData(cusId);
      // console.log("fetchMonthlygraphData Action", fetchMonthlygraphData);
      dispatch({
        type: FETCHMONTHLYGRAPH_FETCH_SUCCESS,
        payload: {
          fetchMonthlygraphData,
        },
      });
    } catch (error) {
      // console.log("fetchMonthlygraphData:fetchMonthlygraphData:Error2", error);
      dispatch({ type: FETCHMONTHLYGRAPH_FETCH_FAILURE });
      throw error;
    }
  };
}

export function fetchProductTrendData1(cusId) {

  return async (dispatch) => {
    try {
      dispatch({ type: FETCHPRODUCTGRAPH_INFO});
      const fetchProductTrendData = await mgmtdashboard.fetchProductTrendData(cusId);
      // console.log("fetchMonthlygraphData Action", fetchMonthlygraphData);
      dispatch({
        type: FETCHPRODUCTGRAPH_FETCH_SUCCESS,
        payload: {
          fetchProductTrendData,
        },
      });
    } catch (error) {
      // console.log("fetchMonthlygraphData:fetchMonthlygraphData:Error2", error);
      dispatch({ type: FETCHPRODUCTGRAPH_FETCH_FAILURE });
      throw error;
    }
  };
}

export function fetchMonthProductTrendData(cusId) {

  return async (dispatch) => {
    try {
      dispatch({ type: FETCHPRODUCTMONTHGRAPH_INFO});
      const fetchMonthProductTrend = await mgmtdashboard.fetchMonthProductTrendData(cusId);
      // console.log("fetchMonthlygraphData Action", fetchMonthlygraphData);
      dispatch({
        type: FETCHPRODUCTMONTHGRAPH_FETCH_SUCCESS,
        payload: {
          fetchMonthProductTrend,
        },
      });
    } catch (error) {
      // console.log("fetchMonthlygraphData:fetchMonthlygraphData:Error2", error);
      dispatch({ type: FETCHPRODUCTMONTHGRAPH_FETCH_FAILURE });
      throw error;
    }
  };
}


