import React, {
  useState,
  useEffect,
} from 'react';
import {
  Container,
  Grid,  
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from './Header';

import { fetchOrdersByCriteria } from 'src/actions/orderActions';
import MperitoCard from 'src/components/MperitoCard';
import OrderDetails from '../ViewOrder/OrderDetails';
import UpdatePayment from './UpdatePayment';
import UpdateCancel from './UpdateCancel';
import UpdateHold from './UpdateHold';
import UpdateDelivered from './UpdateDelivered';
import UpdateShip from './UpdateShip';
import UpdateAcept from './UpdateAccept';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));


const OrderStatusComponent = ({ action, customerId, orderId }) => {
  switch (action) {
    case 'CAPTURE_PAYMENT':
      return <UpdatePayment customerId={customerId} orderId={orderId} action={action}/>;

    case 'ACCEPT_ORDER':
      return <UpdateAcept customerId={customerId} orderId={orderId} action={action} />;

    case 'CANCEL_ORDER':
      return <UpdateCancel customerId={customerId} orderId={orderId} action={action} />;

    case 'SHIP_ORDER':
      return <UpdateShip customerId={customerId} orderId={orderId} action={action} />;

    case 'DELIVERED':
      return <UpdateDelivered customerId={customerId} orderId={orderId} action={action}/>;

    case 'HOLD':
      return <UpdateHold customerId={customerId} orderId={orderId} action={action} />;

    default:
      return <div>Default Component</div>;
  }
};



function UpdateOrderStatus() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { orderId, status, action } = state;

  const [orderDetails, setOrderDetails] = useState(undefined);

  const businessInfo = useSelector((state) => state?.app?.businessInfo);
  const customerId = businessInfo?.customerId;

  const [criteria, setCriteria] = useState({ customerId: customerId, orderId: orderId });

  console.log("View Order: ", state, criteria);

  useEffect(() => {
    async function getOrders(customerId) {
      const ordersList = await dispatch(fetchOrdersByCriteria(customerId, criteria));
      console.log("Fetch Order: ", ordersList);
      setOrderDetails((ordersList?.length > 0) ? ordersList[0] : undefined);
      console.log("Fetch Order View", orderDetails);
    }
    getOrders(customerId);

  }, [customerId, criteria]);

  return (
    <Page
      className={classes.root}
      title="Update Order Status"
    >
      <Container maxWidth>
        <Header />
        <br />
        <br />
        <MperitoCard title={`Manage Order Status ( ${businessInfo?.businessName} - ${businessInfo?.customerId} )`}>
          <Grid item ml={2} xs={12}>
            <Typography>{`Order Id    : ${orderId}`}</Typography>
            <Typography>{`Order Status: ${status}`}</Typography>
          </Grid>

          <Grid item >
            <OrderStatusComponent action={action} customerId={customerId} orderId={orderId} />  
          </Grid>


          {/* <UpdateCancel customerId={customerId} orderId={orderId} />
          <UpdatePayment customerId={customerId} orderId={orderId} /> */}
        </MperitoCard>
        <br />

        <MperitoCard title="Orders Details">
          <OrderDetails orderDetails={orderDetails} />
        </MperitoCard>
      </Container>
    </Page>

  );
}

export default UpdateOrderStatus;
