import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import {
  Box,
  Button,
  CardContent,
  Container,
  Grid, Card,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'src/utils/axios';
import Page from 'src/components/Page';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLocation } from 'react-router-dom';
import { customerJsonDownload } from 'src/actions/mgmtCustomerCreateAction';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function JsonDownload() {
  const customerIdList = useSelector(
    (state) => state?.customer?.customerInfoList
  );
  const [myBooleanShowContacts, setMyBooleanShowContacts] = useState(false)
  const customer15 = useSelector(
    (state) => state?.user?.cusState
  );

  const dispatch = useDispatch();
  const { state } = useLocation();
  const cusId = state

  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [services, setServices] = useState(null);
  const [id, setId] = React.useState('');

  const selectedCustomerId = (id) ? id : "JSON";


  const customerId = useSelector(
    (state) => state?.customer?.jsonData
  );
  function refreshPage() {
    window.location.reload(false);
  }
  const isDisabled1 = false;
  const handleChange1 = (event) => {
    if (event) {
      setMyBooleanShowContacts(false)
    } else if (!event) {
      setMyBooleanShowContacts(true)
    }
    setId(event.target.value);
    dispatch(customerJsonDownload(cusId, event.target.value));
  };

  const url = customerId.jsonURL

  function handleClick() {
    window.location.href = url
  }

  const isDisabled = (customerId.jsonURL) ? myBooleanShowContacts : true;

  return (
    <Page
      className={classes.root}
      title="Customer Json Download"
    >
      <Header />
      <br /><br />
      <Card style={{ maxWidth: '500px', }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <div>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Download Format</InputLabel>
                    <Select

                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Select Download Format"
                      onChange={handleChange1}
                      name="categoryId"
                      // onBlur={handleBlur}
                      value={id}

                    >
                      <MenuItem value={"CSV"}>CSV</MenuItem>
                      <MenuItem value={"JSON"}>JSON</MenuItem>
                      <MenuItem value={"XLS"}>XLS</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </FormControl>
            </Grid>
            <br />

            <Button color="secondary"
              variant="contained" disabled={isDisabled} style={{ width: "300px", height: "60px", marginLeft: "2em", marginTop: "2em" }} onClick={handleClick}>Download Customer File</Button>
          </Grid>
        </CardContent>
      </Card>

    </Page>
  );
}

export default JsonDownload;
