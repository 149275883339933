import { DASHBOARD_SUMMARY_TYPES } from 'src/constants';
import { 
    DASHBOARD_FINANCIAL_ORDER_SALES_TODAY_URL, 
    DASHBOARD_FINANCIAL_ORDER_SALES_YEARLY_URL, 
    DASHBOARD_ORDERS_TREND_MONTH_URL, 
    DASHBOARD_ORDERS_TREND_WEEK_URL
} from 'src/constants/services';
import axios from 'src/utils/axios';


class DashboardFinancialService {

    fetchSalesSummary = (customerId, summaryType) =>
        new Promise((resolve, reject) => {
            let dashboardUrl;

            switch (summaryType) {
                case DASHBOARD_SUMMARY_TYPES.TOTAL:
                    // TODO_SP: need api to get total data for the system
                    //dashboardUrl = "/api/dashboard/order/month/day-wise/order-sales?customerId=" + customerId;
                    break;
                
                case DASHBOARD_SUMMARY_TYPES.YEARLY:                    
                    dashboardUrl = DASHBOARD_FINANCIAL_ORDER_SALES_YEARLY_URL 
                                    + "?customerId=" + customerId;
                    break;

                case DASHBOARD_SUMMARY_TYPES.MONTHLY:
                    // TODO_SP: need api to get total data for the system
                    break;

                case DASHBOARD_SUMMARY_TYPES.TODAY:
                    dashboardUrl = DASHBOARD_FINANCIAL_ORDER_SALES_TODAY_URL
                                    + "?customerId=" + customerId;
                    //dashboardUrl = "/api/dashboard/order/today/order-sales?customerId=" + customerId;
                    break;

                default:
                    // console.log("No type provided");
            }

            axios
                .get(dashboardUrl)
                .then((response) => {
                    // console.log("fetchSalesSummary response:", response);
                    if (response.data) {
                        let payload = response.data?.payload;
                        // console.log("fetchSalesSummary() payload:", payload);
                        resolve(payload);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    // console.log("fetchSalesSummary() error:" + JSON.stringify(error));
                    reject(error);
                });
        })


    fetchSalesTrend = (customerId, summaryType) =>
        new Promise((resolve, reject) => {
            let dashboardUrl;

            switch (summaryType) {
                case DASHBOARD_SUMMARY_TYPES.TOTAL:
                    // TODO_SP: need api to get total data for the system
                    //dashboardUrl = "/api/dashboard/order/month/day-wise/order-sales?customerId=" + customerId;
                    break;
                
                // case DASHBOARD_SUMMARY_TYPES.YEARLY:                    
                //     dashboardUrl = DASHBOARD_FINANCIAL_ORDER_SALES_YEARLY_URL 
                //                     + "?customerId=" + customerId;
                //     break;

                case DASHBOARD_SUMMARY_TYPES.MONTHLY:
                    dashboardUrl = DASHBOARD_ORDERS_TREND_MONTH_URL
                                    + "?customerId=" + customerId;
                    // TODO_SP: need api to get total data for the system
                    break;

                case DASHBOARD_SUMMARY_TYPES.WEEKLY:
                    dashboardUrl = DASHBOARD_ORDERS_TREND_WEEK_URL
                                    + "?customerId=" + customerId;
                        // TODO_SP: need api to get total data for the system
                        break;

                // case DASHBOARD_SUMMARY_TYPES.TODAY:
                //     dashboardUrl = DASHBOARD_FINANCIAL_ORDER_SALES_TODAY_URL
                //                     + "?customerId=" + customerId                    
                    break;

                default:
                    // console.log("No type provided");
            }

            axios
                .get(dashboardUrl)
                .then((response) => {
                    // console.log("fetchSalesTrend response:", response);
                    if (response.data) {
                        let payload = response.data?.payload;
                        // console.log("fetchSalesTrend() payload:", payload);
                        resolve(payload);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    // console.log("fetchSalesSummary() error:" + JSON.stringify(error));
                    reject(error);
                });
        })

}

const dashboardFinancialService = new DashboardFinancialService();
export default dashboardFinancialService;