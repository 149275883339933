import React, {
    useEffect,
    useState,
} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import axios from 'src/utils/axios';
import { Formik } from "formik";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSnackbar } from "notistack";
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createCategory, updateCategory, deleteCategory } from "src/actions/mgmtCategoryAction";
import { fetchCategory } from 'src/actions/mgmtSelectValueAction';
import { IMAGE_TYPE, IMAGE_BASE_URL } from 'src/config';
import ImageEditor from 'src/components/ImageEditor';

import ConfirmBox from './ConfirmBox';


const useStyles = makeStyles(() => ({
    root: {},
    editor: {
        "& .ql-editor": {
            height: 400,
        },
    },
}));



function CategoryForm({ customerId, categoryInfo, parentCategoryNames, isNewCategory, onSubmitSuccess, onSubmitCancel, className, ...rest }) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [isDeleteOpen, setDeleteOpen] = useState(false);

    const [image, setImage] = useState('')
    function handleImage(e) {
      // console.log(e.target.files)
      setImage(e.target.files[0])      
    }
 
    const imageUrl =  `${IMAGE_BASE_URL}/${categoryInfo?.categoryImage}`;

    // console.log("CategoryForm: imageUrl: ", imageUrl, categoryInfo, parentCategoryNames);

    const isNew = categoryInfo ? false : true;

    const { enqueueSnackbar } = useSnackbar();
    const account = useSelector((state) => state.account);
    const addedBy = account.user.email;

    useEffect(() => {

    }, [categoryInfo, isNewCategory]);

    function handleDeleteOpen () {
        setDeleteOpen(true);
        // Call delete action
    }

    async function handleDeleteCategory(customerId, categoryId) {
        // console.log("Deleting Category: ", customerId, categoryId);
        setDeleteOpen(false);
        try{
            await dispatch(deleteCategory(customerId, categoryId));
            enqueueSnackbar("Deleted Category Successfully", {
                variant: "success",
            });                    
            await dispatch(fetchCategory(customerId));
            onSubmitSuccess();
        } catch (err) {
            // console.log("Error Delete: ", err);
            enqueueSnackbar("Failed to delete category " + err?.message, {
                variant: "failure",
            });
        }
        
        
        // onSubmitSuccess();
    }

    // if(isNewCategory) {
    //     parentCategoryNames?.push(categoryInfo?.name);
    // }


    return (

        <Formik
            enableReinitialize
            initialValues={{
                customerId: customerId,
                categoryId: isNewCategory ? "" : categoryInfo?.categoryId || "",
                name: isNewCategory ? "" :  categoryInfo?.name || "",
                parentCategory:  isNewCategory? categoryInfo?.name || "" : parentCategoryNames || "",
                description: isNewCategory ? "" : categoryInfo?.description || "",
                categoryImage: isNewCategory ? "" : categoryInfo?.categoryImage,
            }}
            validationSchema={Yup.object().shape({

            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

                try {
                    if(isNewCategory) {
                        await dispatch(createCategory(addedBy, values, image));
                    } else {
                        await dispatch(updateCategory(addedBy,values,image));
                    }
                    
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar("Category Created", {
                        variant: "success",
                    });                    
                    await dispatch(fetchCategory(customerId));
                    onSubmitSuccess();
                    // navigate("/app/management/category/view");
                } catch (err) {
                    // setErrors({ submit: err.message });
                    setStatus({ success: false });
                    setSubmitting(false);
                    enqueueSnackbar("customerId or name Exits", {
                        variant: "failure",
                    });
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
            }) => (
                <form
                    onSubmit={handleSubmit}
                    className={clsx(classes.root, className)}
                    {...rest}
                >
                    <Grid container spacing={3} mt={2} >

                        <Grid container item xs={12} ml={2} >
                            <Typography> Parent Hierarchy : { parentCategoryNames?.length ? parentCategoryNames?.join(" -> ") : "Main Category" } </Typography>
                        </Grid>
                        <Grid container item xs={12} sm={4} >
                        <Grid item xs={12}>
                            {!isNewCategory && 
                            <ImageEditor type={IMAGE_TYPE.CATEGORYIMAGE} 
                                    inImage={imageUrl} customerId={customerId} id={values.categoryId} />  }
                            {isNewCategory && <TextField
                                error={Boolean(
                                    touched.categoryImage && errors.categoryImage
                                )}
                                fullWidth
                                helperText={
                                    touched.categoryImage && errors.categoryImage
                                }
                                type="file"
                                name="categoryImage"
                                onBlur={handleBlur}
                                onChange={handleImage}
                                value={values.image}
                                variant="outlined"
                            />}
                        </Grid>
                        </Grid>
                        <Grid container item spacing={3} xs={12} sm={8} >
                        <Grid item xs={12} >
                            <TextField
                                error={Boolean(
                                    touched.categoryId && errors.name
                                )}
                                fullWidth
                                helperText={
                                    touched.categoryId && errors.categoryId
                                }
                                label="Category Id"
                                name="categoryId"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.categoryId}
                                variant="outlined"
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <TextField
                                error={Boolean(
                                    touched.parentCategory && errors.parentCategory
                                )}
                                fullWidth
                                helperText={
                                    touched.parentCategory && errors.parentCategory
                                }
                                label="Parent Category Id"
                                name="parentCategory"
                                disabled
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.parentCategory}
                                variant="outlined"
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                error={Boolean(
                                    touched.name && errors.name
                                )}
                                fullWidth
                                helperText={
                                    touched.name && errors.name
                                }
                                label="Name"
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={Boolean(
                                    touched.description && errors.description
                                )}
                                fullWidth
                                helperText={
                                    touched.description && errors.description
                                }
                                label="Description"
                                name="description"
                                multiline
                                rows={3}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.description}
                                variant="outlined"
                            />
                        </Grid>

                            
                        </Grid>
                    </Grid>
                    {errors.submit && (
                        <Box mt={3}>
                            <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                    )}
                    <Stack mt={2} ml={2} spacing={2} direction="row">
    
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => onSubmitCancel()}
                            // type="submit"
                            // disabled={isSubmitting}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}                            
                        >
                            Save Category
                        </Button>
                        {!isNewCategory &&  <Button
                            color="secondary"
                            variant="contained"
                            // type="submit"
                            // disabled={isSubmitting}
                            onClick={handleDeleteOpen}
                        >
                            Delete Category
                        </Button>}
                        <ConfirmBox
                            open={isDeleteOpen}
                            closeDialog={() => setDeleteOpen(false)}
                            title={"Category: " + values.name + "(" + values.categoryId + ")"}
                            deleteFunction={()=> handleDeleteCategory(values.customerId, values.categoryId)}
                            />
                    </Stack>
                </form>
            )}
        </Formik>
    );
}

CategoryForm.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default CategoryForm;


