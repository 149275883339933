import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import axios from 'src/utils/axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { updateCategory } from "src/actions/mgmtCategoryAction";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { fetchCategory } from 'src/actions/mgmtSelectValueAction';
import ImageEditor from 'src/components/ImageEditor';
import { IMAGE_TYPE } from 'src/config';

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function CategoryEditForm({ className,category,id, ...rest }) {

  const [image, setImage] = useState('')

  function handleImage(e) {
    console.log(e.target.files)
    setImage(e.target.files[0])
  }

  const isMountedRef = useIsMountedRef();
  const cusId = category.customerId
const catId = category.categoryId
  const [services, setServices] = useState(null);

  const getServices = useCallback(() => {
    axios
      .get(`/api/admin/catalog/category/names?customerId=${id}`) //, {})
      .then((response) => {
        if (isMountedRef.current) {
          // console.log("getParentCategory =>" + JSON.stringify(response.data));
          if (response?.data?.data?.categories) {
            setServices(response.data.data.categories);
          } else {
            setServices([]);
          }
        }
      });
  }, [isMountedRef]);
  
  const path = process.env.REACT_APP_PATH
  const base = category.categoryImage
 
  const ur =  `${path}/${base}`

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const addedBy = account.user.email;

  useEffect(() => {
    getServices();
  }, [getServices]);

  if (!services) {
    return null;
  }
  
  return (

    <Formik
      initialValues={{
        customerId:category.customerId ||'',
        categoryId:category.categoryId ||'',
        name:category.name ||'',
        parentCategory:category.name ||'',
        description:category.description ||'',
      }}
      validationSchema={Yup.object().shape({

      })}
      onSubmit={async (values, {  setStatus, setSubmitting }) => {
        console.log("Submitting the request",values);
        try {
          await dispatch(updateCategory(addedBy,values,image));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Category Updated", {
            variant: "success",
          });
         await dispatch(fetchCategory(values.customerId));
          navigate("/app/management/category/view");
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Category update failed", {
            variant: "failure",

          });
             navigate("/app/management/category/view");
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
          <CardHeader title="Update Category" />
            <Divider />
            <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={Boolean(
                  touched.customerId && errors.customerId
                )}
                fullWidth
                helperText={
                  touched.customerId && errors.customerId
                }
                label="customerId"
                name="customerId"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.customerId}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(
                  touched.categoryId && errors.categoryId
                )}
                fullWidth
                helperText={
                  touched.categoryId && errors.categoryId
                }
                label="categoryId"
                name="categoryId"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.categoryId}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(
                  touched.name && errors.name
                )}
                fullWidth
                helperText={
                  touched.name && errors.name
                }
                label="name"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select Parent Name</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Parent Name"
                        onChange={handleChange}
                        name="parentCategory"
                        onBlur={handleBlur}
                        value={values.parentCategory}
                      >
                        {services.map((cities) => (
                          <MenuItem value={cities.name}>
                            {cities.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </FormControl>
              </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(
                  touched.description && errors.description
                )}
                fullWidth
                helperText={
                  touched.description && errors.description
                }
                label="Description"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                variant="outlined"
              />
            </Grid>
            {/* <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.categoryImage && errors.categoryImage
                    )}
                    fullWidth
                    helperText={
                      touched.categoryImage && errors.categoryImage
                    }
                    type="file"
                    name="categoryImage"
                    onBlur={handleBlur}
                    onChange={handleImage}
                    value={values.image}
                    variant="outlined"
                  />
                </Grid> */}
          </Grid>
          <br/>
       {/* <img alt="not fount" width={"400px"} height={240} src={ur} /> */}
       <ImageEditor type={IMAGE_TYPE.CATEGORYIMAGE} inImage={ur} customerId={cusId} id={catId} />  

          </CardContent>
          </Card>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
                 <Button
                      color="secondary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                  >
               Update Category
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

CategoryEditForm.propTypes = {
  className: PropTypes.string,
};

export default CategoryEditForm;





