import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { customerBrandDetailsUpdate, fetchCustomerList } from "src/actions/mgmtCustomerCreateAction";
import { FONTFAMILY_TYPE } from "src/utils/constants";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function CustomerInfoEditForm({ className, service, brandDetails, ...rest }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
 
  const fontFamily = (brandDetails.theme) ? brandDetails.theme.fontFamily : undefined;
  const fontColor = (brandDetails.theme) ? brandDetails.theme.fontColor : undefined;
  const scaffoldColor = (brandDetails.theme) ? brandDetails.theme.scaffoldColor : undefined;
  const backgroundColor = (brandDetails.theme) ? brandDetails.theme.backgroundColor : undefined;
  const primaryColor = (brandDetails.theme) ? brandDetails.theme.primaryColor : undefined;
  const secondaryColor = (brandDetails.theme) ? brandDetails.theme.secondaryColor : undefined;
 
  return (

    <Formik
      initialValues={{
        customerId: service.customerId || '',
        brandId:brandDetails.brandId || '',
        brandName:brandDetails.brandName || '',
        appName:brandDetails.appName || '',
        appDescription:brandDetails.appDescription || '',
        fontFamily:fontFamily || '',
        fontColor:fontColor || '',
        backgroundColor:backgroundColor || '',
        scaffoldColor: scaffoldColor || '',
        primaryColor: primaryColor || '',
        secondaryColor: secondaryColor || '',
        
      }}
      validationSchema={Yup.object().shape({

      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        // console.log("Submitting the request", addedBy, values, image, image1, image2);
        try {
          await dispatch(customerBrandDetailsUpdate(values));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Customer Brand Details Updated", {
            variant: "success",
          });
          dispatch(fetchCustomerList());
          navigate("/app/management/customer/view");
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Customer Brand Details Updated Failed", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            {/* <CardHeader title="Add Customer" />
            <Divider /> */}
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.customerId && errors.customerId
                    )}
                    fullWidth
                    helperText={
                      touched.customerId && errors.customerId
                    }
                    label="customerId"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.brandId && errors.brandId
                    )}
                    fullWidth
                    helperText={
                      touched.brandId && errors.brandId
                    }
                    label="Brand Id"
                    name="brandId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.brandId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <br/>
                <Grid item xs={12}>
                <Typography align="left">Brand Details</Typography>
                </Grid>
                 <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.brandName && errors.brandName
                    )}
                    fullWidth
                    helperText={
                      touched.brandName && errors.brandName
                    }
                    label="Brand Name"
                    name="brandName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.brandName}
                    variant="outlined"
                  />
                </Grid> 
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.appName && errors.appName
                    )}
                    fullWidth
                    helperText={
                      touched.appName && errors.appName
                    }
                    label="App Name"
                    name="appName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.appName}
                    variant="outlined"
                  />
                </Grid> 
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.appDescription && errors.appDescription
                    )}
                    fullWidth
                    helperText={
                      touched.appDescription && errors.appDescription
                    }
                    label="App Description"
                    name="appDescription"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.appDescription}
                    variant="outlined"
                  />
                </Grid> 
                <Grid item xs={12}>
                <Typography align="left">Brand Theme</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Autocomplete
                    fullWidth
                    value={values.fontFamily}
                    onChange={(event, newValue) => {
                      setFieldValue("fontFamily", newValue);
                    }}
                    disablePortal
                    id="fontFamily"
                    options={FONTFAMILY_TYPE}
                    required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    error={errors.fontFamily}
                    helperText={errors.fontFamily}
                    renderInput={(params) => (
                      <>
                        {" "}
                        <TextField {...params} label="Font Family"
                          error={Boolean(touched.fontFamily && errors.fontFamily)}
                          helperText={touched.fontFamily && errors.fontFamily}
                        />
                      </>
                    )}
                  />
                </Grid> 
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.fontColor && errors.fontColor
                    )}
                    fullWidth
                    helperText={
                      touched.fontColor && errors.fontColor
                    }
                    label="Font Color"
                    name="fontColor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fontColor}
                    variant="outlined"
                  />
                </Grid> 
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.backgroundColor && errors.backgroundColor
                    )}
                    fullWidth
                    helperText={
                      touched.backgroundColor && errors.backgroundColor
                    }
                    label="Back Ground Color"
                    name="backgroundColor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.backgroundColor}
                    variant="outlined"
                  />
                </Grid> 
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.primaryColor && errors.primaryColor
                    )}
                    fullWidth
                    helperText={
                      touched.primaryColor && errors.primaryColor
                    }
                    label="Primary Color"
                    name="primaryColor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.primaryColor}
                    variant="outlined"
                  />
                </Grid> 
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.secondaryColor && errors.secondaryColor
                    )}
                    fullWidth
                    helperText={
                      touched.secondaryColor && errors.secondaryColor
                    }
                    label="Secondary Color"
                    name="secondaryColor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.secondaryColor}
                    variant="outlined"
                  />
                </Grid> 
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.scaffoldColor && errors.scaffoldColor
                    )}
                    fullWidth
                    helperText={
                      touched.scaffoldColor && errors.scaffoldColor
                    }
                    label="Scaffold Color"
                    name="scaffoldColor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.scaffoldColor}
                    variant="outlined"
                  />
                </Grid> 
              </Grid>
            </CardContent>
          </Card>

          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              style={{ marginBottom: '5em' }}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

CustomerInfoEditForm.propTypes = {
  className: PropTypes.string,

};

export default CustomerInfoEditForm;


