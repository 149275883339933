import produce from "immer";
import { ATTIBUTE_SEARCH_SUCCESS } from "src/actions/mgmtAttibuteAction";

const initialState = {
    attribute: [],
};

const attributeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ATTIBUTE_SEARCH_SUCCESS: {
            const { attribute } = action.payload;
            return produce(state, (draft) => {
                draft.attribute = attribute;
            });
        }
        default: {
            return state;
        }
    }
};

export default attributeReducer;


