import { Service } from "src/interface/serviceIf";
import serviceService from "src/services/serviceService";

export const FETCH_SERVICE = '@business/fetch-service';
export const FETCH_SERVICE_SUCCESS = '@business/fetch-service-success';
export const FETCH_SERVICE_FAILURE = '@business/fetch-service-failure';

export const SERVICE_CREATE = '@service/create';
export const SERVICE_CREATE_SUCCESS = '@service/create-success';
export const SERVICE_CREATE_FAILURE = '@service/create-failure';

export function fetchServices(customerId) {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_SERVICE });
            const resp = await serviceService.fetchServices(customerId);
            console.log("fetchServices Action", resp);
            dispatch({
                type: FETCH_SERVICE_SUCCESS,
                payload: {
                    services : resp?.services
                },
            });
        } catch (error) {
            dispatch({ type: FETCH_SERVICE_FAILURE });
            throw error;
        }
    };
}

export function createService(userId, info, image) {
    return async (dispatch) => {
      console.log("createService():", userId, info);
      let newService = new Service(userId, info);
      newService.addedBy = userId;
      console.log("createService():" + JSON.stringify(info) + " " + JSON.stringify(newService));
      try {
        dispatch({ type: SERVICE_CREATE });
        const service = await serviceService.createService(newService,image);
        console.log("createService()(after):" + JSON.stringify(service));
        dispatch({
          type: SERVICE_CREATE_SUCCESS,
          payload: {
            service
          }
        });
      } catch (error) {
        dispatch({ type: SERVICE_CREATE_FAILURE });
        throw error;
      }
    };
  }


