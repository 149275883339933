class News {
  constructor(updatedBy, info) {
    this.customerId = info.customerId;
    this.newsId = info.newsId;
    this.heading = info.heading;
    this.description = info.description;
    this.imageUrl = info.imageUrl;
    this.videoUrl = info.videoUrl;
    this.updatedBy = updatedBy;
  }
}

export default { News };
export { News };



