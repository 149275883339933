import React, { useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import {
  Box,
  IconButton,
  SvgIcon,
} from '@mui/material';

import { formatDateToDDMMYYYY } from 'src/utils/dateUtils';
import {inrPrice} from 'src/utils/formats';

const columns = [
  { field: 'quotationId', headerName: 'Quotation Id', width: 100 },
  { field: 'quotationDate', headerName: 'Quotation Date', width: 110, 
     valueFormatter: (params) => formatDateToDDMMYYYY(params.value),
     },
  { field: 'totalAmount', headerName: 'Total Amount', width: 90, ...inrPrice, align: 'right'},

  { field: 'customerId', headerName: 'CustomerId', width: 100 },
  { field: 'userId', headerName: 'User Id', width: 100 },
  { field: 'status', headerName: 'Status', width: 100 },
  { field: 'requestedBy', headerName: 'Requested By', width: 100 },
  { field: 'userType', headerName: 'User Type', width: 100 },
]

function QuotationRequestResults({ className, quotations, ...rest }) {

  const navigate = useNavigate();

  useEffect(() => {}, [quotations]);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Tooltip title="Generate Quotation">
              <IconButton
                onClick={() => {
                  navigate('/app/management/quotation/generate', { state: params.row });
                }}
              >
                <SvgIcon fontSize="small">
                  <BookmarkIcon/>
                </SvgIcon>
              </IconButton>
            </Tooltip><m>
              </m>
          </div>
        );
      },
    },
  ];


  return (
    <Box sx={{ height: 400, width: '100%', margin: 2 }}>
      <DataGrid
        rows={quotations}
        columns={columns.concat(actionColumn)}
        getRowId={(row) => row.quotationId} 
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Box>
  )
}

QuotationRequestResults.propTypes = {
  className: PropTypes.string,
  quotations: PropTypes.array,
};

QuotationRequestResults.defaultProps = {
  quotations: []
};

export default QuotationRequestResults;