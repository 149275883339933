import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    backgroundColor: "white",
    // border: "0.5px solid gray",
    // lineHeight: 1.5,
    height: "600px",
    width: "100%",
  },
  thankU: {
    fontSize: 12,
    color: "#1078bd",
  },

  text: {
    marginTop: "3px",
  },
  grandTotal: {
    marginTop: "10px",
  },
  section: {
    height: "auto",
    // flexGrow: 5,
    // borderBottom: "1px solid black",
    // border: "2px dotted Black",
    backgroundColor: "white",
    paddingTop: "10px",
  },
  tncSection: {
    padding: "10px",
    paddingTop: "10px",
    height: "auto",
    // borderBottom: "1px solid black",
  },
  billToSection: {
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 5,
    // borderBottom: "1px solid black",
    // borderLeft: "0.4px solid grey",
    backgroundColor: "white",
    paddingTop: "10px",
    width: "40%",
  },
  deliverySection: {
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 5,
    // borderBottom: "1px solid black",
    // borderLeft: "0.4px solid grey",
    backgroundColor: "white",
    paddingTop: "10px",
    width: "40%",
  },
  SupplierSection: {
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 5,
    // borderBottom: "1px solid black",
    backgroundColor: "white",
    paddingTop: "10px",
    width: "40%",
  },

  OrderIdSection: {
    // fontFamily: "Lato Bold",
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 1,
    backgroundColor: "white",
    // paddingTop: "10px",
    flexDirection: "column-reverse",
    marginLeft: "70px",
    marginBottom: "50px",
  },
  accountDetailsSection: {
    height: "auto",
    backgroundColor: "white",
    paddingTop: "20px",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    // borderBottom: "0.5px solid black",
  },
  rightSection: {
    paddingRight: "180px",
  },
  leftSection: {
    paddingRight: "-150px",
  },
  totalSection: {
    // padding: "10px",
    height: "auto",
    borderBottom: "0.5px solid gray",
    backgroundColor: "white",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  signiture: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: "50px",
  },
  addressSection: {
    flexDirection: "row",
  },
  subTotalSection: {
    flexDirection: "row",
    justifyContent: "flex-end",
    // height: "100px",
    height: "auto",
    flexGrow: 1,
    // border: "0.5px solid gray",
    backgroundColor: "white",
  },
  subTotalInnerSection: {
    padding: 15,
  },
  title: {
    fontSize: 11,
    fontWeight: 900,
  },
  itemListHead: {
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "white",
    color: "black",
    // fontWeight: "ultrabold",
    // fontFamily: "Lato Bold",
  },
  itemList: {
    flexDirection: "row",
    fontSize: 10,
  },
  itemListTotal: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    fontSize: 10,
  },
  id: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    textAlign: 'right',
    width: "5%",
  },
  desc: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    width: "25%",
  },
  quantity: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    textAlign: 'right',
    width: "10%",
  },
  unitPrice: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    textAlign: 'right',
    width: "15%",
  },
  amount: {
    flexDirection: "column-reverse",
    padding: 10,
    border: "0.3px solid black",
    textAlign: 'right',
    width: "15%",
  },
  total: {
    flexDirection: "column-reverse",
    padding: 10,
    border: "0.3px solid black",
    textAlign: 'right',
    width: "15%",
  },
  totalTax: {
    flexDirection: "column-reverse",
    padding: 10,
    border: "0.3px solid black",
    // borderTop: "0.3px solid black",
    // borderBottom: "0.3px solid black",
    width: "15%",
  },
  totalEmptySpace: {
    flexDirection: "row",
    padding: 10,
    border: "0.3px solid black",
    width: "75%",
  },
  totalCell: {
    padding: 6,
    border: "0.3px solid black",
    textAlign: 'right',    
    // fontFamily: "Lato Bold",    
  },
  totalCell2: {
    border: "0.3px solid black",
    padding: 6,
  },

  itemListTable: {
    flexDirection: "row-reverse",
    fontSize: 10,
  },
  totalTable: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    // width: "100%",
  },
  table: {
    // padding: 4,
    backgroundColor: "white",
    border:"0.3px solid black",
    marginTop: "20px",
  },
  thankyou: {
    flexDirection: "row",
    justifyContent: "center",
  },
  cardHeaders: {
    flexDirection: "start",
    backgroundColor: "white",
    // fontFamily: "Lato Bold",
  },
  invoiceT: {
    fontSize: "18px",
    color: "black",
    letterSpacing: "5px",
    // marginTop: "10px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 12,
  },
  leftColumn: {
    justifyContent: "center",
    backgroundColor: "white",
    padding: "5px",
    width: "50%",
  },
});

export default styles;