import businessService from "src/services/businessService";

export const FETCH_BUSINESSES = '@business/fetch-businesses';
export const FETCH_BUSINESSES_SUCCESS = '@business/fetch-businesses-success';
export const FETCH_BUSINESSES_FAILURE = '@business/fetch-businesses-failure';

export const FETCH_BUSINESS_DETAILS = '@business/fetch-business-detail';
export const FETCH_BUSINESS_DETAILS_SUCCESS = '@business/fetch-business-details-success';
export const FETCH_BUSINESS_DETAILS_FAILURE = '@business/fetch-business-detail-failure';

/*
    Returns the BusinessList[ { businessId, businessName, businessType }] based on perviliges.
*/
export function fetchBusinesses() {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_BUSINESSES });
            const businesses = await businessService.fetchBusinesses();
            console.log("fetchBusinesses Action", businesses);
            dispatch({
                type: FETCH_BUSINESSES_SUCCESS,
                payload: {
                    businesses
                },
            });
        } catch (error) {
            dispatch({ type: FETCH_BUSINESSES_FAILURE });
            throw error;
        }
    };
}

export function fetchBusinessDetails(customerId) {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_BUSINESS_DETAILS });
            const businessDetails = await businessService.fetchBusinessDetails(customerId);
            // console.log("fetchBusinessDetails Action", businessDetails);
            dispatch({
                type: FETCH_BUSINESS_DETAILS_SUCCESS,
                payload: {
                    businessDetails
                },
            });
        } catch (error) {
            dispatch({ type: FETCH_BUSINESS_DETAILS_FAILURE });
            throw error;
        }
    };
}
