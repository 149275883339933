class Service {
  constructor(updatedBy, info) {
    this.customerId = info.customerId;
    this.serviceId = info.serviceId;
    this.serviceName = info.serviceName;
    this.description = info.description;
    this.videoUrl = info.videoUrl;
    this.updatedBy = updatedBy;
  }
}

export default { Service };
export { Service };



