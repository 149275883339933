import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createGreeting, updateGreeting } from "src/actions/mgmtGreetingAction";
import ImageEditor from "src/components/ImageEditor";
import { IMAGE_TYPE } from "src/config";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function GreetingForm({ className, customerId, data, ...rest }) {

  const [image, setImage] = useState('')
  function handleImage(e) {
    console.log(e.target.files)
    setImage(e.target.files[0])
  }

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const addedBy = account.user.email;
  console.log("GreetingForm: data", data);

  const path1 = process.env.REACT_APP_PATH
  const imgUrl = `${path1}/${data?.ImageUrl}`;

  return (

    <Formik
      enableReinitialize
      initialValues={{
        customerId: customerId,
        greetId: data?.greetId || "",
        title: data?.Title || "",
        greetImage: data?.ImageUrl || "",
        videoUrl: data?.VideoUrl || "",
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(50).required("Title is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        //console.log("Submitting the request", businessId, addedBy, values);
        try {

          if (data) {
            await dispatch(updateGreeting(addedBy, values, ''));
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar("Greeting Updated Successfully", {
              variant: "success",
            });
          } else {
            await dispatch(createGreeting(addedBy, values,image));
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar("Greeting Created Successfully", {
              variant: "success",
            });
          }


          navigate("/app/management/greetings/view");
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Failed to create greetings", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Grid container spacing={3} padding={2}>
            {/* <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.customerId && errors.customerId
                    )}
                    fullWidth
                    helperText={
                      touched.customerId && errors.customerId
                    }
                    label="Customer Id"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                  />
                </Grid> */}

            <Grid item xs={12}>
              <TextField
                error={Boolean(
                  touched.title && errors.title
                )}
                fullWidth
                helperText={
                  touched.title && errors.title
                }
                label="Title"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Greeting Image</Typography>
              {data ? (
                <Paper
                  sx={{
                    width: 500,
                    height: 320,
                    backgroundSize: "cover",
                    mb: 1,
                  }}
                >

                  <ImageEditor type={IMAGE_TYPE.GREETINGIMAGE} inImage={imgUrl} customerId={customerId} id={values.greetId} />
                </Paper>
              ) : (
                <TextField
                  error={Boolean(
                    touched.greetImage && errors.greetImage
                  )}
                  fullWidth
                  helperText={
                    touched.greetImage && errors.greetImage
                  }
                  type="file"
                  name="greetImage"
                  onBlur={handleBlur}
                  onChange={handleImage}
                  value={values.image}
                  variant="outlined"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(
                  touched.videoUrl && errors.videoUrl
                )}
                fullWidth
                helperText={
                  touched.videoUrl && errors.videoUrl
                }
                label="Video Url"
                name="videoUrl"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.videoUrl}
                variant="outlined"
              />
            </Grid>
          </Grid>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2} padding={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              style={{ marginBottom: '5em' }}
            >
              Submit
            </Button>
            <Button style={{ marginLeft: '1em', marginBottom: '5em' }}
              color="secondary" variant='contained'
              component={RouterLink}
              to="/app/management/greetings/view"
            >
              Cancel
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

GreetingForm.propTypes = {
  className: PropTypes.string,
  customerId: PropTypes.number,
};

export default GreetingForm;