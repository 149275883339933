import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function UserProfile() {
  const classes = useStyles();
  const account = useSelector((state) => state.account);
console.log("account",account)
  if (!account) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="User Profile"
    >
      <Container maxWidth={false}>
        <Grid>
          <Card style={{ maxWidth: '270px', maxHeight: '400px' }}>
            <CardHeader title="User Profile" />
            <Divider />
            <CardContent>
              <Grid className="row" container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Name"
                    name={`${account.user.name}`}
                    value={`${account.user.name}`}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Email"
                    name={`${account.user.email}`}
                    value={`${account.user.email}`}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Mobile"
                    name={`${account.user.mobile}`}
                    value={`${account.user.mobile}`}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="userCategory"
                    name={`${account.user.userCategory}`}
                    value={`${account.user.userCategory}`}
                    disabled
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
}

export default UserProfile;
