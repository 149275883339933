export const dateFormatter = (dbFormatDate) => {
    let formatedDate = dbFormatDate;
    const options = {
        // weekday: 'short',
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        // hour: 'numeric', // minute: 'numeric', // timeZone: 'Asia/Kolkata',
    };

    if (typeof dbFormatDate === "string") {
        formatedDate = new Date(dbFormatDate).toLocaleString("en-IN", options);
    }
    return formatedDate;
};