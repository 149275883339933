import axios from 'src/utils/axios';

class MgmtLicense {

  createLicense = (newLicense,cusId) => new Promise((resolve, reject) => {

    const payload = { customerId: cusId, fromDate: newLicense.fromDate, toDate: newLicense.toDate,
      remarks: newLicense.remarks }
    // console.log("payload",payload);
    axios.post('/api/admin/customer/add-update-expiry', payload)
      .then((response) => {
        // console.log("MgmtService(Response):" + JSON.stringify(response));
        if (response.data) {
          let services = response.data;
          resolve(services);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })


}




const mgmtLicense = new MgmtLicense();

export default mgmtLicense;
