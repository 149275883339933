import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from './Header';
// import Results from './Result';
import { fetchOrder } from 'src/actions/mgmtOrderAction';
import { fetchOrders, fetchOrdersByCriteria } from 'src/actions/orderActions';
import MperitoCard from 'src/components/MperitoCard';
import OrderDetails from './OrderDetails';
// import SearchForm from './SearchForm';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function ViewOrder() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {state} = useLocation();
  const [orderDetails, setOrderDetails] = useState(undefined);

  const businessInfo = useSelector((state) => state?.app?.businessInfo);
  const customerId = businessInfo?.customerId;

  const [criteria, setCriteria] = useState({customerId: customerId, orderId: state?.orderId});

  console.log("View Order: ", state, criteria);

  useEffect(() => {
    async function getOrders(customerId){
      const ordersList = await dispatch(fetchOrdersByCriteria(customerId,criteria));
      console.log("Fetch Order: ", ordersList);
      setOrderDetails((ordersList?.length >0 )? ordersList[0] : undefined);
      console.log("Fetch Order View", orderDetails);
    }
    getOrders(customerId);

  }, [customerId, criteria]);


  // useEffect(()=> {

  // }, [orderDetails]);


  return (
    <Page
      className={classes.root}
      title="View Order"
    >
      <Container maxWidth>
        <Header />        
        <br />        
        <br />
        <MperitoCard title="Orders Details">
          <OrderDetails orderDetails={orderDetails} />
        </MperitoCard>       
      </Container>
    </Page>

  );
}

export default ViewOrder;
