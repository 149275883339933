import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ORDER_STATUS_TYPE, USER_TYPE } from "src/utils/constants";
import { createDistributorType } from "src/actions/mgmtDistributorTypeAction";



const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function DistributorCrateForm({ className, state,customer1,customer,customerList, ...rest }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const addedBy = account.user.email;
  const [id, setId] = React.useState('');
  // console.log("age", id)
  const handleChange1 = (event) => {
    setId(event.target.value);
  };
  // console.log("ServiceCreateForm: addedBy", addedBy);

  return (

    <Formik
      initialValues={{
        customerId:customer1 || '',
        category: "",
        discount: "",
      }}
      validationSchema={Yup.object().shape({

      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        //console.log("Submitting the request", businessId, addedBy, values);
        try {
          await dispatch(createDistributorType(values,id,customer));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Distributor Type Created", {
            variant: "success",
          });
          navigate("/app/management/distributor/type");
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Distributor Type Create failed", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Create Distributor Type" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                
            {
             customer !== "ADMIN" ? 
           
            <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <div>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select CustomerId</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    // id="demo-simple-select"
                    label="Select CustomerId"
                    onChange={handleChange1}
                  >
                    {customerList.map((cities) => (
                      <MenuItem value={cities.customerId}>
                
                        {cities.customerId}
                        {"..."}
                        {cities.businessName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </FormControl>
          </Grid>
         
       
    : 
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.customerId && errors.customerId
                    )}
                    fullWidth
                    helperText={
                      touched.customerId && errors.customerId
                    }
                    label="Customer Id"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                disabled
                  />
                </Grid>

                  }
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.category && errors.category
                    )}
                    fullWidth
                    helperText={
                      touched.category && errors.category
                    }
                    label="Category"
                    name="category"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.category}
                    variant="outlined"
                    
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.discount && errors.discount
                    )}
                    fullWidth
                    helperText={
                      touched.discount && errors.discount
                    }
                    label="Discount"
                    name="discount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.discount}
                    variant="outlined"
                    
                  />
                </Grid>
              
              </Grid>
            </CardContent>
          </Card>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

DistributorCrateForm.propTypes = {
  className: PropTypes.string,
};

export default DistributorCrateForm;


