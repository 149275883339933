import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 5,
        justifyContent: 'flex-start',
        width: '50%'
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
        fontSize: 12,
        fontStyle: 'bold',
        color: '#3778C2',
    },
    label: {
        width: 120,
        alignItems: 'left',
    },
    label1: {
        width: 100,
        alignItems: 'left',
        fontSize: 12,
        fontStyle: 'bold',
        // marginTop:5
    },
    invoiceDateContainer1: {
        flexDirection: 'row',
        // justifyContent: 'flex-end',
        alignItems: 'right',
    },
});

const ShipTo = ({ invoice }) => {
    // console.log(`ShipTo() :: invoice: `, invoice);
    const name = invoice?.shipTo?.address?.name ?? "";
    const addressLine1 = invoice?.shipTo?.address?.addressLine1 ?? "";
    const addressLine2 = invoice?.shipTo?.address?.addressLine2 ?? "";
    const city = invoice?.shipTo?.address?.city ?? "";
    const country = invoice?.shipTo?.address?.country ?? "";
    const state = invoice?.shipTo?.address?.state ?? "";
    const pinCode = invoice?.shipTo?.address?.pinCode ?? "";
    console.log(`ShipTo() :: name: `, name,` addressLine1: `, addressLine1,` addressLine2: `, addressLine2,` city: `, city,` country: `, country,` state: `, state,` pinCode: `, pinCode);
    // console.log("discount",total)
    return (
        <View style={styles.headerContainer}>
            <Text style={styles.billTo}>Ship TO:</Text>
            <View style={styles.invoiceDateContainer1}>
                <Text style={styles.label}>NAME :- </Text>
                <Text >{name}</Text>
            </View >
            <View style={styles.invoiceDateContainer1}>
                <Text style={styles.label}>addressLine1:- </Text>
                <Text>{addressLine1}</Text>
            </View >
            <View style={styles.invoiceDateContainer1}>
                <Text style={styles.label}>addressLine2:- </Text>
                <Text>{addressLine2}</Text>
            </View >
            <View style={styles.invoiceDateContainer1}>
                <Text style={styles.label}>City:- </Text>
                <Text>{city}</Text>
            </View >
            <View style={styles.invoiceDateContainer1}>
                <Text style={styles.label}>Country:- </Text>
                <Text>{country}</Text>
            </View >
            <View style={styles.invoiceDateContainer1}>
                <Text style={styles.label}>State:- </Text>
                <Text>{state}</Text>
            </View >
            <View style={styles.invoiceDateContainer1}>
                <Text style={styles.label}>Pin Code:- </Text>
                <Text>{pinCode}</Text>
            </View >
        </View>
    )
};

export default ShipTo;