class Category {
  constructor(updatedBy, info) {
    this.customerId = info.customerId;
    this.name = info.name;
    this.parentCategory = info.parentCategory;
    this.description = info.description;
    this.categoryImage = info.categoryImage;
    this.updatedBy = updatedBy;
  }
}

export default { Category };
export { Category };



