import axios from 'src/utils/axios';

class MgmtDashboard {

  fetchFinancialData = (cusId) => new Promise((resolve, reject) => {
    axios
      .get(`/api/dashboard/order/financial/year/order-sales?customerId=${cusId}`)
      .then((response) => {
        // console.log("MgmtUser(fetchUsers->Response): 1", response);
        if (response?.data) {
          const nodata = []
          const greeting = (response?.data?.payload) ? response.data.payload : nodata;
          resolve(greeting);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  financialTodaySalesAndOrdersData = (cusId) => new Promise((resolve, reject) => {
    axios
      .get(`/api/dashboard/order/today/order-sales?customerId=${cusId}`)
      .then((response) => {
        // console.log("MgmtfinancialTodaySalesAndOrdersData(fetchfinancialTodaySalesAndOrdersData->Response): 1", response);
        if (response?.data) {
          const nodata = []
          const financialTodaySalesAndOrdersData = (response?.data?.payload) ? response.data.payload : nodata;
          resolve(financialTodaySalesAndOrdersData);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtfinancialTodaySalesAndOrdersData. (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  fetchweeklygraphData = (cusId) => new Promise((resolve, reject) => {
    axios
      .get(`/api/dashboard/order/week/day-wise/order-sales?customerId=${cusId}`)
      .then((response) => {
        console.log("MgmtfinancialTodaySalesAndOrdersData(fetchfinancialTodaySalesAndOrdersData->Response): 1", response);
        if (response?.data) {
          const nodata = []
          const fetchweeklygraphData = (response?.data?.payload) ? response.data.payload : nodata;
          resolve(fetchweeklygraphData);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtfinancialTodaySalesAndOrdersData. (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  fetchmonthlygraphData = (cusId) => new Promise((resolve, reject) => {
    axios
      .get(`/api/dashboard/order/month/day-wise/order-sales?customerId=${cusId}`)
      .then((response) => {
        console.log("fetchmonthlygraphData(fetchfinancialTodaySalesAndOrdersData->Response): 1", response);
        if (response?.data) {
          const nodata = []
          const fetchmonthlygraphData = (response?.data?.payload) ? response.data.payload : nodata;
          resolve(fetchmonthlygraphData);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtfinancialTodaySalesAndOrdersData. (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  fetchProductTrendData = (cusId) => new Promise((resolve, reject) => {
    axios
      .get(`/api/admin/order/month/day-wise/product-trend?customerId=${cusId}`)
      .then((response) => {
        console.log("fetchproductTrendData(fetchproductTrendData->Response): 1", response);
        if (response?.data) {
          const nodata = []
          const fetchproductTrendData = (response?.data?.payload?.orders) ? response.data.payload.orders : nodata;
          resolve(fetchproductTrendData);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtfinancialTodaySalesAndOrdersData. (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  fetchMonthProductTrendData = (cusId) => new Promise((resolve, reject) => {
    axios
      .get(`/api/admin/order/month/product-trend?customerId=${cusId}`)
      .then((response) => {
        console.log("fetchproductTrendData(fetchproductTrendData->Response): 1", response);
        if (response?.data) {
          const nodata = []
          const fetchproductTrendData = (response?.data?.payload?.orders) ? response.data.payload.orders : nodata;
          resolve(fetchproductTrendData);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtfinancialTodaySalesAndOrdersData. (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

}

const mgmtdashboard = new MgmtDashboard();

export default mgmtdashboard;
