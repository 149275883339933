import { FETCH_BUSINESS_LIST } from 'src/constants';
import { BUSINESS_DETAILS_URL } from 'src/constants/services';
import axios from 'src/utils/axios';

class BusinessService {

  fetchBusinesses = () => new Promise((resolve, reject) => {
    axios
      .get(FETCH_BUSINESS_LIST)
      .then((response) => {
        // console.log("MgmtUser(fetchUsers->Response): 1", response);
        if (response.data) {
          let businesses = response.data?.data?.customerList;
          // console.log("MgmtUser(fetchUsers)", users);
          resolve(businesses);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })


  fetchBusinessDetails = ( customerId) => new Promise((resolve, reject) => {
    axios
      .get(`${BUSINESS_DETAILS_URL}?customerId=${customerId}`)
      .then((response) => {
        // console.log("MgmtUser(fetchUsers->Response): 1", response);
        if (response.data) {
          let business = response.data?.data;
          // console.log("MgmtUser(fetchUsers)", users);
          resolve(business);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

}

const businessService = new BusinessService();
export default businessService;
