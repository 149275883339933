import produce from "immer";
import { FETCHMONTHLYGRAPH_FETCH_SUCCESS, FETCHPRODUCTGRAPH_FETCH_SUCCESS, FETCHPRODUCTMONTHGRAPH_FETCH_SUCCESS, FETCHWEEKLYGRAPH_FETCH_SUCCESS, FINANCIALINFO_FETCH_SUCCESS,FINANCIALTODAYSALESANDORDERS_FETCH_SUCCESS } from "src/actions/mgmtDashBoardAction";

const initialState = {
    financialData: [],
    financialTodaySalesAndOrdersData: [],
    fetchweeklygraphData:[],
    fetchMonthlygraphData:[],
    fetchProductTrendData:[],
    fetchMonthProductTrend:[],
};

const financialDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FINANCIALINFO_FETCH_SUCCESS: {
            const { financialData } = action.payload;
            return produce(state, (draft) => {
                draft.financialData = financialData;
            });
        }
        case FINANCIALTODAYSALESANDORDERS_FETCH_SUCCESS: {
            const { financialTodaySalesAndOrdersData } = action.payload;
            return produce(state, (draft) => {
                draft.financialTodaySalesAndOrdersData = financialTodaySalesAndOrdersData;
            });
        }
        case FETCHWEEKLYGRAPH_FETCH_SUCCESS: {
            const { fetchweeklygraphData } = action.payload;
            return produce(state, (draft) => {
                draft.fetchweeklygraphData = fetchweeklygraphData;
            });
        }
        case FETCHMONTHLYGRAPH_FETCH_SUCCESS: {
            const { fetchMonthlygraphData } = action.payload;
            return produce(state, (draft) => {
                draft.fetchMonthlygraphData = fetchMonthlygraphData;
            });
        }
        case FETCHPRODUCTGRAPH_FETCH_SUCCESS: {
            const { fetchProductTrendData } = action.payload;
            return produce(state, (draft) => {
                draft.fetchProductTrendData = fetchProductTrendData;
            });
        }
        case FETCHPRODUCTMONTHGRAPH_FETCH_SUCCESS: {
            const { fetchMonthProductTrend } = action.payload;
            return produce(state, (draft) => {
                draft.fetchMonthProductTrend = fetchMonthProductTrend;
            });
        }
        default: {
            return state;
        }
    }
};

export default financialDataReducer;


