import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  Button,
  CardContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { fetchUsers } from 'src/actions/idActions';
import Results from './Result';
import { fetchCategory, fetchUsers5 } from 'src/actions/mgmtSelectValueAction';
import { fetchAttribute } from 'src/actions/mgmtAttibuteAction';
import { fetchAdminList } from 'src/actions/mgmtAdminAction';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function AdminView() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const businessInfo = useSelector((state) => state?.app?.businessInfo);
  const adminList = useSelector((state) => state?.admin?.adminList);

  // const [id, setId] = React.useState('');

  // const customer15 = useSelector(
  //   (state) => state?.user?.users1
  // );
  // const selectedCustomerId = (id) ? id : customer15;

  // const handleChange = (event) => {
  //   setId(event.target.value);
  //   dispatch(fetchUsers5(event.target.value));
  //   dispatch(fetchCategory(event.target.value));
  //   dispatch(fetchAttribute(event.target.value));
  //   dispatch(fetchAdminList(event.target.value));
  // };

  // const customerIdList = useSelector(
  //   (state) => state?.user?.users?.customerList
  // );

  // const userTy = useSelector(
  //   (state) => state?.account?.user?.userType
  // );

  // const userType = userTy

  // const customer1 = useSelector(
  //   (state) => state?.account?.user?.customerId
  // );

  

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  // const cusId = (userType === "SUPER_ADMIN") ? selectedCustomerId : customer1;

  useEffect(() => {
    dispatch(fetchAdminList(businessInfo?.customerId));
  }, [businessInfo]);

  // if (!customerIdList) {
  //   return null;
  // }

  return (
    <Page
      className={classes.root}
      title="Admin List"
    >
      <Container maxWidth={false}>
        <Header />
        <br />
        {/* {
          userType !== "ADMIN" ?
            <Card style={{ maxWidth: '1200px', maxHeight: '400px' }}>
              <CardContent>
                <Grid container spacing={2}> <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Customer Id *</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          // id="demo-simple-select"
                          label="Select Customer Id *"
                          onChange={handleChange}
                          value={selectedCustomerId}
                        >
                          {customerIdList.map((idList) => (
                            <MenuItem value={idList.customerId}>

                              {idList.customerId}
                              {"..."}
                              {idList.businessName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FormControl>
                </Grid>
                </Grid>
              </CardContent>
            </Card> :
            <Box />
          //  <Results2 services={attributeInfo} setServices={setServices}/>
          //   <Grid item xs={12}>
          //   <TextField
          //    fullWidth
          //     label="Customer Id"
          //     name="customerId"
          //     onChange={handleChange}
          //     value={customer1}
          //     variant="outlined"
          //   />
          // </Grid>
        } */}
        <br />
        <Results adminList={adminList} customerId={businessInfo?.customerId} />
      </Container>
    </Page>
  );
}

export default AdminView;
