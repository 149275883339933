import React, { useState } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,  
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { updateOrderStatus } from "src/actions/orderActions";


const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));


function UpdateDelivered({ customerId, orderId, action, className, ...rest }) {
  const classes = useStyles();

  const account = useSelector((state) => state.account);

  const addedBy = account.user.email;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (

    <Formik
      initialValues={{
        customerId: customerId,
        orderId: orderId,
        status: "DELIVERED",
        comment: "",
      }}
      validationSchema={Yup.object().shape({
        // paymentType: Yup.string().max(20).required("Select Payment Type"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await dispatch(updateOrderStatus(action, addedBy, values));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Update Delivery Status Successfully", {
            variant: "success",
          });
          navigate("/app/management/order/search");

        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Failed to Update Delivery Status", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Grid container spacing={3} padding={2}>
            <Grid item xs={12}>
              <Typography>Updating Order Delivery Status</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(
                  touched.comment && errors.comment
                )}
                fullWidth
                helperText={
                  touched.comment && errors.comment
                }
                label="Comments (Delivery details)"
                name="comment"
                onBlur={handleBlur}
                onChange={handleChange}
                multiline
                rows={4}
                value={values.comment}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} >

            {errors.submit && (
            <Box mt={3} >
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2} >
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              style={{ marginBottom: '5em' }}
            >
              Confirm Order Delivered
            </Button>
            <Button style={{ marginLeft: '1em', marginBottom: '5em' }}
              color="secondary" variant='contained'
              component={RouterLink}
              to="/app/management/order/search"
            >
              Cancel
            </Button>
          </Box>
          </Grid>

          </Grid>

          
        </form>
      )}
    </Formik>
  );
}

UpdateDelivered.propTypes = {
  className: PropTypes.string,
  customerId: PropTypes.number,
  orderId: PropTypes.number,    
};

export default UpdateDelivered;