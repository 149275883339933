
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalCase } from 'change-case';
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  IconButton,
  Popover,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import BusinessIcon from '@mui/icons-material/Business';
import { changeBusiness } from 'src/actions/settingsAction';

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 400,
    padding: theme.spacing(2)
  }
}));

function Business() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = useRef(null);

  const businesses = useSelector((state) => state?.app?.businesses);
  const businessInfo = useSelector((state) => state?.app?.businessInfo);
  const [selectedCustomerId, setSelectedCustomerId] = useState(businessInfo);
  // console.log("Business: businesses: ", businesses);
  // console.log("Business: businessInfo: ", businessInfo);

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCustomer = (event, newValue) => {
    setSelectedCustomerId(newValue);

    if (event) {
      // dispatch(changedCustomerId(newValue?.customerId));
    }
  };

  // Custom filter function to match the input value against businessName.
  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) =>
      option?.businessName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleSave = async () => {
    // console.log("changeBusiness: ", selectedCustomerId);
    dispatch(changeBusiness(selectedCustomerId));
    setOpen(false);
  };


  useEffect(() => {
    
  }, [businesses, businessInfo]);


  return (
    <>
      <Tooltip title="Business Selection">
        <Badge
          color="secondary"
          variant="dot"
          classes={{ badge: classes.badge }}
        >
          <IconButton
            color="inherit"
            onClick={handleOpen}
            ref={ref}
          >
            <SvgIcon fontSize="small">
              <BusinessIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography
          variant="h4"
          color="textPrimary"
        >
          Select Business
        </Typography>

        <Box mt={2}>
          <Autocomplete
            id="customer-id-autocomplete"

            options={businesses}

            getOptionLabel={(option) => option.customerId + "..." + option.businessName}
            fullWidth
            value={selectedCustomerId}
            onChange={handleChangeCustomer}
            filterOptions={filterOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Business"
                variant="outlined"

              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                {option.customerId} ... {option.businessName}
              </li>
            )}
          />

        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            Change Business
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default Business;
