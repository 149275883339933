import React, {
  useEffect,
  useState,
} from 'react';
import {
  Autocomplete,
  Box,
  Container,
  Grid,
  Card,
  Divider,
  Paper,
  Typography,
  TextField,
  CardContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { fetchUsers } from 'src/actions/idActions';
import DashboardInfo from './dashBoardSub';
import SuperAdminDashboardInfo from './superAdminDashboard';
import { fetchUserData } from 'src/actions/accountActions';
import { fetchCategory, fetchUsers5 } from 'src/actions/mgmtSelectValueAction';
import { fetchOrder } from 'src/actions/mgmtOrderAction';
import { fetchProductDetailsForDashboard } from 'src/actions/mgmtProductAction';
import { fetchMonthProductTrendData, fetchMonthlygraphData, fetchProductTrendData1, fetchweeklygraphData } from 'src/actions/mgmtDashBoardAction';
import { Bar } from 'react-chartjs-2';

const options = {
  indexAxis: 'x',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'center',
    },
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function Info() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  const handleChangeCustomer = (event, newValue) => {
    setSelectedCustomerId(newValue);
    // console.log("FinancialDashboard: handleChangeCustomer() : customerId: ", newValue?.customerId, JSON.stringify(newValue));
    setId(newValue?.customerId);

    if (event) {
      dispatch(fetchUsers5(newValue?.customerId));
      dispatch(fetchUserData(newValue?.customerId));
      dispatch(fetchCategory(newValue?.customerId));
      dispatch(fetchOrder(newValue?.customerId));
      dispatch(fetchProductDetailsForDashboard(newValue?.customerId, customerId, customerType));
      dispatch(fetchweeklygraphData(newValue?.customerId, customerId, customerType));
      dispatch(fetchMonthlygraphData(newValue?.customerId, customerId, customerType));
      dispatch(fetchProductTrendData1(newValue?.customerId, customerId, customerType));
      dispatch(fetchMonthProductTrendData(newValue?.customerId, customerId, customerType));

    }
  };

  // Custom filter function to match the input value against businessName.
  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) =>
      option.businessName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };


  const fetchProductTrendData = useSelector(
    (state) => state?.financialData?.fetchProductTrendData
  )

  const fetchProductMonthTrendData = useSelector(
    (state) => state?.financialData?.fetchMonthProductTrend
  )

  const [id, setId] = React.useState('');

  const customerId = useSelector(
    (state) => state?.account?.user?.customerId
  );

  const customerType = useSelector(
    (state) => state?.account?.user?.userType
  );

  const [weekdata, setWeekData] = useState({
    datasets: [4, 1, 5, 4,
    ],
  });

  const fetchProductTrend = async () => {
    const dataSet1 = [];
    const dataSet2 = [];
    const dataSet8 = [];

    for (const val of fetchProductTrendData) {
      dataSet1.push(val.totalQuantity);
      dataSet2.push(val._id.date)
    }
    setWeekData({
      labels: dataSet2,
      datasets: [
        {
          label: 'Total Quantity',
          data: dataSet1,
          borderColor: 'rgb(28,119,171)',
          backgroundColor: 'rgb(28,119,171)',
        },
      ],
    })
  }

  const [monthdata, setMonthData] = useState({
    datasets: [4, 1, 5, 4,
    ],
  });

  const fetchProductTrendMonthyData = async () => {
    const dataSet1 = [];
    const dataSet2 = [];
    const dataSet8 = [];

    for (const val of fetchProductTrendData) {
      dataSet1.push(val.totalQuantity);
      dataSet2.push(val._id.date)
    }
    setMonthData({
      labels: dataSet2,
      datasets: [
        {
          label: 'Total Quantity',
          data: dataSet1,
          borderColor: 'rgb(28,119,171)',
          backgroundColor: 'rgb(28,119,171)',
        },
      ],
    })
  }

  const handleChange = (event) => {
    setId(event.target.value);
    if (event) {
      dispatch(fetchUsers5(event.target.value));
      dispatch(fetchUserData(event.target.value));
      dispatch(fetchCategory(event.target.value));
      dispatch(fetchOrder(event.target.value));
      dispatch(fetchProductDetailsForDashboard(event.target.value, customerId, customerType));
      dispatch(fetchweeklygraphData(event.target.value, customerId, customerType));
      dispatch(fetchMonthlygraphData(event.target.value, customerId, customerType));
      dispatch(fetchProductTrendData1(event.target.value, customerId, customerType));
      dispatch(fetchMonthProductTrendData(event.target.value, customerId, customerType));
    }
  };

  const customerIdList = useSelector(
    (state) => state?.user?.users?.customerList
  );

  const userTy = useSelector(
    (state) => state?.account?.user?.userType
  );

  const userType = userTy
  const cusId = (userTy === "SUPER_ADMIN") ? id : customerId;

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    dispatch(fetchweeklygraphData(customerId));
  }, []);

  useEffect(() => {
    dispatch(fetchMonthlygraphData(customerId));
  }, []);

  useEffect(() => {
    dispatch(fetchProductTrendData1(customerId));
  }, []);

  useEffect(() => {
    dispatch(fetchMonthProductTrendData(customerId));
  }, []);

  useEffect(() => {
    dispatch(fetchProductDetailsForDashboard(customerId));
  }, []);

  useEffect(() => {
    fetchProductTrend();
    fetchProductTrendMonthyData()
  }, [fetchProductTrendData, fetchProductMonthTrendData]);

  useEffect(() => {
    dispatch(fetchUserData(customerId));
  }, []);

  useEffect(() => {
    dispatch(fetchUserData(customerId));
  }, []);

  useEffect(() => {
    dispatch(fetchCategory(customerId));
  }, []);

  useEffect(() => {
    dispatch(fetchOrder(customerId));
  }, []);

  if (!customerIdList) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Dashboard View"
    >
      <Container maxWidth={false}>
        {
          userType !== "ADMIN" && userType !== "SUPPORT" ?
            <div> <SuperAdminDashboardInfo /></div>
            : <Box></Box>}
        {
          userType !== "ADMIN" && userType !== "SUPPORT" ?
            <Card style={{ marginLeft: "0.5em", maxWidth: '360px', maxHeight: '400px', marginTop: "1em" }}>
              <CardContent>
                <Grid container spacing={2}> <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="customer-id-autocomplete"
                      options={customerIdList}
                      getOptionLabel={(option) => option.customerId + "..." + option.businessName}                      
                      fullWidth
                      value={selectedCustomerId}
                      onChange={handleChangeCustomer}
                      filterOptions={filterOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select CustomerId"
                          variant="outlined"
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          {option.customerId} ... {option.businessName}
                        </li>
                      )}
                    />
                  </FormControl>


                  {/* <FormControl fullWidth>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select CustomerId</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          label="Select CustomerId"
                          onChange={handleChange}
                        >
                          {customerIdList.map((idlist) => (
                            <MenuItem value={idlist.customerId}>
                              {idlist.customerId}
                              {"..."}
                              {idlist.businessName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FormControl> */}
                </Grid>
                </Grid>
              </CardContent>
            </Card> :
            <DashboardInfo idd={id} />
        }
        {
          id > 0 ?
            <DashboardInfo /> :
            <Box />
        }
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} md={6} >

            {
              cusId > 0 ?
                <Card component={Paper} style={{ marginTop: "2em", width: 572 }}>
                  <CardContent>
                    <Typography variant='h6' className={classes.cardTitle} align='left'>
                      Weekly Product Trend
                    </Typography>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Bar data={weekdata} options={options} />
                  </CardContent>
                </Card> :
                <Box />}

          </Grid>
          <Grid item xs={12} sm={7} md={6} >
            {
              cusId > 0 ?
                <Card component={Paper} style={{ marginTop: "2em", width: 572 }}>
                  <CardContent>
                    <Typography variant='h6' className={classes.cardTitle} align='left'>
                      Monthly Product Trend
                    </Typography>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Bar data={monthdata} options={options} />
                  </CardContent>
                </Card> :
                <Box />
            }
          </Grid>
        </Grid>

      </Container>
    </Page>
  );
}

export default Info;


