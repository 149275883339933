import { lazy } from 'react';

import Loadable from 'src/components/Loadable';
import MinimalLayout from 'src/layouts/MinimalLayout';

// project imports
const ErrorPage = Loadable(lazy(() => import("src/views/pages/Error404View")));


// ==============================|| AUTHENTICATION ROUTING ||============================== //

const ErrorRoutes = {
    path: '*',
    element: <MinimalLayout />,
    children: [
       {
          path: '*',
          element: <ErrorPage />
        }
        
    ]
};

export default ErrorRoutes;
