import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { makeStyles } from '@mui/styles';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Results2 from './Result';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function OemList({ cid }) {

  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [services2, setServices2] = useState(null);

  const getServices2 = useCallback(() => {
    axios
      .get(`/api/customer/details?customerId=${cid}`) //, {})
      .then((response) => {
        if (isMountedRef.current) {
          // console.log("getsinglecustomer =>" + JSON.stringify(response));
          if (response?.data?.data) {
            // setProject(response.data.data.projects);
            setServices2(response.data.data);
          } else {
            setServices2([]);
          }
        }
      });
  }, [isMountedRef]);

  useEffect(() => {
    getServices2();
  }, [getServices2]);

  if (!services2) {
    return null;
  }

  return (
    <div>
      <Typography>Oem Orders List</Typography>
      <Results2 ccid={cid} services2={services2} />
    </div>
  );
}

export default OemList;
