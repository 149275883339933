import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles'

import Page from 'src/components/Page';
import Header from './Header';
// import ServiceUpdate from './CustomerEditForm';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import CustomerCreateForm from './CustomerCreateForm';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function CustomerCreate({ match, location }) {
  // const {state} = useLocation();
  // console.log("STATE",state);

  // // let params = useParams();
  // const serviceId = state.serviceId;
  // console.log("ServiceEditView : ", serviceId);

  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  // const [service, setService] = useState();
  const account = useSelector(state => state.account);
 
  // const getService = useCallback(() => {
    
  //     setService(state)
  // }, [isMountedRef]);


  // useEffect(() => {
  //   getService();
  // }, [getService]);

  // if (!service) {
  //   return null;
  // }


  return (
    <Page
      className={classes.root}
      title="Register Business"
    >
      <Container maxWidth="lg">
        <Header />
        <br/>
        {/* <ServiceUpdate service={service} serviceId={serviceId}/> */}
<CustomerCreateForm/>
      </Container>
    </Page>
  );
}

export default CustomerCreate;
