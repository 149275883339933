import React from "react";
import { Container} from "@mui/material";
import Page from "src/components/Page";
import { makeStyles } from '@mui/styles';
import Header from "./Header";
import {Box} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchUsers} from "src/actions/idActions";
import ProductUploadForm from "./ProductUplaodForm";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
}));

function ProductUploadFile() {

  const classes = useStyles();

  const dispatch = useDispatch();

  const customerIdList = useSelector(
    (state) => state?.user?.users?.customerList
  );
  
  const userTy = useSelector(
    (state) => state?.account?.user?.userType
  );

  const userType = userTy

  const customerId = useSelector(
    (state) => state?.account?.user?.customerId
  );

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  if (!customerIdList) {
    return null;
  }

  return (
    <Page className={classes.root} title="Product Upload">
      <Container maxWidth={false} className={classes.container}>
        <Header />
        <Box sx={{ flexDirection: "row", display: "flex" }}>
          <ProductUploadForm customerIdList={customerIdList} userType={userType} customerId={customerId}/>
        </Box>
      </Container>
    </Page>
  );
}

export default ProductUploadFile;

