import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    Hail as HailIcon,
    Inventory as InventoryIcon,
    Group as GroupIcon,
    MiscellaneousServices as MiscellaneousServicesIcon
} from "@mui/icons-material";
import { useSelector } from 'react-redux';

import SummaryCard from "src/views/dashboards/components/SummaryCard";

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: '100%',
    },
  
  }));


function SummarySection({ }) {
    const classes = useStyles();

    const orderCount = useSelector((state) => state?.order?.orders?.length);    
    const productCount = useSelector((state) => state?.searchBusiness?.productInfo?.length)
    const categoryCount = useSelector((state) => state?.user?.category?.length);
    const userCount = useSelector((state) => state?.user?.userData?.length);

    const overViewData = [
        {
            id: 1,
            title: "Users",
            total: userCount,
    
            icon: <GroupIcon sx={{ color: "white" }} />,
        },
    
        {
            id: 2,
            title: "Products",
            total: productCount,
            icon: <InventoryIcon sx={{ color: "white" }} />,
        },
        {
            id: 3,
            title: "Categories",
            total: categoryCount,
            icon: <HailIcon sx={{ color: "white" }} />,
        },
        {
            id: 4,
            title: "Orders",
            total: orderCount,
            icon: <MiscellaneousServicesIcon sx={{ color: "white" }} />,
        },
    ];





    console.log("SummarySection: ", overViewData);

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0}
            marginTop={3}
            marginLeft={1}
            marginRight={1}
        >
            {overViewData.map((item => (
                <Grid item xs={12} md={3} >
                    <SummaryCard title={item.title} total={item.total} imageIcon={item.icon} />
                </Grid>
            )))
            }

        </Grid>

    );
}

export default SummarySection;