import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from './Header';
// import Results from './Result';

import { fetchInvoicesByCriteria } from 'src/actions/orderActions';
import MperitoCard from 'src/components/MperitoCard';
import InvoiceDetails from './InvoiceDetails';
// import SearchForm from './SearchForm';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function ViewInvoice() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {state} = useLocation();
  const [orderDetails, setOrderDetails] = useState(undefined);

  const businessInfo = useSelector((state) => state?.app?.businessInfo);
  const businessLogoUrl = useSelector((state) => state?.app?.businessLogoUrl);
  const customerId = businessInfo?.customerId;

  const [criteria, setCriteria] = useState({customerId: customerId, invoiceId: state?.invoiceId});

  console.log("View Order: ", state, criteria, businessLogoUrl);

  useEffect(() => {
    async function getInvoice(customerId){
      const ordersList = await dispatch(fetchInvoicesByCriteria(customerId,criteria));
      console.log("Fetch Order: ", ordersList);
      setOrderDetails((ordersList?.length >0 )? ordersList[0] : undefined);
      console.log("Fetch Order View", orderDetails);
    }
    getInvoice(customerId);

  }, [customerId, criteria]);


  // useEffect(()=> {

  // }, [orderDetails]);


  return (
    <Page
      className={classes.root}
      title="View Invoice"
    >
      <Container maxWidth>
        <Header />        
        <br />        
        <br />
        <MperitoCard title="Invoice Details">
          <InvoiceDetails orderDetails={orderDetails} logoUrl={businessLogoUrl}/>
        </MperitoCard>       
      </Container>
    </Page>

  );
}

export default ViewInvoice;
