import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Header from './Header';
import { useDispatch, useSelector } from 'react-redux';
import MperitoCard from 'src/components/MperitoCard';
import CategoryTree from './CategoryTree';
import { fetchCategory } from 'src/actions/mgmtSelectValueAction';
import CategoryForm from './CategoryForm';
import { categoryIdToString, findCategoryById } from 'src/utils/categoryUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function CategoryPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const [selectedCategoryId, setSelectedCategory] = useState(['']);
  const [selectedCategoryId, setSelectedCategory] = useState(undefined);

  const [category, setCategory] = useState(undefined);
  const [isNewCategory, setNewCategory] = useState(false);
  const [parentCategoryNames, setParentCategoryNames] = useState([]);

  const businessInfo = useSelector((state) => state?.app?.businessInfo);
  const customerId = businessInfo?.customerId;

  const categories = useSelector((state) => state?.user?.category);
  // console.log("CategoryTree categories: ", categories);

  // const newsList = useSelector((state) => state?.news?.newsList);
  
  useEffect(() => {
    dispatch(fetchCategory(customerId));
    // dispatch(fetchNews(customerId));
  }, [customerId]);

  useEffect(() => {
    // console.log("Selected Node (P): ", selectedCategoryId);
    const categoryS = findCategoryById(categories, selectedCategoryId)
    setCategory(categoryS);
    setParentCategoryNames(categoryIdToString(categories, categoryS?.ancestorsId));    

  }, [selectedCategoryId]);

  function handleAddCategory(){
    setNewCategory(true);    
  }

  function onSubmitSuccess(){
    setNewCategory(false);    
  }

  function onSubmitCancel(){
    setNewCategory(false);    
  }


  return (
    <Page
      className={classes.root}
      title="Category Management"
    >
      <Container maxWidth={false}>
        <Header />
        <Grid container spacing={2} >
            <Grid item xs={12}>
                <MperitoCard title={`Category Management ( ${businessInfo?.businessName} - ${businessInfo?.customerId} )`} />
            </Grid>
            <Grid item xs={4}>
                <MperitoCard title={"Categories"} action={{label:"Add Category", callback: handleAddCategory}}>
                    <CategoryTree categories={categories} setSelectedCategory={setSelectedCategory}/>    
                </MperitoCard>
            </Grid>
            <Grid item xs={8}>
                {/* <MperitoCard title={`Details : ${selectedCategoryId} `} > */}
                <MperitoCard title={isNewCategory ? "Adding New Category" : "View/Update Selected Category"} >
                    <CategoryForm customerId={customerId} categoryInfo={category} parentCategoryNames={parentCategoryNames} isNewCategory={isNewCategory} onSubmitSuccess={onSubmitSuccess} onSubmitCancel={onSubmitCancel}/>
                </MperitoCard>
            </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default CategoryPage;

