import React from 'react'
import { DataGrid,  } from '@mui/x-data-grid'
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

//need to change below fields as per the data
const columns = [
  { field: 'customerId', headerName: 'Customer Id', width: 100 },
  { field: 'attributeId', headerName: 'Attribute Id', width: 100 },
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'type', headerName: 'Type', width: 100 },
  { field: 'isFilterable', headerName: 'Is Filterable', width: 100 },
  { field: 'isSearchable', headerName: 'Is Searchable', width: 100 },
]

function Results({ className, attributes, ...rest }) {
  return (    
    <Grid container sx={{ height: 500, width: '100%', margin: 4, mt:2 }}>
      <DataGrid
        rows={attributes}
        columns={columns}
        getRowId={(row) => row.attributeId}
        // checkboxSelection={true}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Grid>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  attributes: PropTypes.array
};

Results.defaultProps = {
  attributes: []
};

export default Results;