import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 5,
        justifyContent: 'flex-start',
        width: '50%'
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
        fontSize: 12,
        fontStyle: 'bold',
        color: '#3778C2',
    },
    label: {
      
        alignItems: 'left',
        width: 350
    },
    label2: {
      
        alignItems: 'left',
        width: 350
    },
    label4: {
        flexDirection: 'row',
        alignItems: 'left',
        width: 300
    },
    label5: {
        alignItems: 'left',
        width: 800
    },
    label1: {
        width: 250,
        alignItems: 'left',
        fontSize: 12,
        fontStyle: 'bold',
        // marginTop:5
    },
    invoiceDateContainer1: {
        flexDirection: 'row',
        // justifyContent: 'flex-end',
        alignItems: 'right',
        width: 400,
    },
});

const ShipTo = ({ invoice }) => {
    // console.log(`ShipTo() :: invoice: `, invoice);
    const bdAppName = invoice?.businessDetails?.appName ?? "";
    const bdBusinessName = invoice?.businessDetails?.businessName ?? "";
    const bdRegisteredEmail = invoice?.businessDetails?.registeredEmail ?? "";
    const bdRegisteredAddress = invoice?.businessDetails?.registeredAddress ?? "";
    const bdGstin = invoice?.businessDetails?.gstin ?? "";
    const bdStateCode = invoice?.businessDetails?.stateCode ?? "";
    console.log(`ShipTo() :: bdAppName: `, bdAppName, ` bdBusinessName: `, bdBusinessName, ` bdRegisteredEmail: `, bdRegisteredEmail, ` bdRegisteredAddress: `, bdRegisteredAddress, ` bdGstin: `, bdGstin, ` bdStateCode: `, bdStateCode);
    
    return (
        <View style={styles.headerContainer}>
            <Text style={styles.billTo}>Business Details:</Text>
        
            <View style={styles.invoiceDateContainer1}>
                <Text style={styles.label}>App Name:- </Text>
                <Text style={styles.label5}>{bdAppName}</Text>
            </View >
            <View style={styles.invoiceDateContainer1}>
                <Text style={styles.label}>Business Name:- </Text>
                <Text style={styles.label5}>{bdBusinessName}</Text>
            </View >
            {/* <View style={styles.invoiceDateContainer1}>
                <Text style={styles.label}>Email:- </Text>
                <Text style={styles.labe4}>{bdRegisteredEmail}</Text>
            </View > */}
            <View style={styles.invoiceDateContainer1}>
                <Text style={styles.label}>Email:- :- </Text>
                <Text style={styles.label5}>{bdRegisteredEmail}</Text>
            </View >
            <View style={styles.invoiceDateContainer1}>
                <Text style={styles.label}>Address:- </Text>
                <Text style={styles.label5}>{bdRegisteredAddress}</Text>
            </View >
            {/* <View style={styles.invoiceDateContainer1}>
                <Text style={styles.label}>gstin:- </Text>
                <Text style={styles.labe5}>{bdGstin}</Text>
            </View > */}
            <View style={styles.invoiceDateContainer1}>
                <Text style={styles.label}>Gstin:-:- </Text>
                <Text style={styles.label5}>{bdGstin}</Text>
            </View >
            <View style={styles.invoiceDateContainer1}>
                <Text style={styles.label}>State Code:- </Text>
                <Text style={styles.label5}>{bdStateCode}</Text>
            </View >
        
        </View>
    )
}

export default ShipTo;