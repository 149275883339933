import produce from "immer";

import { SIGNIN_SUCCESS, SIGNOUT, SILENT_LOGIN } from "src/actions/accountActions";
import { FETCH_BUSINESSES_SUCCESS, FETCH_BUSINESS_DETAILS, FETCH_BUSINESS_DETAILS_FAILURE, FETCH_BUSINESS_DETAILS_SUCCESS } from "src/actions/businessActions";

import { CHANGE_BUSINESS } from "src/actions/settingsAction";
import { IMAGE_BASE_URL } from "src/config";



const initialState = {
    businessInfo: undefined,   // This is used for global state
    // customerId: undefined,
    // businessName: undefined,
    // isEngineeringType: false
    myBusinessId: undefined,  // This is business id for the given client
    businessDetails: undefined, // The selected business details are stored
    businessLogoUrl: undefined,
    businesses: [],
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_BUSINESS: {
            const { businessInfo } = action.payload;
            // console.log("appReducer: ChangeBusiness: ", businessInfo);
            return produce(state, (draft) => {
                draft.businessInfo = businessInfo;
            });
        }

        break;

        case SIGNIN_SUCCESS:
        case SILENT_LOGIN: {
            const { user } = action.payload;
            // console.log("appReducer: signin/silent_login", user, action.type);
            return produce(state, (draft) => {
              draft.myBusinessId = user?.customerId;
            });
          }
          break;

        case FETCH_BUSINESSES_SUCCESS:
            {
                //console.log("appReducer: businesses: ", action.payload);
                const { businesses } = action.payload;
                return produce(state, (draft) => {
                    draft.businesses = businesses ? businesses : [];
                    if (businesses && businesses.length > 0) {
                        draft.businessInfo = businesses[0];
                    }
                })
            }
            break;

        case FETCH_BUSINESS_DETAILS_SUCCESS:
            {                
                const { businessDetails } = action.payload;
                return produce(state, (draft) => {
                    const logoUrl = (businessDetails?.brandDetails?.length) ? businessDetails.brandDetails[0].logoURL : "";
                    console.log("FETCH_BUSINESS_DETAILS_SUCCESS : ", businessDetails, logoUrl);
                    draft.businessDetails = businessDetails;
                    draft.businessLogoUrl = `${IMAGE_BASE_URL}/${logoUrl}`;
                }); 
            }
            break;
        
        case FETCH_BUSINESS_DETAILS:
        case FETCH_BUSINESS_DETAILS_FAILURE:
            {
                return produce(state, (draft) => {
                    draft.businessDetails = undefined;
                })

            }


        case SIGNOUT:
            {
                return produce(state, (draft) => {
                    draft.businessInfo = undefined;
                    draft.businesses = [];
                    draft.myBusinessId = undefined;
                    draft.businessDetails = undefined;
                    draft.businessLogoUrl = undefined;

                })
            }


        default: {
            return state;
        }
    }
};

export default appReducer;


