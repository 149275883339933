import React, {
  useState,
  useEffect,
} from 'react';
import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";

import { moneyFormatter, dateFormatter } from "src/utils/formatUtils";
import { DataGrid } from "@mui/x-data-grid";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useNavigate, } from "react-router";

import { PDFDownloadLink } from '@react-pdf/renderer';
import OrderPDF from "src/views/prints/OrderA4";
import { ORDER_DOC_TYPES } from 'src/constants';



function OrderDetails({ orderDetails }) {
  const navigate = useNavigate();

  const columns = [
    { field: "_id", headerName: "Order Id", width: 90 },
    {
      field: "productName",
      headerName: "Product Name",
      width: 150,
      editable: true,
    },
    {
      field: "productSKU",
      headerName: "Product SKU",
      width: 150,
      editable: true,
    },
    {
      field: "price",
      headerName: "price",
      width: 150,
      editable: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 150,
      editable: true,
    },
    {
      field: "total",
      headerName: "Total",
      width: 150,
      editable: true,
    },
  ];

  useEffect(() => {

  }, [orderDetails]);

  // console.log("OderDetails 1: ", orderDetails);

  return (
    <Container
      // maxWidth="md"
      className="center-modal"
      sx={{ mb: 5, mt: 5, flexDirection: "column", backgroundColor: "#f5f8fc" }}
    >

      <Grid container spacing={4} sx={{ p: 3, mt: 2 }}>
        <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="outlined">
            <PDFDownloadLink
              document={<OrderPDF docType={ORDER_DOC_TYPES.ORDER} ordersData={orderDetails} />}
              //document={<OrderPDF orderDetails={orderDetails} />}              
              fileName={orderDetails?.orderId}
            >
              {({ blob, url, loading, error }) =>
                loading ? 'Loading...' : 'Download PDF'
              }
            </PDFDownloadLink>
          </Button>

        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <Card sx={{ mb: 2, p: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={4}>
                <Typography variant="subtitle2">Name :</Typography>
              </Grid>
              <Grid item xs={6} md={8}>
                <Typography>{orderDetails?.billTo?.address?.name}</Typography>
              </Grid>

              <Grid item xs={6} md={4}>
                <Typography variant="subtitle2">Bill To :</Typography>
              </Grid>

              <Grid item xs={6} md={8}>
                <Typography>
                  {orderDetails?.billTo?.address?.addressLine1} {", "}
                  {orderDetails?.billTo?.address?.addressLine2} {", "}
                  {orderDetails?.billTo?.address?.city} {", "}
                  {orderDetails?.billTo?.address?.country} {", "}
                  {orderDetails?.billTo?.address?.pinCode} <br />
                  {orderDetails?.billTo?.address?.email} <br />
                  {orderDetails?.billTo?.address?.mobile}
                </Typography>
              </Grid>

              <Grid item xs={6} md={4}>
                <Typography variant="subtitle2">Ship To :</Typography>
              </Grid>

              <Grid item xs={6} md={8}>
                <Typography>
                  {orderDetails?.shipTo?.address?.addressLine1} {", "}
                  {orderDetails?.shipTo?.address?.addressLine2} {", "}
                  {orderDetails?.shipTo?.address?.city} {", "}
                  {orderDetails?.shipTo?.address?.country} {", "}
                  {orderDetails?.shipTo?.address?.pinCode} <br />
                  {orderDetails?.shipTo?.address?.email} <br />
                  {orderDetails?.shipTo?.address?.mobile}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <Card sx={{ mb: 2, p: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={6}>
                <Typography variant="subtitle2">OrderId :</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography>{orderDetails?.orderId}</Typography>
              </Grid>

              <Grid item xs={6} md={6}>
                <Typography variant="subtitle2">Order Date :</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography>
                  {dateFormatter(orderDetails?.updatedAt)}
                </Typography>
              </Grid>

              <Grid item xs={6} md={6}>
                <Typography variant="subtitle2">Order Status :</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography>{orderDetails?.status}</Typography>
              </Grid>
            </Grid>
          </Card>

          <Card sx={{ mb: 2, p: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={6}>
                <Typography variant="subtitle2">Payment Status :</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography>{orderDetails?.status}</Typography>
              </Grid>

              <Grid item xs={6} md={6}>
                <Typography variant="subtitle2">Payment Method :</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography>{orderDetails?.paymentGateway}</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Card sx={{ p: 2 }}>
            <Box sx={{ height: 300, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row._id}
                rows={orderDetails?.items || []}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                // checkboxSelection
                disableRowSelectionOnClick
              />
            </Box>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <Card sx={{ mb: 2, p: 1, width: 400 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Sub Total </TableCell>
                  <TableCell align="right">
                    {isNaN(orderDetails?.paymentDetails?.orderAmount) ? '--' : moneyFormatter(orderDetails?.paymentDetails?.orderAmount)}

                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">CGST </TableCell>
                  <TableCell align="right">
                    {isNaN(orderDetails?.paymentDetails?.gst?.cgst) ? '--' : moneyFormatter(orderDetails?.paymentDetails?.gst?.cgst)}

                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">IGST </TableCell>
                  <TableCell align="right">

                    {isNaN(orderDetails?.paymentDetails?.gst?.igst) ? '--' : moneyFormatter(orderDetails?.paymentDetails?.gst?.igst)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">SGST </TableCell>
                  <TableCell align="right">

                    {isNaN(orderDetails?.paymentDetails?.gst?.sgst) ? '--' : moneyFormatter(orderDetails?.paymentDetails?.gst?.sgst)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">Delivery Charges</TableCell>
                  <TableCell align="right">

                    {isNaN(orderDetails?.paymentDetails?.delivery) ? '--' : moneyFormatter(orderDetails?.paymentDetails?.delivery)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">
                    <b>Total Amount </b>
                  </TableCell>
                  <TableCell align="right">
                    <b>{isNaN(orderDetails?.paymentDetails?.totalAmount) ? '--' : moneyFormatter(orderDetails?.paymentDetails?.totalAmount)}</b>

                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default OrderDetails;
