import React from 'react';
import {
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import {useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function JsonDownload() {
  const classes = useStyles();

  const customerId = useSelector(
    (state) => state?.order?.orderExport
  );

  const url = customerId.orderDataPath
  const path1 = process.env.REACT_APP_PATH
  const ur = `${path1}/${url}`
  
  function handleClick() {
    window.location.href = ur
  }

  return (
    <Page
      className={classes.root}
      title="Export Order"
    >
            <Button color="secondary"
              variant="contained" style={{ width: "300px", height: "60px", marginLeft: "0.5em", marginTop: "1em" }} onClick={handleClick}>Export Order</Button>
    </Page>
  );
}

export default JsonDownload;
