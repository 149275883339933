import React from "react";
import { Container} from "@mui/material";
import Page from "src/components/Page";
import { makeStyles } from '@mui/styles';
import Header from "./Header";
import { useLocation } from "react-router-dom";
import UserEditForm from "./UserEditForm";
import { fetchDistributorCategoryList } from "src/actions/mgmtUserAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
}));

function UserEdit() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { state } = useLocation();
  // console.log("STATE", state);

  const customerId = state.customerId

  const userTy = useSelector(
    (state) => state?.account?.user?.userType
  );

  const customer1 = useSelector(
    (state) => state?.account?.user?.customerId
  );

  useEffect(() => {
    dispatch(fetchDistributorCategoryList(customerId,userTy,customer1));
  }, []);

  const distributorCategoryList = useSelector(
    (state) => state?.user?.distributorCategory
  );
  
  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false} className={classes.container}>
        <Header />
        <UserEditForm  state={state} distributorCategoryList={distributorCategoryList}/>
      </Container>
    </Page>
  );
}

export default UserEdit;

