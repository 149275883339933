import { Greeting } from "src/interface/greetingIf";
import mgmtgreeting from "src/services/mgmtGreeting";

export const GREETINGINFO_INFO = '@greetinginfo/info';
export const GREETINGINFO_CREATE = '@greetinginfo/create';
export const GREETINGINFO_CREATE_SUCCESS = '@greetinginfo/create-success';
export const GREETINGINFO_CREATE_FAILURE = '@greetinginfo/create-failure';
export const GREETING_INFO = '@greeting/info';
export const GREETING_CREATE = '@greeting/create';
export const GREETING_CREATE_SUCCESS = '@greeting/create-success';
export const GREETING_CREATE_FAILURE = '@greeting/create-failure';
export const GREETING_UPDATE = '@greeting/update';
export const GREETING_UPDATE_SUCCESS = '@greeting/update-success';
export const GREETING_UPDATE_FAILURE = '@greeting/update-failure';


export function createGreeting(userId, greetingInfo,image) {
  return async (dispatch) => {
    console.log("createGreeting():", userId, greetingInfo);
    let newGreeting = new Greeting(userId, greetingInfo);
    newGreeting.addedBy = userId;
    console.log("createGreeting():" + JSON.stringify(greetingInfo) + " " + JSON.stringify(newGreeting));
    try {
      dispatch({ type: GREETING_CREATE });
      const greeting = await mgmtgreeting.createGreeting(newGreeting,image);
      console.log("createGreeting()(after):" + JSON.stringify(greeting));
      dispatch({
        type: GREETING_CREATE_SUCCESS,
        payload: {
          greeting
        }
      });
    } catch (error) {
      dispatch({ type: GREETING_CREATE_FAILURE });
      throw error;
    }
  };
}

export function updateGreeting(userId, greetingInfo,greetingImage) {
  return async (dispatch) => {
    // console.log("createGreeting():", userId, greetingInfo,greetingImage);
    let newGreeting = new Greeting(userId, greetingInfo);
    newGreeting.addedBy = userId;
    // console.log("createGreeting():" + JSON.stringify(greetingInfo) + " " + JSON.stringify(newGreeting));
    try {
      dispatch({ type: GREETING_CREATE });
      const greeting = await mgmtgreeting.updateGreeting(newGreeting,greetingImage);
      // console.log("createGreeting()(after):" + JSON.stringify(greeting));
      dispatch({
        type: GREETING_CREATE_SUCCESS,
        payload: {
          greeting
        }
      });
    } catch (error) {
      dispatch({ type: GREETING_CREATE_FAILURE });
      throw error;
    }
  };
}

export function fetchGreeting(cid) {

  return async (dispatch) => {
    try {
      dispatch({ type: GREETINGINFO_INFO });
      const greeting = await mgmtgreeting.fetchGreeting(cid);
      console.log("greeting Action", greeting);
      dispatch({
        type: GREETINGINFO_CREATE_SUCCESS,
        payload: {
          greeting,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: GREETINGINFO_CREATE_FAILURE });
      throw error;
    }
  };
}


