import { News } from "src/interface/newsIf";
import mgmtNews from "src/services/mgmtNews";

export const NEWS_INFO = '@news/info';
export const NEWS_CREATE = '@news/create';
export const NEWS_CREATE_SUCCESS = '@news/create-success';
export const NEWS_CREATE_FAILURE = '@news/create-failure';
export const NEWS_UPDATE = '@news/update';
export const NEWS_UPDATE_SUCCESS = '@news/update-success';
export const NEWS_UPDATE_FAILURE = '@news/update-failure';
export const NEWSINFO_INFO = '@newsinfo/info';
export const NEWSINFO_CREATE = '@newsinfo/create';
export const NEWSINFO_CREATE_SUCCESS = '@newsinfo/create-success';
export const NEWSINFO_CREATE_FAILURE = '@newsinfo/create-failure';

export function createNews(userId, newsInfo,image) {
  return async (dispatch) => {
    console.log("createNews():", userId, newsInfo);
    let newNews = new News(userId, newsInfo);
    newNews.addedBy = userId;
    console.log("createNews():" + JSON.stringify(newsInfo) + " " + JSON.stringify(newNews));
    try {
      dispatch({ type: NEWS_CREATE });
      const news = await mgmtNews.createNews(newNews,image);
      console.log("createNews()(after):" + JSON.stringify(news));
      dispatch({
        type: NEWS_CREATE_SUCCESS,
        payload: {
          news
        }
      });
    } catch (error) {
      dispatch({ type: NEWS_CREATE_FAILURE });
      throw error;
    }
  };
}
export function createNews1(userId, newsInfo) {
  return async (dispatch) => {
    console.log("createNews():", userId, newsInfo);
    let newNews = new News(userId, newsInfo);
    newNews.addedBy = userId;
    console.log("createNews():" + JSON.stringify(newsInfo) + " " + JSON.stringify(newNews));
    try {
      dispatch({ type: NEWS_UPDATE });
      const news = await mgmtNews.createNews1(newNews);
      console.log("createNews()(after):" + JSON.stringify(news));
      dispatch({
        type: NEWS_UPDATE_FAILURE,
        payload: {
          news
        }
      });
    } catch (error) {
      dispatch({ type: NEWS_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function fetchNews(cid) {

  return async (dispatch) => {
    try {
      dispatch({ type: NEWSINFO_INFO });
      const news = await mgmtNews.fetchNews(cid);
      console.log("news Action", news);
      dispatch({
        type: NEWSINFO_CREATE_SUCCESS,
        payload: {
          news,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: NEWSINFO_CREATE_FAILURE });
      throw error;
    }
  };
}






