import React, { useState} from 'react'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types';
import axios from 'src/utils/axios';
import PersonIcon from '@mui/icons-material/Person';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ModeIcon from '@mui/icons-material/Mode';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate';
import DownloadIcon from '@mui/icons-material/Download';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import PaymentIcon from '@mui/icons-material/Payment';
import {
  Box,
  IconButton,
  SvgIcon,
} from '@mui/material';
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon
} from 'react-feather';

function Results({ className, services, services1, setServices, path, customer, ...rest }) {
 
  const navigate = useNavigate();

  const columns = [
    { field: 'customerId', headerName: 'CustomerId ', width: 100 },
    { field: 'businessName', headerName: 'BusinessName', width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 340,
      renderCell: (params) => {
        const paymentType = params.row.paymentGateway
        const paymentTypeInfo = paymentType
        return (
          <div className="cellAction">
            <Tooltip title="Edit Logo And Banner">
              <IconButton
                onClick={() => {
                  navigate('/app/management/customer/update', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <ModeIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Customer Brand Details">
              <IconButton
                onClick={() => {
                  navigate('/app/management/customer/info/update', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Privacy Policy">
              <IconButton
                onClick={() => {
                  navigate('/app/management/customer/privacypolicy/update', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <PrivacyTipIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Customer Details">
              <IconButton
                onClick={() => {
                  navigate('/app/management/customer/customerdetails/update', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <PersonIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Customer View">
              <IconButton
                onClick={() => {
                  navigate('/app/management/customer/info/view', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <VisibilityIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Customer Json File Download">
              <IconButton
                onClick={() => {
                  navigate('/app/management/customer/json/download', { state: params.row.customerId });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <DownloadIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Update License">
              <IconButton
                onClick={() => {
                  navigate('/app/management/license/view', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <SecurityUpdateIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Update Payment Credentials">
              <IconButton
                onClick={() => {
                  navigate('/app/management/update/payment/credentials', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <PaymentIcon/>
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Delivery Charges">
              <IconButton
                onClick={() => {
                  navigate('/app/management/delivery/charges', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <DeliveryDiningIcon/>
                </SvgIcon>
              </IconButton>
            </Tooltip><m>
            </m>
          </div>
        );
      },
    },
    { field: 'whatsApp', headerName: 'whatsApp', width: 150 },
    { field: 'registeredEmail', headerName: 'registeredEmail', width: 200 },
    { field: 'about', headerName: 'about', width: 200 },
    { field: 'mobile', headerName: 'Mobile', width: 100 },
    { field: 'GSTN', headerName: 'GSTN', width: 100 },
    { field: 'paymentGateway', headerName: 'paymentGateway', width: 200 },
    // { field: 'brandDetails', headerName: 'brandDetails', width: 100 },
    // { field: 'privacyPolicy', headerName: 'privacyPolicy', width: 100 },
    // { field: 'profileImage', headerName: 'ProfileImage', width: 100 },
  ]

  return (

    <Box sx={{ height: 500, width: '100%' }}>
      {customer === "ADMIN" || customer === "SUPPORT" ?
      <DataGrid
        rows={services1}
        columns={columns}
        getRowId={(row) => row.customerId}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />:
      <DataGrid
      rows={services}
      columns={columns}
      getRowId={(row) => row.customerId}
      pageSize={10}
      rowsPerPageOptions={[10]}
    />}
    </Box>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  services: PropTypes.array
};

Results.defaultProps = {
  services: []
};

export default Results;



















