class Quotation {
  constructor(info) {
    this.quotationTitle = info.quotationTitle;
    if(info && info?.discount){
      this.discount = info.discount;
    }
    this.customerId = info.customerId
    this.userType = info.userType
    this.email = info.email
    this.quotationId = info.quotationId
    this.discountType = info.discountType;
    this.quotationExpiry = info.quotationExpiry;
    this.quotationDescription = info.quotationDescription;
    this.specialInstructions = info.specialInstructions;
    // this.GSTN = info.GSTN;
    this.items = info.items;
  
  }
}
export default { Quotation };
export { Quotation };



