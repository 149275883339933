import React, { useEffect } from 'react';
import {
    Box,
    Card,
    CardContent,
    Grid,
    Paper,
    Divider,
    Typography
} from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@mui/styles';
import {
    Hail as HailIcon,
    Inventory as InventoryIcon,
    Group as GroupIcon,
    MiscellaneousServices as MiscellaneousServicesIcon
} from "@mui/icons-material";
import { useSelector } from 'react-redux';
import ChartBarLine from 'src/views/dashboards/components/ChartBarLine';
import { prepareSalesWeekData } from 'src/utils/chartsDataUtils';


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
    },

}));

const options = {
    indexAxis: 'x',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'center',
        },
    },
};


function TrendSection({ }) {
    const classes = useStyles();

    const trends = useSelector((state) => state?.dashboardFinancial.trends);
    
    const dataSalesM = prepareSalesWeekData(trends?.month);
    const dataSalesW = prepareSalesWeekData(trends?.week);
    // console.log("TrendSection: ", trends, dataSalesM);

    useEffect(() => {

    }, [trends]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="top"
            spacing={1}
            marginTop={3}
            marginLeft={1}
            marginRight={1}
        >
            <Grid item xs={12} md={6}  >
            <ChartBarLine title={"Monthly Sales"} labely1={"Orders"} labely2={"Sales"} labelx={"Date"} 
                            datay1={dataSalesM?.datay1} datay2={dataSalesM?.datay2} datax={dataSalesM?.datax}/>
            </Grid>
            
            <Grid item xs={12} md={6} > 
                <ChartBarLine title={"Weekly Sales"} labely1={"Orders"} labely2={"Sales"} labelx={"Date"} 
                                datay1={dataSalesW?.datay1} datay2={dataSalesW?.datay2} datax={dataSalesW?.datax}/>
                
            </Grid>



        </Grid>

    );
}

export default TrendSection;