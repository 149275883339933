import React from "react";
import { Container, Grid } from "@mui/material";
import Page from "src/components/Page";
import { makeStyles } from '@mui/styles';
import Header from "./Header";
import { useLocation } from "react-router-dom";
import OrderEditForm from "./OrderEditForm";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
}));

function OrderEdit() {
  const classes = useStyles();
  const { state } = useLocation();
  // console.log("STATE", state);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false} className={classes.container}>
        <Header />
        <OrderEditForm  state={state}/>
      </Container>
    </Page>
  );
}

export default OrderEdit;

