import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux';

import axios from 'src/utils/axios';
import Page from 'src/components/Page';
import DeliveryCharges from './DeliveryChargesForm';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function AddDeliveryCharges() {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const { state } = useLocation();

  const [ deliverychargesInfo, setDeliverychargesInfo ] = useState({});
  
  async function getDeliverychargesInfo(cid) {
    console.log("AddDeliveryCharges.getDeliverychargesInfo() :: cid: ", cid);
  
    let axiosUrl = `/api/admin/order/get/delivery-charges`;
    if (cid !== undefined && cid !== null && cid !== "" && cid !== 0) {
      axiosUrl = `${axiosUrl}?customerId=${cid}`;
    }
    const axiosConfig = {};
  
    try {
      const response = await axios.get(axiosUrl, axiosConfig);
      const result = (response?.data?.data?.deliverycharges) ? response.data.data.deliverycharges[0] : {};
      console.log("AddDeliveryCharges.getDeliverychargesInfo() :: result: ", result);
      setDeliverychargesInfo(result);
    } catch (error) {
      console.error(error);
    }
  }  
  
  useEffect(() => {
    getDeliverychargesInfo(state.customerId);
  }, []);

  return (
    <Page
      className={classes.root}
      title="Add Delivery Charges"
    > 
      <Header/>
        <DeliveryCharges state={state} deliverychargesInfo={deliverychargesInfo} />
    </Page>
  );
}

export default AddDeliveryCharges;
