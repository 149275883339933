import axios from 'src/utils/axios';

class MgmtPaymentGateWayType {

 
  gatewaypaymentTypeInfo = () => new Promise((resolve, reject) => {
    const url = `/api/master/payment/gateway/types`
    axios
      .get(url)
      .then((response) => {
        // console.log("MgmtAdmin(fetchAdmin11->Response): 1", response);
        if (response?.data) {
          const nodata = []
          const paymentInfo = (response?.data?.data?.paymentGatewayType) ? response.data.data.paymentGatewayType : nodata;
          resolve(paymentInfo);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  addPaymentGateWayType = (newPaymentGateWayTypeInfo,showAccessCode,showKeyIndex) => new Promise((resolve, reject) => {
    
    const payload = 
    {
      "gatewayType":newPaymentGateWayTypeInfo.gatewayType,
      "showKeyIndex":showKeyIndex,
      "showAccessCode":showAccessCode
  }
   
    axios.post('/api/master/payment/gateway/types',payload)
    .then((response) => {
      // console.log("MgmtService(Response):" + JSON.stringify(response));

      if(response?.data?.paymentGatewayType){
        let paymentInfo = response.data.paymentGatewayType;
        resolve(paymentInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  editPaymentGateWayType = (newPaymentGateWayTypeInfo,showAccessCode,showKeyIndex,paymentType) => new Promise((resolve, reject) => {
    
    const payload = 
    {
      "gatewayType":paymentType,
      "showKeyIndex":showKeyIndex,
      "showAccessCode":showAccessCode
  }
   
    axios.post('/api/master/payment/gateway/types',payload)
    .then((response) => {
      // console.log("MgmtService(Response):" + JSON.stringify(response));

      if(response?.data?.paymentGatewayType){
        let paymentInfo = response.data.paymentGatewayType;
        resolve(paymentInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })



}

const mgmtPaymentGateWay = new MgmtPaymentGateWayType();

export default mgmtPaymentGateWay;
