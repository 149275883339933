import React from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { ORDER_STATUS } from "src/constants";

const useStyles = makeStyles(() => ({
  root: {},
}));

function SearchForm({ className, setCriteria, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        mobile: "",
        invoiceId: "",
        orderStatus: "",
        startDate: "",
        endDate: "",
      }}
      validationSchema={Yup.object().shape({
        mobile: Yup.string(),
        invoiceId: Yup.string(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try { 
          setCriteria(values);
        } catch (error) {
          console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Grid container alignItems="flex-start" spacing={2} margin={1}  >
            <Grid item xs={6} md={2} mt={-2}>
              <TextField
                error={Boolean(touched.mobile && errors.mobile)}
                fullWidth
                helperText={touched.mobile && errors.mobile}
                label="Mobile"
                margin="normal"
                name="mobile"
                onBlur={handleBlur}
                onChange={handleChange}
                type="mobile"
                value={values.mobile}
                variant="outlined"
              /></Grid>
            <Grid item xs={6} md={2} mt={-2}>
              <TextField
                error={Boolean(touched.invoiceId && errors.invoiceId)}
                fullWidth
                helperText={touched.invoiceId && errors.invoiceId}
                label="Invoice Id"
                margin="normal"
                name="invoiceId"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.invoiceId}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <Autocomplete
                id="order-status"
                options={ORDER_STATUS}
                getOptionLabel={(option) => option.label}
                value={ORDER_STATUS.find((option) => option.value === values.orderStatus) || null}
                onChange={(_, newValue) => {
                  setFieldValue('orderStatus', newValue ? newValue.value : ''); // Set to empty string if newValue is null
                }}
                renderInput={(params) => <TextField {...params} label="Invoice Status" />}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <TextField
                error={Boolean(
                  touched.startDate && errors.startDate
                )}
                fullWidth
                helperText={
                  touched.startDate && errors.startDate
                }
                label="Start Date"
                type="date"
                name="startDate"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.startDate}
                variant="outlined"
                InputLabelProps={{ shrink: (!values.startDate || values.startDate) }}    
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <TextField
                error={Boolean(
                  touched.endDate && errors.endDate
                )}
                fullWidth
                helperText={
                  touched.endDate && errors.endDate
                }
                label="End Date"
                type="date"
                name="endDate"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.endDate}
                variant="outlined"
                InputLabelProps={{ shrink: (!values.startDate || values.startDate) }}    
              />
            </Grid>

            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Grid item xs={6} md={2}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Search Invoices
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

SearchForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

SearchForm.default = {
  onSubmitSuccess: () => { },
};

export default SearchForm;
