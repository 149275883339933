import produce from "immer";
import { GREETINGINFO_CREATE_SUCCESS } from "src/actions/mgmtGreetingAction";
import { FETCHGENERATEDQUOTATIONINFO_INFO_SUCCESS, QUOTATIONINFO_CREATE_SUCCESS } from "src/actions/mgmtQuotationGenerateAction";

const initialState = {
    quotation: [],
    generatedQuotation:[]

};

const quotationReducer = (state = initialState, action) => {
    switch (action.type) {
        case QUOTATIONINFO_CREATE_SUCCESS: {
            const { quoreqlist } = action.payload;
            return produce(state, (draft) => {
                draft.quotation = quoreqlist;
            });
        }
        case FETCHGENERATEDQUOTATIONINFO_INFO_SUCCESS: {
            const { generatedQuotation } = action.payload;
            return produce(state, (draft) => {
                draft.generatedQuotation = generatedQuotation;
            });
        }
        default: {
            return state;
        }
    }
};

export default quotationReducer;


