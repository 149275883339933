import React, {
    useState,
    useEffect,
    useCallback
} from 'react';
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import InventoryIcon from "@mui/icons-material/Inventory";
import GroupIcon from "@mui/icons-material/Group";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import { useDispatch, useSelector } from 'react-redux';



function SuperAdminDashboardInfo() {

    const isMountedRef = useIsMountedRef();
    const [categoryAdminInfo, setCategoryAdminInfo] = useState(null);

    const getCategoryAdminInfo = useCallback(() => {
        axios
            .get(`/api/admin/catalog/category/names`)
            .then((response) => {
                if (isMountedRef.current) {
                    // console.log("get user info =>" + JSON.stringify(response.data));
                    if (response?.data?.data?.total) {
                        setCategoryAdminInfo(response.data.data.total);
                    } else {
                        setCategoryAdminInfo([]);
                    }
                }
            });
    }, [isMountedRef]);

    const [services, setServices] = useState(null);

    const getServices = useCallback(() => {
        axios
            .get('/api/admin/customer/all') //, {})
            .then((response) => {
                if (isMountedRef.current) {
                    // console.log("getServices222 =>" + JSON.stringify(response.data.data));

                    if (response?.data?.data?.totalCount) {
                        // setProject(response.data.data.projects);
                        setServices(response.data.data.totalCount);
                    } else {
                        setServices([]);
                    }
                }
            });
    }, [isMountedRef]);

    const [users, setUsers] = useState(null);

    const getUsers = useCallback(() => {
        axios
            .get('/api/auth/admin/users') //, {})
            .then((response) => {
                if (isMountedRef.current) {
                    // console.log("getServices333 =>" + JSON.stringify(response.data.data));

                    if (response?.data?.data?.count) {
                        // setProject(response.data.data.projects);
                        setUsers(response.data.data.count);
                    } else {
                        setUsers([]);
                    }
                }
            });
    }, [isMountedRef]);

    const [products, setProducts] = useState(null);

    const getProducts = useCallback(() => {
        axios
            .get('/api/admin/catalog/product') //, {})
            .then((response) => {
                if (isMountedRef.current) {
                    // console.log("getServices444 =>" + JSON.stringify(response));

                    if (response?.data?.data?.total) {
                        // setProject(response.data.data.projects);
                        setProducts(response.data.data.total);
                    } else {
                        setProducts([]);
                    }
                }
            });
    }, [isMountedRef]);
    
   
    useEffect(() => {
        getCategoryAdminInfo();
    }, [getCategoryAdminInfo]);

    useEffect(() => {
        getServices();
    }, [getServices]);

    
    useEffect(() => {
        getUsers();
    }, [getUsers]);

    useEffect(() => {
        getProducts();
    }, [getProducts]);

    if (!products) {
        return null;
    }


    if (!users) {
        return null;
    }

    if (!services) {
        return null;
    }

    if (!categoryAdminInfo) {
        return null;
    }

    const OverviewData = [
        {
            id: 1,
            title: "Total Customers",
            total: services,

            icon: <GroupIcon sx={{ color: "white" }} />,
        },

          {
            id: 2,
            title: "Total Users",
            total: users,
            icon: <InventoryIcon sx={{ color: "white" }} />,
          },
          {
            id: 3,
            title: "Total Products",
            total: products,
            icon: <InventoryIcon sx={{ color: "white" }} />,
          },
        {
            id: 4,
            title: "Total Categories",
            total: categoryAdminInfo,
            icon: <MiscellaneousServicesIcon sx={{ color: "white" }} />,
        },
    ];

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0}
            marginTop={3}
            marginLeft={1}
            marginRight={1}
        >
            {OverviewData.map((overviewdata) => {
                return (

                    <Grid item md={3} xs={12} key={overviewdata.id}>
                        <Card sx={{ width: "90%" }}>
                            <Box sx={{ flexDirection: "row", display: "flex" }}>
                                <Box sx={{ padding: 2 }}>
                                    <Card sx={{ backgroundColor: "#0c2860", height: 50 }}>
                                        <CardContent sx={{}}>{overviewdata.icon}</CardContent>
                                    </Card>
                                </Box>
                                <Box sx={{ padding: 2 }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography sx={{ mb: 1 }}>{overviewdata.title}</Typography>
                                        <Typography sx={{ fontWeight: "bold" }}>
                                            {overviewdata.total}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Card>

                    </Grid>

                );
            })}

        </Grid>


    );
}

export default SuperAdminDashboardInfo;
