export function getCurrentDate(separator = '-') {
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
}

export function formatDateToYYYYMMDD(utcDateString) {
    const utcDate = new Date(utcDateString);
    const year = utcDate.getUTCFullYear();
    const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
    const day = utcDate.getUTCDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  export function formatDateToDDMMYYYY(utcDateString) {
    const utcDate = new Date(utcDateString);
    const year = utcDate.getUTCFullYear();
    const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
    const day = utcDate.getUTCDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
  }