import mgmtInvoice from "src/services/mgmtInvoice";

export const INVOICE_INFO = '@invoice/info';
export const INVOICE_CREATE = '@invoice/create';
export const INVOICE_CREATE_SUCCESS = '@invoice/create-success';
export const INVOICE_CREATE_FAILURE = '@invoice/create-failure';

export function fetchInvoice(cid) {

  return async (dispatch) => {
    try {
      dispatch({ type: INVOICE_INFO });
      const invoice = await mgmtInvoice.fetchInvoice(cid);
      console.log("Invoice Action", invoice);
      dispatch({
        type: INVOICE_CREATE_SUCCESS,
        payload: {
        invoice,
        },
      });
    } catch (error) {
       
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: INVOICE_CREATE_FAILURE });
      throw error;
    }
  };
}






