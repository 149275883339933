import React, { useEffect, useState } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTestimonial, updateTestimonial } from "src/actions/testimonialActions";
import ImageEditor from "src/components/ImageEditor";
import { IMAGE_TYPE } from "src/config";  
import { IMAGE_BASE_URL } from "src/config";




const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function TestimonialForm({ customerId, data, className, ...rest }) {
  const classes = useStyles();

  const [image, setImage] = useState('');
  const account = useSelector((state) => state.account);

  const addedBy = account.user.email;
  function handleImage(e) {
    console.log(e.target.files)
    setImage(e.target.files[0])
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {

  }, [data]);



  return (
    <Formik
      initialValues={{
        customerId: customerId,
        testimonialId: data ? data.testimonyId : undefined,
        userName: data ? data.userName || "" : "",
        description: data ? data.description || "" : "",
        date: data ? data.date : new Date().toISOString().substr(0, 10),
        imageUrl: data ? `${IMAGE_BASE_URL}/${data.image}` : "",
      }}
      validationSchema={Yup.object().shape({
        userName: Yup.string().max(50).required("Name is required"),
        description: Yup.string().max(500).required("Description is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if(data){
            await dispatch(updateTestimonial(addedBy, values));
          } else {
            await dispatch(createTestimonial(addedBy, values, image));
          }
         
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Testimonial Created Successfully", {
            variant: "success",
          });
          navigate("/app/management/testimonials");

        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Failed to Add Testimonial", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Grid container spacing={3} padding={2}>
            <Grid item xs={6}>
              <TextField
                error={Boolean(
                  touched.userName && errors.userName
                )}
                fullWidth
                helperText={
                  touched.userName && errors.userName
                }
                label="Customer Name"
                name="userName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.userName}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={Boolean(
                  touched.date && errors.date
                )}
                fullWidth
                helperText={
                  touched.date && errors.date
                }
                label="Testimonial Date"
                type="date"
                name="date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.date}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(
                  touched.description && errors.description
                )}
                fullWidth
                helperText={
                  touched.description && errors.description
                }
                multiline
                rows={4}
                label="Description"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Upload Image</Typography>
              {data && <ImageEditor type={IMAGE_TYPE.TESTIMONIAL} inImage={values.imageUrl} customerId={customerId} id={values.testimonialId}/> }
              {!data && <TextField
                error={Boolean(
                  touched.imageUrl && errors.imageUrl
                )}
                fullWidth
                helperText={
                  touched.imageUrl && errors.imageUrl
                }
                type="file"

                name="imageUrl"
                onBlur={handleBlur}
                onChange={handleImage}
                value={values.image}
                variant="outlined"
              /> }
            </Grid>

          </Grid>

          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2} padding={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              style={{ marginBottom: '5em' }}
            >
              Submit
            </Button>
            <Button style={{ marginLeft: '1em', marginBottom: '5em' }}
              color="secondary" variant='contained'
              component={RouterLink}
              to="/app/management/testimonials"
            >
              Cancel
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

TestimonialForm.propTypes = {
  className: PropTypes.string,
  customerId: PropTypes.number,
};

export default TestimonialForm;