import React, { useEffect } from 'react';
import {
  Container,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Header from './Header';
import { useDispatch, useSelector } from 'react-redux';
import MperitoCard from 'src/components/MperitoCard';
import Results from './Results';

import { fetchNews } from 'src/actions/newsActions';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function NewsListView() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const businessInfo = useSelector((state) => state?.app?.businessInfo);
  const customerId = businessInfo?.customerId;

  const newsList = useSelector((state) => state?.news?.newsList);
  
  useEffect(() => {
    dispatch(fetchNews(customerId));
  }, [businessInfo]);

  useEffect(() => {

  }, [newsList]);


  return (
    <Page
      className={classes.root}
      title="News List"
    >
      <Container maxWidth={false}>
        <Header />
        <br />
        <br />
        <MperitoCard title={`News List ( ${businessInfo?.businessName} - ${businessInfo?.customerId} )`}>
          <Results newsList={newsList} businessInfo={businessInfo} />
        </MperitoCard>
      </Container>
    </Page>
  );
}

export default NewsListView;

