import React from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Field, FieldArray, Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { QuotationCreate, fetchGeneratedQuotationList } from "src/actions/mgmtQuotationGenerateAction";
import { DISTRIBUTORCATEGORY_TYPE } from 'src/utils/constants';

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function QuotationCreateForm({ className, customerIdList, customerId, userType, productInfo, id, customer, s, id1, onClick, quotation1, ...rest }) {

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (

    <Formik
      initialValues={{
        customerId:quotation1.customerId || '',
        quotationId:quotation1.quotationId || '',
        userType:quotation1.userType || '',
        email:quotation1.requestedBy || '',
        quotationTitle: "",
        quotationExpiry: "",
        quotationDescription: "",
        specialInstructions: "",
        discountType: "",
        discount:quotation1.discount || 0,
        items:quotation1.items ||  [
          {
            productId:quotation1.items.productId || '',
            quantity: quotation1.items.quantity ||  '',
            productName:quotation1.items.productName || '',
            sellPrice:quotation1.price.sellPrice || '',
          }]
      }}
      validationSchema={Yup.object().shape({
        quotationExpiry: Yup.date().required("Quotation Expiry is required"),
        discountType: Yup.string().required("Discount Type is required"),
        

      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        console.log("Submitting the request", values, id1);
        try {
          await dispatch(QuotationCreate(values, customer, id1, s));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Quotation Created", {
            variant: "success",
          });
          dispatch(fetchGeneratedQuotationList(values.customerId));
          navigate("/app/management/quotation/generated/view");
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Quotation Failed", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Generate Quotation" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                       <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.customerId && errors.customerId
                    )}
                    fullWidth
                    helperText={
                      touched.customerId && errors.customerId
                    }
                    label="Customer Id"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.quotationId && errors.quotationId
                    )}
                    fullWidth
                    helperText={
                      touched.quotationId && errors.quotationId
                    }
                    label="Quotation Id"
                    name="quotationId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.quotationId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.userType && errors.userType
                    )}
                    fullWidth
                    helperText={
                      touched.userType && errors.userType
                    }
                    label="User Type"
                    name="userType"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.userType}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.email && errors.email
                    )}
                    fullWidth
                    helperText={
                      touched.email && errors.email
                    }
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography>Quotation Title</Typography>
                  <TextField
                    error={Boolean(
                      touched.quotationTitle && errors.quotationTitle
                    )}
                    fullWidth
                    helperText={
                      touched.quotationTitle && errors.quotationTitle
                    }
                    name="quotationTitle"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.quotationTitle}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography>Quotation Description</Typography>
                  <TextField
                    error={Boolean(
                      touched.quotationDescription && errors.quotationDescription
                    )}
                    fullWidth
                    helperText={
                      touched.quotationDescription && errors.quotationDescription
                    }
                    name="quotationDescription"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.quotationDescription}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography>Special Instructions</Typography>
                  <TextField
                    error={Boolean(
                      touched.specialInstructions && errors.specialInstructions
                    )}
                    fullWidth
                    helperText={
                      touched.specialInstructions && errors.specialInstructions
                    }
                    name="specialInstructions"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.specialInstructions}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography>Quotation Expiry</Typography>
                  <TextField
                    error={Boolean(
                      touched.quotationExpiry && errors.quotationExpiry
                    )}
                    fullWidth
                    helperText={
                      touched.quotationExpiry && errors.quotationExpiry
                    }
                    name="quotationExpiry"
                    type="date"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.quotationExpiry}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={values.discountType}
                    onChange={(event, newValue) => {
                      setFieldValue("discountType", newValue);
                    }}
                    disablePortal
                    id="discountType"
                    options={DISTRIBUTORCATEGORY_TYPE}
                    required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    error={errors.discountType}
                    helperText={errors.discountType}
                    renderInput={(params) => (
                      <>
                        {" "}
                        <TextField {...params} label="Discount Type"
                          error={Boolean(touched.discountType && errors.discountType)}
                          helperText={touched.discountType && errors.discountType}
                        />
                      </>
                    )}
                  />
                </Grid>
                {values.discountType === "direct" ?
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(
                        touched.discount && errors.discount
                      )}
                      fullWidth
                      helperText={
                        touched.discount && errors.discount
                      }
                      label="Discount Rs"
                      name="discount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.discount}
                      variant="outlined"
                    />
                  </Grid>
                  :
                  <Box />} 
                      {values.discountType === "percentage" ?
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(
                        touched.discount && errors.discount
                      )}
                      fullWidth
                      helperText={
                        touched.discount && errors.discount
                      }
                      label="Discount %"
                      name="discount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.discount}
                      variant="outlined"
                    />
                  </Grid>
                  :
                  <Box />} 
              </Grid>
              <br />
              <Card >
                <CardContent>
                  <FieldArray name="items">
                    {fieldArrayProps => (
                      <>
                        {values.items.map((option, index) => (
                          <div key={`${index}`}>
                            <br />
                            <Card className={classes.root}>
                              <CardContent>
                                <Grid container spacing={2} >
                                  <Grid item xs={12} sm={3}>
                                    <Typography>Product Id</Typography>
                                    <Field name={`items.${index}.productId`} minWidth>
                                      {fieldProps => (
                                        <div>
                                          {console.log("FIELDARRAY", fieldProps.field)
                                          }
                                          <input placeholder="Product Id" {...fieldProps.field} />
                                        </div>
                                      )}
                                    </Field>
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                    <Typography>Product Name</Typography>
                                    <Field name={`items.${index}.productName`} minWidth>
                                      {fieldProps => (
                                        <div>
                                          {console.log("FIELDARRAY", fieldProps.field)
                                          }
                                          <input placeholder="Product Name" {...fieldProps.field} />
                                        </div>
                                      )}
                                    </Field>
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                    <Typography>Price</Typography>
                                    <Field name={`items.${index}.sellPrice`} minWidth>
                                      {fieldProps => (
                                        <div>
                                          {console.log("FIELDARRAY", fieldProps.field)
                                          }
                                          <input placeholder="Price" {...fieldProps.field} />
                                        </div>
                                      )}
                                    </Field>
                                  </Grid>
                                  <Grid item xs={12} sm={3}>   <Typography>Quantity</Typography>
                                    <Field name={`items.${index}.quantity`} minWidth>
                                      {fieldProps => (
                                        <div>
                                          {console.log("FIELDARRAY", fieldProps.field)
                                          }
                                          <input placeholder="Quantity" {...fieldProps.field} />
                                        </div>
                                      )}
                                    </Field>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </div>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </CardContent>
              </Card>
            </CardContent>
          </Card>

          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

QuotationCreateForm.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default QuotationCreateForm;


