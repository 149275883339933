import newsService from "src/services/newsService";

export const FETCH_NEWS = '@business/fetch-news';
export const FETCH_NEWS_SUCCESS = '@business/fetch-news-success';
export const FETCH_NEWS_FAILURE = '@business/fetch-news-failure';


export function fetchNews(customerId) {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_NEWS });
            const resp = await newsService.fetchNews(customerId);
            // console.log("fetchBusinessDetails Action", businessDetails);
            dispatch({
                type: FETCH_NEWS_SUCCESS,
                payload: {
                    newsList : resp?.newsRes
                },
            });
        } catch (error) {
            dispatch({ type: FETCH_NEWS_FAILURE });
            throw error;
        }
    };
}
