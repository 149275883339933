import axios from 'src/utils/axios';


class MgmtInvoice {
  getSearchOrder = (info) => new Promise((resolve, reject) => {

    const bid = info ? info.customerId : undefined
    let searchUrl = "/api/admin/invoice/customerId?";
    searchUrl+=(bid)?`&customerId=${bid}`:"";
    // console.log("SEARCHURL",searchUrl)
    axios.get(searchUrl)
    // axios.get(`/api/supplier/mgmt/suppliers?businessName=${bname}&supplierId=${bid}&email=${bemail}`)
    .then((response) => {
      console.log("MgmtInvoice(Response):" + JSON.stringify(response.data.data.invoices));
      if(response.data.data.invoices){
        let business = response.data.data.invoices;
        resolve(business);
        // setBusiness(response.data.data.suppliers);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  fetchInvoice = (cid) => new Promise((resolve, reject) => {
    // const cusid = (userType === "SUPER_ADMIN") ? cid : customerId;
    axios
    .get(`/api/admin/invoice/by/customerId?customerId=${cid}`)
      .then((response) => {
        // console.log("MgmtInvoice(fetchInvoice->Response): 1", response);
        if (response?.data) {
          const nodata = []
          const invoice = (response?.data?.data?.invoices) ? response.data.data.invoices : nodata;
          resolve(invoice);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

}

const mgmtInvoice = new MgmtInvoice();

export default mgmtInvoice;
