import axios from 'src/utils/axios';

class MgmtUser {

  createUser = (newUser) => new Promise((resolve, reject) => {
const payload = newUser

    axios.post('/api/auth/admin/manage/user', payload)
      .then((response) => {
        // console.log("MgmtUser(Response):" + JSON.stringify(response));
        if (response.data.data) {
          let users = response.data.data;
          resolve(users);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  createUserTarget = (newUserTarget) => new Promise((resolve, reject) => {

    const payload = newUserTarget;
    // console.log("payload",payload)
    axios.post('/api/master/sales/target', payload)
      .then((response) => {
        // console.log("MgmtUserTarget(Response):" + JSON.stringify(response.data.data));
        if (response.data.data) {
          // console.log("response",response)
          let target = response.data.data;
          // console.log("target",target)
          resolve(target);
          // console.log("target1",target)
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  adminPasswordChange = (editAdminPassword,email) => new Promise((resolve, reject) => {
    // console.log("EDITDATA SERVICE",editAdminPassword)

    const payload =
    {
        "email": email,
        "oldpassword": editAdminPassword.oldpassword,
        "newpassword": editAdminPassword.newPassword,
    } ;
  
    axios.post('/api/auth/admin/changepassword',payload)
    .then((response) => {
      // console.log("MgmtAdminChangePassword(Response) service:", response);
      console.log("MgmtAdminChangePassword(Response):" + JSON.stringify(response));
      if(response.data){
        let admin = response.data;
        resolve(admin);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      console.log("MgmtAdminChangePassword (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  fetchDistributorCategory = (cid,userType,customerId) => new Promise((resolve, reject) => {
    const cusid = (userType === "SUPER_ADMIN") ? cid : customerId;
    axios
    .get(`/api/master/distributor/dealer/type?customerId=${cusid}`)
      .then((response) => {
        console.log("MgmtdistributorCategory(fetchUsers->Response): 1", response);
        if (response?.data?.data) {
          let distributorCategory = response?.data?.data;
          // console.log("MgmtUser(fetchUsers)", users);
          resolve(distributorCategory);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  fetchUserData = (cid) => new Promise((resolve, reject) => {
    // const cusid = (userType === "SUPER_ADMIN") ? cid : customerId;
    axios
    .get(`/api/auth/admin/users?customerId=${cid}`)
      .then((response) => {
        // console.log("MgmtUser(fetchUsers->Response): 1", response);
        if (response?.data) {
          const nodata = []
          const userData = (response?.data?.data) ? response.data.data.users : nodata;
          resolve(userData);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  fetchUserTargetData = (id,userId) => new Promise((resolve, reject) => {
    // const cusid = (userType === "SUPER_ADMIN") ? cid : customerId;
    axios
    .get(`/api/master/sales/target?userId=${userId}&customerId=${id}`)
      .then((response) => {
        // console.log("MgmtUser(fetchUsers->Response): 1", response);
        if (response?.data?.data) {
          let userTargetDetails = response?.data?.data;
          // console.log("MgmtUser(fetchUsers)", users);
          resolve(userTargetDetails);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

}

const mgmtUser = new MgmtUser();

export default mgmtUser;
