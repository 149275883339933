import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import appReducer from './appReducer';
import accountReducer from './accountReducer';
import searchReducer from './categoryReducer';
import searchProductReducer from './searchProductReducer';
import searchUserReducer from './searchUserReducer';
import greetingReducer from './greetingReducer';
import newsReducer from './newsReducer';
import invoiceReducer from './invoiceReducer';
import attributeReducer from './attributeReducer';
import quotationReducer from './quotationReducer';
import orderReducer from './orderReducer';
import distributorTypeListReducer from './distributeTypeReducer';
import adminReducer from './adminReducer';
import customerReducer from './customerReducer';
import financialDataReducer from './dashboardReducer';
import imageReducer from './imageReducer';
import paymentGateWayTypeReducer from './paymentGatewayTypeReducer';
import paymentReducer from './paymentReducer';
import testimonialReducer from './testimonialReducer';
import serviceReducer from './serviceReducer';
import dashboardFinancialReducer from './dashboardFinancialReducer';

const rootReducer = combineReducers({
  app: appReducer,
  account: accountReducer,
  searchBusiness: searchProductReducer,
  categories: searchReducer,
  user: searchUserReducer,
  greetings:greetingReducer,
  news:newsReducer,
  invoice:invoiceReducer,
  quotation:quotationReducer,
  order:orderReducer,
  distributetype:distributorTypeListReducer,
  attribute:attributeReducer,
  admin:adminReducer,
  customer:customerReducer,
  financialData:financialDataReducer,
  imageUpload:imageReducer,
  paymentGateWayType:paymentGateWayTypeReducer,
  form: formReducer,
  payment: paymentReducer,
  testimonial: testimonialReducer,
  service: serviceReducer,

  dashboardFinancial: dashboardFinancialReducer,
});

export default rootReducer;
