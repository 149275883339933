class User {
  constructor(info) {
    this.customerId = info.customerId;
    if(info && info?.userId){
      this.userId = info.userId;
    }
    this.name = info.name;
    this.email = info.email;
    this.mobile = info.mobile;
    this.GSTN = info.GSTN;
    this.userType = info.userType;
    this.distributorCategory = info.distributorCategory
  }
}
export default { User };
export { User };



