import { License } from "src/interface/licenseIf";
import mgmtLicense from "src/services/mgmtValidateLicense";

export const LICENSE_INFO = '@license/info';
export const LICENSE_CREATE = '@license/create';
export const LICENSE_CREATE_SUCCESS = '@license/create-success';
export const LICENSE_CREATE_FAILURE = '@license/create-failure';
export const LICENSE_UPDATE = '@license/update';
export const LICENSE_UPDATE_SUCCESS = '@license/update-success';
export const LICENSE_UPDATE_FAILURE = '@license/update-failure';

export function createLicense(licenseInfo,cusId) {
  return async (dispatch) => {
    // console.log("createLicense():",licenseInfo);
    let newLicense = new License( licenseInfo);
    // console.log("createLicense():" + JSON.stringify(licenseInfo) + " " + JSON.stringify(newLicense));
    try {
      dispatch({ type: LICENSE_CREATE });
      const license = await mgmtLicense.createLicense(newLicense,cusId);
      // console.log("createLicense()(after):" + JSON.stringify(license));
      dispatch({
        type: LICENSE_CREATE_SUCCESS,
        payload: {
          license
        }
      });
    } catch (error) {
      dispatch({ type: LICENSE_CREATE_FAILURE });
      throw error;
    }
  };
}



