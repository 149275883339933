import axios from 'src/utils/axios';

class MgmtProduct {

  createProduct = (newProdcut, image, files, customerId, userType) => new Promise((resolve, reject) => {
    //const custIdInfo1 = (userType === "SUPER_ADMIN") ? custIdInfo : customerId;
   
    const payload = newProdcut.additionalFeatures;
    const payload1 = JSON.stringify(payload)
    const formData = new FormData()
    formData.append('productData', `{"customerId":${customerId}, 
    "categoryId":[${newProdcut.categoryId}],
    "productName":"${newProdcut.productName}","productSKU":"${newProdcut.productSKU}"
    ,"brandName":"${newProdcut.brandName}"
    ,"availableStock":"${newProdcut.availableStock}"
    ,"description":"${newProdcut.description}"
    ,"header":"${newProdcut.header}"
    ,"metrics":"${newProdcut.metrics}"
    ,"amPartNo":"${newProdcut.amPartNo}"
    ,"make":"${newProdcut.make}"
    ,"additionalFeatures":${payload1},
    "price": {
      "sellPrice": ${newProdcut.sellPrice},
      "specialPrice": {
      "price": ${newProdcut.specialPrice},
      "discount": ${newProdcut.discount},
      "from": "${newProdcut.from}",
      "till": "${newProdcut.till}"
      }
    }
  }`);
    formData.append('baseImageUrl', image);
    for (let i = 0; i < files.length; i++) {
      formData.append(`imageURL`, files[i]);
    }

    axios.post('/api/admin/catalog/product', formData)
      .then((response) => {
        // console.log("MgmtService(Response):" + JSON.stringify(response));
        if (response.data.data.product) {
          let services = response.data.data.product;
          resolve(services);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })


  updateProduct = (formData) =>
    new Promise((resolve, reject) => {

      axios.put('/api/admin/catalog/product', formData)
        .then((response) => {

          if (response.data.data.productInfo) {
            let productInfo = response.data.data.productInfo;
            resolve(productInfo);
          } else {
            console.log("MgmtProduct.updateProduct (Response Error):" + JSON.stringify());
            reject(response.data.error);
          }
        })
        .catch((error) => {
          console.log("MgmtProduct.updateProduct (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });





  updateService5 = (productInfo) => new Promise((resolve, reject) => {
    console.log(`MgmtProduct.updateService5() :: productInfo: `, productInfo);
    const payload = productInfo;
    const formData = new FormData()
    formData.append('productData', `{"customerId":${productInfo.customerId}, 
      "categoryId":[${productInfo.categoryId}],
      "productId":${productInfo.productId},
      "productName":"${productInfo.productName}","productSKU":"${productInfo.productSKU}",
      "price": {
        "sellPrice": ${productInfo.price},
        "specialPrice": {
          "price": ${productInfo.specialPrice},
          "discount": ${productInfo.discount},
          "from": "${productInfo.from}",
          "till": "${productInfo.till}"
        }
      }
      ,"make":"${productInfo.make}"
      ,"description":"${productInfo.description}"
      ,"header":"${productInfo.header}"
      ,"metrics":"${productInfo.metrics}"
      ,"brandName":"${productInfo.brandName}"
      ,"availableStock":"${productInfo.availableStock}"
      ,"amPartNo":"${productInfo.amPartNo}"
      ,"additionalFeatures":${JSON.stringify(productInfo.additionalFeatures)}
    }`);
    console.log(`MgmtProduct.updateService5() :: formData: `, formData);

    axios.put('/api/admin/catalog/product', formData)
      .then((response) => {

        if (response.data.data.productInfo) {
          let services = response.data.data.productInfo;
          resolve(services);
        } else {
          console.log("MgmtProductService.createService (Response Error):" + JSON.stringify());
          reject(response.data.error);
        }
      })
      .catch((error) => {
        console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  getProductDetails = (customerId, categoryId) => new Promise((resolve, reject) => {

    // const bid = id ? id : "undefined"
    // const bname = equipmentId
    // const cid1 = (customerType === "SUPER_ADMIN") ? bid : customer;

    let searchUrl = "/api/admin/catalog/products/categoryId?";
    searchUrl += (categoryId) ? `&categoryId=${categoryId}` : "";
    searchUrl += (customerId) ? `&customerId=${customerId}` : "";
    console.log("SEARCHURL", searchUrl)
    axios.get(searchUrl)
      .then((response) => {
        // console.log("MgmtSearchBusiness(Response):" + JSON.stringify(response));
        if (response.data.data.result[0]) {
          let business = response.data.data.result;
          resolve(business);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  getProductDetails_ToBeDeleted = (id, customer, customerType, equipmentId) => new Promise((resolve, reject) => {

    const bid = id ? id : "undefined"
    const bname = equipmentId
    const cid1 = (customerType === "SUPER_ADMIN") ? bid : customer;

    let searchUrl = "/api/admin/catalog/products/categoryId?";
    searchUrl += (bname) ? `&categoryId=${bname}` : "";
    searchUrl += (cid1) ? `&customerId=${cid1}` : "";
    console.log("SEARCHURL", searchUrl)
    axios.get(searchUrl)
      .then((response) => {
        // console.log("MgmtSearchBusiness(Response):" + JSON.stringify(response));
        if (response.data.data.result[0]) {
          let business = response.data.data.result;
          resolve(business);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  getProductDetailsForDashboard = (id,customerId,customerType) => new Promise((resolve, reject) => {
    const bid = id ? id : "undefined"
    const cid1 = (customerType === "SUPER_ADMIN") ? bid : customerId;

    let searchUrl = "/api/admin/catalog/products/categoryId?";
    searchUrl += (cid1) ? `&customerId=${cid1}` : "";
    axios.get(searchUrl)
      .then((response) => {
        // console.log("MgmtSearchBusiness(Response):" + JSON.stringify(response));
        if (response.data.data.result[0]) {
          let business = response.data.data.result;
          resolve(business);
          // setBusiness(response.data.data.suppliers);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

}

const mgmtProduct = new MgmtProduct();

export default mgmtProduct;
