import React, { useCallback, useEffect, useState } from "react";
import Page from "src/components/Page";
import { makeStyles } from '@mui/styles';
import Header from "./Header";
import CategoryCreateForm from "./CategoryCreateForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "src/actions/idActions";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import axios from 'src/utils/axios';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  Box,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { fetchCategory, fetchUsers5 } from "src/actions/mgmtSelectValueAction";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
}));

function CategoryCreate() {

  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();

  const customerIdList = useSelector(
    (state) => state?.user?.users?.customerList
  );

  const customerId = useSelector(
    (state) => state?.account?.user?.customerId
  );

  const [id, setId] = React.useState('');

  const storedCustomerId = useSelector(
    (state) => state?.user?.users1
  );

  const isEngineeringType = (id) ? id : storedCustomerId;

  function handleChange(event) {
    console.log(event.target.value);
    setId(event.target.value);
    if (event.target.value) {
      dispatch(fetchCategory(event.target.value));
      dispatch(fetchUsers5(event.target.value));
    }
  }

  const userTy = useSelector(
    (state) => state?.account?.user?.userType
  );

  const userType = userTy
  const cusid = (userType <= "ADMIN") ? customerId : id;

  const disable = (storedCustomerId > 0)? true : false
  const [services, setServices] = useState(null);

  const getServices = useCallback(() => {
    axios
      .get(`/api/admin/catalog/category/names?customerId=${cusid}`) //, {})
      .then((response) => {
        if (isMountedRef.current) {
          // console.log("getServices111 =>" + JSON.stringify(response.data));
          if (response?.data?.data?.categories) {
            setServices(response.data.data.categories);
          } else {
            setServices([]);
          }
        }
      });
  }, [isMountedRef]);

 

  useEffect(() => {
    getServices();
  }, [getServices]);

  const onSubmitSuccess = () => {
    getServices();
  }

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);


  if (!services) {
    return null;
  }

  if (!customerIdList) {
    return null;
  }

  return (
    <Page className={classes.root} title="New Category">
      <Container maxWidth={false} className={classes.container}>
        <Header />
        <br />
        {
          userType !== "ADMIN" ?
            <Card style={{ maxWidth: '400px', maxHeight: '400px' }}>
              <CardContent>
                <Grid container spacing={2}> <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select CustomerId</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          // id="demo-simple-select"
                          label="Select CustomerId"
                          onChange={handleChange}
                          value={isEngineeringType}
                        >
                          {customerIdList.map((list) => (
                            <MenuItem value={list.customerId}>
                              {list.customerId}
                              {"..."}
                              {list.businessName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FormControl>
                </Grid>
                  <br />
                </Grid>
              </CardContent>
            </Card> :
        <Box/>
        }
         {userType === "ADMIN" ?
            <CategoryCreateForm onSubmitSuccess={onSubmitSuccess} services={services} customerIdList={customerIdList} userType={userType} customerId={customerId} id={isEngineeringType}  />
       :
            <Box/>
        }
        <br /><br /><br />
        {storedCustomerId > 0 ?
          <CategoryCreateForm onSubmitSuccess={onSubmitSuccess} services={services} customerIdList={customerIdList} userType={userType} customerId={customerId} id={isEngineeringType} />
          :
         <Box/>}
      </Container>
    </Page>
  );
}

export default CategoryCreate;

