import { fetchBusinessDetails } from "./businessActions";

export const CHANGE_BUSINESS = "@settings/change-business";


export function changeBusiness(businessInfo) {
  return async (dispatch) => {   
    
    // console.log("changeBusiness() : ", businessInfo);
    try {
      dispatch({ type: CHANGE_BUSINESS, payload :{ businessInfo: businessInfo  }});

      dispatch(fetchBusinessDetails(businessInfo?.customerId));
      // console.log("changeBusiness() Fetching Customer Detail", businessInfo?.customerId );
    } catch (error) {

    }
  };
}
