import React from "react";
import { Container, Grid } from "@mui/material";
import Page from "src/components/Page";
import { makeStyles } from '@mui/styles';
import Header from "./Header";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import DistributorCrateForm from "./DistributoCreateForm";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
}));

function DistributorTypeCreate() {
  const classes = useStyles();
  const { state } = useLocation();
  console.log("STATE", state);
  const customer1 = useSelector(
    (state) => state?.account?.user?.customerId
  );
  const customer = useSelector(
    (state) => state?.account?.user?.userType
  );
  const customerList = useSelector(
    (state) => state?.user?.users?.customerList
  );

  return (
    <Page className={classes.root} title="Distribution Type Create">
      <Container maxWidth={false} className={classes.container}>
        <Header />
        <DistributorCrateForm  state={state} customer1={customer1} customer={customer} customerList={customerList}/>
      </Container>
    </Page>
  );
}

export default DistributorTypeCreate;

