class Greeting {
  constructor(updatedBy, info) {
    this.customerId = info.customerId;
    this.title = info.title;
    this.greetId = info.greetId;
    this.isActive = info.isActive;
    this.isDeleted = info.isDeleted;
    this.greetImage = info.greetImage;
    this.videoUrl = info.videoUrl;
    this.updatedBy = updatedBy;
  }
}

export default { Greeting };
export { Greeting };



