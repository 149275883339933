import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles'
import { Buffer } from 'buffer';
import Page from 'src/components/Page';
import Header from './Header';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import NewsCreateForm from './NewsEditForm';
import NewsEditForm from './NewsEditForm';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function NewsEdit({ match, location }) {
  const {state} = useLocation();
  // console.log("STATE",state);
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [service, setService] = useState();
  const account = useSelector(state => state.account);

  const getService = useCallback(() => {
    
      setService(state)
  }, [isMountedRef]);
  const [ myImg, setMyImg ] = useState({});
  const [ myImg1, setMyImg1 ] = useState({});
  const base = state.ImageUrl

  
// console.log("B",base)
  // Base64 string data
  
  const decodeBase64 = (base64data) => {
    // let base64ToString = Buffer.from(base64data, "base64").toString();
    let base64ToString = Buffer.from(base64data).toString('base64');
    // console.log(`base64ToString: `, base64ToString);
    setMyImg(base64ToString)
    setMyImg1(base64ToString)
  }

  const getImage = () => {
    const imgURL = `/${base}`;
    axios.get(imgURL , { responseType:"arraybuffer"})
    .then((result) => {
      // console.log(`result: `, result);
      const { config, data, headers, request, status, statusText } = result;
      // console.log(`data: `, data);

      setMyImg({ image: result })

      decodeBase64(data)
    });
  }

  useEffect(() => {
    getService();
  }, [getService]);
   

  useEffect(() => {
    getImage();
  },[]);

  if (!service) {
    return null;
  }


  return (
    <Page
      className={classes.root}
      title="News Edit"
    >
      <Container maxWidth="lg">
        <Header />
        <br/>
        <NewsEditForm base={base} myImg={myImg} service={state} />
      </Container>
    </Page>
  );
}

export default NewsEdit;
