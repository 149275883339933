import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import Page from "src/components/Page";
import { makeStyles } from '@mui/styles';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Header from "./Header";

import ProductCreateForm from "./ProductCreateForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "src/actions/idActions";
import { fetchUsers5 } from 'src/actions/mgmtSelectValueAction';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
}));

function ProductCreate() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isMountedRef = useIsMountedRef();
  const [categories, setCategories] = useState([]); // TODO_SP : Need to check if we can replace with with data from reducer

  const businessInfo = useSelector((state) => state?.app?.businessInfo);

  useEffect(() => {

  }, [businessInfo]);

  const customerId = businessInfo?.customerId;



  const [id, setId] = React.useState('');

  function handleChange(event) {
    setId(event.target.value);
    dispatch(fetchUsers5(event.target.value));
  }

  const [isShown1, setIsShown1] = useState(false);
    const storedCustomerId = useSelector(
    (state) => state?.user?.users1
  );

  const isEngineeringType = (id) ? id : storedCustomerId;
  
  const handleClick1 = event => {
    if (isEngineeringType) {
      setIsShown1(current => !current);
    } else if (!isEngineeringType)
      alert('Please Select customer Id')
  };

  const customerIdList = useSelector(
    (state) => state?.user?.users?.customerList
  );
  const userTy = useSelector(
    (state) => state?.account?.user?.userType
  );

  // const customerId = useSelector(
  //   (state) => state?.account?.user?.customerId
  // );

  const userType = userTy

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);
  const cusid = (userType === "ADMIN") ? customerId : id;



  const getCategories = useCallback(() => {
    axios
      .get(`/api/admin/catalog/category?customerId=${businessInfo?.customerId}`) //, {})
      .then((response) => {
        if (isMountedRef.current) {
          console.log("get category info =>" + JSON.stringify(response.data));
          if (response?.data?.data?.categories) {
            // setProject(response.data.data.projects);
            setCategories(response.data.data.categories);
          } else {
            setCategories([]);
          }
        }
      });
  }, [isMountedRef]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false} className={classes.container}>
        <Header />
        <ProductCreateForm customerId={customerId} isEngineeringType={businessInfo?.isEngineeringType} categories={categories} userType={userType}/>
        <br />
      </Container>
    </Page>
  );
}

export default ProductCreate;
