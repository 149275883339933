import { FETCH_NEWS_URL } from 'src/constants/services';
import axios from 'src/utils/axios';

class NewsService {

    fetchNews = (customerId) => new Promise((resolve, reject) => {
        axios
            .get(`${FETCH_NEWS_URL}?customerId=${customerId}`)
            .then((response) => {
                console.log("NewsService(fetchNews->Response): 1", response);
                if (response.data) {
                    let news = response.data?.data;
                    console.log("NewsService(fetchNews)", news);
                    resolve(news);
                } else {
                    reject(response.data.error);
                }
            })
            .catch((error) => {
                console.log("NewsService.fetchNews (Response Error):" + JSON.stringify(error));
                reject(error);
            });
    })
}

const newsService = new NewsService();
export default newsService;
