import { CREATE_TESTIMONIAL_URL, FETCH_TESTIMONIALS_URL, TESTIMONIAL_DELETE_URL } from 'src/constants/services';
import axios from 'src/utils/axios';

class TestimonialService {

    fetchTestimonials = (customerId) => new Promise((resolve, reject) => {
        axios
            .get(`${FETCH_TESTIMONIALS_URL}?customerId=${customerId}`)
            .then((response) => {
                console.log("TestimonialService(fetchTestimonial->Response): 1", response);
                if (response.data) {
                    let testimonials = response.data?.data;
                    console.log("TestimonialService(fetchTestimonial)", testimonials);
                    resolve(testimonials);
                } else {
                    reject(response.data.error);
                }
            })
            .catch((error) => {
                console.log("TestimonialService.fetchTestimonial (Response Error):" + JSON.stringify(error));
                reject(error);
            });
    })

    createTestimonial = (newTestimonial,image) => new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('customerId',newTestimonial.customerId);
        formData.append('userName',newTestimonial.userName);
        formData.append('description',newTestimonial.description);
        formData.append('date',newTestimonial.date);
        formData.append('testimonialImage',image);
        
       
        axios.post(CREATE_TESTIMONIAL_URL,formData)
        .then((response) => {
          // console.log("MgmtService(Response):" + JSON.stringify(response));
          if(response.data){
            let services = response.data;
            resolve(services);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
          reject(error);
        });
      })


      updateTestimonial = (newTestimonial) => new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('customerId',newTestimonial.customerId);
        formData.append('testimonyId',newTestimonial.testimonialId);
        formData.append('userName',newTestimonial.userName);
        formData.append('description',newTestimonial.description);
        formData.append('date',newTestimonial.date);
        
       
        axios.post(CREATE_TESTIMONIAL_URL,formData)
        .then((response) => {
          // console.log("MgmtService(Response):" + JSON.stringify(response));
          if(response.data){
            let services = response.data;
            resolve(services);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
          reject(error);
        });
      })

      deleteTestimonial = (customerId, testimonialId) => new Promise((resolve, reject) => {

        // console.log("testimonialService.deleteTestimonial() ", testimonialId);
        axios.delete(`${TESTIMONIAL_DELETE_URL}?testimonyId=${testimonialId}`)
        .then((response) => {
          // console.log("deleteTestimonial(Response):" + JSON.stringify(response));
          if(response.data){
            let services = response.data;
            resolve(services);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
          reject(error);
        });
      })
}

const testimonialService = new TestimonialService();
export default testimonialService;
