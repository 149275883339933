class Admin {
  constructor(info) {
    this.customerId = info.customerId;
    this.name = info.name;
    this.email = info.email;
    this.mobile = info.mobile;
    // this.userCategory = info.userCategory;
    this.userType = info.userType;
    this.password = info.password;
}
}
class AdminChangePassword {
  constructor( info) {
    this.email = info.email;
    this.oldpassword = info.oldpassword;
    this.newPassword = info.newPassword;
  }
}
export default { Admin,AdminChangePassword };
export { Admin,AdminChangePassword };



