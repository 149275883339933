import produce from "immer";
import {DISTRIBUTORTYPE_INFO } from "src/actions/mgmtDistributorTypeAction";

const initialState = {
    distributorTypeList: [],
};

const distributorTypeListReducer = (state = initialState, action) => {
    switch (action.type) {
        case DISTRIBUTORTYPE_INFO: {
            const { distributorTypeList } = action.payload;
            return produce(state, (draft) => {
                draft.distributorTypeList = distributorTypeList;
            });
        }
        default: {
            return state;
        }
    }
};

export default distributorTypeListReducer;
