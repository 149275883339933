import { User } from "src/interface/userIf";
import UserTarget from "src/interface/userTargetIf";
import mgmtUser from "src/services/mgmtUser";

export const USER_SEARCH = '@user/search';
export const USER_SEARCH_SUCCESS = '@user/search-success';
export const USER_SEARCH_FAILURE = '@user/search-failure';
export const USERTARGET_SEARCH = '@usertarget/search';
export const USERTARGET_SEARCH_SUCCESS = '@usertarget/search-success';
export const USERTARGET_SEARCH_FAILURE = '@usertarget/search-failure';
export const DISTRIBUTORCATEGORY_LIST = '@distributorcategorylist/search';
export const DISTRIBUTORCATEGORY_LIST_SUCCESS = '@distributorcategorylist/search-success';
export const DISTRIBUTORCATEGORY_LIST_FAILURE = '@distributorcategorylist/search-failure';

export function UserCreate(userInfo) {
  return async (dispatch) => {
    // console.log("USERINFO", userInfo);
    let newUser = new User(userInfo);
    console.log("USERAction():" + JSON.stringify(userInfo) + " " + JSON.stringify(newUser));
    try {
      dispatch({ type: USER_SEARCH });
      const user = await mgmtUser.createUser(newUser);
      dispatch({
        type: USER_SEARCH_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: USER_SEARCH_FAILURE });
      throw error;
    }
  };
}

export function CreateUserTarget(userTargetInfo) {
  return async (dispatch) => {
    console.log("USERTARGETINFO", userTargetInfo);
    let newUserTarget = new UserTarget(userTargetInfo);
    console.log("USERTARGETAction():" + JSON.stringify(userTargetInfo) + " " + JSON.stringify(newUserTarget));
    try {
      dispatch({ type: USERTARGET_SEARCH });
      const userTarget = await mgmtUser.createUserTarget(newUserTarget);
      dispatch({
        type: USERTARGET_SEARCH_SUCCESS,
        payload: {
          userTarget
        }
      });
    } catch (error) {
      dispatch({ type: USERTARGET_SEARCH_FAILURE });
      throw error;
    }
  };
}

export function fetchDistributorCategoryList(cid,userType,customerId) {
  // console.log("cid",cid,userType,customerId);
  return async (dispatch) => {
    try {
      dispatch({ type: DISTRIBUTORCATEGORY_LIST });
      const distributorCategory = await mgmtUser.fetchDistributorCategory(cid,userType,customerId);
      console.log("distributorCategory Action", distributorCategory);
      dispatch({
        type: DISTRIBUTORCATEGORY_LIST_SUCCESS,
        payload: {
          distributorCategory,
        },
      });
    } catch (error) {
      console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: DISTRIBUTORCATEGORY_LIST_FAILURE });
      throw error;
    }
  };
}



