import mgmtId from "src/services/mgmtid";
export const IMAGE_UPLOAD = "@image/upload";
export const FETCH_ID = '@userid/fetch-users';
export const FETCH_ID_SUCCESS = '@userid/fetch-users-success';
export const FETCH_ID_FAILURE = '@userid/fetch-users-failure';

export function fetchUsers() {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_ID });
      const users = await mgmtId.fetchUsers();
      // console.log("fetchUsers Action", users);
      dispatch({
        type: FETCH_ID_SUCCESS,
        payload: {
          users,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: FETCH_ID_FAILURE });
      throw error;
    }
  };
}




