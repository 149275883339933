import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import Header from './Header';
import NewsForm from './NewsForm';
// import ProjectCreateForm from './ProjectCreateForm';
import MperitoCard from 'src/components/MperitoCard';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: 100
    }
}));

function NewsCreate() {
    const classes = useStyles();

    const businessInfo = useSelector((state) => state?.app?.businessInfo);
    const customerId = businessInfo?.customerId;

    useEffect(() => {

    }, [businessInfo]);

    return (
        <Page
            className={classes.root}
            title="News Create"
        >
            <Container maxWidth={false}>
                <Header />
                <MperitoCard title={`Create News ( ${businessInfo?.businessName} - ${businessInfo?.customerId} )`}>
                    <NewsForm customerId={customerId} />
                </MperitoCard>
            </Container>
        </Page>
    );
}

export default NewsCreate;