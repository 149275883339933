import produce from "immer";
import { PRODUCT_CREATE_SUCCESS, PRODUCT_INFO_DASHBOARD_SUCCESS } from "src/actions/mgmtProductAction";
import {
    BUSINESS_SEARCH,
    BUSINESS_SEARCH_FAILURE,
    BUSINESS_SEARCH_SUCCESS
} from "src/actions/mgmtSearchProductAction";

const initialState = {
    suppliers: [],
    productInfo:[],
    products:[]
};

const searchProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case BUSINESS_SEARCH: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.suppliers = [];
            });
        }

        case BUSINESS_SEARCH_SUCCESS: {
            const { searchBusiness } = action.payload;
            return produce(state, (draft) => {
                draft.suppliers = searchBusiness;
            });
        } 
         case PRODUCT_INFO_DASHBOARD_SUCCESS: {
            const { productInfo } = action.payload;
            return produce(state, (draft) => {
                draft.productInfo = productInfo;
            });
        }

        case PRODUCT_CREATE_SUCCESS: {
            const { product } = action.payload;
            return produce(state, (draft) => {
                draft.products = product;
            });
        }
        case BUSINESS_SEARCH_FAILURE: {
            return produce(state, () => {
                // Maybe store error
            });
        }
        default: {
            return state;
        }
    }
};

export default searchProductReducer;


