import produce from "immer";
import { SIGNOUT } from "src/actions/accountActions";

import { FETCH_GREETINGS, FETCH_GREETINGS_SUCCESS, FETCH_GREETINGS_FAILURE } from "src/actions/greetingAction";

const initialState = {
    greetings: [],

};

const greetingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GREETINGS_SUCCESS: {
            const { greetings } = action.payload;
            return produce(state, (draft) => {
                draft.greetings = greetings;
            });
        }

        case FETCH_GREETINGS:
        case FETCH_GREETINGS_FAILURE: {
            return produce(state, (draft) => {
                draft.greetings = [];
            })
        }

        case SIGNOUT: {
            return produce(state, (draft) => {
                draft.greetings = [];
            })
        }

        default: {
            return state;
        }
    }
};

export default greetingReducer;


