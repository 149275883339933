import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles'
import Page from 'src/components/Page';
import Header from './Header';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import CustomerViewForm from './CustomerInfoViewForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function CustomerInfoView() {
  const {state} = useLocation();

  const bid = state.brandDetails[0].customerId
  const bid1 = state.brandDetails[0].customerId
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [service, setService] = useState();

  const getService = useCallback(() => { 
      setService(state)
  }, [isMountedRef]);

  const userTy = useSelector(
    (state) => state?.account?.user?.userType
  );

  const userType = userTy

  const cusid = (userType <= "ADMIN") ? bid :bid1
  const [banner, setBanner] = useState(null);
  const getBanner = useCallback(() => {
   
    axios
      .get(`api/customer/brand?brandId=${cusid}`) //, {})
      .then((response) => {
        if (isMountedRef.current) {
          // console.log("getBanner =>" + JSON.stringify(response.data.data));
      
          if(response?.data?.data){
            // setProject(response.data.data.projects);
            setBanner(response.data.data);
          }else{
            setBanner([]);
          }
        }
      });
  }, [isMountedRef]);

  useEffect(() => {
    getBanner();
  }, [getBanner]);


  useEffect(() => {
    getService();
  }, [getService]);

  if (!service) {
    return null;
  }

  if (!banner) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Edit Customer Brand Details"
    >
      <Container maxWidth="lg">
        <Header />
        <br/>
        <CustomerViewForm service={service} banner={banner} userType={userType} state={state}/>
      </Container>
    </Page>
  );
}

export default CustomerInfoView;
