import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles'

import Page from 'src/components/Page';
import Header from './Header';
import ServiceUpdate from './CustomerInfoEditForm';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import CategoryForm from './CustomerInfoEditForm';
import CustomeForm from './CustomerInfoEditForm';
import CustomerInfoEditForm from './CustomerInfoEditForm';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function CustomerInfoEdit({ match, location }) {
  const {state} = useLocation();
  console.log("STATE",state);
  const brandDetails = state.brandDetails[0]
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [service, setService] = useState();
 
  const getService = useCallback(() => {
      setService(state)
  }, [isMountedRef]);

  useEffect(() => {
    getService();
  }, [getService]);

  if (!service) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Edit Customer Brand Details"
    >
      <Container maxWidth="lg">
        <Header />
        <br/>
        <CustomerInfoEditForm service={service} brandDetails={brandDetails}/>

      </Container>
    </Page>
  );
}

export default CustomerInfoEdit;
