import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ADMIN_TYPE } from "src/utils/constants";
import { AdminCreate, fetchAdminList } from "src/actions/mgmtAdminAction";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function AdminCreateForm({ className, customerIdList, ...rest }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (

    <Formik
      initialValues={{
        customerId: "",
        name: "",
        email: "",
        mobile: "",
        userType: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(100)
          .required("Email is required"),
        password: Yup.string().min(5).max(15).required("Password is required"),
        name: Yup.string().max(50).required("Name is required"),
        mobile: Yup.string().max(12).required("Mobile is required"),
        userType: Yup.string().required("User Type is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        //console.log("Submitting the request", businessId, addedBy, values);
        try {
          await dispatch(AdminCreate(values));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Admin Created", {
            variant: "success",
          });
          dispatch(fetchAdminList(values.customerId));
          navigate("/app/management/admin/view");
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("customerId or name Exits", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Create Admin" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={values.userType}
                    onChange={(event, newValue) => {
                      setFieldValue("userType", newValue);
                    }}
                    disablePortal
                    id="userType"
                    options={ADMIN_TYPE}
                    required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    error={errors.userType}
                    helperText={errors.userType}
                    renderInput={(params) => (
                      <>
                        {" "}
                        <TextField {...params} label="User Type *"
                          error={Boolean(touched.userType && errors.userType)}
                          helperText={touched.userType && errors.userType}
                        />
                      </>
                    )}
                  />
                </Grid>
                {values.userType === "ADMIN" || values.userType === "SUPPORT" ?
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <div>
                      <FormControl fullWidth required>
                        <InputLabel id="demo-simple-select-label">Select CustomerId</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select CustomerId"
                          onChange={handleChange}
                          name="customerId"
                          onBlur={handleBlur}
                          value={values.customerId}
                        >
                          {customerIdList.map((cities) => (
                            <MenuItem value={cities.customerId}>
                              {cities.customerId}
                              {"..."}
                              {cities.businessName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FormControl>
                </Grid>
                :
                <Box/>
                          } 
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.name && errors.name
                    )}
                    fullWidth
                    helperText={
                      touched.name && errors.name
                    }
                    label="Name *"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.mobile && errors.mobile
                    )}
                    fullWidth
                    helperText={
                      touched.mobile && errors.mobile
                    }
                    label="Mobile *"
                    name="mobile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.mobile}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.email && errors.email
                    )}
                    fullWidth
                    helperText={
                      touched.email && errors.email
                    }
                    label="Email *"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.password && errors.password
                    )}
                    fullWidth
                    helperText={
                      touched.password && errors.password
                    }
                    label="Password *"
                    type="password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Create Admin
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

AdminCreateForm.propTypes = {
  className: PropTypes.string,
};

export default AdminCreateForm;


