import { CREATE_SERVICE_URL, FETCH_SERVICES_URL } from 'src/constants/services';
import axios from 'src/utils/axios';

class ServiceService {

  fetchServices = (customerId) => new Promise((resolve, reject) => {
    axios
      .get(`${FETCH_SERVICES_URL}?customerId=${customerId}`)
      .then((response) => {
        // console.log("ServiceService(fetchService->Response): 1", response);
        if (response.data) {
          let services = response.data?.data;
          // console.log("ServiceService(fetchService)", services);
          resolve(services);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        // console.log("ServiceService.fetchService (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  createService = (newService, image) => new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('customerId', newService.customerId);
    formData.append('serviceName', newService.serviceName);
    formData.append('description', newService.description);
    formData.append('videoLink', newService.videoUrl);
    formData.append('serviceImage', image);


    axios.post(CREATE_SERVICE_URL, formData)
      .then((response) => {
        // console.log("ServiceService(Response):" + JSON.stringify(response));
        if (response.data) {
          let services = response.data;
          resolve(services);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("ServiceService.createService (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })
}

const serviceService = new ServiceService();
export default serviceService;
