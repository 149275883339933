import { Typography } from "@mui/material";
import React from "react";

function Support() {
    return(
        <div>
            <Typography>You Are Not Allowed</Typography>
        </div>
    )
}

export default Support