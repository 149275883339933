import React, { useCallback, useState } from "react";
import { Container, Grid } from "@mui/material";
import Page from "src/components/Page";
import { makeStyles } from '@mui/styles';
import Header from "./Header";
import CategoryCreateForm2 from "./ImportCsvFileForm";
import {Box} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchUsers, setProductData } from "src/actions/idActions";
import useIsMountedRef from "src/hooks/useIsMountedRef";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
}));

function ImportFile1() {

  const classes = useStyles();

  const dispatch = useDispatch();

  const clientImage = useSelector(
    (state) => state?.user?.users?.customerList
  );
  
  useEffect(() => {
    dispatch(fetchUsers());
  }, []);
 
  if (!clientImage) {
    return null;
  }
 
  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false} className={classes.container}>
        <Header />
        <Box sx={{ flexDirection: "row", display: "flex" }}>
          <CategoryCreateForm2 clientImage={clientImage} />
        </Box>
      </Container>
    </Page>
  );
}

export default ImportFile1;

