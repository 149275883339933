import { useRoutes } from 'react-router-dom';

// routes
import DashboardRoutes from './DashboardRoutes';
import AuthRoutes from './AuthRoutes';
import HomeRoutes from './HomeRoutes';
import ErrorRoutes from './ErrorRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {

    return useRoutes([HomeRoutes, AuthRoutes, DashboardRoutes, ErrorRoutes]);
}
