import React, {
  useState,
} from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import JsonDownload from './download';
import { orderExport } from 'src/actions/mgmtOrderAction';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function OrderJsonDownload() {

  const dispatch = useDispatch();
  const classes = useStyles();

  const [id, setId] = React.useState('');
  const [id1, setId1] = React.useState('');

  const [isShown1, setIsShown1] = useState(false);
  const handleClick1 = event => {
  setIsShown1(current => !current);
  };

  const handleChange = (event) => {
    setId(event.target.value);
  };

  const handleChange1 = (event) => {
    setId1(event.target.value);
    dispatch(orderExport(id,event.target.value));
  };

  const userType = useSelector(
    (state) => state?.account?.user?.userType
  );

  const customerList = useSelector(
    (state) => state?.user?.users?.customerList
  );

  if (!customerList) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Order Export"
    >
      <Container maxWidth={false}>
      <Typography
            variant="h3"
            color="textPrimary"
          >
           Export Order
          </Typography>
      <Grid container spacing={3}>
      {
          userType !== "ADMIN" && userType !== "SUPPORT" ?
            <Card style={{width:450,marginLeft:"2em",marginTop:"3em" }}>
              <CardContent>
                <Grid container spacing={2}> <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select CustomerId *</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          label="Select CustomerId *"
                          onChange={handleChange}
                          value={id}
                        >
                          {customerList.map((list) => (
                            <MenuItem value={list.customerId}>

                              {list.customerId}
                              {"..."}
                              {list.businessName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    onChange={handleChange1}
                    value={id1}
                    variant="outlined"
                    type="date" 
                  />
                </Grid>
                </Grid>
              </CardContent>
            </Card> :
            <Box />
        }
                </Grid>
          <JsonDownload  onClick={handleClick1} id={id} id1 = {id1}/>
        {isShown1 && <Box />}
      </Container>
    </Page>
  );
}

export default OrderJsonDownload;
