import { deleteCategory } from 'src/actions/mgmtCategoryAction';
import axios from 'src/utils/axios';


class MgmtCategory {

  createCategory = (newCategory, image, id, userType) => new Promise((resolve, reject) => {
    const customerId = newCategory.customerId
    const formData = new FormData()
    // const cusid = (userType !== "ADMIN") ? id : customerId;

    formData.append('categoryData', `{"customerId":"${customerId}",
                                      "name":"${newCategory.name}", 
                                      "parentCategory":"${newCategory.parentCategory}",
                                      "description":"${newCategory.description}"
                                     }`);
    formData.append('categoryImage', image);

    axios.post('/api/admin/catalog/category', formData)
      .then((response) => {
        // console.log("MgmtCategory(Response):" + JSON.stringify(response));
        if (response.data.data.category) {
          let category = response.data.data.category;
          resolve(category);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtCategory.createCategory (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  updateCategory = (categoryInfo, image) => new Promise((resolve, reject) => {
    // console.log("categoryInfo",categoryInfo)
    const formData = new FormData()

    formData.append('categoryData', `{"customerId":${categoryInfo.customerId},
    "categoryId":${categoryInfo.categoryId},
    "description":"${categoryInfo.description}",
     "name":"${categoryInfo.name}"
    }`);
    // formData.append('categoryImage', image);

    axios.put('/api/admin/catalog/category', formData)
      .then((response) => {
        console.log("MgmtCategory(Response):" + JSON.stringify(response));
        if (response.data.data.category) {
          let category = response.data.data.category;
          resolve(category);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtCategory.createCategory (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  deleteCategory = (customerId, categoryId) =>
    new Promise((resolve, reject) => {

      axios
        .delete(`/api/admin/catalog/category?customerId=${customerId}&categoryId=${categoryId}`)
        .then((response) => {
          resolve();
        })
        .catch((error) =>{
          // console.log("deleteCategory error", error);
          reject(error?.response?.data);
        })
    })




  fetchCategoryList = (customerId) => new Promise((resolve, reject) => {

    let searchUrl = "/api/admin/catalog/category";
    searchUrl +=  `?&customerId=${customerId}`;
    axios
      .get(searchUrl)
      .then((response) => {
        // console.log("MgmtUser(fetchUsers->Response): 1", response);
        if (response?.data?.data?.categories) {
          let categories = response.data?.data?.categories;
          // console.log("MgmtUser(fetchUsers)", users);
          resolve(categories);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  fetchCustomerList = (customerId) => new Promise((resolve, reject) => {
    axios
      .get(`/api/customer/details?customerId=${customerId}`)
      .then((response) => {
        // console.log("MgmtUser(fetchUsers->Response): 1", response);
        if (response?.data?.data?.categories) {
          let customer = response.data?.data?.categories;
          // console.log("MgmtUser(fetchUsers)", users);
          resolve(customer);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

}

const mgmtCategory = new MgmtCategory();

export default mgmtCategory;
