import produce from "immer";
import { IMAGE_UPLOAD } from "src/actions/imageActions";

const initialState = {
    image: [],
};

const imageReducer = (state = initialState, action) => {
    switch (action.type) {
        case IMAGE_UPLOAD: {
            return produce(state, (draft) => {
                const {file, type,customerId} = action.payload;
                // Ensure we clear current session
                draft.image = {file, type,customerId};
            });
        }
        default: {
            return state;
        }
    }
};

export default imageReducer;


