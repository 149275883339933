export const RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount";
export const DAEMON = "@@saga-injector/daemon";
export const ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount";


export const DISTRIBUTORCATEGORY_TYPE = [
  "direct",
  "percentage",
];

export const USER_TYPE = [
  "SALES",
  "USER",
  "DISTRIBUTOR",
  "SELLER",
];

export const USER_CATEGORY = [
  "BASIC",
  "PREMIUM",
];

export const ORDER_STATUS_TYPE = [
  "CREATED",
  "INPROGRESS",
  "PAID",
  "READY_TO_BE_SHIPPED",
  "IN_TRANSIT",
  "SHIPPED",
  "DELIVERED",
  "CANCELLED",
];

export const USER_TARGRT_TYPE = [
  "DAILY",
  "MONTHLY",
  "QUARTERLY",
];

export const PAYMENTGATEWAY_TYPE = [
  "ONLINE",
  "OFFLINE",
];

export const FONTFAMILY_TYPE = [
"Literata",
"Lato",
"Montserrat",
"Open Sans",
"Raleway",
"Oswald",
"Merriweather",
"Poppins",
"Roboto Slab",
"Playfair Display"
];

export const PAYMENTGATEWAYTYPE_TYPE = [
  "Razorpay",
  "PayU",
  "Instamojo",
  "CCAvenue",
];

export const SHOW_TYPE = [
  'false',
  'true'
];

export const ADMIN_TYPE = [
  "SUPER_ADMIN",
  "ADMIN",
  "SUPPORT",
  "MPERITO_ADMIN",
];

export const APIResponseStatusEnum = {
  SERVER_SUCCESS_CODE: 200,
  SERVER_ERROR_CODE: 400,
  SERVER_UNAUTHORIZED: 401,
  SERVER_ERROR_FORBIDDEN_CODE: 403,
  SERVER_NOT_FOUND_CODE: 404,
  SERVER_INTERNAL_ERROR_CODE: 500,
  SERVER_REQUEST_TIMEOUT_CODE: 502,
};

