import { Product } from "src/interface/productIf";
import mgmtProduct from "src/services/mgmtProduct";

export const SERVICES_INFO = '@service/info';
export const SERVICE_CREATE = '@service/create';
export const SERVICE_CREATE_SUCCESS = '@service/create-success';
export const SERVICE_CREATE_FAILURE = '@service/create-failure';
export const SERVICE_UPDATE = '@service/update';
export const SERVICE_UPDATE_SUCCESS = '@service/update-success';
export const SERVICE_UPDATE_FAILURE = '@service/update-failure';
export const PRODUCTS_INFO = '@product/info';
export const PRODUCT_CREATE = '@product/create';
export const PRODUCT_CREATE_SUCCESS = '@product/create-success';
export const PRODUCT_CREATE_FAILURE = '@product/create-failure';
export const PRODUCTS_INFO_DASHBOARD = '@productinfodashboard/info';
export const PRODUCT_INFO_DASHBOARD_SUCCESS = '@productinfodashboard/info-success';
export const PRODUCT_INFO_DASHBOARD_FAILURE = '@productinfodashboard/info-failure';

export const PRODUCT_UPDATE_SUCCESS = "@product/update-success";
export const PRODUCT_UPDATE_FAILED = "@product/update-failed";

export function createProduct(userId, productInfo,image,files,customerId, userType) {
  return async (dispatch) => {
    //console.log("createProduct():", userId, productInfo);
    let newProduct = new Product(userId, productInfo,image,files);
    newProduct.addedBy = userId;
    // console.log("createProduct():" + JSON.stringify(productInfo) + " " + JSON.stringify(newProduct));
    try {
      dispatch({ type: SERVICE_CREATE });
      const product = await mgmtProduct.createProduct(newProduct,image,files,customerId,userType);
      // console.log("createProduct()(after):" + JSON.stringify(product));
      dispatch({
        type: SERVICE_CREATE_SUCCESS,
        payload: {
          product
        }
      });
    } catch (error) {
      dispatch({ type: SERVICE_CREATE_FAILURE });
      throw error;
    }
  };
}


export function updateProduct(productInfo) {
  return async (dispatch) => {

  
  console.log(`MgmtProductAction.updateProduct() :: productInfo: `, productInfo);
  const payload = productInfo;
  const formData = new FormData()
  formData.append('productData', `{"customerId":${productInfo.customerId}, 
    "categoryId":[${productInfo.categoryId}],
    "productId":${productInfo.productId},
    "productName":"${productInfo.productName}","productSKU":"${productInfo.productSKU}",
    "price": {
      "sellPrice": ${productInfo.sellPrice},
      "specialPrice": {
        "price": ${productInfo.specialPrice},
        "discount": ${productInfo.discount},
        "from": "${productInfo.from}",
        "till": "${productInfo.till}"
      }
    }
    ,"make":"${productInfo.make}"
    ,"description":"${productInfo.description}"
    ,"header":"${productInfo.header}"
    ,"metrics":"${productInfo.metrics}"
    ,"brandName":"${productInfo.brandName}"
    ,"availableStock":"${productInfo.availableStock}"
    ,"amPartNo":"${productInfo.amPartNo}"
    ,"additionalFeatures":${JSON.stringify(productInfo.additionalFeatures)}
  }`);
  console.log(`MgmtProductAction.updateProduct()  :: formData: `, formData);

  try {

    const product = await mgmtProduct.updateProduct(formData);
    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: {
        product
      }
    });

  } catch (error) {
    console.log("MgmtProductAction.updateProduct() : ", error);
    dispatch({type: PRODUCT_UPDATE_FAILED});
    throw error;
  }




  };
}

export function updateService(userId, serviceId, productInfo,image,files) {
  return async (dispatch) => {
    console.log("updateProductAction():", userId, serviceId, productInfo);
    let editProduct = new Product(userId, productInfo,image,files);
    editProduct.serviceId = serviceId;
    editProduct.updatedBy = userId;
    console.log("editProductAction():" + JSON.stringify(productInfo) + " " + JSON.stringify(editProduct));
    try {
      dispatch({ type: SERVICE_UPDATE });
      const product = await mgmtProduct.updateService5(editProduct,image,files);
      dispatch({
        type: SERVICE_UPDATE_SUCCESS,
        payload: {
          product
        }
      });
    } catch (error) {
      dispatch({ type: SERVICE_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function fetchProductDetails(customerId, categoryId) {
  console.log("fetchProductionDetails() : ", customerId, categoryId);
  return async (dispatch) => {
    try {
      dispatch({ type: PRODUCTS_INFO });
      const product = await mgmtProduct.getProductDetails(customerId, categoryId);
      dispatch({
        type: PRODUCT_CREATE_SUCCESS,
        payload: {
          product,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: PRODUCT_CREATE_FAILURE });
      throw error;
    }
  };
}

export function fetchProductDetailsForDashboard(id,customerId,customerType) {
  return async (dispatch) => {
    try {
      dispatch({ type: PRODUCTS_INFO_DASHBOARD });
      const productInfo = await mgmtProduct.getProductDetailsForDashboard(id,customerId,customerType);
      dispatch({
        type: PRODUCT_INFO_DASHBOARD_SUCCESS,
        payload: {
          productInfo,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: PRODUCT_INFO_DASHBOARD_FAILURE });
      throw error;
    }
  };
}
