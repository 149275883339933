import axios from 'src/utils/axios';

class MgmtProductUpload {

  uploadProduct = (newKart,gstin) => new Promise((resolve, reject) => {

    const formData = new FormData()
    formData.append('customerId',newKart.customerId);
    formData.append('excelSheet',gstin);
    axios.post('/api/admin/catalog/product/upload',formData)
    .then((response) => {
      // console.log("MgmtService(Response):" + JSON.stringify(response));
      if(response.data){
        let services = response.data;
        resolve(services);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

}

const mgmtproductUpload = new MgmtProductUpload();

export default mgmtproductUpload;




