import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux';

import Page from 'src/components/Page';
import Header from './Header';

import MperitoCard from 'src/components/MperitoCard';
import SummarySection from './components/SummarySection';
import { fetchUserData } from 'src/actions/accountActions';
import { fetchCategory } from 'src/actions/mgmtSelectValueAction';
import { fetchProductDetailsForDashboard } from 'src/actions/mgmtProductAction';
import { fetchOrders } from 'src/actions/orderActions';
import { fetchDashboardFinancialData } from 'src/actions/dashboardFinancialActions';
import TrendSection from './components/TrendSection';
// import { fetchOrder } from 'src/actions/mgmtOrderAction';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: 100
    }
}));

function DashboardFinancial() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const businessInfo = useSelector((state) => state?.app?.businessInfo);
    const customerId = businessInfo?.customerId;

    // TODO_SP : Need to replace the 4 calls to fetch data in single API
    useEffect(() => {
        // console.log("DashboardFinancial() customerId: ", customerId);
        dispatch(fetchDashboardFinancialData(customerId));


        // dispatch(fetchUserData(customerId));
        // dispatch(fetchCategory(customerId));
        // dispatch(fetchOrders(customerId));
        // dispatch(fetchProductDetailsForDashboard(customerId, customerId, "User"));
    }, [businessInfo]);

    return (
        <Page
            className={classes.root}
            title="Dashboard"
        >
            <Container maxWidth={false}>
                <Header />
                <MperitoCard title={`Dashboard Overview ( ${businessInfo?.businessName} - ${businessInfo?.customerId} )`} />                
                <SummarySection />
                <TrendSection />
            </Container>
        </Page>
    );
}

export default DashboardFinancial;