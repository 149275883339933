import { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Chart,
  PointElement,
  LineElement,
} from 'chart.js';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import {
  ResponsiveContainer,
} from "recharts";
import axios from 'src/utils/axios';

import { Bar, Line } from 'react-chartjs-2';
import { useStyles } from './BodyStyles';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  indexAxis: 'x',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'center',
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Horizontal Bar Chart',
    // },
  },
};
Chart.register(PointElement);
const ProductBarGraph = ({cusid}) => {
  // console.log("CU",cusid)
  const classes = useStyles();
  const [data, setData] = useState({
    // labels:['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    datasets: [

    ],
  });
  const fetchAndUpdate = async () => {


    const url = `/api/admin/order/month/product-trend?customerId=${cusid}`;
    // console.log("RESPONSE:", url);
    const labelSet = []
    const labels1 = ['4', '8', '8', '5', '5', '8', '9'];
    const labels = ['Week1', 'Week2', 'Week3', 'Week4', 'Week5'];
    const dataSet1 = [];
    const dataSet2 = [];
    //await fetch(url)
    await axios.get(url).then((response) => {
      const res = response.data;
      // console.log("ressss month (roleInfo): ", res)
      for (const val of res.payload.orders) {
        dataSet1.push(val.totalQuantity,);
        // dataSet2.push(val._id.weekOfMonth)
         labelSet.push(val._id.weekOfMonth)
      }
      setData({
        labels: labelSet,
        labels1: dataSet1,
        datasets: [
          {
            label: 'Total Quantity',
            data: dataSet1,
            borderColor: 'rgb(28,119,171)',
            backgroundColor: 'rgb(28,119,171)',
          },
          // {
          //   label: 'UserType',
          //   data:dataSet2,
          //   borderColor: 'rgb(58,149,249)',
          //   backgroundColor: 'rgb(58,149,249)',
          // },
        ],
      })
      // console.log("arrData (dataSet1): ", dataSet1,)
    }).catch(e => {
      // console.log("error (roleInfo): ", e)
    })
  }

  useEffect(() => {
    fetchAndUpdate();
  }, []);

  return (
    <Grid item xs={12} sm={7} md={6} >
      <Card component={Paper} style={{ marginTop: "2em",width:550}}>
        <CardContent>
          <Typography variant='h6' className={classes.cardTitle} align='left'>
        Weekly Product Trend
          </Typography>
        </CardContent>
        <Divider />
        <CardContent>
          <Bar data={data} options={options} />
        </CardContent>
      </Card>
    </Grid>
  )
}
export default ProductBarGraph;