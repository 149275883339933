import axios from 'src/utils/axios';


class MgmtGreeting {

  createGreeting = (newGreeting,image) => new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('customerId',newGreeting.customerId);
    formData.append('title',newGreeting.title);
    // formData.append('isActive',newGreeting.isActive);
    formData.append('greetImage',image);
    formData.append('videoUrl',newGreeting.videoUrl);
   
    axios.post('/api/admin/customer/info/greeting',formData)
    .then((response) => {
      // console.log("MgmtService(Response):" + JSON.stringify(response));

      if(response.data){
        let services = response.data;
        resolve(services);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })


  updateGreeting = (newGreeting,greetingImage) => new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('customerId',newGreeting.customerId);
    formData.append('title',newGreeting.title);
    formData.append('greetId',newGreeting.greetId);
    formData.append('greetImage',greetingImage);
    formData.append('videoUrl',newGreeting.videoUrl);
   
    axios.post('/api/admin/customer/info/greeting',formData)
    .then((response) => {
      // console.log("MgmtGreeting(Response):" + JSON.stringify(response));
      if(response.data){
        let services = response.data;
        resolve(services);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  fetchGreeting = (cid) => new Promise((resolve, reject) => {
    // const cusid = (userType === "SUPER_ADMIN") ? cid : customerId;
    axios
    .get('/api/customer/info/greetings', {
      headers: {
        'customerId': `${cid}`
      }
    })
      .then((response) => {
        // console.log("MgmtUser(fetchUsers->Response): 1", response);
        if (response?.data) {
          const nodata = []
          const greeting = (response?.data?.data) ? response.data.data.greetingsRes : nodata;
          resolve(greeting);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })
}

const mgmtgreeting = new MgmtGreeting();

export default mgmtgreeting;
