import { IMAGE_TYPE } from "src/config";
import mgmtImageUpload from "src/services/mgmtImageService";

export const IMAGE_UPLOAD = "@image/upload";
export const IMAGE_UPLOAD_SUCCESS = "@image/upload-success";
export const IMAGE_UPLOAD_FAILURE = "@image/upload-failure";
export const PROJECT_IMAGE_UPLOAD_SUCCESS = "@project-image/upload-success";

export function uploadImage(file,type,customerId,id,service,index) {
  return async (dispatch) => {
    try {
      console.log("ImageAction.uploadImage:", file, type, customerId);
      dispatch({
        type: IMAGE_UPLOAD,
        payload: {
          file,
          type,
          customerId,
          id,
          service,
          index
        },
      });
      // console.log("ImageAction.uploadImage:", file, type, name, supplierId);
      if (type === IMAGE_TYPE.LOGO || type === IMAGE_TYPE.SHORTLOGO || type === IMAGE_TYPE.BANNER1 
        || type === IMAGE_TYPE.BANNER2 || type === IMAGE_TYPE.BANNER3 || type === IMAGE_TYPE.BANNER4|| type === IMAGE_TYPE.BANNER5 || type === IMAGE_TYPE.BANNER6
        || type === IMAGE_TYPE.NEWSIMAGE || type === IMAGE_TYPE.GREETINGIMAGE || type === IMAGE_TYPE.CATEGORYIMAGE
        || type === IMAGE_TYPE.BASEIMAGE ||  type === IMAGE_TYPE.UPDATEBANNER1 ||  type === IMAGE_TYPE.UPDATEBANNER2
        ||  type === IMAGE_TYPE.UPDATEBANNER3 ||  type === IMAGE_TYPE.UPDATEBANNER4 ||  type === IMAGE_TYPE.UPDATEBANNER5
        ||  type === IMAGE_TYPE.UPDATEBANNER6 ||  type === IMAGE_TYPE.PRODUCTIMAGE1 ||  type === IMAGE_TYPE.PRODUCTIMAGE2
        ||  type === IMAGE_TYPE.PRODUCTIMAGE3 ||  type === IMAGE_TYPE.PRODUCTIMAGE4 ||  type === IMAGE_TYPE.PRODUCTIMAGE5
        ||  type === IMAGE_TYPE.PRODUCTIMAGE6 ||  type === IMAGE_TYPE.UPDATEPRODUCTIMAGE1
        ||  type === IMAGE_TYPE.UPDATEPRODUCTIMAGE2 ||  type === IMAGE_TYPE.UPDATEPRODUCTIMAGE3
        ||  type === IMAGE_TYPE.UPDATEPRODUCTIMAGE4 ||  type === IMAGE_TYPE.UPDATEPRODUCTIMAGE5
        ||  type === IMAGE_TYPE.UPDATEPRODUCTIMAGE6 || type === IMAGE_TYPE.PRODUCT_IMAGE
        ||  type === IMAGE_TYPE.TESTIMONIAL || type === IMAGE_TYPE.SERVICE
      ) 
      {
        const resp = await mgmtImageUpload.uploadImage(file, type,customerId,id,service,index);
        // console.log("ImageAction.uploadImage: Resp:", resp);
        dispatch({
          type: PROJECT_IMAGE_UPLOAD_SUCCESS,
          payload: {
            ...resp,
          },
        });
        dispatch({
          type: IMAGE_UPLOAD_SUCCESS,
          payload: {
            resp,
          },
        });
      }

    
    } catch (err) {
      // console.log("ImageAction.uploadImag: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: IMAGE_UPLOAD_FAILURE });
      throw error;
    }
  };
}




