import * as React from 'react';
import { BarPlot } from '@mui/x-charts/BarChart';
import { LinePlot } from '@mui/x-charts/LineChart';
import { ChartContainer } from '@mui/x-charts/ChartContainer';

import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';

import { Typography } from '@mui/material';
import MperitoCard from 'src/components/MperitoCard';

export default function ChartBarLine({ title, labely1, labely2, labelx, datay1, datay2, datax }) {

    const series = [
        {
            type: 'bar',
            stack: '',
            yAxisKey: 'labely1',
            data: datay1,
        },
        {
            type: 'line',
            yAxisKey: 'labely2',
            color: 'blue',
            data: datay2,
            showMark: true,
        },
    ];

    if (!datay1 || datay1.length === 0 || !datax || datax.length === 0 || !datax?.[0]) {
        // Display a message or fallback UI when data is not available
        return (
            <MperitoCard title={title}>
                <Typography margin={2}>No data available for the chart.</Typography>;
            </MperitoCard>
        )
    }


    return (
        <MperitoCard title={title}>
            <ChartContainer
                series={series}
                width={500}
                height={400}
                xAxis={[
                    {
                        id: 'labelx',
                        data: datax,
                        scaleType: 'band',
                        valueFormatter: (value) => value.toString(),
                    },
                ]}
                yAxis={[
                    {
                        id: 'labely1',
                        scaleType: 'linear',
                    },
                    {
                        id: 'labely2',
                        scaleType: 'log',
                    },
                ]}
            >
                <BarPlot />
                <LinePlot />
                <ChartsXAxis label={labelx} position="bottom" axisId="labelx" />
                <ChartsYAxis label={labely1} position="left" axisId="labely1" />
                <ChartsYAxis label={labely2} position="right" axisId="labely2" />
            </ChartContainer>
        </MperitoCard>
    );
}
