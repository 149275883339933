class DistributorType {
  constructor( info) {
    this.customerId = info.customerId;
    this.distributorTypeId = info.distributorTypeId;
    this.category = info.category;
    this.discount = info.discount;
   
    
  }
}

export default { DistributorType };
export { DistributorType };



