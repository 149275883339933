class Product {
  constructor(updatedBy, info,categoryId) {
    this.customerId = info.customerId;
    // this.categoryId = info.categoryId;
    if(info && info?.categoryId){
      this.categoryId = `${[info.categoryId]}`;
    }
    this.description = info.description;
    this.header = info.header;
    this.productName = info.productName;
    this.brandName = info.brandName;
    this.availableStock = info.availableStock;
    this.amPartNo = info.amPartNo;
    this.metrics = info.metrics;
    this.productName = info.productName;
    this.price = info.price;
    this.specialPrice = info.specialPrice;
    this.make = info.make;
    this.productSKU = info.productSKU;
    this.baseImageUrl = info.baseImageUrl;
    this.imageURL = info.imageURL;
    this.images = info.images;
    this.additionalFeatures = info.additionalFeatures;
    this.sellPrice = info.sellPrice;
    this.discount = info.discount;
    this.from = info.from;
    this.till = info.till;
    this.updatedBy = updatedBy;
  }
}
export default { Product };
export { Product };
