import React from "react";
import { Container} from "@mui/material";
import Page from "src/components/Page";
import { makeStyles } from '@mui/styles';
import Header from "./Header";
import CategoryCreateForm from "./UserTargetForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "src/actions/idActions";
import { useEffect } from "react";
import UserTargetForm from "./UserTargetForm";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
}));

function UserTarget() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { state } = useLocation();
  // console.log("STATE", state);
  const customerIdList = useSelector(
    (state) => state?.user?.users?.customerList
  );

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const userTy = useSelector(
    (state) => state?.account?.user?.userType
  );
  // console.log("customer",customer)

  const customerId = useSelector(
    (state) => state?.account?.user?.customerId
  );
  // console.log("customer1",customer1)

const userType = userTy
  if (!customerIdList) {
    return null;
  }


  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false} className={classes.container}>
        <Header />
        <UserTargetForm customerIdList={customerIdList} userType={userType} customerId={customerId}state={state}/>
      </Container>
    </Page>
  );
}

export default UserTarget;

