import jwtDecode from "jwt-decode";
import axios from "src/utils/axios";
import { UserProfile } from "src/interface/authIf";

import {
  SIGNUP_URL,
  SIGNIN_URL,
  SIGNOUT_URL,
  GET_USER_PROFILE_URL,
  CHANGE_PASSWORD,
  UPDATE_PROFILE,
} from "src/constants";

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          this.setUserId(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
      this.setUserId(null);
    }
  }

  signup = (newUser) =>
    new Promise((resolve, reject) => {
      axios
        .post(SIGNUP_URL, newUser)
        .then((response) => {
          console.log("authService:signup", response.data);
          if (response.data) {
            const email = response.data;

            console.log("Response: " + JSON.stringify(response.data));

            resolve(email);
          } else {
            console.log("authService:signup: error", response.data);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          console.log("authService:signup: error", error);
          reject(error);
        });
    });
    
//  signinWithEmailAndPassword = (email,password,customerId) =>
//     new Promise((resolve, reject) => {
//       axios
//         .post('/api/auth/admin/signin', {email,password,customerId} )
//         .then((response) => {
  signinWithEmailAndPassword = (email, password,customerId) =>
    new Promise((resolve, reject) => {
      axios
        .post(SIGNIN_URL, {email, password,customerId} )
        .then((response) => {
          console.log(
            "authService:signinWithEmailAndPassword: Response",
            response.data
          );
          if (response.data) {
            const userInfo = response.data.data.user;

            this.setUserId(userInfo.id);
            this.setSession(response.data.data.token);

            const user = new UserProfile(userInfo);

            resolve(user);
          } else {
            console.log(
              "authService:signinWithEmailAndPassword:Error1",
              response.error,
              response.data
            );
            reject(response.error);
          }
        })
        .catch((error) => {
          if (error.response) {
            //console.log("authService:signinWithEmailAndPassword:Error2", error.response);
            reject(error.response.data);
          }

          reject(error);
        });
    });

  loginInWithToken = (userId,token) =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_USER_PROFILE_URL, {
          params: { userId: userId ,token:token},
        })
        .then((response) => {
          if (response.data) {

            const userInfo = response.data.data.user;

            const user = new UserProfile(userInfo);
            console.log("loginInWithToken->User: " + JSON.stringify(user));

            resolve(user);
          } else {
            console.log(
              "loginInWithToken->reject" + JSON.stringify(response.data.error)
            );
            reject(response.data.error);
          }
        })
        .catch((error) => {
          console.log("loginInWithToken->error" + JSON.stringify(error));
          reject(error);
        });
    });

  signout = (token) => {
    this.setSession(null);
    this.setUserId(null);
    new Promise((resolve, reject) => {
      axios
        .post(SIGNOUT_URL,{params:{token}},)
        .then((response) => {
          console.log(
            "authService:signinWithEmailAndPassword: Response",
            response.data
          );
          resolve();
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "authService:signinWithEmailAndPassword:Error2",
              error.response
            );
            reject(error.response.data);
          }
          reject(error);
        });
    });
  };

  setUserId = (userId) => {
    if (userId) {
      localStorage.setItem("userId", userId);
    } else {
      localStorage.removeItem("userId");
    }
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      axios.defaults.headers.common.Authorization = `${accessToken}`;
    } else {
      localStorage.removeItem("accessToken");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem("accessToken");

  getUserId = () => localStorage.getItem("userId");

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  changePassword = (passInfo) =>
    new Promise((resolve, reject) => {
      //console.log("changePassword() Service:" + JSON.stringify(passInfo));

      const payload = passInfo;

      axios
        .post(CHANGE_PASSWORD, { payload })
        .then((response) => {
          console.log("changePassword(response)" + JSON.stringify(response));

          if (response.data.error) {
            reject(response.data.error);
          } else {
            resolve(response.data.message);
          }
        })
        .catch((error) => {
          console.log(
            "changePassword() Service(Response Error):" + JSON.stringify(error)
          );
          reject(error);
        });
    });

  updateProfile = (userInfo) =>
    new Promise((resolve, reject) => {
      //console.log("updateProfile() Service:" + JSON.stringify(userInfo));

      const payload = userInfo;

      axios
        .post(UPDATE_PROFILE, { payload })
        .then((response) => {
          //console.log("updateProfile(response)" + JSON.stringify(response));

          if (response.data.error) {
            reject(response.data.error);
          } else {
            resolve(response.data.payload);
          }
        })
        .catch((error) => {
          //console.log("updateProfile() Service(Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
