import React from "react";
import { Page, Document, StyleSheet } from "@react-pdf/renderer";
import InvoiceTitle from "./InvoiceTitle";
import InvoiceNo from "./InvoiceNo";
import CompanyDetails from "./CompanyDetails";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg";
import InvoiceItemsTable from "./InvoiceItemsTable";
import BillTo from "./BillTo";

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 50,
        paddingRight: 50,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 84,
        height: 70,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

const PdfDocument = ({ invoicedata }) => {
    console.log("invoicedata",invoicedata)
    // const did = invoicedata.discountType
    // const cusid = (did === "BASIC" ) ? 1/10 : 1/5;
    return (
        <Document>
            <Page size="A4" style={styles.page} >
                {/* <Image style={styles.logo} src={logo} /> */}
                <InvoiceTitle title={'Quotation'} />
                <InvoiceNo invoice={invoicedata} />
                <CompanyDetails invoice={invoicedata} />
                <BillTo  invoice={invoicedata}/>
                <InvoiceItemsTable invoice={invoicedata} />
                <InvoiceThankYouMsg />
            </Page>
        </Document>
    );
}

export default PdfDocument;