import produce from "immer";
import { SIGNOUT } from "src/actions/accountActions";

import { NEWSINFO_CREATE_SUCCESS } from "src/actions/mgmtNewsAction";
import { FETCH_NEWS, FETCH_NEWS_SUCCESS, FETCH_NEWS_FAILURE } from "src/actions/newsActions";
const initialState = {
    news: [],
    newsList: [],

};

const newsReducer = (state = initialState, action) => {
    switch (action.type) {
        case NEWSINFO_CREATE_SUCCESS: {   // TODO_SP : Need to review and remove this
            const { news } = action.payload;
            return produce(state, (draft) => {
                draft.news = news;
            });
        }

        case FETCH_NEWS_SUCCESS: {
            const { newsList } = action.payload;

            return produce(state, (draft) => {
                draft.newsList = newsList;
            })
        }



        case FETCH_NEWS:
        case FETCH_NEWS_FAILURE: {
            return produce(state, (draft) => {
                draft.newsList = [];
            })
        }


        case SIGNOUT: {
            return produce(state, (draft) => {
                draft.newsList = [];
            })
        }



        default: {
            return state;
        }
    }
};

export default newsReducer;


