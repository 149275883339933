import React, { useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types';
import Delete from '@mui/icons-material/Delete';
import axios from 'src/utils/axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import {
  Box,
  IconButton,
  SvgIcon,
} from '@mui/material';
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductDetails } from 'src/actions/mgmtProductAction';

function Results({ className, customerId, categoryId, ...rest }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log("Results: ", customerId, categoryId);

  // TODO_SP: Check if this is needed or can be removed witout feature impact
  useEffect(() => {
    dispatch(fetchProductDetails(customerId, categoryId));
  }, [customerId, categoryId]);

  const products = useSelector(
    (state) => state?.searchBusiness?.products
  );

  const handleDelete = async (productId, customerId) => {
    try {
      const deleteResult = await axios.delete(`/api/admin/catalog/product?customerId=${customerId}&productId=${productId}`);
      dispatch(fetchProductDetails(customerId, categoryId));
    } catch (err) { }
  };

  const columns = [
    { field: 'productId', headerName: 'ProductId', width: 100 },
    
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        // console.log("RENDERCELLPARAMS:", params)
        return (
          <div className="cellAction">
            <Tooltip title="Edit Product">
              <IconButton
                onClick={() => {
                  // navigate('/app/management/product/update', { state: params.row });
                  navigate('/app/management/product/update', { 
                    state: {
                      info: params.row,
                      customerId: params?.row?.customerId, 
                      productId: params?.row?.productId
                    }}
                    ); 
                  }
                }
              >
                <SvgIcon fontSize="small">
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip><m>
              <Tooltip title="Delete Product">
                <IconButton
                  onClick={() => handleDelete(params.row.productId, params.row.customerId)}
                >
                  <SvgIcon fontSize="small">
                    <Delete />
                  </SvgIcon>
                </IconButton>
              </Tooltip></m>
          </div>
        );
      },
    },
    { field: 'productName', headerName: 'Product Name', width: 200 },
    { field: 'productSKU', headerName: 'ProductSKU ', width: 150 },
    { field: 'make', headerName: 'Make', width: 200 },    
    {
      field: 'price', headerName: 'Price', width: 100,
      valueGetter: (params) => {
        return params.row.price.sellPrice;
      }
    },
    { field: 'description', headerName: 'Description', width: 100 },
    { field: 'header', headerName: 'Header', width: 100 },
    { field: 'metrics', headerName: 'Stock', width: 100 },
    { field: 'customerId', headerName: 'CustomerId', width: 100 },
    { field: 'iCategoryId', headerName: 'Categories', width: 100 },
  ];

  if (!products) {
    return null;
  }

  return (

    <Box sx={{ height: 500, width: '100%' }}>

      <DataGrid
        rows={products}
        columns={columns}
        getRowId={(row) => row.productId}
        // checkboxSelection={true}
        pageSize={8}
        rowsPerPageOptions={[8]}
      />
    </Box>
  )
}

Results.propTypes = {
  className: PropTypes.string,
};

Results.defaultProps = {
};

export default Results;
