import { Attibute } from "src/interface/attibuteIf";
import mgmtAttibute from "src/services/mgmtAttibute";

export const ATTIBUTE_SEARCH = '@attibute/search';
export const ATTIBUTE_SEARCH_SUCCESS = '@attibute/search-success';
export const ATTIBUTE_SEARCH_FAILURE = '@attibute/search-failure';

export const ATTRIBUTE_INFO = '@attribute/info';
export const ATTRIBUTE_CREATE = '@attribute/create';
export const ATTRIBUTE_CREATE_SUCCESS = '@attribute/create-success';
export const ATTRIBUTE_CREATE_FAILURE = '@attribute/create-failure';

export function createAtti(attibuteInfo) {
  return async (dispatch) => {
    // console.log("ATTIBUTEINFO",attibuteInfo);
    let newAttibute = new Attibute(attibuteInfo);
    // console.log("ATTIBUTEINFO():" + JSON.stringify(attibuteInfo) + " " + JSON.stringify(newAttibute));
    try {
      dispatch({ type: ATTIBUTE_SEARCH });
      const attibute = await mgmtAttibute.createAttibute(newAttibute);
      dispatch({
        type: ATTIBUTE_SEARCH_SUCCESS,
        payload: {
          attibute
        }
      });
    } catch (error) {
      dispatch({ type: ATTIBUTE_SEARCH_FAILURE });
      throw error;
    }
  };
}

export function fetchAttribute(cid) {
  return async (dispatch) => {
    try {
      dispatch({ type: ATTRIBUTE_INFO });
      const attribute = await mgmtAttibute.fetchAttribute(cid);
      // console.log("attribute Action", attribute);
      dispatch({
        type: ATTIBUTE_SEARCH_SUCCESS,
        payload: {
          attribute,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: ATTRIBUTE_CREATE_FAILURE });
      throw error;
    }
  };
}



