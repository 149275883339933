import mgmtCategory from "src/services/mgmtCategory";

export const FETCH1_ID = '@userid1/fetch-users';
export const FETCH1_ID_SUCCESS = '@userid1/fetch-users-success';
export const FETCH1_ID_FAILURE = '@userid1/fetch-users-failure';

export const CUSTOMERSTATE_ID = '@customerstate/fetch-users';
export const CUSTOMERSTATE_ID_SUCCESS = '@customerstate/fetch-users-success';
export const CUSTOMERSTATE_ID_FAILURE = '@customerstate/fetch-users-failure';

export const FETCH_CATEGORIES = '@category/fetch-categories';
export const FETCH_CATEGORIES_SUCCESS = '@category/fetch-categories-success';
export const FETCH_CATEGORIES_FAILURE = '@category/fetch-categories-failure';

export const FETCHCUSTOMER_ID = '@customer/fetch-users';
export const FETCHCUSTOMER_ID_SUCCESS = '@customer/fetch-users-success';
export const FETCHCUSTOMER_ID_FAILURE = '@customer/fetch-users-failure';

export function fetchUsers5(val) {
    // console.log("val",val);
    const users1 = val
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH1_ID, payload: {
        users1
      }, });

    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: FETCH1_ID_FAILURE });
      throw error;
    }
  };
}

export function fetchCategory(customerId) {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_CATEGORIES });
      const category = await mgmtCategory.fetchCategoryList(customerId);
      // console.log("cid Action", cid);
      // console.log("category Action", category);
      dispatch({
        type: FETCH_CATEGORIES_SUCCESS,
        payload: {
          category,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: FETCH_CATEGORIES_FAILURE });
      throw error;
    }
  };
}

export function fetchCustomerDetails(customerId) {
  return async (dispatch) => {
    try {
      // console.log("fetchCustomerDetails: ", customerId);
      if(!customerId) return;

      dispatch({ type: FETCHCUSTOMER_ID })      
        const customer = await mgmtCategory.fetchCustomerList(customerId);
      
      // console.log("customer",customer);
      dispatch({
        type: FETCHCUSTOMER_ID_SUCCESS,
        payload: {
          customer,
        },
      });
    } catch (error) {
      // console.log("authAction:fetchCustomerDetails:Error2", error);
      dispatch({ type: FETCHCUSTOMER_ID_FAILURE });
      throw error;
    }
  };
}

export function customerState(val) {
    // console.log("val",val);
    const cusState = val
  return async (dispatch) => {
    try {
      dispatch({ type: CUSTOMERSTATE_ID, payload: {
        cusState
      }, });

    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: CUSTOMERSTATE_ID_FAILURE });
      throw error;
    }
  };
}

