import axios from 'src/utils/axios';

class MgmtAdmin {

  createAdmin = (newAdmin) => new Promise((resolve, reject) => {

    const payload = newAdmin;

    axios.post('/api/auth/admin/signup', payload)
      .then((response) => {
        console.log("MgmtAdmin(Response):" + JSON.stringify(response));

        if (response.data.data) {
          let admin = response.data.data;
          resolve(admin);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtAdmin.createAdmin (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  adminPasswordChange = (editAdminPassword,email) => new Promise((resolve, reject) => {
    // console.log("EDITDATA SERVICE",editAdminPassword)

    const payload =
    {
        "email": email,
        "oldpassword": editAdminPassword.oldpassword,
        "newpassword": editAdminPassword.newPassword,
    } ;
  
    axios.post('/api/auth/admin/change-password', payload) /* /api/auth/admin/changepassword */
    .then((response) => {
      // console.log("MgmtAdminChangePassword(Response) service:", response);
      console.log("MgmtAdminChangePassword(Response):" + JSON.stringify(response));
      if(response.data){
        let admin = response.data;
        resolve(admin);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      console.log("MgmtAdminChangePassword (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  renewPassword = (values) => new Promise((resolve, reject) => {
    // console.log("EDITDATA SERVICE",editAdminPassword)

    const payload =
    {
        "email": values.email,
        "customerId": values.customerId
    } ;
  
    axios.post('/api/auth/admin/renew-password',payload)
    .then((response) => {
      // console.log("MgmtAdminChangePassword(Response) service:", response);
      console.log("MgmtAdminRenewPassword(Response):" + JSON.stringify(response));
      if(response.data){
        let admin = response.data;
        resolve(admin);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      console.log("MgmtAdminrenewPassword (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  fetchAdminList = (cid) => new Promise((resolve, reject) => {
    // const cusid = (userType === "SUPER_ADMIN") ? cid : customerId;
    // console.log("cid",cid);
    axios
    .get(`/api/auth/admin/all?customerId=${cid}`)
      .then((response) => {
        // console.log("MgmtAdmin(fetchAdmin->Response): 1", response);
        if (response?.data) {
          const nodata = []
          const invoice = (response?.data?.data?.admins) ? response.data.data.admins : nodata;
          resolve(invoice);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })


}

const mgmtAdmin = new MgmtAdmin();

export default mgmtAdmin;
