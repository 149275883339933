import produce from "immer";
import { INVOICE_CREATE_SUCCESS} from "src/actions/mgmtInvoiceAction";


const initialState = {
    invoice: [],


};

const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case INVOICE_CREATE_SUCCESS: {
            const { invoice } = action.payload;
            return produce(state, (draft) => {
                draft.invoice = invoice;
            });
        }

        default: {
            return state;
        }
    }
};

export default invoiceReducer;


