import React, {
  useEffect
} from 'react';
import {
  Container,
  Grid,
  TextField,
  Card,
  CardContent
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Header from './Header';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchBusiness } from 'src/actions/mgmtSearchProductAction';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { fetchUsers } from 'src/actions/idActions';
import Results from './Results';
import { fetchCategory, fetchUsers5 } from 'src/actions/mgmtSelectValueAction';
import { fetchGeneratedQuotationList } from 'src/actions/mgmtQuotationGenerateAction';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function GeneratedQuotations() {
  const dispatch = useDispatch();
  const classes = useStyles();
 
  const [id1, setId1] = React.useState('');

  const customer15 = useSelector(
    (state) => state?.user?.users1
  );

  const selectedCustomerId = (id1) ? id1 : customer15;

  const handleChange1 = (event) => {
    setId1(event.target.value);
    dispatch(fetchUsers5(event.target.value));
    dispatch(fetchCategory(event.target.value));
    dispatch(fetchGeneratedQuotationList(event.target.value));
  };

  useEffect(() => {
    dispatch(getSearchBusiness());
  }, []);

  const customerId = useSelector(
    (state) => state?.user?.users?.customerList
  );
  const userType = useSelector(
    (state) => state?.account?.user?.userType

  );
  const customer1 = useSelector(
    (state) => state?.account?.user?.customerId
  );
 
  const cid12 = (userType === "SUPER_ADMIN") ? selectedCustomerId : customer1;

  const generatedQuotationList = useSelector(
    (state) => state?.quotation?.generatedQuotation
  );
 
  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    dispatch(fetchGeneratedQuotationList(cid12));
  }, []);

  const s = userType

  if (!customerId) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Generated Quotation List"
    >
      <Container maxWidth={false}>
        <Header />
        <br />
        <Card style={{ maxWidth: '1200px' }}>
          <CardContent>
            {
              s !== "ADMIN"  && s !== "SUPPORT"  ? <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select CustomerId *</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        // id="demo-simple-select"
                        label="Select CustomerId *"
                        onChange={handleChange1}
                        value={selectedCustomerId}
                      // value={values.categoryId}
                      >
                        {customerId.map((cities) => (
                          <MenuItem value={cities.customerId}>

                            {cities.customerId}
                            {"..."}
                            {cities.businessName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </FormControl>
              </Grid> :
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Customer Id"
                    name="customerId"
                    onChange={handleChange1}
                    value={customer1}
                    variant="outlined"
                  />
                </Grid>
            }
          </CardContent>
        </Card>
        <br />
        <Results generatedQuotationList={generatedQuotationList} />
      </Container>
    </Page>
  );
}

export default GeneratedQuotations;
