import React, {
  useEffect,
} from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { fetchUsers } from 'src/actions/idActions';
import { fetchCategory, fetchUsers5 } from 'src/actions/mgmtSelectValueAction';
import { fetchDistributorTypeList } from 'src/actions/mgmtDistributorTypeAction';
import Header from './Header';
import DistributorTypeResults from './Result';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function DistributorListView() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [id, setId] = React.useState('');

  const selectedCustomerIdValue = useSelector(
    (state) => state?.user?.users1
  );

  const selectedCustomerId = (id) ? id : selectedCustomerIdValue;

  const handleChange = (event) => {
    setId(event.target.value);
    dispatch(fetchUsers5(event.target.value));
    dispatch(fetchCategory(event.target.value));
    dispatch(fetchDistributorTypeList(event.target.value));
  };

  const customerIdList = useSelector(
    (state) => state?.user?.users?.customerList
  );

  const userTy = useSelector(
    (state) => state?.account?.user?.userType
  );

  const userType = userTy

  const customerId = useSelector(
    (state) => state?.account?.user?.customerId
  );

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const cusId = (userType === "SUPER_ADMIN") ? selectedCustomerId : customerId;

  useEffect(() => {
    dispatch(fetchDistributorTypeList(cusId));
  }, []);

  if (!customerIdList) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Distributor Type List"
    >
      <Container maxWidth={false}>
        <Header />
        <br />
        {
          userType !== "ADMIN" && userType !== "SUPPORT"   ?
            <Card style={{ maxWidth: '1105px', maxHeight: '400px' }}>
              <CardContent>
                <Grid container spacing={2}> <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select CustomerId *</InputLabel>
                        <Select
                          labelId="demo-simple-select-label *"
                          // id="demo-simple-select"
                          label="Select CustomerId"
                          onChange={handleChange}
                          value={selectedCustomerId}
                        >
                          {customerIdList.map((idList) => (
                            <MenuItem value={idList.customerId}>
                              {idList.customerId}
                              {"..."}
                              {idList.businessName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FormControl>
                </Grid>
                </Grid>
              </CardContent>
            </Card> :
            <Box />
          //  <Results2 services={attributeInfo} setServices={setServices}/>
          //   <Grid item xs={12}>
          //   <TextField
          //    fullWidth
          //     label="Customer Id"
          //     name="customerId"
          //     onChange={handleChange}
          //     value={customer1}
          //     variant="outlined"
          //   />
          // </Grid>
        }
        <br />
        <DistributorTypeResults />
      </Container>
    </Page>
  );
}

export default DistributorListView;
