import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import axios from 'src/utils/axios';
import { Formik } from "formik";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSnackbar } from "notistack";
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createCategory } from "src/actions/mgmtCategoryAction";
import { fetchCategory } from 'src/actions/mgmtSelectValueAction';

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function CategoryCreateForm({ className, onSubmitSuccess, customerIdList, customerId, userType, services, id, onClick, ...rest }) {

  const isMountedRef = useIsMountedRef();

  const [services1, setServices1] = useState(null);

  const getServices1 = useCallback(() => {
    axios
      .get(`/api/admin/catalog/category/names?customerId=${id}`) //, {})
      .then((response) => {
        if (isMountedRef.current) {
          // console.log("getServices111 =>" + JSON.stringify(response.data));
          if (response?.data?.data?.categories) {
            // setProject(response.data.data.projects);
            setServices1(response.data.data.categories);
          } else {
            setServices1([]);
          }
        }
      });
  }, [isMountedRef]);

  useEffect(() => {
    getServices1();
  }, [getServices1]);

  const cusid = (userType <= "ADMIN") ? services : services1;

  const classes = useStyles();

  const [image, setImage] = useState('')
  function handleImage(e) {
    console.log(e.target.files)
    setImage(e.target.files[0])
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const addedBy = account.user.email;

  if (!services1) {
    return null;
  }

  return (

    <Formik
      initialValues={{
        customerId: customerId || '',
        name: "",
        parentCategory: "",
        description: "",
        categoryImage: "",
      }}
      validationSchema={Yup.object().shape({

      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

        console.log("Submitting the request", addedBy, id, values);
        try {
          await dispatch(createCategory(addedBy, values, image, id, userType));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Category Created", {
            variant: "success",
          });
          await dispatch(fetchCategory(id));
          navigate("/app/management/category/view");
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("customerId or name Exits", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Create Category" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                {
                  userType !== "ADMIN" ?
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          touched.customerId && errors.customerId
                        )}
                        fullWidth
                        helperText={
                          touched.customerId && errors.customerId
                        }
                        label="Customer Id"
                        name="customerId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={id}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                    :
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          touched.customerId && errors.customerId
                        )}
                        fullWidth
                        helperText={
                          touched.customerId && errors.customerId
                        }
                        label="Customer Id"
                        name="customerId"
                        onBlur={handleBlur}
                        value={values.customerId}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                }
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.name && errors.name
                    )}
                    fullWidth
                    helperText={
                      touched.name && errors.name
                    }
                    label="Name"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Parent Name</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select Parent Name"
                          onChange={handleChange}
                          name="parentCategory"
                          onBlur={handleBlur}
                          value={values.parentCategory}
                        >
                          {cusid.map((cities) => (
                            <MenuItem value={cities.name}>
                              {cities.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.description && errors.description
                    )}
                    fullWidth
                    helperText={
                      touched.description && errors.description
                    }
                    label="Description"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.categoryImage && errors.categoryImage
                    )}
                    fullWidth
                    helperText={
                      touched.categoryImage && errors.categoryImage
                    }
                    type="file"
                    name="categoryImage"
                    onBlur={handleBlur}
                    onChange={handleImage}
                    value={values.image}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Create Category
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

CategoryCreateForm.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default CategoryCreateForm;


