import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { USER_TYPE } from "src/utils/constants";
import { UserCreate } from "src/actions/mgmtUserAction";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function CategoryCreateForm({ className,customerIdList,customerId,userType, ...rest }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // const account = useSelector((state) => state.account);
  // const addedBy = account.user.email;
  // console.log("ServiceCreateForm: addedBy", addedBy);

  return (

    <Formik
      initialValues={{
        customerId:customerId || '',
        name:"",
        GSTN: "",
        email: "",
        mobile: "",
        userType: "",
      }}
      validationSchema={Yup.object().shape({
        customerId: Yup.string().max(50).required("Customer Id is required"),
        name: Yup.string().max(50).required("Name is required"),
        email: Yup.string().max(50).required("Email is required"),
        userType: Yup.string().max(50).required("User Type is required"),
        mobile: Yup.string()
        .matches(
          /^[6-9][0-9]{9}/,
          "Please enter valid 10 digit mobile number"
        )
        .max(10)
        .min(10)
        .required("Please enter mobile number"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        //console.log("Submitting the request", businessId, addedBy, values);
        try {
          await dispatch(UserCreate(values));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("User Created", {
            variant: "success",
          });
          navigate("/app/management/user/view");
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Email or Name Exits", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Create User" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
              {
                userType !== "ADMIN" ? 
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select CustomerId *</InputLabel>
                      <Select
                      required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select CustomerId *"
                        onChange={handleChange}
                        name="customerId"
                        onBlur={handleBlur}
                        value={values.customerId}
                      >
                        {customerIdList.map((cities) => (
                          <MenuItem value={cities.customerId}>
                            {cities.customerId}
                            {"..."}
                            {cities.businessName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </FormControl>
              </Grid> :
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.customerId && errors.customerId
                    )}
                    fullWidth
                    helperText={
                      touched.customerId && errors.customerId
                    }
                    label="Customer Id"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
}

                  <Grid item xs={12} sm={6}>
                  <Autocomplete
                  required
                    value={values.userType}
                    onChange={(event, newValue) => {
                      setFieldValue("userType", newValue);
                    }}
                    disablePortal
                    id="userType"
                    options={USER_TYPE}
                  
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    error={errors.userType}
                    helperText={errors.userType}
                    renderInput={(params) => (
                      <>
                        {" "}
                        <TextField {...params} label="User Type *"

                          error={Boolean(touched.userType && errors.userType)}
                          helperText={touched.userType && errors.userType}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.name && errors.name
                    )}
                    fullWidth
                    helperText={
                      touched.name && errors.name
                    }
                    label="Name *"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.mobile && errors.mobile
                    )}
                    fullWidth
                    helperText={
                      touched.mobile && errors.mobile
                    }
                    label="Mobile *"
                    name="mobile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.mobile}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.email && errors.email
                    )}
                    fullWidth
                    helperText={
                      touched.email && errors.email
                    }
                    label="Email *"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.GSTN && errors.GSTN
                    )}
                    fullWidth
                    helperText={
                      touched.GSTN && errors.GSTN
                    }
                    label="GSTN"
                    name="GSTN"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.GSTN}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Create User
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

CategoryCreateForm.propTypes = {
  className: PropTypes.string,
};

export default CategoryCreateForm;


