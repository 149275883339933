import React, {
  useState,
  useEffect,
} from 'react';
import {
  Container,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Header from './Header';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from 'src/actions/idActions';
import AdminCreateForm from './AdminCreateForm';
import { fetchCategory, fetchUsers5 } from 'src/actions/mgmtSelectValueAction';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function AdminAddView() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [id, setId] = React.useState('');

  const handleChange = (event) => {
    setId(event.target.value);
    dispatch(fetchUsers5(event.target.value));
    dispatch(fetchCategory(event.target.value));
  };

  const customer15 = useSelector(
    (state) => state?.user?.users1
  );

  const selectedCustomerId = (id) ? id : customer15;

  const customerIdList = useSelector(
    (state) => state?.user?.users?.customerList
  );

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  if (!customerIdList) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Create Admin"
    >
      <Container maxWidth={false}>
        <Header />
        <br />
        <AdminCreateForm customerId={selectedCustomerId} customerIdList={customerIdList} />
      </Container>
    </Page>
  );
}

export default AdminAddView;
