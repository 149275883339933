import produce from "immer";
import { SIGNOUT } from "src/actions/accountActions";
import { CUSTOMERORDER_DOWNLOAD_SUCCESS, ORDERINFO_CREATE_SUCCESS } from "src/actions/mgmtOrderAction";
import { FETCH_ORDERS_SUCCESS } from "src/actions/orderActions";

const initialState = {
    orders: [],    
    orderExport:{}

};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORDERS_SUCCESS: {
            const { orders } = action.payload;
            return produce(state, (draft) => {
                draft.orders = orders ? orders : []
            })
        }

        // case ORDERINFO_CREATE_SUCCESS: {
        //     const { order } = action.payload;
        //     return produce(state, (draft) => {
        //         draft.order = order;
        //     });
        // }

        case CUSTOMERORDER_DOWNLOAD_SUCCESS: {
            const { orderExport } = action.payload;
            return produce(state, (draft) => {
                draft.orderExport = orderExport;
            });
        }

        case SIGNOUT: {
            return produce(state, (draft) => {
                draft.orders = [];
            })
        }

        default: {
            return state;
        }

    }
};

export default orderReducer;


