import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ImageEditor from 'src/components/ImageEditor';
import { IMAGE_TYPE } from "src/config";

const ProductImages = ({ productInfo, customerId, IMAGE_PATH }) => {
    const [images, setImages] = useState(productInfo?.images || []);

    const defaultImage = "/static/home/defaultImage.png";

    const addImage = () => {
        setImages([...images, defaultImage]);
    };

    return (
        <Grid container xs={12} spacing={2} margin={2}>
            {images.map((image, index) => (
                <Grid item xs={12} md={6} key={index}>
                    <ImageEditor
                        type={IMAGE_TYPE.PRODUCT_IMAGE}
                        inImage={`${IMAGE_PATH}/${image}`}
                        customerId={customerId}
                        id={productInfo?.productId}
                        service={productInfo}
                        index={index.toString()}
                    />
                </Grid>
            ))}
            <Grid item xs={12} md={6} >
                <Button variant="outlined" color="primary" onClick={addImage}>
                    Add Image
                </Button>
            </Grid>
        </Grid>
    );
};

export default ProductImages;
