import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Breadcrumbs,
  Button,
  Grid,
  Typography,
  SvgIcon,
} from '@mui/material';
import {
  PlusCircle as PlusCircleIcon,
  Download as DownloadIcon,
  Upload as UploadIcon
} from 'react-feather';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {}
}));

function Header({ className,isDisabled, ...rest }) {
  const classes = useStyles();
 
  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Typography
            variant="h3"
            color="textPrimary"
          >
           Customer View
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item>
        <Button
          color="secondary"
          variant="contained"
          className={classes.action}
          component={RouterLink}
          to="/app/management/customer/upload/view"
          disabled={isDisabled}
        >
          <SvgIcon
            fontSize="small"
            className={classes.actionIcon}
          >
            <PlusCircleIcon />
          </SvgIcon>
          Upload Customer Csv or Excel file
        </Button>

      </Grid>
        <Grid item>
        <Button
          color="secondary"
          variant="contained"
          className={classes.action}
          component={RouterLink}
          to="/app/management/customer/create"
          disabled={isDisabled}
        >
          <SvgIcon
            fontSize="small"
            className={classes.actionIcon}
          >
            <PlusCircleIcon />
          </SvgIcon>
          Add Customer
        </Button>
      </Grid>
      {/* <Grid item>
        <Button
          color="secondary"
          variant="contained"
          className={classes.action}
          component={RouterLink}
          to="/app/management/customer/json/download"
          disabled={isDisabled}
        >
          <SvgIcon
            fontSize="small"
            className={classes.actionIcon}
          >
            <PlusCircleIcon />
          </SvgIcon>
          Download Customer Json Or Xls File
        </Button>

      </Grid> */}
    
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
