import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { customerPrivacyPolicyUpdate, fetchCustomerList } from "src/actions/mgmtCustomerCreateAction";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function CustomerPrivacyPolicyEditForm({ className, service, banner, ...rest }) {
 
  const privacyPolicy = service.privacyPolicy[0]
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (

    <Formik
      initialValues={{
        customerId: service.customerId || '',
        policyId:privacyPolicy.policyId || '',
        privacyPolicy:privacyPolicy.privacyPolicy || '',
        termsOfUse:privacyPolicy.termsOfUse || '',
      }}
      validationSchema={Yup.object().shape({
        privacyPolicy: Yup.string().max(10000),
        termsOfUse: Yup.string().max(10000),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await dispatch(customerPrivacyPolicyUpdate(values));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Customer Privacy Policy Updated", {
            variant: "success",
          });
          navigate("/app/management/customer/view");
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Customer Privacy Policy Update Failed", {
            variant: "failure",
          });
          dispatch(fetchCustomerList());
          // navigate("/app/management/customer/view");
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.customerId && errors.customerId
                    )}
                    fullWidth
                    helperText={
                      touched.customerId && errors.customerId
                    }
                    label="customerId"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.policyId && errors.policyId
                    )}
                    fullWidth
                    helperText={
                      touched.policyId && errors.policyId
                    }
                    label="policyId"
                    name="policyId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.policyId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.privacyPolicy && errors.privacyPolicy
                    )}
                    fullWidth
                    helperText={
                      touched.privacyPolicy && errors.privacyPolicy
                    }
                    label="Privacy Policy"
                    name="privacyPolicy"
                    multiline
                    rows={4}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.privacyPolicy}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.termsOfUse && errors.termsOfUse
                    )}
                    fullWidth
                    helperText={
                      touched.termsOfUse && errors.termsOfUse
                    }
                    multiline
                    rows={4}
                    label="Terms Of Use"
                    name="termsOfUse"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.termsOfUse}
                    variant="outlined"
                  />
                </Grid> 
              </Grid>
            </CardContent>
          </Card>

          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              style={{ marginBottom: '5em' }}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

CustomerPrivacyPolicyEditForm.propTypes = {
  className: PropTypes.string,

};

export default CustomerPrivacyPolicyEditForm;


