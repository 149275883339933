import produce from "immer";
import { CUSTOMERJSONDOWNLOAD_UPDATE_SUCCESS, FETCHADMINCUSTOMERPAYMENT_INFO_SUCCESS, FETCHADMINCUSTOMER_INFO_SUCCESS, FETCHCUSTOMER_INFO_SUCCESS} from "src/actions/mgmtCustomerCreateAction";



const initialState = {
    customerInfoList: [],
    customerpaymentInfoList:{},
    jsonData:{},
    customeradminInfoList:{}
};

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHCUSTOMER_INFO_SUCCESS: {
            const { customerInfoList } = action.payload;
            return produce(state, (draft) => {
                draft.customerInfoList = customerInfoList;
            });
        }
        case FETCHADMINCUSTOMERPAYMENT_INFO_SUCCESS: {
            const { customerpaymentInfoList } = action.payload;
            return produce(state, (draft) => {
                draft.customerpaymentInfoList = customerpaymentInfoList;
            });
        }
        case CUSTOMERJSONDOWNLOAD_UPDATE_SUCCESS: {
            const { jsonData } = action.payload;
            return produce(state, (draft) => {
                draft.jsonData = jsonData;
            });
        }
        case FETCHADMINCUSTOMER_INFO_SUCCESS: {
            const { customeradminInfoList } = action.payload;
            return produce(state, (draft) => {
                draft.customeradminInfoList = customeradminInfoList;
            });
        }
        default: {
            return state;
        }
    }
};

export default customerReducer;


