import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types';
import Delete from '@mui/icons-material/Delete';
import axios from 'src/utils/axios';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  IconButton,
  SvgIcon,
} from '@mui/material';
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon
} from 'react-feather';

import { dateFormatter } from './DateFormate';
import { useDispatch } from 'react-redux';


function Results({ className, greetings, ...rest }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {

  }, [greetings]);
  
  
  const handleDelete = async (customerId, greetId) => {
    try {
      const payload = {
        "customerId": customerId,
        "greetId": greetId
      }
      const deleteResult = await axios.post(`/api/admin/customer/info/delete/greeting`, payload);
      // console.log(`deleteResult: `, deleteResult);
      // setServices(services.filter((row) => row.greetId !== greetId));
    } catch (err) { }
  };

  const columns = [
    { field: 'customerId', headerName: 'Customer Id', width: 100 },
    { field: 'greetId', headerName: 'Greeting Id', width: 100 },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Tooltip title="Edit Service">
              <IconButton
                onClick={() => {
                  navigate('/app/management/greetings/edit', {state : params.row});
                }
                }
              >
                <SvgIcon fontSize="small">
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip><m>
              <Tooltip title="Delete Greeting">
                <IconButton
                  onClick={() => handleDelete(params.row.customerId, params.row.greetId)}
                >
                  <SvgIcon fontSize="small">
                    <Delete />
                  </SvgIcon>
                </IconButton>
              </Tooltip></m>
          </div>
        );
      },
    },
    { field: 'Title', headerName: 'Title', width: 200 },
    { field: 'ImageUrl', headerName: 'Image Url', width: 200 },
    { field: 'VideoUrl', headerName: 'Video Url', width: 200 },
    {
      field: "CreatedDate",
      headerName: "Created Date",
      width: 120,
      // type: 'date',
      valueGetter: (params) => dateFormatter(params.row.CreatedDate),
    },
  ];
  return (
    <Box sx={{ height: 420, width: '100%', padding: 2 }}>
      <DataGrid
        rows={greetings}
        columns={columns}
        getRowId={(row) => row.greetId}
        // checkboxSelection={true}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Box>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  greetings: PropTypes.array,
  onSubmitSuccess: PropTypes.func,
};

Results.defaultProps = {
  greetings: []
};

export default Results;