class SearchBusiness {
  constructor(info) {
 
    if(info && info?.customerId){
      this.customerId = info.customerId;
    }
    // this.pageNo = info.pageNo;
    // this.limit = info.limit;
    if(info && info?.categoryId){
      this.categoryId = info.categoryId;
    }
   
  }
}

export default { SearchBusiness };
export { SearchBusiness };



