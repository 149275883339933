import { SEARCH_ORDERS_URL, SEARCH_INVOICES_URL, UPDATE_ORDER_STATUS_URL } from 'src/constants/services';
import axios from 'src/utils/axios';

class OrderService {

  fetchOrders = (customerId) =>
    new Promise((resolve, reject) => {
      
       let searchUrl = `${SEARCH_ORDERS_URL}?customerId=${customerId}`;
      // let searchUrl = SEARCH_ORDER_URL;
      // let searchUrl = "/api/admin/order/by/customerId?customerId=668&orderId=23"

      axios
        .get(searchUrl)
        .then((resp) => {

          // console.log("OrderService: resp", resp)
          if (resp.data) {
            let orders = resp.data?.data?.orders;

            // console.log("OrderService.fetchOrders() ", orders);
            resolve(orders);
          } else {
            reject(resp.data.error);
          }
        })
        .catch((error) => {
          // console.log("OrderService.fetchOrders() (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    })

    fetchOrdersByCriteria = (customerId, params) =>
    new Promise((resolve, reject) => {
      
      // let searchUrl = `${SEARCH_ORDER_URL}?customerId=${customerId}`;
      let searchUrl = `${SEARCH_ORDERS_URL}?customerId=${customerId}${params}`;
      //let searchUrl = "/api/admin/order/by/customerId?customerId=668&orderId=23"


      axios
        .get(searchUrl)
        .then((resp) => {

          console.log("OrderService: resp", resp)
          if (resp.data) {
            let orders = resp.data?.data?.orders;

            // console.log("OrderService.fetchOrders() ", orders);
            resolve(orders);
          } else {
            reject(resp.data.error);
          }
        })
        .catch((error) => {
          // console.log("OrderService.fetchOrders() (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    })

    fetchInvoicesByCriteria = (customerId, params) =>
    new Promise((resolve, reject) => {
      let searchUrl = `${SEARCH_INVOICES_URL}?customerId=${customerId}${params}`;

      axios
        .get(searchUrl)
        .then((resp) => {

          console.log("OrderService: resp", resp)
          if (resp.data) {
            let orders = resp.data?.data?.invoices;

            // console.log("OrderService.fetchOrders() ", orders);
            resolve(orders);
          } else {
            reject(resp.data.error);
          }
        })
        .catch((error) => {
          // console.log("OrderService.fetchOrders() (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    })

    updateOrderStatus = (orderStatus) =>
    new Promise((resolve, reject) => {
      const payload = orderStatus;

      console.log("OrderService.updateOrderStatus:" + JSON.stringify(payload));

      axios
        .post(UPDATE_ORDER_STATUS_URL, payload)
        .then((response) => {
          console.log("OrderService(Response):" + JSON.stringify(response));

          if (response.data.payload) {
            let project = response.data.payload;
            resolve(project);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.createService (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

}

const orderService = new OrderService();
export default orderService;
