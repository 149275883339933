

export function prepareSalesWeekData( data ){

    // const datay1 = [5, 6, 2, 8, 9];
    // const datay2 = [1000, 1500, 3000, 5000, 10000];
    // const datax = [2010, 2011, 2012, 2013, 2014];

    const datay1 = [];
    const datay2 = [];
    const datax = [];

    // console.log("prepareSalesWeekData() ", data);

    data?.map((item) => {
        datay1.push(item.totalOrders);
        datay2.push(item.totalSales);
        datax.push(item._id);
    });

    return { datay1, datay2, datax};


}